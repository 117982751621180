import type { ChartConfiguration } from "chart.js";
import { useMetrics } from "./data";

export enum ChartType {
  Line = "line",
  // Bar = 'bar',
  // Scatter = 'scatter',
  // Bubble = 'bubble',
  // Pie = 'pie',
  // Doughnut = 'doughnut',
  // PolarArea = 'polarArea',
  // Radar = 'radar',
}

export enum MetricInterval {
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
  WEEKS = "WEEKS",
  MONTHS = "MONTHS",
  YEARS = "YEARS",
}

export enum MetricType {
  TREND = "TREND",
  VALUE = "VALUE",
  PARTITION = "PARTITION",
}

export enum MetricAggregateFunction {
  SUM = "SUM",
  MIN = "MIN",
  MAX = "MAX",
  AVG = "AVG",
  COUNT = "COUNT",
}

export type ChartTypeLike = ChartType | "LINE" | "line";

export type MetricIntervalLike =
  | MetricInterval
  | "MINUTES"
  | "HOURS"
  | "DAYS"
  | "WEEKS"
  | "MONTHS"
  | "YEARS"
  | "minutes"
  | "hours"
  | "days"
  | "weeks"
  | "months"
  | "years";

export type MetricTypeLike =
  | MetricType
  | "TREND"
  | "VALUE"
  | "PARTITION"
  | "trend"
  | "value"
  | "partition";

export type MetricAggregateFunctionLike =
  | MetricAggregateFunction
  | "SUM"
  | "MIN"
  | "MAX"
  | "AVG"
  | "COUNT"
  | "sum"
  | "min"
  | "max"
  | "avg"
  | "count";

export interface MetricDataset {
  label: string;
  labels: string[];
  data: number[];
  value: number;
  meta?: any;
}

export interface MetricGrowthRate {
  value: number;
  trendUp: boolean;
  unit: MetricUnit;
}

export interface MetricUnit {
  label: string;
  prefix: boolean;
}

export interface MetricResult<T extends string> {
  handle: T;
  type: MetricType;
  labels: string[];
  datasets: MetricDataset[];
  growthRate?: MetricGrowthRate;
  unit?: MetricUnit;
  meta?: any;
}

export enum MetricsDataSourceType {
  Db = "orie:db",
  Posthog = "orie:posthog",
}

export type MetricsDataSource_Db<M extends string> = {
  source: MetricsDataSourceType.Db;
  /** Local ref handle for the metric. Defaults to name */
  handle?: M;
  /** Backend name of the metrics */
  name: string;
  type?: MetricTypeLike;
  args?: Record<string, any>;
  aggregate?: MetricAggregateFunctionLike;
  precision?: number;
  growthRate?: boolean;
  interval?: MetricIntervalLike;
  column?: string;
  where?: Record<string, any>;
};

export type MetricsDataSource_Posthog<M extends string> = {
  source: MetricsDataSourceType.Posthog;
  name: M;
  query: string;
};

export type MetricsDataSource<M extends string> =
  | MetricsDataSource_Db<M>
  | MetricsDataSource_Posthog<M>;

export type MetricsDataSourceInput<M extends string> = {
  // title?: string;
  interval?: MetricInterval;
  precision?: number;
  growthRate?: boolean;
  comparisons?: string[];
  since?: string;
  until?: string;
  metrics: MetricsDataSource<M>[];
};

export type MetricsContext = ReturnType<typeof useMetrics>;

export interface BaseChartOptions {
  group?: string;
  syncCursor?: boolean;
  legend?: boolean;
  xAxes?: boolean;
  xGridLines?: boolean;
  beginAtZero?: boolean;
}

export interface TrendChartOptions extends BaseChartOptions {
  // title?: string
  // trendSum?: boolean
  // trendSumPrefix?: string
  // trendSumSuffix?: string
}

type _ChartOptions = {
  [ChartType.Line]: TrendChartOptions;
};

export type ChartOptions<T extends ChartType> = _ChartOptions[T];

export interface IChart<T extends ChartType> {
  elId?: string;
  cmpName?: string;
  type: T;
  plugins?: ChartConfiguration<T>["plugins"][];
  options: ChartOptions<T>;
}

export type MetricsControls = {
  interval?: MetricInterval;
  precision?: number;
  growthRate?: boolean;
  comparisons?: string[];
  since?: string;
  until?: string;
};

export type GenericMetricsDataSource =
  | Record<string, Omit<MetricsDataSource<string>, "handle">>
  | MetricsDataSource<string>[]
  | string[]
  | string;

export type GroupMetricsItem = {
  title: string;
  /**
   * Refs the name/handle of the value source
   */
  value: string;
  /**
   * Include metric unit in the value
   */
  withUnit?: boolean;
  /**
   *Format the metric value as a price
   */
  formatPrice?: boolean;
  /**
   * Display spark line from a trend source. Refs the name/handle
   * of the source listed in `sources`.
   */
  trend?: string;
  sources: GenericMetricsDataSource;
};
