import { isFunction, isNil } from "lodash";
import { v4 as uuidv4 } from "uuid";

export const withValue = <T, R>(
  value: T | null | undefined,
  cb: { (v: T): R }
): R | undefined => {
  if (!isNil(value)) {
    return cb(value);
  }
};

export const withDisplayValue = <T, R>(
  value: T | null | undefined,
  cb?: { (v: T): R },
  defu = "—"
) => {
  if (cb && !isNil(value)) {
    return cb(value) || defu;
  }
  return value || defu;
};

export function useIf<T extends boolean, V>(
  condition: T,
  value: { (): V } | V
): T extends true ? V : undefined {
  if (condition) {
    return (isFunction(value) ? value() : value) as any;
  }
  return undefined as any;
}

export function once<A extends any[], R, C>(
  this: C,
  fn: {
    (...a: A): R;
  }
): { (...a: A): R } {
  let result: R;
  let called = false;
  return function (this: C, ...a: A): R {
    if (!called) {
      result = fn.apply(this, a);
      called = true;
    }
    return result;
  };
}

/**
 * A custom debounce method for async.
 * Whenever the target is eventually called, we resolve/reject all source calls.
 *
 * @param fn
 * @param timeout
 * @returns
 */
export function asyncDebounce<A extends any[], R>(
  fn: { (...a: A): Promise<R> },
  timeout = 300
) {
  type P = {
    resolve: (value: R | PromiseLike<R>) => void;
    reject: (reason?: any) => void;
  };
  let timer: NodeJS.Timeout;
  let promises: P[] = [];

  return function (...a: A): Promise<R> {
    clearTimeout(timer);

    timer = setTimeout(() => {
      fn(...a)
        .then((r) => {
          for (const { resolve } of promises) {
            resolve(r);
          }
        })
        .catch((e) => {
          for (const { reject } of promises) {
            reject(e);
          }
        })
        .finally(() => {
          promises = [];
        });
    }, timeout);

    return new Promise<R>((resolve, reject) => {
      promises.push({ resolve, reject });
    });
  };
}

export function pQueue<A extends any[], R>(fn: { (...a: A): Promise<R> }) {
  type P = {
    resolve: (value: R | PromiseLike<R>) => void;
    reject: (reason?: any) => void;
  };
  const handlers = new Map<string, P>();
  const promises = new Map<string, Promise<R>>();
  const ids: string[] = [];
  let started = false;

  return function (...a: A): Promise<R> {
    const id = uuidv4();
    const p = fn(...a);

    ids.push(id);
    promises.set(id, p);

    const next = async () => {
      const n = ids.shift();
      if (n) {
        const p = promises.get(n)!;
        const h = handlers.get(n)!;
        p.then(h.resolve).catch(h.reject).finally(next);
      } else {
        started = false;
      }
    };

    return new Promise<R>((resolve, reject) => {
      handlers.set(id, { resolve, reject });

      if (!started) {
        started = true;
        next();
      }
    });
  };
}

export function beforeEach<A extends any[], R>(
  fn: { (...a: A): R },
  hk: { (...a: A): any }
) {
  return (...a: A): R => {
    hk(...a);
    return fn(...a);
  };
}

export function afterEach<A extends any[], R>(
  fn: { (...a: A): Promise<R> | R },
  hk: { (r: R, ...a: A): any }
) {
  return async (...a: A): Promise<R> => {
    const r = await Promise.resolve(fn(...a));
    hk(r, ...a);
    return r;
  };
}

export const timeout = <R>(
  fn: { (): Promise<R> },
  ms: number,
  errorMsg = "Method timeout"
) => {
  return new Promise<R>((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error(errorMsg));
    }, ms);

    const p = fn();

    p.then((r) => {
      clearTimeout(timer);
      resolve(r);
    }).catch((error) => {
      clearTimeout(timer);
      reject(error);
    });
  });
};

export const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const retry = <R>(
  fn: { (): Promise<R> },
  attempts: number = 5,
  delayMs = 250
) => {
  const doRetry = (op: { (): Promise<any> }, tries: number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      return op()
        .then(resolve)
        .catch((err) => {
          if (tries > 0) {
            return wait(delayMs)
              .then(doRetry.bind(null, op, tries - 1))
              .then(resolve)
              .catch(reject);
          }
          return reject(err);
        });
    });
  };

  return () => doRetry(fn, attempts);
};

/**
 * Wrap fn so that whenever its called, fn2 is also called.
 *
 * @param fn
 * @param fn2
 * @returns
 */
export const wrapCalls = <A extends any[], R>(
  fn: { (...a: A): R },
  fn2: { (): void }
) => {
  return (...a: A): R => {
    const x = fn(...a);
    if (x instanceof Promise) {
      const p = new Promise((resolve, reject) => {
        x.then((a) => {
          resolve(a);
          fn2();
        }).catch(reject);
      });
      return p as any;
    }

    fn2();
    return x;
  };
};

/**
 * Wrap the provided function around a controller logic.
 * This can be used to externally disabled forwarding calls.
 *
 * @param fn
 * @returns
 */
export const guard = <A extends any[], R>(fn: { (...a: A): R }) => {
  let disabled = false;
  let queueing = false;
  let queue: A[] = [];

  function f(...a: A): R | void {
    if (!disabled) {
      return fn(...a);
    } else if (queueing) {
      queue.push(a);
    }
  }

  const flush = () => {
    queue.forEach((a) => {
      fn(...a);
    });
    queue = [];
  };

  const ctx = {
    disable() {
      disabled = true;
      queueing = false;
    },
    queue() {
      queueing = true;
      disabled = true;
    },
    enable() {
      const shouldFlush = queueing;
      disabled = false;
      queueing = false;
      if (shouldFlush) {
        flush();
      }
    },
    get disabled() {
      return disabled;
    },
    get queued() {
      return queueing;
    },
  };

  return Object.assign(f, ctx);
};

/**
 * Only call the given function if arguments are unique from previous.
 * @param fn
 * @returns
 */
export const wrapUniqueCalls = <A extends any[], R>(fn: { (...a: A): R }) => {
  let prev: any[];
  let result: any;

  const isSameArgs = (a: any[], b: any[]) => {
    if (a.length != b.length) return false;
    return !a.some((x, i) => !equals(x, b[i]));
  };

  return (...a: A): R => {
    const shouldCall = !prev || !isSameArgs(prev, a);
    prev = a;
    if (shouldCall) {
      result = fn(...a);
    }
    return result;
  };
};

export const execFn = <T>(fn: { (): T }): T => {
  return fn();
};
