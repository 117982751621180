export type PlaceAddress = {
  street: string;
  street_extra?: string;
  city?: string;
  country?: string;
  country_code?: string;
  latitude?: number;
  longitude?: number;
  postal_code?: string;
  province?: string;
};

export function stringifyAddress(data: PlaceAddress): string | null {
  if (typeof data !== "object") return null;

  let address = data.street;

  if (data.street_extra) address += ", " + data.street_extra;
  // if (data.city) address += ', ' + data.city
  if (address && (data.province || data.postal_code || data.country)) {
    address += ",";
  }
  if (data.province) address += " " + data.province;
  if (data.postal_code) address += " " + data.postal_code;
  if (!address) return null;
  if (data.country) address += " " + getCountryName(data.country);

  return address;
}
