import { min } from 'lodash'
import { BioSiteStyleBackgroundType } from './background'
import type {
  BioSiteStyleBackground,
  BioSiteStyleBackgroundFlatColor,
  BioSiteStyleBackgroundGradient,
  BioSiteStyleBackgroundImage,
  BioSiteStyleBackgroundVideo,
  BioSiteStyleBackgroundAnkara,
  BioSiteStyleBackgroundAnimation,
  BioSiteStyleBackgroundHtml,
  BioSiteStyleBackgroundTexture,
  ScrimableBackground,
} from './background'
import {
  BioSiteSectionType,
  BioSiteStyleSectionStyle,
  BioSiteStyleSectionRadius,
  BioSiteStyleSectionShadow,
  BioSiteStyleSectionOutline,
} from './section'
import type {
  BioSiteSection,
  BioSiteSectionSocials,
  BioSiteSectionConfigurable,
  BioSiteSectionHeading,
  BioSiteSectionLink,
  BioSiteSectionText,
  BioSiteSectionGallery,
  BioSiteSectionService,
  BioSiteSectionEvent,
  BioSiteSectionCollections,
  BioSiteSectionLatestEvent,
  BioSiteSectionAbout,
  BioSiteSectionCollectEmail,
  BioSiteSectionLocation,
  BioSiteSectionContact,
  BioSiteSectionReviews,
  BioSiteSectionSpotify,
  BioSiteSectionAppleMusic,
  BioSiteSectionSoundcloud,
  BioSiteSectionYoutube,
  BioSiteSectionVimeo,
  BioSiteSectionTwitch,
  BioSiteSectionInstagram,
  BioSiteSectionTiktok,
  BioSiteSectionXTwitter,
  BioSiteSectionFacebook,
  BioSiteSectionPinterest,
  BioSiteSectionTelegram,
  BioSiteSectionProduct,
  BioSiteStyleSection,
  BioSiteSectionFile,
  BioSiteSectionProducts,
  BioSiteSectionServices,
  BioSiteSectionCheckout,
} from './section'
import { BioSiteStyleFontType } from './fonts'
import type {
  BioSiteSectionThumb,
  BioSiteSectionThumbImage,
  BioSiteSectionThumbIcon,
} from './thumb'
import { BioSiteSectionThumbType } from './thumb'

export const DefaultScrimOpacity = 0.3
export const DefaultTextFont = BioSiteStyleFontType.Roboto
export const DefaultTextColor = '#060606'

export const isHeadingSection = (
  value?: BioSiteSection
): value is BioSiteSectionHeading => {
  return value?.type === BioSiteSectionType.Heading
}

export const isLinkSection = (
  value?: BioSiteSection
): value is BioSiteSectionLink => {
  return value?.type === BioSiteSectionType.Link
}

export const isSocialsSection = (
  value?: BioSiteSection
): value is BioSiteSectionSocials => {
  return value?.type === BioSiteSectionType.Socials
}

export const isTextSection = (
  value?: BioSiteSection
): value is BioSiteSectionText => {
  return value?.type === BioSiteSectionType.Text
}

export const isGallerySection = (
  value?: BioSiteSection
): value is BioSiteSectionGallery => {
  return value?.type === BioSiteSectionType.Gallery
}

export const isProductSection = (
  value?: BioSiteSection
): value is BioSiteSectionProduct => {
  return value?.type === BioSiteSectionType.Product
}

export const isServiceSection = (
  value?: BioSiteSection
): value is BioSiteSectionService => {
  return value?.type === BioSiteSectionType.Service
}

export const isEventSection = (
  value?: BioSiteSection
): value is BioSiteSectionEvent => {
  return value?.type === BioSiteSectionType.Event
}

export const isProductsSection = (
  value?: BioSiteSection
): value is BioSiteSectionProducts => {
  return value?.type === BioSiteSectionType.Products
}

export const isServicesSection = (
  value?: BioSiteSection
): value is BioSiteSectionServices => {
  return value?.type === BioSiteSectionType.Services
}

export const isCollectionsSection = (
  value?: BioSiteSection
): value is BioSiteSectionCollections => {
  return value?.type === BioSiteSectionType.Collections
}

export const isLatestEventSection = (
  value?: BioSiteSection
): value is BioSiteSectionLatestEvent => {
  return value?.type === BioSiteSectionType.LatestEvent
}

export const isCheckoutSection = (
  value?: BioSiteSection
): value is BioSiteSectionCheckout => {
  return value?.type === BioSiteSectionType.Checkout
}

export const isAboutSection = (
  value?: BioSiteSection
): value is BioSiteSectionAbout => {
  return value?.type === BioSiteSectionType.About
}

export const isCollectEmailSection = (
  value?: BioSiteSection
): value is BioSiteSectionCollectEmail => {
  return value?.type === BioSiteSectionType.CollectEmail
}

export const isLocationSection = (
  value?: BioSiteSection
): value is BioSiteSectionLocation => {
  return value?.type === BioSiteSectionType.Location
}

export const isContactSection = (
  value?: BioSiteSection
): value is BioSiteSectionContact => {
  return value?.type === BioSiteSectionType.Contact
}

export const isReviewsSection = (
  value?: BioSiteSection
): value is BioSiteSectionReviews => {
  return value?.type === BioSiteSectionType.Reviews
}

export const isFileSection = (
  value?: BioSiteSection
): value is BioSiteSectionFile => {
  return value?.type === BioSiteSectionType.File
}

export const isSpotifySection = (
  value?: BioSiteSection
): value is BioSiteSectionSpotify => {
  return value?.type === BioSiteSectionType.Spotify
}

export const isAppleMusicSection = (
  value?: BioSiteSection
): value is BioSiteSectionAppleMusic => {
  return value?.type === BioSiteSectionType.AppleMusic
}

export const isSoundcloudSection = (
  value?: BioSiteSection
): value is BioSiteSectionSoundcloud => {
  return value?.type === BioSiteSectionType.Soundcloud
}

export const isYoutubeSection = (
  value?: BioSiteSection
): value is BioSiteSectionYoutube => {
  return value?.type === BioSiteSectionType.Youtube
}

export const isVimeoSection = (
  value?: BioSiteSection
): value is BioSiteSectionVimeo => {
  return value?.type === BioSiteSectionType.Vimeo
}

export const isTwitchSection = (
  value?: BioSiteSection
): value is BioSiteSectionTwitch => {
  return value?.type === BioSiteSectionType.Twitch
}

export const isInstagramSection = (
  value?: BioSiteSection
): value is BioSiteSectionInstagram => {
  return value?.type === BioSiteSectionType.Instagram
}

export const isTiktokSection = (
  value?: BioSiteSection
): value is BioSiteSectionTiktok => {
  return value?.type === BioSiteSectionType.Tiktok
}

export const isTwitterSection = (
  value?: BioSiteSection
): value is BioSiteSectionXTwitter => {
  return value?.type === BioSiteSectionType.XTwitter
}

export const isFacebookSection = (
  value?: BioSiteSection
): value is BioSiteSectionFacebook => {
  return value?.type === BioSiteSectionType.Facebook
}

export const isPinterestSection = (
  value?: BioSiteSection
): value is BioSiteSectionPinterest => {
  return value?.type === BioSiteSectionType.Pinterest
}

export const isTelegramSection = (
  value?: BioSiteSection
): value is BioSiteSectionTelegram => {
  return value?.type === BioSiteSectionType.Telegram
}

export const isConfigurableSection = (
  value?: BioSiteSection
): value is BioSiteSectionConfigurable => {
  const disallowed = [BioSiteSectionType.Heading, BioSiteSectionType.Socials]
  return !disallowed.includes(value?.type!)
}

export const isFlatColorBackground = (
  value?: BioSiteStyleBackground
): value is BioSiteStyleBackgroundFlatColor => {
  return value?.type === BioSiteStyleBackgroundType.FlatColor
}

export const isGradientBackground = (
  value?: BioSiteStyleBackground
): value is BioSiteStyleBackgroundGradient => {
  return value?.type === BioSiteStyleBackgroundType.Gradient
}

export const isImageBackground = (
  value?: BioSiteStyleBackground
): value is BioSiteStyleBackgroundImage => {
  return value?.type === BioSiteStyleBackgroundType.Image
}

export const isVideoBackground = (
  value?: BioSiteStyleBackground
): value is BioSiteStyleBackgroundVideo => {
  return value?.type === BioSiteStyleBackgroundType.Video
}

export const isAnkaraBackground = (
  value?: BioSiteStyleBackground
): value is BioSiteStyleBackgroundAnkara => {
  return value?.type === BioSiteStyleBackgroundType.Ankara
}

export const isAnimationBackground = (
  value?: BioSiteStyleBackground
): value is BioSiteStyleBackgroundAnimation => {
  return value?.type === BioSiteStyleBackgroundType.Animation
}

export const isTextureBackground = (
  value?: BioSiteStyleBackground
): value is BioSiteStyleBackgroundTexture => {
  return value?.type === BioSiteStyleBackgroundType.Textures
}

export const isHtmlBackground = (
  value?: BioSiteStyleBackground
): value is BioSiteStyleBackgroundHtml => {
  return value?.type === BioSiteStyleBackgroundType.Html
}

export const usesColor = (value?: BioSiteStyleBackground) => {
  const allowed = [
    BioSiteStyleBackgroundType.FlatColor,
    BioSiteStyleBackgroundType.Textures,
  ]
  return allowed.includes(value?.type!)
}

export const usesScrims = (
  value?: BioSiteStyleBackground
): value is ScrimableBackground => {
  const allowed = [
    BioSiteStyleBackgroundType.Image,
    BioSiteStyleBackgroundType.Video,
    BioSiteStyleBackgroundType.Ankara,
    BioSiteStyleBackgroundType.Animation,
    BioSiteStyleBackgroundType.Textures,
  ]
  return allowed.includes(value?.type!)
}

export const getScrimOpacity = (bg: ScrimableBackground): number => {
  const o = bg.scrimOpacity || DefaultScrimOpacity
  return min([o, 1]) || 0
}

export const sectionStyleDefault = (
  style?: Partial<BioSiteStyleSection>
): BioSiteStyleSection => ({
  style: BioSiteStyleSectionStyle.Plain,
  rounded: BioSiteStyleSectionRadius.Default,
  shadow: BioSiteStyleSectionShadow.Soft,
  outline: BioSiteStyleSectionOutline.Outline,
  ...style,
})

export const defineRegexRule = (name: string, regex: RegExp): string => {
  return `custom:${name}:${regex.source}`
}

export const isRegexRule = (rule: string) => {
  const [code, name, source] = rule.split(':')
  return code == 'custom' && !!name && !!source
}

export const parseRegexRule = (rule: string) => {
  const [_, name, source] = rule.split(':')
  return {
    name,
    regex: new RegExp(source),
  }
}

export const isImageThumb = (
  v?: BioSiteSectionThumb
): v is BioSiteSectionThumbImage => {
  return v?.type == BioSiteSectionThumbType.Image
}

export const isIconThumb = (
  v?: BioSiteSectionThumb
): v is BioSiteSectionThumbIcon => {
  return v?.type == BioSiteSectionThumbType.Icon
}
