import dayjs, { Dayjs } from "dayjs";
import type { MetricsPeriod } from "../types/common";

export const now = () => dayjs();

export const parseTime = (time?: string) => {
  const [h, m, s] = (time || "").split(":");
  const f = (v: any) => Number(v || 0);

  return {
    hours: f(h),
    minutes: f(m),
    seconds: f(s),
  };
};

export const parseTimeToDate = (
  time: string | undefined,
  offset?: dayjs.Dayjs
) => {
  const { hours, minutes, seconds } = parseTime(time);

  return (offset || now())
    .startOf("day")
    .add(hours, "hours")
    .add(minutes, "minutes")
    .add(seconds, "seconds");
};

export const dateMinutes = (date: DateLike) => {
  const d = parseDateLike(date);

  const h = d.hour();
  const m = d.minute();

  return h * 60 + m;
};

export const parseMetricsPeriod = (period: MetricsPeriod | string): Dayjs => {
  return match(period, {
    today: () => now(),
    // yesterday: () => now().subtract(1, "day"),
    "previous-period": () => now().subtract(1, "day"),
    "previous-hour": () => now().subtract(1, "hour"),
    "previous-day": () => now().subtract(1, "day"),
    "previous-week": () => now().subtract(1, "week"),
    "previous-month": () => now().subtract(1, "month"),
    "previous-quarter": () => now().subtract(3, "months"),
    "previous-year": () => now().subtract(1, "year"),
    default: () => dayjs(period),
  });
};

export const findRelevantDate = <T extends string | Dayjs>(dates: T[]) => {
  const n = now();
  let earliestFutureDate: Dayjs | undefined;
  let latestPastDate: Dayjs | undefined;

  dates.forEach((d) => {
    const date = dayjs(d);

    if (date.isAfter(n)) {
      if (!earliestFutureDate || date.isBefore(earliestFutureDate)) {
        earliestFutureDate = date;
      }
    } else {
      if (!latestPastDate || date.isAfter(latestPastDate)) {
        latestPastDate = date;
      }
    }
  });

  return earliestFutureDate ? earliestFutureDate : latestPastDate;
};

export const isActivePeriod = (start: DateLike, end: DateLike) => {
  const s = parseDateLike(start);
  const e = parseDateLike(end);
  const n = now();

  return (s.isSame(n) || s.isBefore(n)) && (s.isSame(n) || s.isAfter(n));
};
