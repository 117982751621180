import { get } from "lodash";
// @ts-ignore
import currencySymbolMap from "currency-symbol-map/map";

const cache: Record<string, string> = {};

export default function currencySymbol(
  currency: string | undefined,
  $default?: string
): string | undefined {
  if (!currency) return $default;
  currency = currency.toUpperCase();

  if (!cache[currency]) {
    cache[currency] = get(currencySymbolMap, currency);
  }

  return cache[currency] || $default || currency;
}
