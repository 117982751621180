import { get, isString, omit, set } from "lodash";
import {
  MediaFileType,
  type BrowseData,
  type MediaFile,
  type MediaFolder,
  type SearchFilter,
} from "./types";
import { getFolderLocation, isFolder, isImage } from "./utils";

const searchFilter: SearchFilter = (
  item: MediaFile,
  filterText: string | undefined,
  filterTypes: MediaFileType[]
) => {
  function filterType(type: MediaFileType, types: MediaFileType[]) {
    return !types.length || (types.length && types.includes(type));
  }
  if (!(filterText && filterText.length))
    return filterType(item.type || MediaFileType.OTHER, filterTypes);

  return (
    item.name.toLowerCase().includes(filterText.toLowerCase()) &&
    !!filterType(item.type || MediaFileType.OTHER, filterTypes)
  );
};

export const useMediaLibraryStore = defineStore("media-library", () => {
  const props = ref({
    acceptUpload: [] as string[],
    searchFilter,
  });

  const location = ref<string>("");
  const locations = ref<Record<string, string>>({});
  const _loading = ref({
    browsing: false,
    uploading: false,
    creatingFolder: false,
    downloading: false,
    deleting: false,
  });
  const filterFileSearch = ref<string>();
  const filterFileTypes = ref<MediaFileType[]>([]);
  const files = ref<Record<string, MediaFile>>({});
  const browse = ref<Record<string, string[]>>({});
  const pagination = ref<Record<string, BrowseData["paginate"]>>({});
  const selection = ref<any>();
  const active = ref<MediaFile>();

  const loading = computed(() => Object.values(_loading.value).some((v) => v));
  const browsing = computed(() => _loading.value.browsing);
  const uploading = computed(() => _loading.value.uploading);
  const creatingFolder = computed(() => _loading.value.creatingFolder);
  const downloading = computed(() => _loading.value.downloading);
  const deleting = computed(() => _loading.value.deleting);

  const selectItem = computed((): MediaFile | MediaFile[] | undefined => {
    if (!selection.value) return undefined;
    if (Array.isArray(selection.value)) {
      return selection.value.map((selection) =>
        isString(selection) ? files.value[selection] : selection
      );
    }

    return isString(selection.value)
      ? files.value[selection.value]
      : selection.value;
  });

  const getFiles = (): MediaFile[] => {
    return getLocationFiles(location.value);
  };

  const filterFiles = (files: MediaFile[]): MediaFile[] =>
    files.filter((item) =>
      props.value.searchFilter(
        item,
        filterFileSearch.value,
        filterFileTypes.value
      )
    );

  const getLocationFiles = (location: string): MediaFile[] => {
    const ids: string[] = get(browse.value, location, []);
    return ids.map((id) => get(files.value, id)).filter((file) => !!file);
  };

  const getLocationPagination = (location: string) =>
    get(pagination.value, location);

  const getFileBrowseLocation = (file: MediaFile): string | undefined => {
    const entry = Object.entries(browse.value).find(([_loc, ids]) =>
      ids.includes(file.id)
    );
    if (entry) {
      const [location] = entry;
      return location;
    }
    return undefined;
  };

  const removeFileFromBrowseLocation = (file: MediaFile) => {
    const location = getFileBrowseLocation(file);
    if (isString(location)) {
      deleteAt(browse.value[location], browse.value[location].indexOf(file.id));
    }
  };

  const selecting = computed(() =>
    Array.isArray(selection.value)
      ? !!selection.value.length
      : !!selection.value
  );

  const locationFiles = computed(() => getLocationFiles(location.value));
  const locationPagination = computed(() =>
    getLocationPagination(location.value)
  );
  const _pagination = computed(
    () =>
      (get(pagination.value, location.value) || {}) as typeof pagination.value
  );
  const mediaFiles = computed(() => filterFiles(getFiles()));
  const folders = computed(() =>
    filterFiles(getFiles()).filter((f: MediaFile) => isFolder(f))
  );
  const _files = computed(() =>
    filterFiles(getFiles()).filter((f: MediaFile) => !isFolder(f))
  );
  const images = computed(() =>
    filterFiles(getFiles()).filter((f: MediaFile) => isImage(f))
  );
  const noneImageFiles = computed(() =>
    filterFiles(getFiles()).filter(
      (f: MediaFile) => !isFolder(f) && !isImage(f)
    )
  );
  const isEmpty = computed(() => !getFiles().length);
  const noResults = computed(() => !filterFiles(getFiles()).length);
  const _props = computed(() => {
    let types: string[] = [];
    if (isString(props.value.acceptUpload)) {
      types = props.value.acceptUpload.split(",");
    } else if (props.value.acceptUpload) {
      types = props.value.acceptUpload;
    }

    return {
      ...props.value,
      acceptUpload: types
        .map((type) => {
          if (type === MediaFileType.IMAGE) return "image/*";
          if (type === MediaFileType.AUDIO) return "audio/*";
          if (type === MediaFileType.VIDEO) return "video/*";
          return type;
        })
        .join(","),
    };
  });

  const setBrowsing = (loading: boolean) => {
    _loading.value.browsing = loading;
  };
  const setUploading = (loading: boolean) => {
    _loading.value.uploading = loading;
  };
  const setCreatingFolder = (loading: boolean) => {
    _loading.value.creatingFolder = loading;
  };
  const setDownloading = (loading: boolean) => {
    _loading.value.downloading = loading;
  };
  const setDeleting = (loading: boolean) => {
    _loading.value.deleting = loading;
  };
  const setLoading = (key: keyof typeof _loading.value, loading: boolean) => {
    _loading.value[key] = loading;
  };

  const select = (item: any) => {
    selection.value = item;
  };
  const unselect = () => {
    selection.value = undefined;
  };

  const setBrowseFiles = (location: string, inputFiles: MediaFile[]) => {
    const ids = inputFiles.map((file) => file.id);
    set(browse.value, location, ids);
    for (const file of inputFiles) {
      set(files.value, file.id, file);
    }
  };
  const setBrowsePagination = (
    location: string,
    data: BrowseData["paginate"]
  ) => {
    set(pagination.value, location, data);
  };
  const addBrowseFiles = (location: string, inputFiles: MediaFile[]) => {
    const ids = get(browse.value, location, [] as string[]).concat(
      inputFiles.map((file) => file.id)
    );
    set(browse.value, location, ids);
    for (const file of inputFiles) {
      set(files.value, file.id, file);
    }
  };
  const addMediaFiles = (
    files: MediaFile[],
    inputLocation?: string
  ): MediaFile[] => {
    addBrowseFiles(
      inputLocation === undefined ? location.value : inputLocation,
      files
    );
    return getFiles();
  };
  const removeMediaFiles = (inputFiles: MediaFile[]): MediaFile[] => {
    for (const file of inputFiles) {
      if (files.value[file.id]) {
        removeFileFromBrowseLocation(file);
        files.value = omit(files.value, [file.id]);
      }
    }
    return getFiles();
  };
  const getFile = (id: string) => {
    return get(files.value, id);
  };
  const setProps = (input: Partial<typeof props.value>) => {
    props.value = {
      ...props.value,
      ...props,
    };
  };
  const moveBrowse = (file: MediaFile, destination: string | MediaFolder) => {
    const location = isString(destination)
      ? destination
      : getFolderLocation(destination);

    removeFileFromBrowseLocation(file);

    if (!browse.value[location]) {
      set(browse.value, location, []);
    }

    if (!browse.value[location].includes(file.id)) {
      browse.value[location].push(file.id);
    }
  };
  const updateFile = (fileId: string, data: MediaFile) => {
    // const updatedFile = Object.assign(state.files[fileId] || {}, data);
    // Vue.set(state.files, fileId, updatedFile);
    set(files.value, fileId, data);
    return data;
  };

  return {
    filterFileSearch,
    filterFileTypes,
    browse,
    location,
    loading,
    browsing,
    uploading,
    creatingFolder,
    downloading,
    deleting,
    selection,
    selectItem,
    selecting,
    active,
    locationFiles,
    locationPagination,
    pagination: _pagination,
    mediaFiles,
    folders,
    files: _files,
    images,
    noneImageFiles,
    isEmpty,
    noResults,
    props: _props,
    getLocationFiles,
    getLocationPagination,
    setBrowsing,
    setUploading,
    setCreatingFolder,
    setDownloading,
    setDeleting,
    setLoading,
    select,
    unselect,
    setBrowseFiles,
    setBrowsePagination,
    addBrowseFiles,
    addMediaFiles,
    removeMediaFiles,
    getFile,
    setProps,
    moveBrowse,
    updateFile,
  };
});

export type MediaLibraryStore = ReturnType<typeof useMediaLibraryStore>;
