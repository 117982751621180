import { id, integer, model, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, Pricing, datetime } from "~/layers/admin/models/dobby";
import Publisher from "./Publisher";
import SalesChannel from "./SalesChannel";

export default class App extends Model {
  static entity = "App";
  static primaryKey = "handle";
  static queryAttributes = ["handle", "name"];

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @datetime() declare readonly published_at?: Dayjs;
  @id() declare readonly id: string;
  @id() declare readonly handle: string;
  @string() declare name: string;
  @string() declare short_description?: string;
  @string() declare description?: string;
  @integer() declare trial_period: number;
  @string() declare website?: string;
  @string() declare support_privacy_url?: string;
  @string() declare support_website?: string;
  @string() declare support_phone?: string;
  @string() declare support_faq_url?: string;
  @string() declare privacy_policy_url?: string;
  @string() declare support_url?: string;
  @string() declare support_email?: string;

  @model(() => Pricing) declare pricing?: Pricing;
  // image, gallery, features,

  @model(() => Publisher) declare readonly publisher: Publisher;
  @model(() => SalesChannel, { default: [], list: true })
  declare channels: SalesChannel[];
  // @model(() => FulfillmentService, { default: [], list: true })
  // declare fulfillmentServices: FulfillmentService[]
}
