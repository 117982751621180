export enum BioSiteStyleBackgroundType {
  FlatColor = 'flatColor',
  Gradient = 'gradient',
  Image = 'image',
  Video = 'video',
  Ankara = 'ankara',
  Animation = 'animation',
  Textures = 'textures',
  Html = 'html', //  used by themes
}

export interface ScrimableBackground {
  type: BioSiteStyleBackgroundType
  scrim: boolean
  scrimColor?: string
  scrimOpacity?: number
}

export interface BioSiteStyleBackgroundFlatColor {
  type: BioSiteStyleBackgroundType.FlatColor
  color: string
}

export interface BioSiteStyleBackgroundGradient {
  type: BioSiteStyleBackgroundType.Gradient
  gradient?: string | 'custom' // used by presets
  color1?: string
  color2?: string
  direction: 'up' | 'down'
}

export interface BioSiteStyleBackgroundImage extends ScrimableBackground {
  type: BioSiteStyleBackgroundType.Image
  image: string
}

export interface BioSiteStyleBackgroundVideo extends ScrimableBackground {
  type: BioSiteStyleBackgroundType.Video
  video: string
}

export interface BioSiteStyleBackgroundAnkara extends ScrimableBackground {
  type: BioSiteStyleBackgroundType.Ankara
  ankara: string
}

export interface BioSiteStyleBackgroundAnimation extends ScrimableBackground {
  type: BioSiteStyleBackgroundType.Animation
  animation: string
}

export interface BioSiteStyleBackgroundTexture extends ScrimableBackground {
  type: BioSiteStyleBackgroundType.Textures
  texture: string
  color: string
}

export interface BioSiteStyleBackgroundHtml {
  type: BioSiteStyleBackgroundType.Html
  html: string
}

export type BioSiteStyleBackground =
  | ScrimableBackground
  | BioSiteStyleBackgroundFlatColor
  | BioSiteStyleBackgroundGradient
  | BioSiteStyleBackgroundImage
  | BioSiteStyleBackgroundVideo
  | BioSiteStyleBackgroundAnkara
  | BioSiteStyleBackgroundAnimation
  | BioSiteStyleBackgroundTexture
  | BioSiteStyleBackgroundHtml

export enum TextureGroup {
  Tile = 'tile',
  Lines = 'lines',
  Checkered = 'checkered',
  Geometric = 'geometric',
  Paper = 'paper',
  Wood = 'wood',
  Brick = 'brick',
  Wall = 'wall',
  Cloth = 'cloth',
  Fabric = 'fabric',
  Scales = 'scales',
  Snow = 'snow',
}

export interface Texture {
  title: string
  id: string
  group: TextureGroup
  color: string
  url: string
}

export interface Ankara {
  title: string
  id: string
  url: string
}

export interface Animation {
  title: string
  id: string
  url: string
}
