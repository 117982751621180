import { defineNuxtPlugin } from "#app";
import type { RouteLocationRaw } from "vue-router";

export default defineNuxtPlugin(() => {
  const { listenMany } = useAppEvent();
  const { loading } = useLoading();
  const { logout } = useAuth();

  const handleLogout = async () => {
    loading("Logging out");
    await logout();
    loading(false);
  };

  const handleRouteTo = (to: RouteLocationRaw) => {
    return () => {
      navigateTo(to);
    };
  };

  listenMany({
    "app:logout": handleLogout,

    // general navigation
    "goto:analytics": handleRouteTo({ name: "analytics" }),
    "goto:apps": handleRouteTo({ name: "settings-apps" }),
    "goto:app-store": handleRouteTo({ name: "apps-store" }),
    "goto:orders": handleRouteTo({ name: "orders" }),
    "goto:draft-orders": handleRouteTo({ name: "orders-drafts" }),
    "goto:products": handleRouteTo({ name: "products" }),
    "goto:services": handleRouteTo({ name: "services" }),
    "goto:events": handleRouteTo({ name: "events" }),
    "goto:bio-sites": handleRouteTo({ name: "bio-sites" }),
    "goto:customers": handleRouteTo({ name: "customers" }),
    "goto:discounts": handleRouteTo({ name: "discounts" }),
    "goto:availability": handleRouteTo({ name: "services-availability" }),
    "goto:settings": handleRouteTo({ name: "settings" }),

    // resources
    "add:product": handleRouteTo({ name: "products-new" }),
    "add:service": handleRouteTo({ name: "services-new" }),
    "add:event": handleRouteTo({ name: "events-new" }),
    "add:discount": handleRouteTo({ name: "discounts-new" }),
    // "add:order": handleRouteTo({ name: "orders-drafts-new" }),
    "add:customer": handleRouteTo({ name: "customers-new" }),
    "add:checkout-link": handleRouteTo({ name: "orders-checkout-links-new" }),
    "add:collection": handleRouteTo({ name: "products-collections-new" }),
  });
});
