import {
  attr,
  boolean,
  float,
  id,
  integer,
  json,
  model,
  string,
} from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { cloneDeep } from "@apollo/client/utilities";
import { omitBy, isNil } from "lodash";
import ProductType from "./ProductType";
import ProductVariant from "./ProductVariant";
import {
  type Publishable,
  Model,
  datetime,
  PublishableStatus,
  ProductKind,
  ProductAvailability,
  WeightUnit,
  ServicePeriodType,
  BookingConfirmation,
  ServiceSchedulingType,
  PublishedStatus,
  OnboardStep,
  Pricing as PricingModel,
  type IProductOption,
  type IProduct,
} from "@/layers/admin/models";
import SalesChannel from "./SalesChannel";
import Inventory from "./Inventory";
import { Media } from "../../layers/admin/models/dobby/Media";
import PublishedItem from "./PublishedItem";
import { creatingProduct, updatingProduct } from "./hooks/product-hooks";
import ResourceFaq from "./ResourceFaq";
import { IntervalLimit, ServiceSchedule } from "./Service";
import TeamMember from "./TeamMember";
import { pricingDefaults } from "~/layers/admin/helpers/defaults";
import {
  availableVariants,
  variantOptionsHash,
} from "~/layers/admin/helpers/product";

export type ProductPricingAttribute =
  | "pricing"
  | "comparison_pricing"
  | "whole_sale_pricing";

export class Product extends Model implements IProduct, Publishable {
  static entity = "Product";
  static primaryKey = "handle";
  static primaryKeys = ["id", "gid", "handle"];
  static queryAttributes = ["id", "handle", "name"];

  constructor(attributes = {}) {
    super({
      pricing: cloneDeep(pricingDefaults),
      comparison_pricing: cloneDeep(pricingDefaults),
      whole_sale_pricing: cloneDeep(pricingDefaults),
      // image: {},
      gallery: [],
      options: [],
      variants: [],
      tags: [],
      faqs: [],
      inventory: {},
      booking_order_limits: {},
      booking_duration_limits: {},
      bookingSchedule: {},
      teamMembers: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @id() declare readonly gid: string;
  @string() declare readonly objectName: string;
  @string() declare readonly handle: string;
  @string() declare name: string;
  @string() declare kind: ProductKind;
  @boolean() declare bookable?: boolean;
  @string() declare short_description?: string;
  @string() declare description?: string;
  @string() declare vendor?: string;
  @string() declare currency_code?: string;
  @string() declare status: PublishableStatus;
  @string() declare publishedStatus: PublishedStatus;
  @boolean() declare virtual?: boolean;
  @boolean() declare private?: boolean;
  @json() declare meta?: Object;
  // @string({ default: [], list: true }) declare channels: string[]
  @string({ default: [], list: true }) declare channels: string[];
  @string({ default: [], list: true }) declare tags: string[];
  @string() declare purchase_action?: string;
  @string() declare video_url?: string;
  @string() declare cancellation_policy?: string;

  // bookable
  @string() declare booking_timezone?: string;
  @string() declare booking_period_type?: ServicePeriodType;
  @string() declare booking_period_start_date?: string;
  @string() declare booking_period_end_date?: string;
  @integer() declare booking_period_days?: number;
  @boolean() declare booking_period_count_calendar_days?: boolean;
  @string() declare booking_confirmation?: BookingConfirmation;
  @boolean() declare booking_hide_calendar_notes?: boolean;
  @integer() declare booking_minimum_notice?: number;
  @integer() declare booking_before_buffer?: number;
  @integer() declare booking_after_buffer?: number;
  @boolean() declare booking_block_before_buffer?: boolean;
  @boolean() declare booking_block_after_buffer?: boolean;
  @integer() declare booking_orders_per_time_slot?: number;
  @string() declare booking_scheduling_type?: ServiceSchedulingType;
  @integer() declare booking_slot_interval?: number;
  @string() declare booking_schedule_id?: string;
  @model(() => IntervalLimit) declare booking_order_limits?: IntervalLimit;
  @model(() => IntervalLimit) declare booking_duration_limits?: IntervalLimit;
  @model(() => TeamMember, { list: true }) declare teamMembers: TeamMember[];

  @model(() => OnboardStep, { list: true }) declare nextSteps?: OnboardStep[];
  @model(() => PublishedItem, { list: true })
  declare publications?: PublishedItem[];

  @string() declare template_suffix?: string;

  // Default variant
  @float() declare cost_per_item?: number;
  @boolean() declare taxable?: boolean;
  @string() declare availability?: ProductAvailability;
  @boolean({ default: false }) declare enable_comparison_pricing: boolean;
  @float() declare weight?: number;
  @string() declare weight_unit?: WeightUnit;
  @string() declare fulfillment_service?: string;
  @string() declare inventory_management?: string;
  @integer() declare booking_duration?: number;
  @float() declare booking_cancellation_fee?: number;
  @model(() => PricingModel) declare pricing?: PricingModel;
  @model(() => PricingModel) declare comparison_pricing?: PricingModel;
  @model(() => PricingModel) declare whole_sale_pricing?: PricingModel;
  @model(() => Inventory) declare inventory?: Inventory;
  @model(() => ResourceFaq, { list: true }) declare faqs: ResourceFaq[];

  // relationships
  @model(() => ProductType) declare type?: ProductType;
  // @model(() => ProductCategories, { list: true }) categories?: ProductCategories[]
  // @model(() => ProductCollection, { list: true }) collections?: ProductCollection[]
  @model(() => Media) declare image?: Media;
  @model(() => Media, { list: true }) declare gallery?: Media[];
  @model(() => ProductOption, { default: [], list: true })
  declare options: ProductOption[];
  @model(() => ProductVariant, { default: [], list: true })
  declare variants: ProductVariant[];
  @model(() => Product) declare parent?: Product;
  @model(() => Product, { list: true }) declare children?: Product[];
  @model(() => SalesChannel, { list: true })
  declare SalesChannel?: SalesChannel[];
  @model(() => ServiceSchedule) declare bookingSchedule?: ServiceSchedule;

  get last_updated(): Dayjs {
    return this.updated_at || this.created_at;
  }

  $isVariant(): this is ProductVariant {
    return false;
  }

  $isDraft() {
    return this.$getAttribute("status") === PublishableStatus.DRAFT;
  }
  $isStandardKind() {
    return this.$getAttribute("kind") === ProductKind.STANDARD;
  }
  $isCompositeKind() {
    return this.$getAttribute("kind") === ProductKind.COMPOSITE;
  }
  $isVariationKind() {
    return this.$getAttribute("kind") === ProductKind.VARIATION;
  }

  $keepChanges(): void | false {
    super.$keepChanges();
    if (this.pricing) {
      this.pricing.$keepChanges();
    }
    if (this.comparison_pricing) {
      this.comparison_pricing.$keepChanges();
    }
    if (this.whole_sale_pricing) {
      this.whole_sale_pricing.$keepChanges();
    }
    if (this.inventory) {
      this.inventory.$keepChanges();
    }
    if (this.options && this.options.length) {
      this.options.forEach((option) => option.$keepChanges());
    }
    if (this.variants && this.variants.length) {
      this.variants.forEach((variant) => variant.$keepChanges());
    }
  }

  $variantOptionsHash(): string[] {
    return variantOptionsHash(this);
  }

  $availableVariants() {
    return availableVariants(this);
  }

  public $url(): string | undefined {
    if (this.handle) {
      return `https://orie.market/products/${this.handle}`;
    }
  }

  $getDataForCreate() {}

  $getDataForUpdate() {
    const data: Record<string, any> = this.$getAttributeChanges();

    return data;
  }

  static hooks() {
    return {
      $creating: creatingProduct,
      $updating: updatingProduct,
    };
  }
}

export class ProductOption extends Model implements IProductOption {
  static entity = "ProductOption";
  constructor(attributes = {}) {
    super({
      values: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @attr() declare id: string;
  @string() declare name: string;
  @integer() declare position: number;
  @string({ default: [], list: true }) declare values: string[];
  // @model(() => Product) product: Product
}

export default Product;
