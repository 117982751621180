import type { Attributes } from "@moirei/dobby";
import { omit } from "lodash";
import { EventTicket } from "../Event";
import { updatesProductPricing } from "./common";

export const creatingEventTicket = (model: EventTicket, data?: Attributes) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }
  // unset ID. Tempt used by UI to track instances
  model.$setChangesAttribute("id", undefined);
  return omit(data, ["id"]);
};

export const updatingEventTicket = (model: EventTicket, data?: Attributes) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }
  data[model.$getKeyName()] = model.$getKey();

  updatesProductPricing(model, data);

  return data;
};
