import { isFunction, isNil } from "lodash";

export function convertToUnit(
  str: string | number | null | undefined,
  unit = "px"
): string | undefined {
  if (!str) {
    return undefined;
  } else if (isNaN(+str!)) {
    return String(str);
  } else {
    return `${Number(str)}${unit}`;
  }
}

export const isUuid = (str: string) =>
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    str
  );

export const orUse = <A, B>(
  a: A | null | undefined,
  b: B | { (): B }
): A | B => {
  if (!isNil(a)) {
    return a;
  }
  if (isFunction(b)) {
    return b();
  }
  return b;
};
