import { default as indexdCH2fZTsB1Meta } from "/opt/build/repo/pages/access/index.vue?macro=true";
import { default as pricing9gN5KoWq5dMeta } from "/opt/build/repo/pages/access/pricing.vue?macro=true";
import { default as indexiQQjftOIAUMeta } from "/opt/build/repo/pages/access/subscription/confirm/[plan]/index.vue?macro=true";
import { default as successuOBjRqkSmeMeta } from "/opt/build/repo/pages/access/subscription/success.vue?macro=true";
import { default as index0FDTD3mC5DMeta } from "/opt/build/repo/pages/access/usage/[service]/index.vue?macro=true";
import { default as indexWXTYeJThB4Meta } from "/opt/build/repo/pages/analytics/index.vue?macro=true";
import { default as sales8u4tfibJ5YMeta } from "/opt/build/repo/pages/analytics/reports/sales.vue?macro=true";
import { default as _91_46_46_46handle_93MNzcPFKHyNMeta } from "/opt/build/repo/pages/apps/[...handle].vue?macro=true";
import { default as _91app_93Yf7nrpiflyMeta } from "/opt/build/repo/pages/apps/manage/[app].vue?macro=true";
import { default as storen5cQ8gZ8JHMeta } from "/opt/build/repo/pages/apps/store.vue?macro=true";
import { default as _91handle_93v5yXIuMTmJMeta } from "/opt/build/repo/pages/bio-sites/[handle].vue?macro=true";
import { default as indexEqcWwRkNuDMeta } from "/opt/build/repo/pages/bio-sites/index.vue?macro=true";
import { default as _91id_93MYJJQueMiZMeta } from "/opt/build/repo/pages/customers/[id].vue?macro=true";
import { default as indexuqTjbSei46Meta } from "/opt/build/repo/pages/customers/index.vue?macro=true";
import { default as newEgSW6TkXkTMeta } from "/opt/build/repo/pages/customers/new.vue?macro=true";
import { default as _91id_93d5r0jgtTGHMeta } from "/opt/build/repo/pages/discounts/[id].vue?macro=true";
import { default as index1GmqJ2xAdyMeta } from "/opt/build/repo/pages/discounts/index.vue?macro=true";
import { default as new9og8Sf4SA3Meta } from "/opt/build/repo/pages/discounts/new.vue?macro=true";
import { default as _91code_93bUBsCbd9Z7Meta } from "/opt/build/repo/pages/events/[handle]/change-seats/[code].vue?macro=true";
import { default as indexQKSmixP0bqMeta } from "/opt/build/repo/pages/events/[handle]/index.vue?macro=true";
import { default as _91occurrence_93OBBujvrEoAMeta } from "/opt/build/repo/pages/events/[handle]/seating/[occurrence].vue?macro=true";
import { default as sta3ned34nmcGMeta } from "/opt/build/repo/pages/events/[handle]/sta.vue?macro=true";
import { default as calendarhjNokLZIUUMeta } from "/opt/build/repo/pages/events/calendar.vue?macro=true";
import { default as _91handle_93FB27UGLqXAMeta } from "/opt/build/repo/pages/events/checkins/[handle].vue?macro=true";
import { default as indexHR5Bie6djpMeta } from "/opt/build/repo/pages/events/index.vue?macro=true";
import { default as newbOaGdtDVwaMeta } from "/opt/build/repo/pages/events/new.vue?macro=true";
import { default as filesNpgU1WRfjjMeta } from "/opt/build/repo/pages/files.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as invite3Fw2jChPLSMeta } from "/opt/build/repo/pages/links/invite.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as authorizecpp8j47vgpMeta } from "/opt/build/repo/pages/oauth/authorize.vue?macro=true";
import { default as forgot_45passwordKsNeKC6cfzMeta } from "/opt/build/repo/pages/oauth/forgot-password.vue?macro=true";
import { default as reset_45passwordsD4YofwC1KMeta } from "/opt/build/repo/pages/oauth/reset-password.vue?macro=true";
import { default as indexYw9kJj45fLMeta } from "/opt/build/repo/pages/onboarding/index.vue?macro=true";
import { default as new_45store7KZhWA5vi8Meta } from "/opt/build/repo/pages/onboarding/new-store.vue?macro=true";
import { default as indexLDtZzpTzzmMeta } from "/opt/build/repo/pages/orders/[number]/index.vue?macro=true";
import { default as abandoned_45checkoutsas4O6nMp6LMeta } from "/opt/build/repo/pages/orders/abandoned-checkouts.vue?macro=true";
import { default as _91id_93CWTQbYQRUTMeta } from "/opt/build/repo/pages/orders/checkout-links/[id].vue?macro=true";
import { default as indexjMrfR8qjoyMeta } from "/opt/build/repo/pages/orders/checkout-links/index.vue?macro=true";
import { default as newKsvaCevknXMeta } from "/opt/build/repo/pages/orders/checkout-links/new.vue?macro=true";
import { default as index82oHI8HC5xMeta } from "/opt/build/repo/pages/orders/drafts/index.vue?macro=true";
import { default as newibSvyLsrOmMeta } from "/opt/build/repo/pages/orders/drafts/new.vue?macro=true";
import { default as indexi2fh0drxOOMeta } from "/opt/build/repo/pages/orders/index.vue?macro=true";
import { default as indexdhhs25ojAvMeta } from "/opt/build/repo/pages/products/[handle]/index.vue?macro=true";
import { default as _91id_93L84Ic7Gf1FMeta } from "/opt/build/repo/pages/products/[handle]/variant/[id].vue?macro=true";
import { default as _91handle_93AGdCsBoybuMeta } from "/opt/build/repo/pages/products/collections/[handle].vue?macro=true";
import { default as indexoYI3XxtEKTMeta } from "/opt/build/repo/pages/products/collections/index.vue?macro=true";
import { default as newE2JnadE1thMeta } from "/opt/build/repo/pages/products/collections/new.vue?macro=true";
import { default as indexBz9HLubbrBMeta } from "/opt/build/repo/pages/products/giftcards/index.vue?macro=true";
import { default as indextMtonKiU6yMeta } from "/opt/build/repo/pages/products/index.vue?macro=true";
import { default as inventoryBPzuqBr7LPMeta } from "/opt/build/repo/pages/products/inventory.vue?macro=true";
import { default as newn6IZwSIg1IMeta } from "/opt/build/repo/pages/products/new.vue?macro=true";
import { default as index9j2easav71Meta } from "/opt/build/repo/pages/products/transfers/index.vue?macro=true";
import { default as registeryZbpxRGV6SMeta } from "/opt/build/repo/pages/register.vue?macro=true";
import { default as _91handle_937ubjOPjzLKMeta } from "/opt/build/repo/pages/services/[handle].vue?macro=true";
import { default as _91reference_93bdgzwZf6OZMeta } from "/opt/build/repo/pages/services/bookings/[reference].vue?macro=true";
import { default as indexBDZ0QUzxHMMeta } from "/opt/build/repo/pages/services/bookings/index.vue?macro=true";
import { default as liveL7lQKdm17IMeta } from "/opt/build/repo/pages/services/bookings/live.vue?macro=true";
import { default as calendarwfEpGDRg9zMeta } from "/opt/build/repo/pages/services/calendar.vue?macro=true";
import { default as indexi5MzpDfjPNMeta } from "/opt/build/repo/pages/services/index.vue?macro=true";
import { default as new08McTkn141Meta } from "/opt/build/repo/pages/services/new.vue?macro=true";
import { default as appsFthORdCnnVMeta } from "/opt/build/repo/pages/settings/apps.vue?macro=true";
import { default as _91id_93GxsTPNnPNDMeta } from "/opt/build/repo/pages/settings/availability/[id].vue?macro=true";
import { default as indexD67IIZhDplMeta } from "/opt/build/repo/pages/settings/availability/index.vue?macro=true";
import { default as billingTtDfKCy67FMeta } from "/opt/build/repo/pages/settings/billing.vue?macro=true";
import { default as generalmiHy4mFvFkMeta } from "/opt/build/repo/pages/settings/general.vue?macro=true";
import { default as giftcards84GMEGYfHdMeta } from "/opt/build/repo/pages/settings/giftcards.vue?macro=true";
import { default as indexoWr0YesFbSMeta } from "/opt/build/repo/pages/settings/index.vue?macro=true";
import { default as legalirokHAlPYeMeta } from "/opt/build/repo/pages/settings/legal.vue?macro=true";
import { default as _91handle_93PIPLj87HqKMeta } from "/opt/build/repo/pages/settings/locations/[handle].vue?macro=true";
import { default as indexQikTzmjkERMeta } from "/opt/build/repo/pages/settings/locations/index.vue?macro=true";
import { default as newhE4E3Gsf5XMeta } from "/opt/build/repo/pages/settings/locations/new.vue?macro=true";
import { default as notificationsfRnVTNy2T1Meta } from "/opt/build/repo/pages/settings/notifications.vue?macro=true";
import { default as payoutswAqAeQqmK7Meta } from "/opt/build/repo/pages/settings/payouts.vue?macro=true";
import { default as plan3xihucFcqiMeta } from "/opt/build/repo/pages/settings/plan.vue?macro=true";
import { default as profileNcyTuBLl16Meta } from "/opt/build/repo/pages/settings/profile.vue?macro=true";
import { default as businessJhrWxoEJHeMeta } from "/opt/build/repo/pages/settings/profiles/business.vue?macro=true";
import { default as eventsVNUjZQHLOMeta } from "/opt/build/repo/pages/settings/profiles/event.vue?macro=true";
import { default as serviceKalUooFWkPMeta } from "/opt/build/repo/pages/settings/profiles/service.vue?macro=true";
import { default as indexTTUhsErpPSMeta } from "/opt/build/repo/pages/settings/shipping/index.vue?macro=true";
import { default as _91handle_93znmmHKRsX2Meta } from "/opt/build/repo/pages/settings/shipping/local-delivery/[handle].vue?macro=true";
import { default as _91handle_93tnyvF2qwgXMeta } from "/opt/build/repo/pages/settings/shipping/local-pickup/[handle].vue?macro=true";
import { default as newcFp1hhrbkxMeta } from "/opt/build/repo/pages/settings/shipping/profiles/new.vue?macro=true";
import { default as indexFxJLiWUTEkMeta } from "/opt/build/repo/pages/settings/team/index.vue?macro=true";
import { default as inviteqzmahv5f8VMeta } from "/opt/build/repo/pages/settings/team/invite.vue?macro=true";
import { default as _91id_93IcIvrGICmtMeta } from "/opt/build/repo/pages/settings/team/member/[id].vue?macro=true";
export default [
  {
    name: "access",
    path: "/access",
    component: () => import("/opt/build/repo/pages/access/index.vue").then(m => m.default || m)
  },
  {
    name: "access-pricing",
    path: "/access/pricing",
    meta: pricing9gN5KoWq5dMeta || {},
    component: () => import("/opt/build/repo/pages/access/pricing.vue").then(m => m.default || m)
  },
  {
    name: "access-subscription-confirm-plan",
    path: "/access/subscription/confirm/:plan()",
    meta: indexiQQjftOIAUMeta || {},
    component: () => import("/opt/build/repo/pages/access/subscription/confirm/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: "access-subscription-success",
    path: "/access/subscription/success",
    component: () => import("/opt/build/repo/pages/access/subscription/success.vue").then(m => m.default || m)
  },
  {
    name: "access-usage-service",
    path: "/access/usage/:service()",
    meta: index0FDTD3mC5DMeta || {},
    component: () => import("/opt/build/repo/pages/access/usage/[service]/index.vue").then(m => m.default || m)
  },
  {
    name: "analytics",
    path: "/analytics",
    component: () => import("/opt/build/repo/pages/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "analytics-reports-sales",
    path: "/analytics/reports/sales",
    component: () => import("/opt/build/repo/pages/analytics/reports/sales.vue").then(m => m.default || m)
  },
  {
    name: "apps-handle",
    path: "/apps/:handle(.*)*",
    component: () => import("/opt/build/repo/pages/apps/[...handle].vue").then(m => m.default || m)
  },
  {
    name: "apps-manage-app",
    path: "/apps/manage/:app()",
    component: () => import("/opt/build/repo/pages/apps/manage/[app].vue").then(m => m.default || m)
  },
  {
    name: "apps-store",
    path: "/apps/store",
    meta: storen5cQ8gZ8JHMeta || {},
    component: () => import("/opt/build/repo/pages/apps/store.vue").then(m => m.default || m)
  },
  {
    name: "bio-sites-handle",
    path: "/bio-sites/:handle()",
    meta: _91handle_93v5yXIuMTmJMeta || {},
    component: () => import("/opt/build/repo/pages/bio-sites/[handle].vue").then(m => m.default || m)
  },
  {
    name: "bio-sites",
    path: "/bio-sites",
    component: () => import("/opt/build/repo/pages/bio-sites/index.vue").then(m => m.default || m)
  },
  {
    name: "customers-id",
    path: "/customers/:id()",
    component: () => import("/opt/build/repo/pages/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: "customers",
    path: "/customers",
    component: () => import("/opt/build/repo/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "customers-new",
    path: "/customers/new",
    component: () => import("/opt/build/repo/pages/customers/new.vue").then(m => m.default || m)
  },
  {
    name: "discounts-id",
    path: "/discounts/:id()",
    component: () => import("/opt/build/repo/pages/discounts/[id].vue").then(m => m.default || m)
  },
  {
    name: "discounts",
    path: "/discounts",
    component: () => import("/opt/build/repo/pages/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: "discounts-new",
    path: "/discounts/new",
    component: () => import("/opt/build/repo/pages/discounts/new.vue").then(m => m.default || m)
  },
  {
    name: "events-handle-change-seats-code",
    path: "/events/:handle()/change-seats/:code()",
    meta: _91code_93bUBsCbd9Z7Meta || {},
    component: () => import("/opt/build/repo/pages/events/[handle]/change-seats/[code].vue").then(m => m.default || m)
  },
  {
    name: "events-handle",
    path: "/events/:handle()",
    component: () => import("/opt/build/repo/pages/events/[handle]/index.vue").then(m => m.default || m)
  },
  {
    name: "events-handle-seating-occurrence",
    path: "/events/:handle()/seating/:occurrence()",
    meta: _91occurrence_93OBBujvrEoAMeta || {},
    component: () => import("/opt/build/repo/pages/events/[handle]/seating/[occurrence].vue").then(m => m.default || m)
  },
  {
    name: "events-handle-sta",
    path: "/events/:handle()/sta",
    meta: sta3ned34nmcGMeta || {},
    component: () => import("/opt/build/repo/pages/events/[handle]/sta.vue").then(m => m.default || m)
  },
  {
    name: "events-calendar",
    path: "/events/calendar",
    meta: calendarhjNokLZIUUMeta || {},
    component: () => import("/opt/build/repo/pages/events/calendar.vue").then(m => m.default || m)
  },
  {
    name: "events-checkins-handle",
    path: "/events/checkins/:handle()",
    component: () => import("/opt/build/repo/pages/events/checkins/[handle].vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    meta: indexHR5Bie6djpMeta || {},
    component: () => import("/opt/build/repo/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events-new",
    path: "/events/new",
    component: () => import("/opt/build/repo/pages/events/new.vue").then(m => m.default || m)
  },
  {
    name: "files",
    path: "/files",
    component: () => import("/opt/build/repo/pages/files.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "links-invite",
    path: "/links/invite",
    component: () => import("/opt/build/repo/pages/links/invite.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginBXD9sq3T3sMeta || {},
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-authorize",
    path: "/oauth/authorize",
    component: () => import("/opt/build/repo/pages/oauth/authorize.vue").then(m => m.default || m)
  },
  {
    name: "oauth-forgot-password",
    path: "/oauth/forgot-password",
    meta: forgot_45passwordKsNeKC6cfzMeta || {},
    component: () => import("/opt/build/repo/pages/oauth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "oauth-reset-password",
    path: "/oauth/reset-password",
    meta: reset_45passwordsD4YofwC1KMeta || {},
    component: () => import("/opt/build/repo/pages/oauth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexYw9kJj45fLMeta || {},
    component: () => import("/opt/build/repo/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-new-store",
    path: "/onboarding/new-store",
    meta: new_45store7KZhWA5vi8Meta || {},
    component: () => import("/opt/build/repo/pages/onboarding/new-store.vue").then(m => m.default || m)
  },
  {
    name: "orders-number",
    path: "/orders/:number()",
    component: () => import("/opt/build/repo/pages/orders/[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-abandoned-checkouts",
    path: "/orders/abandoned-checkouts",
    component: () => import("/opt/build/repo/pages/orders/abandoned-checkouts.vue").then(m => m.default || m)
  },
  {
    name: "orders-checkout-links-id",
    path: "/orders/checkout-links/:id()",
    component: () => import("/opt/build/repo/pages/orders/checkout-links/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-checkout-links",
    path: "/orders/checkout-links",
    meta: indexjMrfR8qjoyMeta || {},
    component: () => import("/opt/build/repo/pages/orders/checkout-links/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-checkout-links-new",
    path: "/orders/checkout-links/new",
    component: () => import("/opt/build/repo/pages/orders/checkout-links/new.vue").then(m => m.default || m)
  },
  {
    name: "orders-drafts",
    path: "/orders/drafts",
    meta: index82oHI8HC5xMeta || {},
    component: () => import("/opt/build/repo/pages/orders/drafts/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-drafts-new",
    path: "/orders/drafts/new",
    component: () => import("/opt/build/repo/pages/orders/drafts/new.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexi2fh0drxOOMeta || {},
    component: () => import("/opt/build/repo/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "products-handle",
    path: "/products/:handle()",
    component: () => import("/opt/build/repo/pages/products/[handle]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-handle-variant-id",
    path: "/products/:handle()/variant/:id()",
    component: () => import("/opt/build/repo/pages/products/[handle]/variant/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-collections-handle",
    path: "/products/collections/:handle()",
    component: () => import("/opt/build/repo/pages/products/collections/[handle].vue").then(m => m.default || m)
  },
  {
    name: "products-collections",
    path: "/products/collections",
    meta: indexoYI3XxtEKTMeta || {},
    component: () => import("/opt/build/repo/pages/products/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "products-collections-new",
    path: "/products/collections/new",
    component: () => import("/opt/build/repo/pages/products/collections/new.vue").then(m => m.default || m)
  },
  {
    name: "products-giftcards",
    path: "/products/giftcards",
    meta: indexBz9HLubbrBMeta || {},
    component: () => import("/opt/build/repo/pages/products/giftcards/index.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    meta: indextMtonKiU6yMeta || {},
    component: () => import("/opt/build/repo/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "products-inventory",
    path: "/products/inventory",
    meta: inventoryBPzuqBr7LPMeta || {},
    component: () => import("/opt/build/repo/pages/products/inventory.vue").then(m => m.default || m)
  },
  {
    name: "products-new",
    path: "/products/new",
    component: () => import("/opt/build/repo/pages/products/new.vue").then(m => m.default || m)
  },
  {
    name: "products-transfers",
    path: "/products/transfers",
    component: () => import("/opt/build/repo/pages/products/transfers/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registeryZbpxRGV6SMeta || {},
    component: () => import("/opt/build/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "services-handle",
    path: "/services/:handle()",
    component: () => import("/opt/build/repo/pages/services/[handle].vue").then(m => m.default || m)
  },
  {
    name: "services-bookings-reference",
    path: "/services/bookings/:reference()",
    component: () => import("/opt/build/repo/pages/services/bookings/[reference].vue").then(m => m.default || m)
  },
  {
    name: "services-bookings",
    path: "/services/bookings",
    component: () => import("/opt/build/repo/pages/services/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "services-bookings-live",
    path: "/services/bookings/live",
    meta: liveL7lQKdm17IMeta || {},
    component: () => import("/opt/build/repo/pages/services/bookings/live.vue").then(m => m.default || m)
  },
  {
    name: "services-calendar",
    path: "/services/calendar",
    meta: calendarwfEpGDRg9zMeta || {},
    component: () => import("/opt/build/repo/pages/services/calendar.vue").then(m => m.default || m)
  },
  {
    name: "services",
    path: "/services",
    meta: indexi5MzpDfjPNMeta || {},
    component: () => import("/opt/build/repo/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "services-new",
    path: "/services/new",
    component: () => import("/opt/build/repo/pages/services/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-apps",
    path: "/settings/apps",
    meta: appsFthORdCnnVMeta || {},
    component: () => import("/opt/build/repo/pages/settings/apps.vue").then(m => m.default || m)
  },
  {
    name: "settings-availability-id",
    path: "/settings/availability/:id()",
    meta: _91id_93GxsTPNnPNDMeta || {},
    component: () => import("/opt/build/repo/pages/settings/availability/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-availability",
    path: "/settings/availability",
    meta: indexD67IIZhDplMeta || {},
    component: () => import("/opt/build/repo/pages/settings/availability/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "/settings/billing",
    meta: billingTtDfKCy67FMeta || {},
    component: () => import("/opt/build/repo/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-general",
    path: "/settings/general",
    meta: generalmiHy4mFvFkMeta || {},
    component: () => import("/opt/build/repo/pages/settings/general.vue").then(m => m.default || m)
  },
  {
    name: "settings-giftcards",
    path: "/settings/giftcards",
    meta: giftcards84GMEGYfHdMeta || {},
    component: () => import("/opt/build/repo/pages/settings/giftcards.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/build/repo/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-legal",
    path: "/settings/legal",
    meta: legalirokHAlPYeMeta || {},
    component: () => import("/opt/build/repo/pages/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "settings-locations-handle",
    path: "/settings/locations/:handle()",
    meta: _91handle_93PIPLj87HqKMeta || {},
    component: () => import("/opt/build/repo/pages/settings/locations/[handle].vue").then(m => m.default || m)
  },
  {
    name: "settings-locations",
    path: "/settings/locations",
    meta: indexQikTzmjkERMeta || {},
    component: () => import("/opt/build/repo/pages/settings/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-locations-new",
    path: "/settings/locations/new",
    meta: newhE4E3Gsf5XMeta || {},
    component: () => import("/opt/build/repo/pages/settings/locations/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "/settings/notifications",
    meta: notificationsfRnVTNy2T1Meta || {},
    component: () => import("/opt/build/repo/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "settings-payouts",
    path: "/settings/payouts",
    meta: payoutswAqAeQqmK7Meta || {},
    component: () => import("/opt/build/repo/pages/settings/payouts.vue").then(m => m.default || m)
  },
  {
    name: "settings-plan",
    path: "/settings/plan",
    meta: plan3xihucFcqiMeta || {},
    component: () => import("/opt/build/repo/pages/settings/plan.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "/settings/profile",
    meta: profileNcyTuBLl16Meta || {},
    component: () => import("/opt/build/repo/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-profiles-business",
    path: "/settings/profiles/business",
    meta: businessJhrWxoEJHeMeta || {},
    component: () => import("/opt/build/repo/pages/settings/profiles/business.vue").then(m => m.default || m)
  },
  {
    name: "settings-profiles-event",
    path: "/settings/profiles/event",
    meta: eventsVNUjZQHLOMeta || {},
    component: () => import("/opt/build/repo/pages/settings/profiles/event.vue").then(m => m.default || m)
  },
  {
    name: "settings-profiles-service",
    path: "/settings/profiles/service",
    meta: serviceKalUooFWkPMeta || {},
    component: () => import("/opt/build/repo/pages/settings/profiles/service.vue").then(m => m.default || m)
  },
  {
    name: "settings-shipping",
    path: "/settings/shipping",
    meta: indexTTUhsErpPSMeta || {},
    component: () => import("/opt/build/repo/pages/settings/shipping/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-shipping-local-delivery-handle",
    path: "/settings/shipping/local-delivery/:handle()",
    meta: _91handle_93znmmHKRsX2Meta || {},
    component: () => import("/opt/build/repo/pages/settings/shipping/local-delivery/[handle].vue").then(m => m.default || m)
  },
  {
    name: "settings-shipping-local-pickup-handle",
    path: "/settings/shipping/local-pickup/:handle()",
    meta: _91handle_93tnyvF2qwgXMeta || {},
    component: () => import("/opt/build/repo/pages/settings/shipping/local-pickup/[handle].vue").then(m => m.default || m)
  },
  {
    name: "settings-shipping-profiles-new",
    path: "/settings/shipping/profiles/new",
    meta: newcFp1hhrbkxMeta || {},
    component: () => import("/opt/build/repo/pages/settings/shipping/profiles/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-team",
    path: "/settings/team",
    meta: indexFxJLiWUTEkMeta || {},
    component: () => import("/opt/build/repo/pages/settings/team/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-team-invite",
    path: "/settings/team/invite",
    meta: inviteqzmahv5f8VMeta || {},
    component: () => import("/opt/build/repo/pages/settings/team/invite.vue").then(m => m.default || m)
  },
  {
    name: "settings-team-member-id",
    path: "/settings/team/member/:id()",
    meta: _91id_93IcIvrGICmtMeta || {},
    component: () => import("/opt/build/repo/pages/settings/team/member/[id].vue").then(m => m.default || m)
  }
]