import type { PricingData } from "@moirei/complex-pricing/build/types";
import { Pricing as ComplexPricing } from "@moirei/complex-pricing";
import { Pricing, PricingModel } from "~/layers/admin/models";
import { isNil } from "lodash";

export const parsePricing = (pricing?: Pricing | PricingData) => {
  if (pricing) {
    const p = toRaw(pricing);
    const data = p instanceof Pricing ? p.$toJson() : p;
    return new ComplexPricing(data);
  }
};

export const isTieredPricing = (pricing?: Pricing | PricingData): boolean => {
  return (
    !!pricing?.model &&
    [PricingModel.VOLUME, PricingModel.GRADUATED].includes(pricing.model as any)
  );
};

export const isValidPricing = (pricing?: Pricing | PricingData) => {
  if (!pricing) {
    return false;
  }

  const validAmount = !isNil(pricing.unit_amount) && pricing.unit_amount >= 0;
  const validUnits = !isNil(pricing.units) && pricing.units > 0;

  if (pricing.model === PricingModel.STANDARD) {
    return validAmount;
  } else if (pricing.model === PricingModel.PACKAGE) {
    return validAmount && validUnits;
  }

  if (!validAmount) {
    return false;
  }

  const m = parsePricing(pricing);

  return !!m?.isValid();
};
