import type { Attributes } from "@moirei/dobby";
import { pick } from "lodash";
import { DeliveryZone } from "../ShippingProfile";
import {
  creatingShippingZoneCountry,
  updatingShippingZoneCountry,
} from "./shipping-zone-country-hooks";
import {
  creatingShippingZoneRate,
  updatingShippingZoneRate,
} from "./shipping-zone-rate-hooks";

export const creatingDeliveryZone = (
  model: DeliveryZone,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (model.countries && model.countries.length) {
    data.countries = model.countries
      .filter((country) => !country.$willDelete)
      .map((country) => creatingShippingZoneCountry(country));
  }

  if (model.rates && model.rates.length) {
    data.rates = model.rates
      .filter((rate) => !rate.$willDelete)
      .map((rate) => creatingShippingZoneRate(rate));
  }

  return pick(data, ["name", "rest_of_world", "countries", "rates"]);
};

export const updatingDeliveryZone = (
  model: DeliveryZone,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (model.countries && model.countries.length) {
    data.countries = {
      create: model.countries
        .filter(
          (country) =>
            !country.$willDelete && country.$isDeepDirty() && !country.$exists()
        )
        .map((country) => creatingShippingZoneCountry(country)),
      update: model.countries
        .filter(
          (country) =>
            !country.$willDelete && country.$isDeepDirty() && country.$exists()
        )
        .map((country) => updatingShippingZoneCountry(country)),
      delete: model.countries
        .filter((country) => country.$willDelete && country.$exists())
        .map((country) => country.id),
    };
  }

  if (model.rates && model.rates.length) {
    data.rates = {
      create: model.rates
        .filter(
          (rate) => !rate.$willDelete && rate.$isDeepDirty() && !rate.$exists()
        )
        .map((rate) => creatingShippingZoneRate(rate)),
      update: model.rates
        .filter(
          (rate) => !rate.$willDelete && rate.$isDeepDirty() && rate.$exists()
        )
        .map((rate) => updatingShippingZoneRate(rate)),
      delete: model.rates
        .filter((rate) => rate.$willDelete && rate.$exists())
        .map((rate) => rate.id),
    };
  }

  data.id = model.id;

  return pick(data, ["id", "name", "rest_of_world", "countries", "rates"]);
};
