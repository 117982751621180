import { defineNuxtRouteMiddleware } from "#imports";
import { withoutHost } from "ufo";

export default defineNuxtRouteMiddleware((to) => {
  // // 404 exclusion
  // const isErrorPage = !(to.matched.length > 0);
  // if (isErrorPage) {
  //   return;
  // }

  const store = useAuthStore();
  const config = useRuntimeConfig().public.auth;
  const auth = useAuth();

  if (store.loggedIn && to.path == config.paths.login) {
    setTimeout(auth.navigateToHome, 1);
    return;
  }

  if (store.loggedIn || to.meta.auth === false) {
    return;
  }

  if (to.path !== config.paths.login) {
    store.set("_dest", withoutHost(window.location.href));
    return navigateTo(config.paths.login);
  }
});
