import dayjs, { Dayjs, type ConfigType } from "dayjs";
import calendar from "dayjs/plugin/calendar";
import advancedFormat from "dayjs/plugin/advancedFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { isString } from "lodash";

dayjs.extend(calendar);
dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(relativeTime);
dayjs.extend(duration);

type Currency = {
  code: string;
  symbol: string;
};

export const formatNumber = (n: number | string, precision = 0): string => {
  let parts = Number(n).toFixed(precision).split(".");
  return (
    parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
    (parts[1] ? "." + parts[1] : "")
  );
};

export const formatUnixToDatetime = (time: string | number): string => {
  return dayjs.unix(Number(time)).format("YYYY-MM-DD HH:mm:ss");
};

export const formatToDatetime = (time?: DateLike): string | undefined => {
  if (time) {
    return parseDateLike(time).format("YYYY-MM-DD HH:mm:ss");
  }
};

export const parseDatetime = (time: string): Dayjs => {
  return dayjs(time, "YYYY-MM-DD HH:mm:ss");
};

export const formatPrice = (
  price: number | string,
  currency?: string | Currency,
  useNilText?: string
): string => {
  if (price == 0 && useNilText) {
    return useNilText;
  }

  if (!currency) {
    const config = useAppConfig();
    currency = config.app.defaultCurrency;
  }

  if (isString(currency)) {
    // is code and not symbol
    if (currency.length > 2) {
      currency = currencySymbol(currency);
    }
  } else {
    currency = currency?.symbol;
  }

  return (currency || "") + formatNumber(price, 2);
};

export const formatTime = (time: ConfigType) => {
  const config = useAppConfig();
  return dayjs(time).calendar(null, config.app.calendar);
};
