import type { Attributes } from "@moirei/dobby";
import { CheckoutLink } from "../Checkout";
import {
  creatingCheckoutLinkItem,
  updatingCheckoutLinkItem,
} from "./checkout-link-item-hooks";
import type { ICheckoutLinkItem } from "~/layers/admin/models";

export const creatingCheckoutLink = (model: CheckoutLink, data: Attributes) => {
  if (data.items && data.items.length) {
    data.items = data.items.map((item: ICheckoutLinkItem) => ({
      id: item.item_gid,
      quantity: item.quantity,
      adjustable_quantity: item.adjustable_quantity
    }));
  }

  return data;
};

export const updatingCheckoutLink = (
  model: CheckoutLink,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (model.items && model.items.length) {
    data.items = {
      add: model.items
        .filter(
          (item) => !item.$willDelete && item.$isDeepDirty() && !item.$exists()
        )
        .map((item) => creatingCheckoutLinkItem(item)),
      update: model.items
        .filter(
          (item) => !item.$willDelete && item.$isDeepDirty() && item.$exists()
        )
        .map((item) => updatingCheckoutLinkItem(item)),
      delete: model.items
        .filter((item) => item.$willDelete && item.$exists())
        .map((item) => item.id),
    };
  }

  // if (model.image) {
  //   data.image = {
  //     set: model.image.id,
  //   }
  // }

  return data;
};
