import type { Media } from './media'
import type {
  BioSiteSectionSocialItem,
  BioSiteSectionSocialsType,
} from './socials'
import type { BioSiteSectionThumb } from './thumb'

export enum BioSiteSectionType {
  Heading = 'heading',
  Link = 'link',
  Socials = 'socials',
  Text = 'text',
  Gallery = 'gallery',
  Product = 'product',
  Service = 'service',
  Event = 'event',
  Products = 'products',
  Services = 'services',
  Collections = 'collections',
  LatestEvent = 'latest-event',
  Checkout = 'checkout',
  About = 'about',
  CollectEmail = 'collect-email',
  Location = 'location',
  Contact = 'contact',
  Reviews = 'reviews',
  File = 'file',
  Spotify = 'spotify',
  AppleMusic = 'apply-music',
  Soundcloud = 'soundcloud',
  Youtube = 'youtube',
  Vimeo = 'vimeo',
  Twitch = 'twitch',
  Instagram = 'instagram',
  Tiktok = 'tiktok',
  XTwitter = 'x-twitter',
  Facebook = 'facebook',
  Pinterest = 'pinterest',
  Telegram = 'telegram',
}

export enum BioSiteSectionVariant {
  Comfortable = 'comfortable',
  Prominent = 'prominent',
  Spotlight = 'spotlight',
}

export enum BioSiteStyleSectionStyle {
  Plain = 'plain',
  Paper = 'paper',
  Waves = 'waves',
}

export enum BioSiteStyleSectionRadius {
  Default = 'default',
  Small = 'small',
  Rounded = 'rounded',
  None = 'none',
}

export enum BioSiteStyleSectionShadow {
  Soft = 'soft',
  Hard = 'hard',
  None = 'none',
}

export enum BioSiteStyleSectionOutline {
  Outline = 'outline',
  Filled = 'filled',
  Dashed = 'dashed',
}

export interface BioSiteStyleSection {
  style: BioSiteStyleSectionStyle
  rounded: BioSiteStyleSectionRadius // applicable if style is Default
  shadow: BioSiteStyleSectionShadow // applicable if style is Default
  outline: BioSiteStyleSectionOutline // applicable if style is Default
  color?: string
  textColor?: string
  fillColor?: string
}

export interface BioSiteSectionBase {
  id: string
  position: number
  type: BioSiteSectionType
  invalid: boolean
  disabled: boolean
}

export interface BioSiteSectionConfigurable extends BioSiteSectionBase {
  title: string
  variant: BioSiteSectionVariant
  thumb?: BioSiteSectionThumb
}

export interface BioSiteSectionVideoBase extends BioSiteSectionConfigurable {
  embedded: boolean
}

export interface BioSiteSectionHeading extends BioSiteSectionBase {
  type: BioSiteSectionType.Heading
  title: string
}

export interface BioSiteSectionLink extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Link
  url: string
}

export interface BioSiteSectionSocials extends BioSiteSectionBase {
  type: BioSiteSectionType.Socials
  socials: Partial<Record<BioSiteSectionSocialsType, BioSiteSectionSocialItem>>
  bottom: boolean
}

export interface BioSiteSectionText extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Text
  title: string
  description?: string
}

export interface BioSiteSectionGallery extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Gallery
  //
}

export interface BioSiteSectionProduct extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Product
  product_id: string
}

export interface BioSiteSectionService extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Service
  service_id: string
}

export interface BioSiteSectionEvent extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Event
  event_id: string
}

export interface BioSiteSectionProducts extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Products
}

export interface BioSiteSectionServices extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Services
}

export interface BioSiteSectionCollections extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Collections
  //
}

export interface BioSiteSectionLatestEvent extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.LatestEvent
  //
}
export interface BioSiteSectionCheckout extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Checkout
}

export interface BioSiteSectionAbout extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.About
  //
}

export interface BioSiteSectionCollectEmail extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.CollectEmail
  //
}

export interface BioSiteSectionLocation extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Location
  location_id: string
  show_working_hours: boolean
}

export interface BioSiteSectionContact extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Contact
  collect_phone: boolean
}

export interface BioSiteSectionReviews extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Reviews
  //
}

export interface BioSiteSectionFile extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.File
  attachment: string // max. 32MB
}

export interface BioSiteSectionSpotify extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Spotify
  username: string
}

export interface BioSiteSectionAppleMusic extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.AppleMusic
  url: string
}

export interface BioSiteSectionSoundcloud extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Soundcloud
  username: string
}

export interface BioSiteSectionYoutube extends BioSiteSectionVideoBase {
  type: BioSiteSectionType.Youtube
  username: string
}

export interface BioSiteSectionVimeo extends BioSiteSectionVideoBase {
  type: BioSiteSectionType.Vimeo
  username: string
}

export interface BioSiteSectionTwitch extends BioSiteSectionVideoBase {
  type: BioSiteSectionType.Twitch
  username: string
}

export interface BioSiteSectionInstagram extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Instagram
  username: string
}

export interface BioSiteSectionTiktok extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Tiktok
  username: string
}

export interface BioSiteSectionXTwitter extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.XTwitter
  username: string
}

export interface BioSiteSectionFacebook extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Facebook
  username: string
}

export interface BioSiteSectionPinterest extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Pinterest
  username: string
}

export interface BioSiteSectionTelegram extends BioSiteSectionConfigurable {
  type: BioSiteSectionType.Telegram
  url: string
}

export type ExtractBioSiteSection<T extends BioSiteSectionType> = Extract<
  BioSiteSection,
  { type: T }
>

export type BioSiteSection =
  | BioSiteSectionBase
  | BioSiteSectionHeading
  | BioSiteSectionLink
  | BioSiteSectionSocials
  | BioSiteSectionText
  | BioSiteSectionGallery
  | BioSiteSectionService
  | BioSiteSectionEvent
  | BioSiteSectionCollections
  | BioSiteSectionLatestEvent
  | BioSiteSectionAbout
  | BioSiteSectionCollectEmail
  | BioSiteSectionLocation
  | BioSiteSectionContact
  | BioSiteSectionReviews
  | BioSiteSectionFile
  | BioSiteSectionSpotify
  | BioSiteSectionAppleMusic
  | BioSiteSectionSoundcloud
  | BioSiteSectionYoutube
  | BioSiteSectionVimeo
  | BioSiteSectionInstagram
  | BioSiteSectionTiktok
  | BioSiteSectionXTwitter
  | BioSiteSectionFacebook
  | BioSiteSectionPinterest
  | BioSiteSectionTelegram
