import { get, has, snakeCase } from "lodash";

const resourceNames = [
  "app",
  "business_profile",
  "category",
  "checkout_link",
  "customer",
  "discount_code",
  "enquiry",
  "event",
  "event_booking",
  "event_profile",
  "event_ticket",
  "event_venue",
  "faq",
  "file",
  "giftcard",
  "location",
  "order",
  "plan",
  "plan_contract",
  "platform_charge",
  "price_rule",
  "product",
  "product_variant",
  "product_type",
  "publisher",
  "recipe",
  "recipe_variant",
  "sales_channel",
  "seller",
  "service",
  "service_profile",
  "shipping_profile",
  "store",
  "store_order",
  "supplier",
  "team_member",
  "user",
] as const;

type ResolvedResource = {
  name: string;
  url: string;
  type: string;
};

type ResourceName = (typeof resourceNames)[number];

const aliasNames: Record<string, ResourceName> = {
  order: "store_order",
  voucher: "discount_code",
};

type ResourceType = ResourceName | keyof typeof aliasNames;

type Resource = {
  gid: string;
  objectName: string;
  id?: string;
  handle?: string;
  name: string;
  created_at: string;
  updated_at?: string;
};

const resourceIcons = {
  product: "grid_on",
  store_order: "storefront",
  // tag: 'tag',
  giftcard: "card_giftcard",
  category: "category",
  shipping_profile: "directions_boat",
  // return_policy: 'policy',
  order: "attach_money",
  // return: 'assignment_return',
  // abandoned_cart: 'remove_shopping_cart',
  team_member: "groups",
  // reseller: 'assignment_ind',
  user: "account_box",
  // review: 'feedback',
  // role: 'security',
  // company: 'business_center',
  // code: 'camera',
  supplier: "business",
  // resource: 'mdi-newspaper',
};

export function getResourceIcon(resource: ResourceType, _default = "app") {
  const name = actualResourceName(resource);
  if (!name) {
    return _default;
  }
  return get(resourceIcons, name, _default);
}

export function actualResourceName(
  resource: ResourceType
): ResourceName | undefined {
  let name: any = snakeCase(resource);
  if (has(aliasNames, name)) {
    name = get(aliasNames, name);
  }
  if (resourceNames.includes(name)) {
    return name;
  }
  return undefined;
}

export const resolveResource = (
  resource: Resource
): ResolvedResource | undefined => {
  if (resource) {
    const router = useRouter();

    let name = get(resource, "name");
    const objectName = get(resource, "objectName") as ResourceName;

    let route: {
      href: string;
    };

    const url = match(objectName, {
      event() {
        const handle = get(resource, "handle");
        if (handle) {
          route = router.resolve({
            name: "events-handle",
            params: { handle },
          });
        } else {
          route = router.resolve({ name: "events" });
        }

        return route.href;
      },
      customer() {
        const id = get(resource, "id");
        if (id) {
          route = router.resolve({
            name: "customers-id",
            params: { id },
          });
        } else {
          route = router.resolve({ name: "customers" });
        }

        return route.href;
      },
      discount_code() {
        const id = get(resource, "id");
        if (id) {
          route = router.resolve({
            name: "discounts-id",
            params: { id },
          });
        } else {
          route = router.resolve({ name: "customers" });
        }

        return route.href;
      },
      product() {
        const handle = get(resource, "handle");
        if (handle) {
          route = router.resolve({
            name: "products-handle",
            params: { handle },
          });
        } else {
          route = router.resolve({ name: "products" });
        }
        return route.href;
      },
      service() {
        const handle = get(resource, "handle");
        if (handle) {
          route = router.resolve({
            name: "services-handle",
            params: { handle },
          });
        } else {
          route = router.resolve({ name: "services" });
        }
        return route.href;
      },
      store_order() {
        const id = get(resource, "id");
        const number = get(resource, "number");
        if (id || number) {
          if (!name) {
            name = number || `#${id}`;
          }
          route = router.resolve({
            name: "orders-number",
            params: { number: id || number },
          });
        } else {
          route = router.resolve({ name: "orders" });
        }
        return route.href;
      },
      checkout_link() {
        const id = get(resource, "id");
        if (id) {
          route = router.resolve({
            name: "orders-checkout-links-id",
            params: { id },
          });
        } else {
          route = router.resolve({ name: "orders-checkout-links" });
        }
        return route.href;
      },
      team_member() {
        const id = get(resource, "id");
        if (id) {
          route = router.resolve({
            name: "settings-team-member-id",
            params: { id },
          });
        } else {
          route = router.resolve({ name: "settings-team" });
        }
        return route.href;
      },
    });

    if (url && objectName) {
      return {
        name: name || objectName,
        url,
        type: objectName,
      };
    }
  }
};
