import {
  type Attributes,
  boolean,
  id,
  integer,
  model,
  string,
} from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import { TimeFormat, UserAccountStatus } from "~/layers/admin/models";
import { Media } from "../../layers/admin/models/dobby/Media";
import { isNil, omitBy } from "lodash";
import { ServiceSchedule } from "./Service";

export class AuthPermission extends Model {
  static entity = "AuthPermission";

  @id() declare readonly id: string;
  @string() declare title: string;
  @string() declare name: string;
}

export class AuthRole extends Model {
  static entity = "AuthRole";

  @id() declare readonly id: string;
  @string() declare title: string;
  @string() declare name: string;
  @string() declare description?: string;
  @model(() => AuthPermission, { list: true })
  declare permissions?: AuthPermission[];
}

export class TeamMember extends Model {
  static entity = "TeamMember";

  constructor(attributes = {}) {
    super({
      avatar: {},
      schedules: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @id() declare readonly id: string;
  @id() declare readonly gid: string;
  @id() declare readonly objectName: string;
  @string() declare name: string;
  @string() declare email: string;
  @model(() => Media) declare avatar?: Media;
  @boolean() declare owner: boolean;
  @string() declare state: UserAccountStatus;
  @string() declare bio?: string;
  @string() declare timezone: string;
  @integer() declare week_start: number;
  @integer() declare buffer_time: number;
  @string() declare locale?: string;
  @string() declare time_format: TimeFormat;
  @boolean() declare away: boolean;
  @boolean() declare allow_dynamic_booking: boolean;
  @boolean() declare allow_seo_indexing: boolean;
  @boolean({ readonly: true }) declare readonly has_seller_account: boolean;
  @datetime() declare readonly last_active?: Dayjs;
  @model(() => ServiceSchedule, { list: true })
  declare schedules: ServiceSchedule[];
  @model(() => AuthPermission, { list: true })
  declare permissions?: AuthPermission[];
  @model(() => AuthRole, { list: true }) declare roles?: AuthRole[];

  static defaultQuery() {
    return this.select([
      "id",
      "name",
      "email",
      "avatar",
      "timezone",
      "away",
      "owner",
      "last_active",
      "has_seller_account",
    ])
      .include("permissions", ["title", "name"])
      .include("roles", ["title", "name"]);
  }

  static hooks() {
    return {
      $updating: (model: TeamMember, data: Attributes) => {
        if (model.avatar && model.avatar.$shouldUpload()) {
          data.avatar = { upload: { file: model.avatar.$getUpload() } };
        } else if (model.avatar && model.avatar.$willDelete) {
          data.avatar = { delete: true };
        }

        return data;
      },
    };
  }
}

export default TeamMember;
