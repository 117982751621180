import { TokenStatusValue } from "./types";

export default class TokenStatus {
  private status: TokenStatusValue;
  constructor(token: string | undefined, tokenExpiresAt?: number | string) {
    this.status = this.calculate(token, tokenExpiresAt);
  }

  unknown() {
    return TokenStatusValue.UNKNOWN === this.status;
  }

  valid() {
    return TokenStatusValue.VALID === this.status;
  }

  expired() {
    return TokenStatusValue.EXPIRED === this.status;
  }

  private calculate(
    token: string | undefined,
    tokenExpiresAt?: number | string
  ) {
    const now = Date.now();
    try {
      if (!token || !tokenExpiresAt) {
        return TokenStatusValue.UNKNOWN;
      }
    } catch (error) {
      return TokenStatusValue.UNKNOWN;
    }
    const timeSlackMillis = 500;
    if (typeof tokenExpiresAt == "string") {
      tokenExpiresAt = Number(tokenExpiresAt);
    }
    tokenExpiresAt -= timeSlackMillis;
    if (now < tokenExpiresAt) {
      return TokenStatusValue.VALID;
    }
    return TokenStatusValue.EXPIRED;
  }
}
