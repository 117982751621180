import { id, json, model, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import SalesChannel from "./SalesChannel";

export default class Publisher extends Model {
  static entity = "Publisher";
  static primaryKey = "handle";
  static queryAttributes = ["handle", "name"];

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @datetime() declare readonly registered_at: Dayjs;
  @id() declare readonly id: string;
  @id() declare readonly handle: string;
  @string() declare name: string;
  @string() declare website?: string;
  @string() declare company?: string;
  @json() declare account_details?: any;

  @model(() => SalesChannel, { default: [], list: true })
  declare apps: SalesChannel[];
  // @model(() => Theme, { default: [], list: true }) declare themes: Theme[]
}
