import { boolean, id, string } from "@moirei/dobby";
import Model from "./Model";
import type { IOnboardStep } from "../interfaces";

export class OnboardStep extends Model implements IOnboardStep {
  static entity = "OnboardStep";
  @id() declare readonly handle: string;
  @string() declare title: string;
  @string() declare name?: string;
  @string() declare description?: string;
  @boolean() declare complete?: boolean;
  @boolean() declare mandatory: boolean;
}
