export type TokenOption = {
  name: string;
  type?: string;
  prefix?: string;
  expirationPrefix?: string;
  storage: IAuthStorage;
  maxAge: number;
};

export enum TokenStatusValue {
  UNKNOWN = "UNKNOWN",
  VALID = "VALID",
  EXPIRED = "EXPIRED",
}

export interface IAuthStorage {
  setUniversal<V extends unknown>(key: string, value: V): V | void;
  getUniversal(key: string): unknown;
  syncUniversal(key: string, defaultValue?: unknown): unknown;
  removeUniversal(key: string): void;
  setState<V extends unknown>(key: string, value: V): V;
  getState(key: string): unknown;
  removeState(key: string): void;
  setLocalStorage<V extends unknown>(key: string, value: V): V | void;
  getLocalStorage(key: string): unknown;
  removeLocalStorage(key: string): void;
  getCookies(): Record<string, unknown>;
  setCookie<V extends unknown>(
    key: string,
    value: V,
    options?: {
      prefix?: string;
    }
  ): V;
  getCookie(key: string): unknown;
  removeCookie(
    key: string,
    options?: {
      prefix?: string;
    }
  ): void;
}
