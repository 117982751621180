import type { Attributes } from "@moirei/dobby";
import { omit } from "lodash";
import { ProductOption } from "../Product";

export const creatingProductOption = (
  model: ProductOption,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  return omit(data, ["id"]);
};

export const updatingProductOption = (
  model: ProductOption,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  data.id = model.id;

  return data;
};
