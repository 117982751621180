import { isBoolean } from "lodash";
import type { VolumeServiceUsage } from "~/layers/admin/modules/auth/runtime/types/api";

export const useServiceUsage = () => {
  const loading = ref(false);
  const { context } = useAuth();

  const usage = computed(() => context.value?.workspace?.usage || {});

  const volumeUsage = computed(() => {
    return Object.entries(usage.value).reduce(
      (acc: VolumeServiceUsage, entry) => {
        const [key, value] = entry;
        if (value.type == "volume" && !isBoolean(value.limit)) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
  });

  // TODO: call auth to refresh context.usage
  const refresh = async () => {
    // update loading
  };

  const feature = (key: string) => {
    const limit = computed(() => usage.value[key]?.limit);
    const usage_ = computed(() => usage.value[key]?.usage);
    return {
      limit,
      usage: usage_,
    };
  };

  return {
    loading,
    usage,
    volumeUsage,
    feature,
    refresh,
  };
};
