<template>
  <span>
    <Icon name="heroicons:information-circle-16-solid" />
    <v-tooltip activator="parent" v-bind="p">
      <slot>{{ text }}</slot>
    </v-tooltip>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VTooltip } from "vuetify/components";
import { omit } from "lodash";

export default defineComponent({
  name: "TipInfo",
  inheritAttrs: false,
  props: {
    ...VTooltip.props,
    text: { type: String },
    maxWidth: { type: [String, Number], default: 400 },
    openOnClick: { type: Boolean, default: true },
    openOnFocus: { type: Boolean, default: true },
  },
  computed: {
    p() {
      return omit(this.$props, ["text", "attach", "activator"]);
    },
  },
});
</script>
