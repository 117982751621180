import { VOverlay, VProgressCircular } from "vuetify/components";
import {
  Chart,
  Interaction,
  type ChartConfiguration,
  type ChartOptions,
  registerables,
} from "chart.js";
import { merge, throttle } from "lodash";
import type { BaseChartOptions, IChart } from "./types";
import { ChartType } from "./types";
import { CrosshairPlugin, Interpolate } from "chartjs-plugin-crosshair";
// import Interpolate from "./chartjs-interpolate-interaction";

Chart.register(...registerables, CrosshairPlugin);

// @ts-expect-error
Interaction.modes.interpolate = Interpolate;

import "chartjs-plugin-style";

export const defineChartComponent = <T extends ChartType>(chart: IChart<T>) => {
  return defineComponent({
    name: chart.cmpName,
    props: {
      elId: { type: String },
      loading: { type: Boolean, default: false },
      width: { type: [Number, String] },
      height: { type: [Number, String] },
      cssClasses: { type: String },
      group: { type: String },
      data: {
        type: Object as PropType<ChartConfiguration<T>["data"]>,
        required: true,
      },
      options: { type: Object as PropType<any> },
    },
    setup(props) {
      const canvas = ref<HTMLCanvasElement>();

      const { render, destroy } = useChartJs(() => ({
        el: canvas.value,
        data: props.data,
        options: props.options as any,
        plugins: chart.plugins as any,
        chart: {
          ...chart,
          options: {
            group: props.group,
            ...chart.options,
          },
        },
      }));

      onMounted(render);
      onUnmounted(destroy);

      return {
        canvas,
        render,
      };
    },
    render() {
      const width = convertToUnit(this.width);
      const height = convertToUnit(this.height);

      const children: any[] = [
        h("canvas", {
          id: this.elId || chart.elId,
          style: {
            width,
            height,
          },
          ref: "canvas",
        }),
      ];

      if (this.loading) {
        children.push(
          h(
            VOverlay,
            {
              modelValue: true,
              opacity: 0.25,
              contained: true,
              class: "flex-center",
              zIndex: 1,
            },
            [
              h(VProgressCircular, {
                width: 1,
                size: 30,
                indeterminate: true,
              }),
            ]
          )
        );
      }

      return h(
        "div",
        {
          style: {
            position: "relative",
            width,
            height: height || "auto",
          },
          class: this.cssClasses,
        },
        children
      );
    },
  });
};

export const useChartJs = <T extends ChartType>(options: {
  (): {
    el: HTMLCanvasElement | undefined;
    chart: IChart<T>;
    data: ChartConfiguration<T>["data"];
    options?: ChartConfiguration<T>["options"];
    plugins?: ChartConfiguration<T>["plugins"];
  };
}) => {
  let chart: Chart<T>;

  const _render = () => {
    const o = options();
    if (o.el) {
      if (chart) chart.destroy();
      const ctx = o.el.getContext("2d")!;
      const _options = getChartOptions({ ...o.chart.options });
      chart = new Chart<any>(ctx, {
        type: o.chart.type,
        data: o.data,
        options: merge(_options, {
          ...o.options,
        }),
      });
    }
  };

  const render: typeof _render = throttle(_render, 100, {
    leading: false,
    trailing: true,
  });

  const destroy = () => chart?.destroy();

  watchEffect(render);

  return {
    render,
    destroy,
  };
};

const getChartOptions = (o: BaseChartOptions): ChartOptions => ({
  legend: { display: o?.legend },
  interaction: {
    // @ts-expect-error
    mode: "interpolate",
    intersect: false,
  },
  plugins: {
    tooltip: {
      // @ts-expect-error
      mode: "interpolate",
      intersect: false,
    },
    crosshair: {
      line: {
        color: "#aaa", // crosshair line color
        // color: "#5c6bc0",
        width: 0.6, // crosshair line width
        dashPattern: [3, 1],
      },
      sync: {
        enabled: !!o.syncCursor, // enable trace line syncing with other charts
        group: o.group || 1, // chart group
        suppressTooltips: false, // suppress tooltips when showing a synced tracer
      },
    },
  },
  scales: {
    x: {
      ticks: {
        display: !!o.xAxes,
      },
      grid: {
        display: !!o.xGridLines,
      },
    },
    y: {
      beginAtZero: o.beginAtZero,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
});
