import { defineNuxtPlugin } from "#app";
import VDynamicForm from "./components/VDynamicForm";
import VInputGroup from "./components/VInputGroup";
import ContactInput from "./components/ContactInput";
import AddressInput from "./components/AddressInput";
import VCountrySelect from "./components/VCountrySelect/VCountrySelect";
import VRegionSelect from "./components/VCountrySelect/VRegionSelect";
import VPhoneInput from "./components/VPhoneInput";
import ConfirmEditInput from "./components/ConfirmEditInput.vue";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("VDynamicForm", VDynamicForm);
  nuxtApp.vueApp.component("VInputGroup", VInputGroup);
  nuxtApp.vueApp.component("ContactInput", ContactInput);
  nuxtApp.vueApp.component("AddressInput", AddressInput);
  nuxtApp.vueApp.component("VCountrySelect", VCountrySelect);
  nuxtApp.vueApp.component("VRegionSelect", VRegionSelect);
  nuxtApp.vueApp.component("VPhoneInput", VPhoneInput);
  nuxtApp.vueApp.component("ConfirmEditInput", ConfirmEditInput);
});
