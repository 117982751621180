import { allCountries } from 'country-region-data'
import { arrayAllIn } from './object'

export type RegionEntity = {
  name: string
  code?: string
}

export type CountryPickerObject = {
  name: string
  code: string
  key: string
  isCountry?: boolean
  disabled?: boolean
  children?: CountryPickerObject[]
  shippingProfile?: string
}

const regionCache: Record<string, RegionEntity[]> = {}
const countryRegionCache: Record<string, CountryPickerObject[]> = {}

export const getCountryName = (countryCode: string): string => {
  if (countryCode?.length > 3) return countryCode // might already be a name
  const country = getCountryRegionData().find((c) => c.code == countryCode)
  return country?.name || countryCode
}

export const getCountryCode = (countryName: string): string => {
  if (countryName?.length < 3) return countryName // might already be a code
  const country = getCountryRegionData().find((c) => c.name == countryName)
  return country?.code || countryName
}

export const getCountryRegions = (
  country: string,
  isName = false
): RegionEntity[] => {
  const queryKey = JSON.stringify({
    country,
    isName,
  })

  if (!regionCache[queryKey]) {
    const data = getCountryRegionData().find((c) => {
      if (isName) return c.name == country
      return c.code == country
    })

    regionCache[queryKey] = data && data.children ? data.children : []
  }

  return regionCache[queryKey]
}

export const isAllCountryRegions = (
  countryCode: string,
  regions: string[],
  isRegionName = false
): boolean => {
  if (regions && regions.length) {
    const knownRegions = getCountryRegions(countryCode)
    if (knownRegions.length) {
      return arrayAllIn(
        knownRegions.map((r) => (isRegionName ? r.name : r.code)),
        regions
      )
    }
  }

  return false
}

export const getCountryRegionData = ({
  useCountryName = false,
  useRegionName = false,
  disabled = [] as string[],
} = {}): CountryPickerObject[] => {
  const queryKey = JSON.stringify({
    useCountryName,
    useRegionName,
    disabled,
  })

  if (!countryRegionCache[queryKey]) {
    countryRegionCache[queryKey] = allCountries.map(
      ([countryName, countryCode, regions]) => {
        const key: string = useCountryName ? countryName : countryCode

        return {
          name: countryName,
          code: countryCode,
          key,
          disabled: disabled.includes(key),
          // disabled: false,
          isCountry: true,
          children: regions.map(([regionName, regionCode]) => {
            const rKey =
              countryCode + ':' + (useRegionName ? regionName : regionCode)
            return {
              name: regionName,
              code: regionCode,
              key: rKey,
              disabled: disabled.includes(rKey),
              // disabled: rKey in disabled,
              // disabled: false,
            }
          }),
        }
      }
    )
  }

  return countryRegionCache[queryKey]
}
