import { id, json, model, string } from "@moirei/dobby";
import { omitBy, isNil } from "lodash";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import Product from "./Product";
import {
  creatingCollection,
  updatingCollection,
} from "./hooks/collection-hooks";

export default class Collection extends Model {
  static entity = "Collection";
  static primaryKey = "handle";
  static queryAttributes = ["handle", "name"];

  constructor(attributes = {}) {
    super({
      products: [],
      ...omitBy(attributes, isNil),
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @string() declare readonly handle: string;
  @string() declare name: string;
  @string() declare icon?: string;
  @string() declare seo_title?: string;
  @string() declare seo_description?: string;
  @string() declare description?: string;
  @json() declare meta?: Object;
  // @boolean()  automated: string
  @model(() => Product, { list: true }) declare products: Product[];
  // @model(() => Event, { list: true })  declare events: Event[]
  // @model(() => Service, { list: true }) declare services: Service[]

  static hooks() {
    return {
      $creating: creatingCollection,
      $updating: updatingCollection,
    };
  }
}
