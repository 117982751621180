import {
  attr,
  boolean,
  float,
  id,
  integer,
  model,
  string,
} from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import {
  mixed,
  OrderFulfillmentStatus,
  OrderKind,
  OrderSource,
  OrderStatus,
  PaymentStatus,
} from "~/layers/admin/models";
import SalesChannel from "./SalesChannel";
import Currency from "./Currency";
import Address from "./Address";
import Customer from "./Customer";
import { EventTicket } from "./Event";
import type { IOrder } from "../interfaces/Order";
import Service, { ServiceBooking } from "./Service";
import { Refund } from "./Refund";
import type { Activity } from "../interfaces/Activity";

export class Order extends Model implements IOrder {
  static override entity = "StoreOrder";

  @id() declare readonly id: string;
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @datetime() declare readonly closed_at?: Dayjs;

  @string() declare readonly gid: string;
  @string() declare readonly objectName: string;

  @string() declare readonly kind: OrderKind;
  @string() declare readonly source?: OrderSource;
  @string() declare readonly channel: string;
  @datetime() declare readonly cancelled_at?: Dayjs;
  @string() declare readonly payment_status: PaymentStatus;
  @string() declare readonly email: string;
  @string() declare readonly phone?: string;
  @string() declare readonly customer_locale: string;
  @model(() => Address) declare readonly shipping_address?: Address;
  @model(() => Address) declare readonly billing_address?: Address;
  @boolean() declare readonly buyer_accepts_marketing?: boolean;
  @string() declare readonly browser_ip?: string;
  @string() declare readonly cancel_reason?: string;

  @string() declare readonly name?: string;
  @string() declare readonly number: string;
  @string() declare readonly status: OrderStatus;
  @string() declare readonly fulfillment_status: OrderFulfillmentStatus;
  @string() declare readonly source_name: string;
  @boolean() declare readonly taxes_included: boolean;
  @string() declare readonly notes: string;
  // payment_details: Json
  // shipping_lines: [Json!]
  // tax_lines: [Json!]
  @float() declare readonly total_tax: number;
  @float() declare readonly total_duties: number;
  @float() declare readonly total_shipping: number;
  @float() declare readonly total_discounts: number;
  @float() declare readonly total_charges: number;
  @float() declare readonly total_outstanding: number;
  @float() declare readonly line_items_total: number;
  @float() declare readonly total_weight: number;
  @float() declare readonly total_refundable: number;
  @float() declare readonly subtotal: number;
  @float() declare readonly total: number;
  @float() declare readonly estimated_taxes?: number;
  @string() declare readonly currency_code?: string;
  @mixed({ list: true }) declare readonly activities: Activity[];
  // meta: Json
  // note_attributes: [Json!]

  // relationships
  @model(() => OrderItem, { default: [], list: true })
  declare items: OrderItem[];
  @model(() => Refund, { default: [], list: true })
  declare refunds: Refund[];
  @model(() => Customer) declare readonly customer?: Customer;
  @model(() => Currency) declare currency: Currency;
  @model(() => SalesChannel) declare salesChannel: SalesChannel;
  @model(() => ServiceBooking, { default: [], list: true })
  declare serviceBookings: ServiceBooking[];

  get itemsCount(): number {
    return this.items?.length ?? 0;
  }

  public $isMixedKind() {
    return this.kind === OrderKind.MIXED;
  }

  public $isProductKind() {
    return this.$isMixedKind() || this.kind === OrderKind.PRODUCT;
  }

  public $isEventKind() {
    return this.$isMixedKind() || this.kind === OrderKind.EVENT;
  }
}

export class OrderItem extends Model {
  static override entity = "StoreOrderItem";

  @attr() declare id: string;
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @string() declare name: string;
  @integer() declare quantity: number;
  @float() declare total: number;
  @boolean() declare requires_shipping: boolean;
  // properties: Json
  @string() declare item_id: string;
  @string() declare item_type: string;
  @string() declare item_gid: string;
  @model(() => EventTicket) declare eventTicket?: EventTicket;
  @model(() => Service) declare service?: Service;
}
