import { float, id, integer, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import { PriceRuleValueType } from "~/layers/admin/models";
import {
  creatingDiscountCode,
  updatingDiscountCode,
} from "./hooks/discount-code-hooks";

export enum VoucherScheme {
  INSTANCE = "INSTANCE",
  ITEM = "ITEM",
  REDEEMER = "REDEEMER",
}

export class DiscountCode extends Model {
  static entity = "DiscountCode";
  static primaryKey = "code";
  static primaryKeys = ["id", "code"];

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;

  @id() declare readonly id: string;
  @string() declare name: string;
  @string() declare code: string;
  @string() declare description?: string;
  @string() declare currency_code: string;
  @integer() declare quantity?: number;
  @float() declare value: number;
  @string() declare value_type: PriceRuleValueType;
  @string() declare limit_scheme: VoucherScheme;
  @datetime() declare active_date: Dayjs;
  @datetime() declare expires_at: Dayjs;
  @string() declare notes?: string;

  public $isPercentage() {
    return this.value_type == PriceRuleValueType.PERCENTAGE;
  }

  get displayValue() {
    if (!isNumeric(this.value)) {
      return "—";
    }

    if (this.$isPercentage()) {
      return `${this.value}% off`;
    }

    return formatPrice(this.value, this.currency_code) + " discount";
  }

  static hooks() {
    return {
      $creating: creatingDiscountCode,
      $updating: updatingDiscountCode,
    };
  }
}
