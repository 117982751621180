import type { Attributes } from "@moirei/dobby";
import { pick } from "lodash";
import { ShippingZoneCountry } from "../ShippingProfile";

export const creatingShippingZoneCountry = (
  model: ShippingZoneCountry,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (!model.include_all_provinces && model.provinces) {
    data.provinces = model.provinces
      .filter((province) => !province.$willDelete)
      .map((province) => province.name);
  }

  return pick(data, ["code", "include_all_provinces", "provinces"]);
};

export const updatingShippingZoneCountry = (
  model: ShippingZoneCountry,
  data?: Attributes
) => {
  const attributes: Attributes = creatingShippingZoneCountry(model, data);
  attributes.id = model.id;

  return attributes;
};
