import { joinURL } from "ufo";
import { useVibrate } from "@vueuse/core";
import { useStaStore } from "./store";
import type {
  StaEventInfo,
  StaScanResult,
  StaScanStatus,
  StaSession,
  Ticket,
} from "./types";
import { events } from "./events";

export const useStaApi = () => {
  const store = useStaStore();
  const config = useRuntimeConfig();
  const baseUrl = joinURL(config.public.server.url, "/api/sta");

  const apiGet = <T>(path: string) => {
    const url = joinURL(baseUrl, path);
    return $fetch<T>(url, {
      method: "GET",
      headers: {
        "X-Event-Sta-Token": store.token,
      },
    });
  };

  const apiPost = <T>(path: string, data?: any) => {
    const url = joinURL(baseUrl, path);
    return $fetch<T>(url, {
      method: "POST",
      body: data,
      headers: {
        "X-Event-Sta-Token": store.token,
      },
    });
  };

  return {
    async start(eventOccurrenceId: string | number) {
      const { getWorkspace } = useWorkspace();
      const { getToken } = useAuth();
      const url = joinURL(baseUrl, "start");

      const headers: any = {
        "X-Access": config.public.server.access,
        ...filterUndefined({
          Authorization: getToken(),
          "X-Workspace": getWorkspace(),
        }),
      };

      const session = await $fetch<StaSession>(url, {
        method: "POST",
        body: {
          occurrence: String(eventOccurrenceId),
        },
        headers,
      });

      store.setSession(session);

      return session;
    },
    async scan(code: string) {
      store.scanning = true;
      const result = await apiPost<StaScanResult>("scan", { code });
      store.scanResult = result;
      store.scanning = false;
      nextTick(() => {
        events.callHook("scanned", result.status);
      });
      return result;
    },
    async admit(code: string) {
      return apiPost("admit", { code });
    },
    async check(token: string) {
      const session = await apiPost<StaSession | null>("check", {
        token,
      });

      if (session) {
        store.setSession(session);
      }

      return session;
    },
    async join(shareToken: string) {
      const session = await apiPost<StaSession>("join", {
        shareToken,
        token: store.token,
      });

      store.setSession(session);

      return session;
    },
    startEvent() {
      return apiPost<boolean>("start-event");
    },
    endEvent() {
      return apiPost<boolean>("end-event");
    },
    info() {
      return apiGet<StaEventInfo>("info");
    },
    ticket(code: string) {
      return apiGet<Ticket>(`ticket/${code}`);
    },
    attendees(search?: string) {
      return apiPost<Ticket[]>("attendees", { search });
    },
  };
};

export const startStaSession = async (eventOccurrenceId: string | number) => {
  const api = useStaApi();
  const session = await api.start(eventOccurrenceId);
  return session.token;
};

export const joinStaSession = async (shareToken: string) => {
  const api = useStaApi();
  const session = await api.join(shareToken);
  return session.token;
};

export const getStaSession = async (token: string) => {
  const api = useStaApi();
  const session = await api.check(token);
  return session?.token;
};

export const invalidStaSession = () => {
  throw createError({
    statusCode: 401,
    statusMessage: "Invalid or expired session",
  });
};

export const initEffectors = () => {
  const store = useStaStore();
  const vibrator = useVibrate();

  const successAudio = new Audio("/sta/success.mp3");
  const errorAudio = new Audio("/sta/error.mp3");

  const effectors = {
    sound: {
      get enabled() {
        return store.sound;
      },
      handle: async (status: StaScanStatus) => {
        if (status === "ok" || status === "checked_in") {
          successAudio.play();
        } else {
          errorAudio.play();
        }
      },
    },
    vibrate: {
      get enabled() {
        return store.vibrate;
      },
      handle: async (status: StaScanStatus) => {
        if (status === "ok") {
          vibrator.vibrate([100]);
        } else if (status === "checked_in") {
          vibrator.vibrate([300, 100, 300]);
        } else {
          vibrator.vibrate([1000]);
        }
      },
    },
  };

  const handle = (status: StaScanStatus) => {
    for (const effector of Object.values(effectors)) {
      if (effector.enabled) {
        effector.handle(status);
      }
    }
  };

  events.hook("scanned", handle);
};
