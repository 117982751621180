import type { FieldInputs } from "~/layers/ui/modules/VDynamicForm/runtime/types";

export type ConfirmationInput = {
  title: string;
  subtitle?: string;
  message?: string;
  doneText?: string;
  cancelText?: string;
  inputs?: FieldInputs;
  width?: number | string;
  height?: number | string;
  maxHeight?: number | string;
  noPadContent?: boolean;
  inputsDefaults?: unknown;
};

export type ConfirmationLikeInput<A extends any[]> =
  | ConfirmationInput
  | { (...a: A): ConfirmationInput | boolean };

export type ConfirmData = {
  id: string;
  options: ConfirmationInput;
  confirm: (inputs?: any) => void;
  cancel: () => void;
};

const store = ref<ConfirmData[]>([]);

export const useConfirmStore = () => {
  const add = (options: ConfirmationInput) => {
    return new Promise<unknown>((resolve, reject) => {
      const id = uuid();
      const rmFn = () => setTimeout(() => remove(id), 1000);
      const confirm = (inputs?: any) => {
        resolve(inputs);
        rmFn();
      };
      const cancel = () => {
        reject();
        rmFn();
      };

      store.value.push({
        id,
        confirm,
        cancel,
        options: {
          doneText: "Confirm",
          cancelText: "Cancel",
          ...options,
        },
      });
    });
  };

  const remove = (id: string) => {
    store.value = store.value.filter((o) => o.id !== id);
  };

  return {
    entries: readonly(store),
    add,
  };
};
