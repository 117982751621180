export enum ProductKind {
  STANDARD = 'STANDARD',
  VARIATION = 'VARIATION',
  COMPOSITE = 'COMPOSITE',
}

export enum ProductAvailability {
  STOCKED = 'STOCKED',
  IN_STORE_ONLY = 'IN_STORE_ONLY',
  LIMITED = 'LIMITED',
  ONLINE_ONLY = 'ONLINE_ONLY',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  ARCHIVED = 'ARCHIVED',
  PREORDER = 'PREORDER',
  PRESALE = 'PRESALE',
  SOLDOUT = 'SOLDOUT',
  DISCONTINUED = 'DISCONTINUED',
}

export enum InventoryPolicy {
  DENY = 'DENY',
  CONTINUE = 'CONTINUE',
}

export enum ProductResourceType {
  PRODUCT = 'PRODUCT',
  EVENT = 'EVENT',
  SERVICE = 'SERVICE',
}
