import { defineNuxtPlugin } from "#app";
import mousetrap from "mousetrap";
import { useAppEvent } from "../composables/actions";

export default defineNuxtPlugin(() => {
  const config = useAppConfig();
  const { broadcast } = useAppEvent();

  for (const group of config.app.shortcuts) {
    for (const shortcut of group.items) {
      const keys = Array.isArray(shortcut.keys)
        ? shortcut.keys.join(" ")
        : shortcut.keys;

      mousetrap.bind(keys, () => {
        if (shortcut.action) {
          broadcast(shortcut.action);
        }
        if (shortcut.routeTo) {
          navigateTo(shortcut.routeTo);
        }
      });
    }
  }
});
