import { defineNuxtPlugin } from "#app";
import MediaLibrary from "./MediaLibrary";
import DobbyAdapter from "./DobbyAdapter";

export default defineNuxtPlugin(() => {
  const adapter = new DobbyAdapter();
  const mediaLibrary = new MediaLibrary({ adapter });

  return {
    provide: {
      mediaLibrary,
    },
  };
});
