import gql from "graphql-tag";
import { throttle, get as get0, set as set0, isEmpty } from "lodash";
import type { UnwrapNestedRefs } from "vue";
import type { UnwrapRef } from "vue";
import type { AppConfig } from "~/types/common";

type ObjectKey = string | string[];

interface Context {
  /**
   * Define a persisted ref field.
   * @param key
   * @param value
   */
  ref<T>(key: ObjectKey, value: T): Ref<UnwrapRef<T>>;
  ref<T = any>(key: ObjectKey): Ref<T | undefined>;

  /**
   * Define a persisted reactive field.
   * @param key
   * @param target
   */
  reactive<T extends object>(key: ObjectKey, target: T): UnwrapNestedRefs<T>;
}

interface StoreAdapter {
  get(key?: string): unknown;
  save(data: unknown): Promise<void>;
  fetch(): Promise<unknown>;
}

type RefRefs = {
  key: ObjectKey;
  ref: Ref<any> | UnwrapNestedRefs<any>;
};

const makeStore = (options: {
  namespace: string;
  adapter: { (name: ObjectKey): StoreAdapter };
}) => {
  return <T>(name: ObjectKey, fn: { (ctx: Context): T }) => {
    return defineStore(`${options.namespace}:${name}`, () => {
      const adapter = options.adapter(name);

      const saving = ref(false);
      const fetching = ref(false);
      const loading = computed(() => saving.value || fetching.value);

      const refs: RefRefs[] = [];

      const ctx: Context = {
        ref: (key: string, v?: any) => {
          const x = orUse(adapter.get(key), v);
          const r = ref(x);
          refs.push({ key, ref: r });
          return r;
        },
        reactive: (key: string, v: any) => {
          const x = orUse(adapter.get(key), v);
          const r = reactive(x);
          refs.push({ key, ref: r });
          return r;
        },
      };

      const s = fn(ctx);

      const updateRefs = wrapUniqueCalls((data: any) => {
        if (refs.length == 1 && !refs[0].key) {
          if (isRef(refs[0].ref)) {
            refs[0].ref.value = data;
          } else {
            Object.assign(refs[0].ref, data);
          }
        } else {
          refs.forEach((entry) => {
            if (isRef(entry.ref)) {
              entry.ref.value = get0(data, entry.key);
            } else {
              Object.assign(entry.ref, get0(data, entry.key));
            }
          });
        }
      });

      const save$0 = wrapUniqueCalls((data) => {
        saving.value = true;
        adapter
          .save(data)
          .catch(() => {
            // TODO: log error
          })
          .finally(() => {
            saving.value = false;
          });
      });

      const save = guard(throttle(save$0, 3e3));

      // disable saving until external data has been fetched
      save.disable();

      watchEffect(() => {
        let data: any = {};
        if (refs.length == 1 && !refs[0].key) {
          // if key is falsy, and only one ref, assume we're using root
          data = unref(refs[0].ref);
        } else {
          refs.forEach((entry) => {
            set0(data, entry.key, unref(entry.ref));
          });
        }
        save(data);
      });

      watchEffect(() => {
        const config = adapter.get();
        // Only update is adapter sourced values so we don't override any local defaults.
        // Values can be empty is user hasn't update this config
        // or it has not been loaded from backend
        if (!isEmpty(config)) {
          save.disable();
          nextTick(() => {
            updateRefs(config);
            nextTick(() => {
              save.enable();
            });
          });
        } else {
          save.enable();
        }
      });

      /** Refresh store refs with external data  */
      const refresh = () => {
        fetching.value = true;
        adapter
          .fetch()
          .then(updateRefs)
          .catch(() => {
            // TODO: log error
          })
          .finally(() => {
            fetching.value = false;
          });
      };

      const $reset = () => {
        saving.value = false;
        fetching.value = false;
      };

      return {
        ...s,
        saving: readonly(saving),
        fetching: readonly(fetching),
        loading,
        refresh,
        $reset,
      };
    });
  };
};

const makeAdapter = (options: {
  /** Name of the config in `AppConfig` */
  config: string;
  query: string;
  mutation: string;
}) => {
  return (name: ObjectKey): StoreAdapter => {
    const apollo = useApollo();
    const auth = useAuth();

    const all = (): any => {
      const ctx = toRaw(auth.context.value);
      const config: Partial<AppConfig> = ctx?.app?.config || {};
      return get0(config, [options.config, ...wrap(name)]);
    };

    const get = (key?: string) => {
      const config = all();
      if (key) {
        return get0(config, key);
      }
      return config;
    };

    const save = async (data: unknown) => {
      const ctx = toRaw(auth.context.value);
      const config = get0(ctx?.app?.config, options.config) || {};
      set0(config, name, data);

      await apollo.mutate({
        mutation: gql`
          mutation ($data: Mixed!) {
            ${options.mutation}(data: $data)
          }
        `,
        variables: { data: config },
      });
    };

    const fetch = async () => {
      const result = await apollo.query({
        query: gql`
          query {${options.query}}
        `,
      });

      return get0(result.data, options.query) || {};
    };

    return {
      get,
      save,
      fetch,
    };
  };
};

export const defineUserConfigStore = makeStore({
  namespace: "user-config",
  adapter: makeAdapter({
    config: "user",
    query: "wsUserConfig",
    mutation: "wsUserConfig",
  }),
});

export const defineWorkspaceConfigStore = makeStore({
  namespace: "workspace-config",
  adapter: makeAdapter({
    config: "workspace",
    query: "wsConfig",
    mutation: "wsConfig",
  }),
});
