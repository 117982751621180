import {
  Active,
  Bool,
  Chip,
  Count,
  Date,
  Email,
  Phone,
  Price,
  Resource,
  Title,
  Url,
} from "./Column/components";
import type {
  DataTableComponent,
  DataTableView,
  DataTableWrappedComponent,
} from "./types";
import { get, has, isString } from "lodash";

export const defaultView: DataTableView = {
  id: "all",
  title: "All",
  // @ts-ignore
  __isDefault: true,
};

export const isDefaultView = (view: DataTableView) => {
  return !!get(view, "__isDefault");
};

export const isWrappedComponent = (
  c?: DataTableComponent
): c is DataTableWrappedComponent => {
  return !!c && Object.keys(c).length >= 2 && has(c, "component");
};

export const getItemComponent = (component?: DataTableComponent) => {
  if (!component) {
    return "div";
  }

  let cmp: any;
  if (isWrappedComponent(component)) {
    cmp = component.component;
  } else {
    cmp = component;
  }

  if (isString(cmp)) {
    return match(cmp, {
      date: Date,
      count: Count,
      boolean: Bool,
      active: Active,
      chip: Chip,
      price: Price,
      email: Email,
      phone: Phone,
      title: Title,
      url: Url,
      resource: Resource,
      default: () => resolveComponent(cmp),
    });
  }

  return cmp;
};

export const getItemComponentProps = (
  key: string,
  item: any,
  component?: DataTableComponent
) => {
  if (isWrappedComponent(component)) {
    if (component.useItemValue) {
      item = get(item, key);
    }
    const propKey = component?.usePropKey || "item";
    return {
      ...component.props,
      [propKey]: item,
      itemKey: key,
    };
  }
  return {
    item,
    itemKey: key,
  };
};

export const useTableAction = () => {
  const tableId = inject("tableId") as string;
  type Event =
    | "save-view"
    | "save-view-as"
    | "exit-configure"
    | "add-view"
    | "edit-view"
    | "delete-view";
  type Events = Record<Event, { (...a: any[]): Promise<void> | void }>;
  return useNamespacedEvent<Events>(`app:table:${tableId}:`);
};
