import type { PropType } from "vue";
import type { MetricsControls, GenericMetricsDataSource } from "../types";

export default defineComponent({
  name: "ProvideMetrics",
  props: {
    tag: { type: String, default: "div" },
    sources: {
      type: [Object, Array, String] as PropType<GenericMetricsDataSource>,
      required: true,
    },
    controls: { type: Object as PropType<MetricsControls> },
  },
  setup(props) {
    const { sources, controls } = toRefs(props);
    const context = provideMetrics(sources, controls);

    return {
      loading: context.loading,
    };
  },
  render() {
    return h(this.tag, [
      this.$slots.default!({
        loading: this.loading,
      }),
    ]);
  },
});
