import type { Attributes } from "@moirei/dobby";
import { isNil, pick } from "lodash";
import { ShippingZoneRate } from "../ShippingProfile";

export const creatingShippingZoneRate = (
  model: ShippingZoneRate,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (model.transit && model.$isManual()) {
    data.transit = model.transit;
  }

  if (model.handling_fee) {
    data.handling_fee = model.handling_fee.$toJson();
  }

  if (
    model.conditions &&
    !isNil(model.conditions.type) &&
    !isNil(model.conditions.min)
  ) {
    data.conditions = model.conditions.$toJson();
  }

  return pick(data, [
    "kind",
    "name",
    "transit",
    "price",
    "conditions",
    "carrier",
    "carrier_services",
    "future_services",
    "handling_fee",
  ]);
};

export const updatingShippingZoneRate = (
  model: ShippingZoneRate,
  data?: Attributes
) => {
  data = creatingShippingZoneRate(model, data);
  data.id = model.id;
  return data;
};
