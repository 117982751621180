import { id, string } from "@moirei/dobby";
import { Model } from "~/layers/admin/models/dobby";

export default class BankCard extends Model {
  static entity = "BankCard";
  @id() declare readonly id: string;
  @string() declare address_city?: string;
  @string() declare address_country?: string;
  @string() declare address_line1?: string;
  @string() declare address_line1_check?: string;
  @string() declare address_line2?: string;
  @string() declare address_state?: string;
  @string() declare address_zip?: string;
  @string() declare address_zip_check?: string;
  @string() declare brand?: string;
  @string() declare country?: string;
  @string() declare cvc_check?: string;
  @string() declare dynamic_last4?: string;
  @string() declare exp_month?: string;
  @string() declare exp_year?: string;
  @string() declare fingerprint?: string;
  @string() declare funding?: string;
  @string() declare last4?: string;
  @string() declare name?: string;
  @string() declare tokenization_method?: string;
  @string() declare account?: string;
}
