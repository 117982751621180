import { attr, boolean, id, integer, model, string } from "@moirei/dobby";
import { omitBy, isNil } from "lodash";
import type { Dayjs } from "dayjs";
import SalesChannel from "./SalesChannel";
import {
  Model,
  datetime,
  InventoryPolicy,
  CheckoutSubmitType,
  PaymentType,
  type ICheckoutLink,
  type ICheckoutLinkItem,
  type IAdjustableQuantity,
} from "@/layers/admin/models";
import Currency from "./Currency";
import {
  creatingCheckoutLink,
  updatingCheckoutLink,
} from "./hooks/checkout-link-hooks";
import { Order } from "./Order";

export class CheckoutLink extends Model implements ICheckoutLink {
  static entity = "CheckoutLink";
  static queryAttributes = ["id", "name", "mode", "web_url"];

  constructor(attributes = {}) {
    super({
      items: [],
      checkouts: [],
      allowed_emails: [],
      discounts: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @datetime() declare readonly expires_at?: Dayjs;
  @id() declare readonly id: string;
  @string() declare readonly token: string;
  @string() declare name: string;
  @string() declare mode: PaymentType;
  @string() declare description?: string;
  @string() declare web_url: string;
  @boolean() declare allow_promotion_codes?: boolean;
  @boolean() declare disabled?: boolean;
  @string() declare customer_email: string;
  @string({ default: [], list: true }) declare allowed_emails: string[];
  @string() declare inventory_policy?: InventoryPolicy;
  @string() declare channel: string;
  @string() declare currency_code: string;
  @string() declare submit_type: CheckoutSubmitType;
  @string({ default: [], list: true }) declare discounts: string[];

  // relationships
  @model(() => CheckoutLinkItem, { default: [], list: true })
  declare items: CheckoutLinkItem[];
  @model(() => Order, { default: [], list: true }) declare orders: Order[];
  @model(() => Checkout, { default: [], list: true })
  declare checkouts: Checkout[];
  @model(() => Currency) declare currency: Currency;
  @model(() => SalesChannel) declare salesChannel: SalesChannel;

  get last_updated(): Dayjs {
    return this.updated_at || this.created_at;
  }

  get itemsCount(): number {
    return this.items?.length ?? 0;
  }

  get checkoutsCount(): number {
    return this.checkouts?.length ?? 0;
  }

  get active(): boolean {
    return !this.disabled;
  }

  $url() {
    return this.web_url;
  }

  $keepChanges(): void | false {
    super.$keepChanges();
    if (this.items && this.items.length) {
      this.items.forEach((item) => item.$keepChanges());
    }
  }

  static hooks() {
    return {
      $creating: creatingCheckoutLink,
      $updating: updatingCheckoutLink,
    };
  }
}

export class CheckoutLinkItem extends Model implements ICheckoutLinkItem {
  static entity = "CheckoutLinkItem";

  constructor(attributes = {}) {
    super({
      adjustable_quantity: {},
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @attr() declare id: string;
  @string() declare name: string;
  @integer() declare quantity: number;
  @string() declare item_id: string;
  @string() declare item_type: string;
  @string() declare item_gid: string;
  @model(() => AdjustableQuantity)
  declare adjustable_quantity?: AdjustableQuantity;
}

export class Checkout extends Model {
  static entity = "Checkout";
  static primaryKey = "token";

  @attr() declare token: string;
  @string() declare mode: PaymentType;
}

export class AdjustableQuantity extends Model implements IAdjustableQuantity {
  static entity = "AdjustableQuantity";

  @boolean() declare enabled: boolean;
  @integer() declare maximum: number;
  @integer() declare minimum: number;
}
