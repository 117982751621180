import type { Attributes } from "@moirei/dobby";
import type { DiscountCode } from "../DiscountCode";
import { omit } from "lodash";

export const creatingDiscountCode = (
  model: DiscountCode,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (data.products && data.products.length) {
    // TODO create with products
  }

  // TODO include
  return omit(data, ["products"]);
};

export const updatingDiscountCode = (
  model: DiscountCode,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (data.products && data.products.length) {
    // TODO update with products
  }

  // TODO include
  return omit(data, ["products"]);
};
