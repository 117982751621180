import { defineStore } from "pinia";

export const useLoadingStore = defineStore("loading", () => {
  const text = ref("Loading");
  const hotText = ref<string>();
  const classes = ref<any>();
  const loading = ref(false);
  const background = ref<any>();
  const customLoader = ref<any>();

  const dark = ref(false);

  return {
    text,
    hotText,
    dark,
    classes,
    loading,
    background,
    customLoader,
  };
});
