import { type PropType } from "vue";
import { isString } from "lodash";
import VPhoneInput from "./VPhoneInput";
import VInputGroup from "./VInputGroup";

export type Contact = {
  first_name?: string;
  last_name?: string;
  email: string;
  phone?: string;
};

export default defineComponent({
  name: "ContactInput",
  props: {
    modelValue: {
      type: Object as PropType<Contact>,
    },
    width: { type: [String, Number] },
    maxWidth: { type: [String, Number] },
    elevation: { type: [String, Number] },
    name: { type: [String, Boolean], default: false },
    phone: { type: [String, Boolean], default: false },
  },
  setup(props) {
    const propRefs = toRefs(props);

    const withRule = (value: string | boolean, rule?: string) => {
      if (isString(value)) {
        return rule ? [value, rule] : value;
      }
      return rule;
    };

    const inputs = computed(() => ({
      first_name: {
        name: "First name",
        type: "text",
        line: 1,
        rules: withRule(propRefs.name.value, "max:32"),
        hidden: propRefs.name.value == false,
        props: {
          placeholder: "First name",
        },
      },
      last_name: {
        name: "Last name",
        type: "text",
        rules: withRule(propRefs.name.value, "max:32"),
        line: 1,
        hidden: propRefs.name.value == false,
        props: {
          placeholder: "Last name",
        },
      },
      email: {
        type: "text",
        rules: "required|email",
        props: {
          placeholder: "emeka.okoye@mail.com",
          prependInnerIcon: "mail_outline",
        },
      },
      phone: {
        rules: withRule(propRefs.phone.value),
        hidden: propRefs.phone.value == false,
        component: markRaw(VPhoneInput),
      },
    }));

    return {
      inputs,
    };
  },
  render() {
    return h(VInputGroup as any, {
      ...this.$attrs,
      inputs: this.inputs,
      modelValue: this.modelValue,
      width: this.width,
      maxWidth: this.maxWidth,
      elevation: this.elevation,
      // 'onUpdate:valid': (valid: boolean) => this.$emit('update:valid', valid),
      defaultProps: {
        density: "compact",
      },
    });
  },
});
