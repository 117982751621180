import { joinStaSession, invalidStaSession, getStaSession } from "./lib";
import { useStaStore } from "./store";

/**
 * Event scan-to-admit middleware
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const { wrapLoading } = useLoading();

  if (to.query.join) {
    const join = wrapLoading(joinStaSession);
    const token = await join(String(to.query.join));
    return navigateTo({
      name: "events-handle-sta",
      params: { handle: String(to.params.handle) },
      query: { token },
    });
  }

  const store = useStaStore();

  if (to.query.token && !store.session?.token) {
    const session = wrapLoading(getStaSession);
    const token = await session(String(to.query.token));
    if (!token) {
      return nextTick(invalidStaSession);
    }
    return;
  }

  if (!to.query.token || to.query.token !== store.session?.token) {
    return nextTick(invalidStaSession);
  }

  if (store.isExpiredSession()) {
    return nextTick(invalidStaSession);
  }
});
