import { jwtDecode } from "jwt-decode";
import TokenStatus from "./TokenStatus";
import type { TokenOption, IAuthStorage } from "./types";
import { addTokenPrefix } from "../utils";

export class Token {
  protected name: string;
  protected type?: string;
  protected prefix: string;
  protected expirationPrefix: string;
  protected storage: IAuthStorage;
  protected maxAge: number;

  constructor({
    name,
    type,
    prefix = "",
    expirationPrefix = "",
    storage,
    maxAge,
  }: TokenOption) {
    this.name = name;
    this.type = type;
    this.prefix = prefix;
    this.expirationPrefix = expirationPrefix;
    this.storage = storage;
    this.maxAge = maxAge;
  }

  get() {
    const _key = this.prefix + this.name;
    return this.storage.getUniversal(_key) as string | undefined;
  }
  set(tokenValue: string) {
    const token = addTokenPrefix(tokenValue, this.type);
    this.setToken(token);
    this.updateExpiration(token);
    return token;
  }
  sync() {
    const token = this.syncToken();
    this.syncExpiration();
    return token;
  }
  reset() {
    this.setToken(false);
    this.setExpiration(false);
  }
  status() {
    return new TokenStatus(this.get(), this.getExpiration());
  }
  private getExpiration() {
    const _key = this.expirationPrefix + this.name;
    return this.storage.getUniversal(_key) as string | undefined;
  }
  private setExpiration(expiration: number | false) {
    const _key = this.expirationPrefix + this.name;
    return this.storage.setUniversal(_key, expiration);
  }
  private syncExpiration() {
    const _key = this.expirationPrefix + this.name;
    return this.storage.syncUniversal(_key);
  }
  private updateExpiration(token: string) {
    let tokenExpiration: number;
    const _tokenIssuedAtMillis = Date.now();
    const _tokenTTLMillis = Number(this.maxAge) * 1e3;
    const _tokenExpiresAtMillis = _tokenTTLMillis
      ? _tokenIssuedAtMillis + _tokenTTLMillis
      : 0;
    try {
      const jwt = jwtDecode(token + "");
      const exp = jwt?.exp || 0;
      tokenExpiration = exp * 1e3 || _tokenExpiresAtMillis;
    } catch (error: any) {
      tokenExpiration = _tokenExpiresAtMillis;
      if (!(error && error.name === "InvalidTokenError")) {
        throw error;
      }
    }
    return this.setExpiration(tokenExpiration || false);
  }
  private setToken(token: string | false) {
    const _key = this.prefix + this.name;
    return this.storage.setUniversal(_key, token);
  }
  private syncToken() {
    const _key = this.prefix + this.name;
    return this.storage.syncUniversal(_key);
  }
}
