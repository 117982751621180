import {
  EntityType,
  SectionAdmissionType,
  TableStyle,
  ShapeStyle,
} from "../types";
import type {
  AssignedSeatingSection,
  Entity,
  ShapeEntity,
  RoundTable,
  SectionEntity,
  TableEntity,
  TextEntity,
  LineEntity,
} from "../types";

export const isSection = (entity: Entity): entity is SectionEntity =>
  entity.type === EntityType.SECTION;

export const isTable = (entity: Entity): entity is TableEntity =>
  entity.type === EntityType.TABLE;

export const isShape = (entity: Entity): entity is ShapeEntity =>
  entity.type === EntityType.SHAPE;

export const isLine = (entity: Entity): entity is LineEntity =>
  entity.type === EntityType.LINE;

export const isText = (entity: Entity): entity is TextEntity =>
  entity.type === EntityType.TEXT;

export const isAssignedSeatingSection = (
  entity: Entity
): entity is AssignedSeatingSection =>
  isSection(entity) && entity.admission === SectionAdmissionType.ASSIGNED;

export const isRoundTable = (entity: Entity): entity is RoundTable =>
  isTable(entity) && entity.style === TableStyle.ROUND;

export const isCircleShape = (entity: Entity): entity is ShapeEntity =>
  isShape(entity) && entity.style === ShapeStyle.CIRCLE;

export const isArrowShape = (entity: Entity): entity is ShapeEntity =>
  isShape(entity) && entity.style === ShapeStyle.ARROW;
