import {
  boolean,
  float,
  id,
  integer,
  json,
  model,
  string,
} from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime, modelKey } from "~/layers/admin/models/dobby";
import { cloneDeep } from "@apollo/client/utilities";
import { omitBy, isNil, isEmpty } from "lodash";
import {
  type Publishable,
  BookingConfirmation,
  Gender,
  ServiceBookingStatus,
  ServiceKind,
  ServiceLocationType,
  ServicePeriodType,
  ServicePricingType,
  ServiceSchedulingType,
  PublishableStatus,
  PublishedStatus,
  OnboardStep,
  PeriodInterval,
  Pricing as PricingModel,
  type IServiceAvailability,
  type IServiceSchedule,
  type IIntervalLimit,
  type IntervalLimitUnit,
  type IServiceLocation,
} from "~/layers/admin/models";
import { Media } from "../../layers/admin/models/dobby/Media";
import {
  creatingServiceSchedule,
  updatingServiceSchedule,
} from "./hooks/service-schedule-hooks";
import TeamMember from "./TeamMember";
import { Location } from "@/layers/admin/models";
import { creatingService, updatingService } from "./hooks/service-hooks";
import { Order } from "./Order";
import { EventAttendee } from "./Event";
import ProductVariant from "./ProductVariant";
import { pricingDefaults } from "~/layers/admin/helpers/defaults";
import ResourceFaq from "./ResourceFaq";

export class IntervalLimit extends Model implements IIntervalLimit {
  static override entity = "IntervalLimit";
  @integer() declare day?: number;
  @integer() declare week?: number;
  @integer() declare month?: number;
  @integer() declare year?: number;

  public $set(value: number, unit: IntervalLimitUnit) {
    this.$setAttribute(unit, value);
  }

  public $swap(oldUnit: IntervalLimitUnit, newUnit: IntervalLimitUnit) {
    const o = this.$getAttribute(oldUnit);
    const n = this.$getAttribute(newUnit);
    this.$setAttribute(newUnit, o);
    this.$setAttribute(oldUnit, n);
  }

  public $clear(unit?: IntervalLimitUnit) {
    if (unit) {
      this.$setAttribute(unit, undefined);
    } else {
      this.day = undefined;
      this.week = undefined;
      this.month = undefined;
      this.year = undefined;
    }
  }

  public $values() {
    return filterUndefined({
      day: this.day,
      week: this.week,
      month: this.month,
      year: this.year,
    });
  }

  public $isEmpty() {
    return isEmpty(this.$values());
  }
}

export class Service extends Model implements Publishable {
  static override entity = "Service";
  static override primaryKey = "handle";
  static override queryAttributes = ["id", "handle", "name"];

  constructor(attributes = {}) {
    super({
      // image: {},
      gallery: [],
      tags: [],
      services: [],
      option: {},
      options: [],
      seo_keywords: [],
      booking_limits: {},
      duration_limits: {},
      schedule: {},
      faqs: [],
      teamMembers: [],
      locations: [],
      variants: [],
      recurring: {},
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @string() declare readonly gid: string;

  // general
  @string() declare readonly kind: ServiceKind;
  // @string() declare type: ServiceType
  @string() declare provider?: string;
  @string() declare name: string;
  @string() declare readonly handle: string;
  @string() declare readonly objectName: string;
  @string() declare status: PublishableStatus;
  @string() declare publishedStatus: PublishedStatus;
  @string() declare template_name?: string;
  @string() declare description?: string;
  @string() declare aftercare_description?: string;
  @string() declare gender?: Gender;
  @model(() => Media) declare image?: Media;
  @model(() => Media, { list: true }) declare gallery?: Media[];
  @string() declare video_url?: string;
  @model(() => Media, { list: true }) declare attachments?: Media[];
  @integer() declare position: number;

  // services times
  @integer() declare offset_start: number;
  @boolean() declare private: boolean;
  @string() declare timezone?: string;
  @string() declare period_type?: ServicePeriodType;
  @string() declare period_start_date?: string;
  @string() declare period_end_date?: string;
  @integer() declare period_days?: number;
  @boolean() declare period_count_calendar_days: boolean;
  @string() declare confirmation: BookingConfirmation;
  @boolean() declare requires_booker_email_verification: boolean;
  @model(() => PeriodInterval) declare recurring?: PeriodInterval;
  @boolean() declare allow_guests: boolean;
  @boolean() declare hide_calendar_notes: boolean;
  @integer() declare minimum_booking_notice: number;
  @integer() declare before_buffer: number;
  @integer() declare after_buffer: number;
  @boolean() declare block_before_buffer: boolean;
  @boolean() declare block_after_buffer: boolean;
  @integer() declare seats_per_time_slot?: number;
  @boolean() declare seats_show_attendees: boolean;
  @boolean() declare seats_show_availability_count: boolean;
  @string() declare scheduling_type?: ServiceSchedulingType;
  @string() declare currency_code?: string;
  @integer() declare slot_interval?: number;
  @string() declare purchase_action?: string;
  @string() declare cancellation_policy?: string;
  @string() declare web_url?: string;
  @string() declare seo_title?: string;
  @string() declare seo_description?: string;
  @string({ default: [], list: true }) declare seo_keywords: string[];
  @string({ default: [], list: true }) declare channels: string[];
  @string({ default: [], list: true }) declare tags: string[];
  @string() declare schedule_id?: string;
  @string() declare group_id?: string;
  @model(() => IntervalLimit) declare booking_limits?: IntervalLimit;
  @model(() => IntervalLimit) declare duration_limits?: IntervalLimit;

  // default option
  @model(() => PricingModel) declare pricing?: PricingModel;
  @model(() => PricingModel) declare comparison_pricing?: PricingModel;
  @float() declare cancellation_fee?: number;
  @integer() declare duration: number;
  @boolean() declare enable_comparison_pricing: boolean;
  @string() declare pricing_description?: string;

  @model(() => OnboardStep, { list: true }) declare nextSteps?: OnboardStep[];
  @model(() => ResourceFaq, { list: true }) declare faqs: ResourceFaq[];
  @model(() => TeamMember, { list: true }) declare teamMembers: TeamMember[];
  @model(() => ServiceGroup) declare group: ServiceGroup;
  @model(() => Service, { list: true }) declare services: Service[];
  @model(() => ServiceVariant) declare variant: ServiceVariant;
  @model(() => ServiceVariant, { list: true })
  declare variants: ServiceVariant[];
  @model(() => ServiceSchedule) declare schedule?: ServiceSchedule;
  @model(() => ServiceLocation, { list: true })
  declare locations: ServiceLocation[];
  // # salesChannels: [SalesChannel!]!
  // @json() meta?: Object

  // @ts-expect-error
  $keepChanges(): void | false {
    super.$keepChanges();
    if (this.pricing) {
      this.pricing.$keepChanges();
    }
    if (this.comparison_pricing) {
      this.comparison_pricing.$keepChanges();
    }
  }

  public $url(): string | undefined {
    if (this.provider && this.handle) {
      return `https://orie.market/services/${this.provider}/${this.handle}?menu=false`;
    }
  }

  public $isStandard() {
    return this.kind === ServiceKind.STANDARD;
  }

  public $isMeeting() {
    return this.kind === ServiceKind.MEETING;
  }

  public $isPackage() {
    return this.kind === ServiceKind.PACKAGE;
  }

  static override hooks() {
    return {
      $creating: creatingService,
      $updating: updatingService,
    };
  }
}

export class ServiceVariant extends Model {
  static override entity = "ServiceVariant";

  constructor(attributes = {}) {
    super({
      pricing: cloneDeep(pricingDefaults),
      comparison_pricing: cloneDeep(pricingDefaults),
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @id() declare readonly gid: string;
  @string() declare name: string;
  @integer() declare duration: number;
  @string() declare pricing_type: ServicePricingType;
  @model(() => PricingModel) declare pricing?: PricingModel;
  @model(() => PricingModel) declare comparison_pricing?: PricingModel;
  @float() declare cancellation_fee?: number;
  @boolean() declare enable_comparison_pricing: boolean;
  @boolean() declare absorb_processing_fees?: boolean;
  @boolean() declare absorb_platform_fees?: boolean;
  @integer() declare position: number;
  @string() declare pricing_description?: string;
  @model(() => Media) declare image?: Media;
  @model(() => Service) declare service: Service;

  // @ts-expect-error
  $keepChanges(): void | false {
    super.$keepChanges();
    if (this.pricing) {
      this.pricing.$keepChanges();
    }
    if (this.comparison_pricing) {
      this.comparison_pricing.$keepChanges();
    }
  }

  @modelKey()
  declare readonly key: string;
}

export class ServiceAvailability extends Model implements IServiceAvailability {
  static override entity = "ServiceAvailability";

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @string() declare name?: string;
  @string() declare start_time: string;
  @string() declare end_time: string;
  @integer({ list: true }) declare days?: number[];
  @string() declare date?: string;

  public $isSchedule() {
    return !!this.days;
  }

  public $isOverride() {
    return !this.$isSchedule() && !!this.date;
  }
}

export class ServiceSchedule extends Model implements IServiceSchedule {
  static override entity = "ServiceSchedule";

  constructor(attributes = {}) {
    super({
      availability: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @string() declare name: string;
  @boolean() declare default: boolean;
  @string() declare timezone?: string;
  @string() declare team_member_id?: string;
  @boolean() declare workspace: boolean;
  @boolean() declare mine: boolean;
  @model(() => TeamMember) declare teamMember?: TeamMember;
  @model(() => ServiceAvailability, { list: true })
  declare availability: ServiceAvailability[];

  public $displayTime() {
    return this.$exists() ? formatScheduleTime(this.availability) : "";
  }

  /**
   * Force this model to trigger checks.
   * Used as workaround for availability details not
   * auto performing dirty checks in computed vars.
   */
  public $forceUpdate() {
    // force update by changing name and immediately changing it back
    const name = this.name;
    const notName = name === "" ? " " : "";
    this.name = notName;
    this.name = name;
  }

  static defaultQuery() {
    return this.select([
      "id",
      "name",
      "default",
      "timezone",
      "workspace",
    ]).include("availability");
  }

  static override hooks() {
    return {
      $creating: creatingServiceSchedule,
      $updating: updatingServiceSchedule,
    };
  }
}

export class ServiceLocation extends Model implements IServiceLocation {
  static override entity = "ServiceLocation";
  static override queryRelationships = ["location"];

  constructor(attributes = {}) {
    super({
      location: {},
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @id() declare readonly id: string;
  @string() declare type: ServiceLocationType;
  @string() declare address?: string;
  @string() declare link?: string;
  @string() declare phone?: string;
  @string() declare location_id?: string;
  @boolean() declare display_on_booking_page?: boolean;

  @model(() => Location) declare location?: Location;
}

export class ServiceBooking extends Model {
  static override entity = "ServiceBooking";

  constructor(attributes = {}) {
    super({
      attendees: [],
      services: [],
      products: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @string() declare name: string;
  @string() declare reference: string;
  @string() declare description?: string;
  @json() declare custom_inputs?: any;
  @datetime() declare readonly start_time: Dayjs;
  @datetime() declare readonly end_time: Dayjs;
  @string() declare status: ServiceBookingStatus;
  @boolean() declare paid: boolean;
  @string() declare cancellation_reason?: string;
  @string() declare rejection_reason?: string;
  @boolean() declare rescheduled: boolean;
  @string() declare sms_reminder_number?: string;

  @model(() => TeamMember) declare teamMember?: TeamMember;
  @model(() => Order) declare order?: Order;
  @model(() => ServiceBookingLocation)
  declare location?: ServiceBookingLocation;
  @model(() => EventAttendee, { list: true })
  declare attendees: EventAttendee[];
  @model(() => ServiceVariant, { list: true })
  declare services: ServiceVariant[];
  @model(() => ProductVariant, { list: true })
  declare products: ProductVariant[];

  // Added for easy searching list table by attendee name/email
  get nonHostAttendees(): string[] {
    if (this.attendees) {
      return this.attendees
        .filter((attendee) => !attendee.host)
        .map((attendee) => `${attendee.name} (${attendee.email})`);
    }
    return [];
  }

  static calendarDisplayQuery() {
    return this.select(
      "id",
      "name",
      "description",
      "start_time",
      "end_time",
      "status",
      "reference"
    )
      .include("attendees", ["name"])
      .include("location", ["full_title"])
      .include("services", (query) => {
        return query.select("id").include("service", (query) => {
          return query.select("id").include("group", ["name", "color"]);
        });
      });
  }
}

export class ServiceGroup extends Model {
  static override entity = "ServiceGroup";

  constructor(attributes = {}) {
    super({
      services: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @string() declare name: string;
  @integer() declare position: number;
  @string() declare description?: string;
  @string() declare color?: string;
  @model(() => Service, { list: true }) declare services: Service[];
}

export class ServiceBookingLocation extends Model {
  static override entity = "ServiceBookingLocation";

  @string() declare title: string;
  @string() declare full_title: string;
  @string() declare type: ServiceLocationType;
  @boolean() declare display_on_booking_page: boolean;
  @string() declare value?: string;
}

export default Service;
