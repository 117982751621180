import { createHooks } from "hookable";
import type { BioSite } from "./remote";
import type { HostAction } from "./types";

interface EventNames {
  exit: { (): Promise<void> | void };
  // save: { (done: { (): void }): Promise<void> | void };
  loaded: { (site: BioSite): Promise<void> | void };
  updated: { (site: BioSite): Promise<void> | void };
  saved: { (): Promise<void> | void };
  action: { (action: HostAction): Promise<void> | void };
  "remote-data": { (id: string, payload: any): Promise<void> | void };
  "section-inserted": { (): Promise<void> | void };
}

const events = createHooks<EventNames>();

export const on = <E extends keyof EventNames>(event: E, fn: EventNames[E]) =>
  events.hook(event, fn as any);

export const off = <E extends keyof EventNames>(event: E, fn: EventNames[E]) =>
  events.removeHook(event, fn as any);

export const once = <E extends keyof EventNames>(event: E, fn: EventNames[E]) =>
  events.hookOnce(event, fn as any);

export const emit = <E extends keyof EventNames>(
  event: E,
  ...a: Parameters<EventNames[E]>
) => events.callHook(event, ...a);

const make = <E extends keyof EventNames>(event: E) => {
  return (fn: EventNames[E]) => events.hook(event, fn as any);
};

export const onUpdated = make("updated");
export const onSaved = make("saved");
export const onExit = make("exit");
