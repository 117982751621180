import type { Attributes } from "@moirei/dobby";
import { omit } from "lodash";
import { EventTicketSection } from "../Event";
import { creatingEventTicket, updatingEventTicket } from "./event-ticket-hooks";

export const creatingEventSection = (
  model: EventTicketSection,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  // unset ID. Tempt used by UI to track instances
  model.$setChangesAttribute("id", undefined);

  if (data.occurrence_id) {
    data.occurrence = data.occurrence_id;
  }

  if (model.tickets) {
    data.tickets = model.tickets
      .filter((ticket) => ticket.$isDirty())
      .map((ticket) => omit(ticket.$toJson(), ["id"])); // omit ID.
  }

  return omit(data, ["id", "occurrence_id"]);
};

export const updatingEventSection = (
  model: EventTicketSection,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }
  data[model.$getKeyName()] = model.$getKey();

  if (model.$isDirty("occurrence_id")) {
    data.occurrence = model.occurrence_id
      ? { connect: model.occurrence_id }
      : {
          disconnect: true,
        };
  }

  if (model.tickets) {
    data.tickets = {
      create: model.tickets
        .filter(
          (ticket) =>
            !ticket.$willDelete && ticket.$isDeepDirty() && !ticket.$exists()
        )
        .map((ticket) => creatingEventTicket(ticket)),
      update: model.tickets
        .filter(
          (ticket) =>
            !ticket.$willDelete && ticket.$isDeepDirty() && ticket.$exists()
        )
        .map((ticket) => updatingEventTicket(ticket)),
      delete: model.tickets
        .filter((ticket) => ticket.$willDelete && ticket.$exists())
        .map((ticket) => ticket.id),
    };
  }

  return omit(data, ["occurrence_id"]);
};
