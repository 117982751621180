import { injectMetricsContext } from "../data";

export default defineComponent({
  name: "MetricValue",
  props: {
    tag: { type: String, default: "span" },
    source: { type: String, required: true },
    dataset: { type: [Number, String], default: 0 },
    withUnit: { type: Boolean, default: false },
    default: {},
  },
  setup(props) {
    const ctx = injectMetricsContext();

    const result = ctx.getters.value(props.source, {
      dataset: Number(props.dataset),
      default: props.default,
      withUnit: props.withUnit,
    });

    return {
      loading: ctx.loading,
      result,
    };
  },
  render() {
    return h(this.tag, [
      this.$slots.default
        ? this.$slots.default({
            loading: this.loading,
            value: this.result?.value,
            unit: this.result?.unit,
            growthRate: this.result?.growthRate,
          })
        : String(this.result?.value),
    ]);
  },
});
