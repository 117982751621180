import { chain, get, isNil, isObject } from "lodash";
import type { ShallowRef } from "vue";

/**
 * Create an array from incremental
 * numbers from size.
 * @param size
 * @returns
 */
export const of = (size: number): number[] => {
  const arr: number[] = [];
  for (var i = 0; i < size; i++) {
    arr.push(i);
  }
  return arr;
};

/**
 * Set array with new values while
 * keeping its object ref.
 *
 * @param {T[]} target
 * @param {T[]} value
 * @returns {T[]}
 */
export const setArray = <T>(target: T[], value: T[]): T[] => {
  if (!Array.isArray(target)) {
    return [];
  }
  while (target.length > 0) {
    target.pop();
  }
  value.forEach((x) => target.push(x));
  return target;
};

/**
 * Chunk array by size.
 *
 * @param {T[]} data
 * @param {number} size
 * @returns {T[][]}
 */
export const chunkArray = <T>(data: T[], size: number): T[][] => {
  const chuncks: T[][] = [];

  for (let i = 0, len = data.length; i < len; i += size) {
    chuncks.push(data.slice(i, i + size));
  }

  return chuncks;
};

/**
 * Add data array to the target array.
 *
 * @param {T[]} target
 * @param {T[]} data
 * @returns {T[]}
 */
export const addArray = <T>(target: T[], data: T[]): T[] => {
  if (!Array.isArray(target) || !Array.isArray(data)) {
    return [];
  }

  for (const x of data) {
    target.push(x);
  }

  return target;
};

export const groupByFields = <T, K extends keyof T>(
  arr: T[],
  fields: K[]
): T[][] => {
  return chain(arr)
    .groupBy((x) => {
      const prop: any[] = [];

      for (var i = 0, length = fields.length; i < length; i++) {
        // @ts-ignore
        prop.push(x[fields[i]]);
      }

      return prop.join("|");
    })
    .values()
    .value();
};

export const filterNilItems = <T>(items?: (T | undefined | null)[]): T[] => {
  return (items?.filter((item) => !isNil(item)) || []) as T[];
};

export const deleteAt = <T>(items: T[] | undefined, index: number): T[] => {
  if (items && index >= 0 && index < items.length) {
    items.splice(index, 1);
  }
  return items || [];
};

export const pullAt = <T>(
  items: T[] | undefined,
  index: number
): T | undefined => {
  const item = get(items, index);
  if (item !== undefined) {
    deleteAt(items, index);
  }
  return item;
};

export const searchFilter = <T>(
  items: T[] | undefined,
  searchTerm: string | undefined,
  searchable?: (keyof T)[]
): T[] => {
  if (!items || !searchTerm) {
    return items || [];
  }

  searchTerm = searchTerm.toLowerCase();

  return items
    .map((item) => {
      let s: any;
      if (isObject(item)) {
        const values = searchable
          ? searchable.map((key) => get(item, key))
          : Object.values(item);
        s = values.join("");
      } else {
        s = item;
      }

      return {
        searchable: String(s).toLowerCase(),
        item,
      };
    })
    .filter((item) => item.searchable.includes(searchTerm))
    .map((item) => item.item);
};

/**
 * This solution for force updating shallow refs is hacky.
 * Currently `triggerRef` isn't working.
 * TODO: fix and debug of references and uses of this util.
 */
export const forceUpdatesShallowRef = <T>(items: ShallowRef<T[]>) => {
  return () => {
    const x = items.value;
    items.value = [] as T[];
    nextTick(() => {
      items.value = x;
    });
  };
};

export const shuffle = <T>(items: T[]): T[] => {
  let index = items.length;

  // While there remain elements to shuffle...
  while (index != 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * index);
    index--;

    // And swap it with the current element.
    [items[index], items[randomIndex]] = [items[index], items[index]];
  }

  return items;
};

export const hashStringArray = (arr: string[]) => {
  return arr.sort().join("\u200b");
};

export const findWhere = <T>(items: T[], where: Partial<T>) => {
  const v = Object.entries(where).map(([key, value]: any) => ({
    key,
    value,
  }));
  return items.find((item: any) => {
    return v.find((entry) => item[entry.key] == entry.value)!!;
  });
};

export const findIndexWhere = <T>(items: T[], where: Partial<T>): number => {
  const v = Object.entries(where).map(([key, value]: any) => ({
    key,
    value,
  }));
  return items.findIndex((item: any) => {
    return v.find((entry) => item[entry.key] == entry.value)!!;
  });
};
