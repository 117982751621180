import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import type { UserProfile } from "./types/api";
import type { AuthContext } from "#orie";

interface AuthState {
  user: UserProfile | false;
  token?: string;
  loggedIn: boolean;
  context?: AuthContext;
  [d: string]: any;
}

export const useAuthStore = defineStore("auth", () => {
  const state = useLocalStorage<AuthState>("auth", {
    loggedIn: false,
    user: false,
  });
  const user = computed(() =>
    state.value.user ? state.value.user : undefined
  );
  const token = computed(() => state.value.token);
  const loggedIn = computed(() => state.value.loggedIn);
  const context = computed(() => state.value.context);

  const set = (key: string, value: any) => {
    state.value[key] = value;
  };

  const get = (key: string): any => {
    return state.value[key];
  };

  return {
    user,
    token,
    loggedIn,
    state,
    context,
    set,
    get,
  };
});

export type AuthStore = ReturnType<typeof useAuthStore>;
