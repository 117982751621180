import type { Attributes } from "@moirei/dobby";
import { omit } from "lodash";
import { CheckoutLinkItem } from "../Checkout";

export const creatingCheckoutLinkItem = (
  model: CheckoutLinkItem,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (model.adjustable_quantity && model.adjustable_quantity.$isDeepDirty()) {
    data.adjustable_quantity = model.adjustable_quantity.$toJson();
  }

  return {
    id: data.item_gid,
    quantity: data.quantity,
    adjustable_quantity: data.adjustable_quantity,
  };
};

export const updatingCheckoutLinkItem = (
  model: CheckoutLinkItem,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (model.adjustable_quantity && model.adjustable_quantity.$isDeepDirty()) {
    data.adjustable_quantity = model.adjustable_quantity.$toJson();
  }

  return {
    id: model.id,
    ...data,
  };
};
