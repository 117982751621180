export enum OrderStatus {
  CONFIRMED = "CONFIRMED",
  ARCHIVED = "ARCHIVED",
  CANCELED = "CANCELED",
}

export enum PaymentStatus {
  AUTHORIZED = "AUTHORIZED",
  OVERDUE = "OVERDUE",
  PAID = "PAID",
  NOT_REQUIRED = "NOT_REQUIRED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  UNPAID = "UNPAID",
  VOIDED = "VOIDED",
}

export enum OrderFulfillmentStatus {
  FULFILLED = "FULFILLED",
  UNFULFILLED = "UNFULFILLED",
  PARTIALLY_FULFILLED = "PARTIALLY_FULFILLED",
  PARTIALLY_SCHEDULE = "PARTIALLY_SCHEDULE",
  PARTIALLY_ONHOLD = "PARTIALLY_ONHOLD",
}

export enum OrderKind {
  PRODUCT = "PRODUCT",
  SERVICE = "SERVICE",
  EVENT = "EVENT",
  CUSTOM = "CUSTOM",
  MIXED = "MIXED",
  OTHER = "OTHER",
}

export enum OrderSource {
  WEB = "WEB",
  POS = "POS",
  MANUAL = "MANUAL",
  IPHONE = "IPHONE",
  ANDROID = "ANDROID",
  LINK = "LINK",
}
