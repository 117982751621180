import type Feature from "ol/Feature";
import type { MapSaveState } from "../store";

export const usePickSeatsStore = defineStore("map-seating:picker", () => {
  const loading = ref(false);
  const eventMap = shallowRef<MapSaveState>();
  const selection = ref<string[]>([]);
  const originalSelection = ref<string[]>([]);
  const availableSeats = ref<string[]>([]);
  const minSeats = ref(0);
  const maxSeats = ref(0);

  const handleSelect = (
    selectedFeatures: Feature[],
    deselectedFeatures: Feature[]
  ) => {
    const newSelection = [...selection.value];

    const selected: string[] = selectedFeatures
      .map((feature) => feature.get("seatId"))
      .filter((id) => !!id);

    const deselected: string[] = deselectedFeatures
      .map((feature) => feature.get("seatId"))
      .filter((id) => !!id);

    selected.forEach((seatId) => {
      if (!newSelection.includes(seatId)) {
        newSelection.push(seatId);
      }
    });

    deselected.forEach((seatId) => {
      if (newSelection.includes(seatId)) {
        const index = newSelection.indexOf(seatId);
        newSelection.splice(index, 1);
      }
    });

    while (newSelection.length > maxSeats.value) {
      newSelection.shift();
    }

    selection.value = newSelection;
  };

  const isSelected = computed(
    () =>
      selection.value.length >= minSeats.value &&
      selection.value.length <= maxSeats.value &&
      !arrayAllIn(selection.value, originalSelection.value)
  );

  const clearSelection = () => {
    selection.value = [];
  };

  const initStore = (
    map: MapSaveState,
    currentSeats: string[],
    maxRequiredSeats: number,
    availSeats: string[]
  ) => {
    const quantity = currentSeats.length;
    // if maxSeats is not set, then its being called for the first time
    // else, check if there has been any changes
    if (!maxSeats.value || maxSeats.value !== quantity) {
      eventMap.value = map;

      // set required seats
      minSeats.value = quantity;
      maxSeats.value = maxRequiredSeats;

      // set original selection
      selection.value = currentSeats;
      originalSelection.value = currentSeats;

      availableSeats.value = availSeats;
    }
  };

  return {
    initStore,
    handleSelect,
    selection,
    isSelected,
    minSeats,
    maxSeats,
    availableSeats,
    loading,
    clearSelection,
    eventMap,
  };
});
