import { boolean, id, model, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import App from "./App";
import type { ISalesChannel } from "../interfaces/SalesChannel";

export default class SalesChannel extends Model implements ISalesChannel {
  static override entity = "SalesChannel";
  static override primaryKey = "handle";
  static override queryAttributes = ["handle", "name"];

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @id() declare readonly id: string;
  @id() declare readonly handle: string;
  @string() declare name: string;
  @boolean() declare offline: boolean;
  @string() declare allowed_currencies: string[];

  @model(() => App) declare readonly app: App;
}
