export const useAppEvent = () => {
  const nuxtApp = useNuxtApp();
  return {
    broadcast: nuxtApp.$broadcast,
    listen: nuxtApp.$listen,
    listenMany: nuxtApp.$listenMany,
    listenOnce: nuxtApp.$listenOnce,

    // aliases
    dispatch: nuxtApp.$broadcast,
    handle: nuxtApp.$listen,
    handleMany: nuxtApp.$listenMany,
    handleOnce: nuxtApp.$listenOnce,
  };
};

export const useNamespacedEvent = <T extends Record<string, any>>(
  namespace: string
) => {
  type E = keyof T & string;

  const events = useAppEvent();
  const n = (action: E) => namespace + action;

  const handle = <A extends E>(action: A, handler: T[A]) => {
    events.listen(n(action), handler);
  };

  const handleMany = (listeners: Partial<T>) => {
    const l = Object.entries(listeners).reduce((acc, [k, h]) => {
      acc[n(k)] = h;
      return acc;
    }, {} as any);
    events.listenMany(l);
  };

  const dispatch = <A extends E>(name: A, ...a: Parameters<T[A]>) => {
    return events.broadcast(n(name), ...a);
  };

  return {
    handle,
    handleMany,
    dispatch,
  };
};
