export default defineAppConfig({
  app: {
    defaultCurrency: "AUD",
    calendar: {
      sameDay: "[Today at] h:mm A", // The same day ( Today at 2:30 AM )
      nextDay: "[Tomorrow at] h:mm A", // The next day ( Tomorrow at 2:30 AM )
      nextWeek: "dddd [at] h:mm A", // The next week ( Sunday at 2:30 AM )
      lastDay: "[Yesterday at] h:mm A", // The day before ( Yesterday at 2:30 AM )
      lastWeek: "[Last] dddd [at] h:mm A", // Last week ( Last Monday at 2:30 AM )
      sameElse: "Do MMM YYYY", // Everything else ( 2nd Jan 2023 )
    },
    nav: [
      {
        name: "Home",
        icon: "jam:home-f",
        activeIcon: "jam:home",
        route: "/",
      },
      {
        name: "Orders",
        icon: "clarity:bundle-solid",
        activeIcon: "clarity:bundle-line",
        route: { name: "orders" },
        items: [
          {
            name: "Checkout links",
            route: { name: "orders-checkout-links" },
          },
          {
            name: "Abandoned checkouts",
            route: { name: "orders-abandoned-checkouts" },
          },
        ],
      },
      {
        name: "Products",
        icon: "jam:tag-f",
        activeIcon: "jam:tag",
        route: { name: "products" },
        items: [
          {
            name: "Collections",
            route: { name: "products-collections" },
          },
          {
            name: "Inventory",
            route: { name: "products-inventory" },
          },
          {
            name: "Transfers",
            route: { name: "products-transfers" },
          },
          {
            name: "Giftcards",
            route: { name: "products-giftcards" },
          },
        ],
      },
      {
        name: "Services",
        icon: "jam:mountain-f",
        activeIcon: "jam:mountain",
        route: { name: "services" },
        items: [
          {
            name: "Bookings",
            route: { name: "services-bookings" },
          },
          {
            name: "Calendar",
            route: { name: "services-calendar" },
          },
        ],
      },
      {
        name: "Events",
        icon: "jam:calendar-f",
        activeIcon: "jam:calendar",
        route: { name: "events" },
        items: [
          {
            name: "Calendar",
            route: { name: "events-calendar" },
          },
        ],
      },
      {
        name: "Customers",
        icon: "clarity:user-solid",
        activeIcon: "clarity:user-line",
        route: { name: "customers" },
      },
      {
        name: "Bio Sites",
        icon: "jam:layers-f",
        activeIcon: "jam:layers",
        route: { name: "bio-sites" },
      },
      {
        name: "Analytics",
        icon: "heroicons:chart-bar-16-solid",
        activeIcon: "heroicons:chart-bar",
        route: { name: "analytics" },
        // items: [
        //   {
        //     name: "Sales report",
        //     route: { name: "analytics-reports-sales" },
        //   },
        // ],
      },
      {
        name: "Discounts",
        icon: "streamline:discount-percent-badge-solid",
        activeIcon: "streamline:discount-percent-badge",
        route: { name: "discounts" },
      },
      {
        name: "Media Library",
        icon: "heroicons:photo-solid",
        activeIcon: "heroicons:photo",
        route: { name: "files" },
        description: "Upload images, videos and other documents",
      },
    ],
    settingsNav: [
      {
        name: "General",
        icon: "heroicons:building-storefront",
        activeIcon: "heroicons:building-storefront-solid",
        route: { name: "settings-general" },
        description: "Manage your general workspace settings",
      },
      {
        name: "Profile",
        icon: "heroicons:user",
        activeIcon: "heroicons:user-20-solid",
        route: { name: "settings-profile" },
        description: "Manage your Orie seller account information",
      },
      {
        name: "Plan",
        icon: "heroicons:clipboard-document-check",
        activeIcon: "heroicons:clipboard-document-check-solid",
        route: { name: "settings-plan" },
        description: "Details about your existing and available Orie plans",
      },
      {
        name: "Billing",
        icon: "heroicons:currency-dollar",
        activeIcon: "heroicons:currency-dollar-solid",
        route: { name: "settings-billing" },
        description: "Manage how you pay bills in Orie",
      },
      {
        name: "Members & permissions",
        icon: "heroicons:user-group",
        activeIcon: "heroicons:user-group-solid",
        route: { name: "settings-team" },
        description: "Manage your team members, roles and permissions",
      },
      {
        name: "Payouts",
        icon: "heroicons:wallet",
        activeIcon: "heroicons:wallet-solid",
        route: { name: "settings-payouts" },
        description: "Manage pay out balances and schedules options",
      },
      {
        name: "Shipping & delivery",
        icon: "heroicons:truck",
        activeIcon: "heroicons:truck-solid",
        route: { name: "settings-shipping" },
        description: "Manage how you ship orders to customers",
      },
      {
        name: "Locations",
        icon: "heroicons:map-pin",
        activeIcon: "heroicons:map-pin-solid",
        route: { name: "settings-locations" },
        description: "Manage the places you stock inventory and fulfill orders",
      },
      {
        name: "Availability",
        icon: "heroicons:calendar-days",
        activeIcon: "heroicons:calendar-days-solid",
        route: { name: "settings-availability" },
        description: "Availability settings for services and bookable products",
      },
      {
        name: "Giftcards",
        icon: "heroicons:gift",
        activeIcon: "heroicons:gift-solid",
        route: { name: "settings-giftcards" },
        description: "Manage your customer Gift cards",
      },
      {
        name: "Applications",
        icon: "heroicons:squares-plus",
        activeIcon: "heroicons:squares-plus-solid",
        route: { name: "settings-apps" },
        description: "Manage installed apps, extensions and sales channels",
      },
      {
        name: "Notifications",
        icon: "heroicons:bell",
        activeIcon: "heroicons:bell-solid",
        route: { name: "settings-notifications" },
        description: "Manage notifications sent to you",
      },
      {
        name: "Public profiles",
        icon: "heroicons:globe-europe-africa",
        activeIcon: "heroicons:globe-europe-africa-solid",
        description:
          "Manage business, event and service profile details on public channels",
        items: [
          {
            name: "Business profile",
            route: { name: "settings-profiles-business" },
          },
          {
            name: "Service profile",
            route: { name: "settings-profiles-service" },
          },
          { name: "Event profile", route: { name: "settings-profiles-event" } },
        ],
      },
      {
        name: "Legal",
        icon: "heroicons:shield-check",
        activeIcon: "heroicons:shield-check-solid",
        route: { name: "settings-legal" },
        description: "Review your legal and compliance agreements",
      },
      // {
      //   name: "Activity log",
      //   icon: "heroicons:newspaper",
      //   activeIcon: "heroicons:newspaper-solid",
      //   route: { name: "settings-activities" },
      // },
    ],
    shortcuts: [
      {
        name: "General shortcuts",
        items: [
          {
            description: "Shortcuts help",
            keys: "?",
            action: "app:help",
          },
          {
            description: "Search",
            keys: ["Ctrl+k"],
            action: "app:search",
          },
          {
            description: "Log out",
            keys: ["b", "y", "e"],
            action: "app:logout",
          },
        ],
      },
      {
        name: "Navigation",
        items: [
          {
            description: "Go to Home",
            keys: ["g", "h"],
            action: "goto:home",
            routeTo: "/",
          },
          {
            description: "Go to Analytics",
            keys: ["g", "a", "n"],
            action: "goto:analytics",
          },
          {
            description: "Go to installed apps",
            keys: ["g", "i", "a"],
            action: "goto:apps",
          },
          {
            description: "Go to app store",
            keys: ["g", "a", "s"],
            action: "goto:apps",
          },
          {
            description: "Go to Orders",
            keys: ["g", "o"],
            action: "goto:orders",
          },
          {
            description: "Go to Draft Orders",
            keys: ["d", "o"],
            action: "goto:draft-orders",
          },
          {
            description: "Go to Products",
            keys: ["g", "p"],
            action: "goto:products",
          },
          {
            description: "Go to Services",
            keys: ["g", "t", "s"],
            action: "goto:services",
          },
          {
            description: "Go to Events",
            keys: ["g", "e"],
            action: "goto:events",
          },
          {
            description: "Go to Bio Sites",
            keys: ["g", "b", "s"],
            action: "goto:bio-sites",
          },
          {
            description: "Go to Customers",
            keys: ["g", "c"],
            action: "goto:customers",
          },
          {
            description: "Go to Discounts",
            keys: ["g", "d"],
            action: "goto:discounts",
          },
          {
            description: "Go to Booking Availability",
            keys: ["g", "b", "a"],
            action: "goto:availability",
          },
          {
            description: "Go to Settings",
            keys: ["g", "s"],
            action: "goto:settings",
          },
        ],
      },
      {
        name: "Resources",
        items: [
          {
            description: "Add product",
            keys: ["a", "p"],
            action: "add:product",
          },
          {
            description: "Add service",
            keys: ["a", "s"],
            action: "add:service",
          },
          {
            description: "Add event",
            keys: ["a", "e"],
            action: "add:event",
          },
          {
            description: "Add discount",
            keys: ["a", "d"],
            action: "add:discount",
          },
          // {
          //   description: "Add order",
          //   keys: ["a", "o"],
          //   action: "add:order",
          // },
          {
            description: "Add customer",
            keys: ["a", "c"],
            action: "add:customer",
          },
          {
            description: "Add checkout link",
            keys: ["a", "l"],
            action: "add:checkout-link",
          },
          {
            description: "Add collection",
            keys: ["a", "n", "c"],
            action: "add:collection",
          },
        ],
      },
    ],
  },
  data: {
    minProductOptionValues: 2,
    maxProductOptions: 3,
    maxServiceVariants: 5,
    maxPaymentLinkItems: 50,
  },
  industries: [
    { name: "Beauty", handle: "beauty" },
    { name: "Clothing", handle: "clothing" },
    { name: "Electronics", handle: "electronics" },
    { name: "Furniture", handle: "furniture" },
    { name: "Handcrafts", handle: "handcrafts" },
    { name: "Jewelry", handle: "jewelry" },
    { name: "Painting", handle: "painting" },
    { name: "Photography", handle: "photography" },
    { name: "Restaurants", handle: "restaurants" },
    { name: "Groceries", handle: "groceries" },
    { name: "Other food & drink", handle: "other-food-drink" },
    { name: "Sports", handle: "sports" },
    { name: "Toys", handle: "toys" },
    { name: "Services", handle: "services" },
    { name: "Virtual services", handle: "virtual-services" },
  ],
  links: {
    terms: "https://orie.market/legal/seller-terms",
    privacy: "https://orie.market/legal/privacy",
    sales: "https://orie.market/legal/sales",
    marketplaceTerms: "https://orie.market/legal/terms",
    contact: "https://orie.market/contact-us",
  },

  // Config format across UI projects
  orie: {
    defaults: {
      currency: "AUD",
    },
  },
});
