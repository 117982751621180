export enum EventOccurrenceType {
  /**
   * Standard event with single occurrence and location.
   */
  STANDARD = "STANDARD",

  /**
   * Session based event with serries of occurrences.
   * Sessions are focused on occurrence times.
   */
  SESSION = "SESSION",

  /**
   * Tours are treat as exact same event occurring at difference locations.
   * Tours are focused on occurrence locations.
   */
  TOUR = "TOUR",
}

export enum EventOccurrenceLocationType {
  PENDING = "PENDING",
  LOCATION = "LOCATION",
  EVENT_VENUE = "EVENT_VENUE",
  ADDRESS = "ADDRESS",
  ONLINE_LINK = "ONLINE_LINK",
}

export enum EventTicketType {
  PAID = "PAID",
  FREE = "FREE",
  DONATION = "DONATION",
}
