import { injectMetricsContext } from "../data";

export default defineComponent({
  name: "MetricTrend",
  props: {
    tag: { type: String, default: "span" },
    source: { type: String, required: true },
  },
  setup(props) {
    const ctx = injectMetricsContext();

    const result = ctx.getters.trend(props.source);

    return {
      loading: ctx.loading,
      result,
    };
  },
  render() {
    return h(this.tag, [
      this.$slots.default!({
        loading: this.loading,
        data: this.result,
      }),
    ]);
  },
});
