import { defineNuxtPlugin } from "#app";
import { createHooks } from "hookable";

type Handler<A extends any[]> = { (...a: A): Promise<any> | any };

export default defineNuxtPlugin(() => {
  const events = createHooks();

  const broadcast = <T extends string, A extends any[]>(name: T, ...a: A) => {
    return events.callHook(name, ...a);
  };

  const autoUnsub = (unsub: () => void) => {
    setTimeout(() => {
      const instance = getCurrentInstance();
      if (instance) {
        onUnmounted(unsub);
      }
    }, 1000);
  };

  /**
   * Register a lister that auto unregisters on component unmount
   * @param name
   * @param handler
   */
  const listen = <T extends string, A extends any[]>(
    name: T,
    handler: Handler<A>
  ) => {
    const unsub = events.hook(name, handler);
    autoUnsub(unsub);
  };

  const listenMany = <T extends string, A extends any[]>(
    listeners: Record<T, Handler<A>>
  ) => {
    const unsub = events.addHooks(listeners);
    autoUnsub(unsub);
  };

  const listenOnce = <T extends string, A extends any[]>(
    name: T,
    handler: Handler<A>
  ) => {
    events.hookOnce(name, handler);
  };

  return {
    provide: {
      broadcast,
      listen,
      listenMany,
      listenOnce,
    },
  };
});
