import { computed } from "#imports";

export const useAuth = () => {
  const nuxtApp = useNuxtApp();
  const store = useAuthStore();
  const auth = nuxtApp.$auth;
  const loggedIn = computed(() => store.loggedIn);
  const user = computed(() => store.user);
  const context = computed(() => store.context);
  const roles = computed(() => store.context?.roles || []);
  const permissions = computed(() => store.context?.permissions || []);

  const expose = <T, K extends keyof T>(o: T, k: K[]) => {
    const r: any = {};
    for (const x of k) {
      // @ts-ignore
      r[x] = o[x].bind(o);
    }
    return r as Pick<T, K>;
  };

  return {
    user,
    me: user,
    loggedIn,
    context,
    roles,
    permissions,
    ...expose(auth, [
      "getToken",
      "login",
      "logout",
      "refreshTokens",
      "forgotPassword",
      "updateForgottenPassword",
      "socialLogin",
      "verifyEmail",
      "resendVerificationEmail",
      "updatePassword",
      "register",
      "navigateToHome",
      "fetchAuthContext",
    ]),
  };
};
