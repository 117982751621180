import type { Attributes } from "@moirei/dobby";
import { LocationGroup } from "../ShippingProfile";
import {
  creatingDeliveryZone,
  updatingDeliveryZone,
} from "./delivery-zone-hooks";

export const creatingLocationGroup = (
  model: LocationGroup,
  data?: Attributes
) => {
  data = {};

  if (model.locations && model.locations.length) {
    data.locations = model.locations
      .filter((location) => !location.$willDelete)
      .map((location) => location.id);
  }

  if (model.zones && model.zones.length) {
    data.zones = model.zones
      .filter((zone) => !zone.$willDelete)
      .map((zone) => creatingDeliveryZone(zone));
  }

  return data;
};

export const updatingLocationGroup = (
  model: LocationGroup,
  data?: Attributes
) => {
  data = {};

  if (model.locations && model.locations.length) {
    data.locations = {
      set: model.locations
        .filter((location) => !location.$willDelete)
        .map((location) => location.id),
    };
  }
  // if (model.locations && model.locations.length) {
  //   data.locations = {
  //     set: model.locations
  //       .filter(
  //         (location) =>
  //           !location.$willDelete &&
  //           location.$isDeepDirty() &&
  //           !location.$exists()
  //       )
  //       .map((location) => location.id),
  //     associate: model.locations
  //       .filter(
  //         (variant) =>
  //           !variant.$willDelete && variant.$isDeepDirty() && variant.$exists()
  //       )
  //       .map((location) => location.id),
  //     delete: model.locations
  //       .filter((location) => location.$willDelete && location.$exists())
  //       .map((location) => location.id),
  //   }
  // }

  if (model.zones && model.zones.length) {
    data.zones = {
      create: model.zones
        .filter(
          (zone) => !zone.$willDelete && zone.$isDeepDirty() && !zone.$exists()
        )
        .map((zone) => creatingDeliveryZone(zone)),
      update: model.zones
        .filter(
          (zone) => !zone.$willDelete && zone.$isDeepDirty() && zone.$exists()
        )
        .map((zone) => updatingDeliveryZone(zone)),
      delete: model.zones
        .filter((zone) => zone.$willDelete && zone.$exists())
        .map((zone) => zone.id),
    };
  }

  data.id = model.id;

  return data;
};
