import type { BioSiteStyleBackground } from "./background";
import type { BioSiteStyleFontType } from "./fonts";
import type { BioSiteStyleLayoutType } from "./layout";
import type { Media } from "./media";
import type {
  Location,
  OrieEvent,
  OrieGiftcard,
  OrieProduct,
  OrieService,
} from "./resource";
import type { BioSiteSection, BioSiteStyleSection } from "./section";

export enum BioSiteStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  UNPUBLISHED_CHANGES = "UNPUBLISHED_CHANGES",
  ARCHIVED = "ARCHIVED",
}

export enum BioSiteScriptType {
  // GTM = "gtm",
  // GA = "ga",
  // Pixel = "pixel",
  CUSTOM = "custom",
}

export interface BioSiteStyleText {
  font?: BioSiteStyleFontType;
  color?: string;
}

export interface BioSiteStyle {
  layout: BioSiteStyleLayoutType;
  theme?: string;
  background?: BioSiteStyleBackground;
  section?: BioSiteStyleSection;
  text?: BioSiteStyleText;
}

export interface BioSiteSeoMeta {
  id?: string;
  content: string;
  name?: string;
  property?: string;
}

export interface BioSiteSeo {
  title: string;
  description?: string;
  // keywords?: string[];
  meta: BioSiteSeoMeta[];
}

export interface BioSiteScript {
  id?: string;
  type: BioSiteScriptType;
  placement: "head"; // | "body";
  async?: boolean;
  defer?: boolean;
  content: string;
}

export interface BioSiteSettings {
  sensitiveContent: boolean;
  hideOrieLogo: boolean;
  subscriptions: boolean;
  integrations?: {
    google?: {
      measurementId: string;
    };
    facebook?: {
      pixelId: string;
      conversionsApiAccessToken: string;
    };
  };
}

export interface BioSiteData {
  name: string;
  bio?: string;
  image: string;
  age_restriction?: string;
  style: BioSiteStyle;
  seo?: BioSiteSeo;
  sections: BioSiteSection[];
  scripts: BioSiteScript[];
  settings: BioSiteSettings;
}

export interface BioSiteResources {
  latestEvent?: OrieEvent;
  files?: Record<string, Media>;
  products?: Record<string, OrieProduct>;
  productVariants?: Record<string, OrieProduct>;
  services?: Record<string, OrieService>;
  serviceVariants?: Record<string, OrieService>;
  events?: Record<string, OrieEvent>;
  eventTickets?: Record<string, OrieEvent>;
  locations?: Record<string, Location>;
  giftcards?: Record<string, OrieGiftcard>;
}

export interface BioSite {
  handle: string;
  workspace?: string;
  status: BioSiteStatus;
  verified: boolean;
  data: BioSiteData;
  resources?: BioSiteResources;
}
