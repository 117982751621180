import { isObject } from "lodash";
import type { FieldInputsWithoutKey } from "../types";

export const defineFormInputs = <T extends FieldInputsWithoutKey>(
  inputs: T
): T => {
  for (const name in inputs) {
    const input = inputs[name];
    if (input.component && isObject(input.component)) {
      input.component = markRaw(input.component);
    }
  }
  return inputs;
};
