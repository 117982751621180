import { injectMetricsContext } from "../data";

export default defineComponent({
  name: "MetricTrendValue",
  props: {
    tag: { type: String, default: "span" },
    source: { type: String, required: true },
    method: { type: String as PropType<"sum" | "avg"> },
    dataset: { type: [Number, String], default: 0 },
    withUnit: { type: Boolean, default: false },
    formatPrice: { type: Boolean, default: false },
    formatDecimal: { type: Boolean, default: false },
  },
  setup(props) {
    const ctx = injectMetricsContext();

    const result = ctx.getters.trendValue(props.source, {
      method: props.method,
      dataset: Number(props.dataset),
      withUnit: props.withUnit,
      formatPrice: props.formatPrice,
      formatDecimal: props.formatDecimal,
    });

    return {
      loading: ctx.loading,
      result,
    };
  },
  render() {
    return h(this.tag, [
      this.$slots.default
        ? this.$slots.default({
            loading: this.loading,
            value: this.result?.value,
            unit: this.result?.unit,
            growthRate: this.result?.growthRate,
          })
        : String(this.result?.value),
    ]);
  },
});
