import type { PropType } from "vue";
import type { DataTableComponent } from "../types";
import type { RouteLocationRaw } from "vue-router";

export const props = {
  item: { type: Object as PropType<any>, required: true },
  itemKey: { type: String, required: true },
  component: { type: Object as PropType<DataTableComponent> },
  itemRoute: { type: Object as PropType<RouteLocationRaw> },
  title: { type: String },
  showMobileTable: { type: Boolean, default: false },
};
