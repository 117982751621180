import { useLocalStorage } from "@vueuse/core";
import type { StaSession, StaScanResult } from "./types";

export const useStaStore = defineStore("sta", () => {
  const session = useState<StaSession>("sta:session");
  const sound = useLocalStorage<boolean>("sta:sound", true);
  const vibrate = useLocalStorage<boolean>("sta:vibrate", true);

  const scanResult = shallowRef<StaScanResult>();
  const menu = ref(false);
  const scanning = ref(false);

  const token = computed(() => session.value?.token);
  const shareToken = computed(() => session.value?.shareToken);
  const isAdmin = computed(() => !!session.value?.admin);
  const event = computed(() => session.value?.event);

  const shareUrl = computed(() => {
    return session.value?.shareToken
      ? location.origin +
          location.pathname +
          `?join=${session.value.shareToken}`
      : "#";
  });

  const setSession = (s: StaSession) => {
    session.value = s;
  };

  const isExpiredSession = () => {
    if (!session.value?.token) {
      return false;
    }
    return parseDateLike(session.value.expiry).isBefore(now());
  };

  return {
    token,
    shareToken,
    isAdmin,
    event,
    session,
    scanResult,
    menu,
    sound,
    vibrate,
    scanning,
    shareUrl,
    setSession,
    isExpiredSession,
  };
});
