export type FilterType = "date" | "float" | "checkbox" | "select";

export enum FilterOperator {
  Equal = "=",
  Between = "<>",
  Greater = ">",
  Lesser = "<",
  GreaterOrEqual = ">=",
  LesserOrEqual = "<=",
  In = "><",
  LastN = "<-",
}

interface Filter {
  type: FilterType;
  operator: FilterOperator;
  name?: string;
}

export interface DateFilter extends Filter {
  type: "date";
  timezone: "local" | "utc";
  operator: FilterOperator;
  values: string[];
}

export interface FloatFilter extends Filter {
  type: "float";
  operator: FilterOperator;
  values: string[];
}

export interface CheckboxFilter extends Filter {
  type: "checkbox";
  values: string[];
  items?: any;
}

export interface SelectFilter extends Filter {
  type: "select";
  values: string[];
  items?: any;
}

export type FilterItem =
  | {
      type: FilterType;
      name?: string;
    }
  | Omit<CheckboxFilter, "values">
  | Omit<SelectFilter, "values">;

export type FilterInput = FilterItem & {
  key: string;
  name: string;
};

export type SavedFilterValue = {
  operator: FilterOperator;
  type?: FilterType;
  values: unknown[];
};

export type SavedFilterValues = Record<string, SavedFilterValue>;

export type DateDaysFormat = "days" | "weeks";
