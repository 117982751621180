import type { Attributes } from "@moirei/dobby";
import Collection from "../Collection";
import { omit } from "lodash";

export const creatingCollection = (model: Collection, data?: Attributes) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  data.products = {
    connect: data.productIds || [],
  };

  return omit(data, ["productIds"]);
};

export const updatingCollection = (model: Collection, data?: Attributes) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  const products = model.$getSaveContextAttribute("productIds");
  if (products?.isDirty) {
    data.products = {
      sync: products.data,
    };
  }

  return data;
};
