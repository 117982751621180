import { defineNuxtPlugin } from "#app";
import { onError } from "@apollo/client/link/error";
import {
  DefaultApolloClient,
  provideApolloClient,
} from "@vue/apollo-composable";
import { get } from "lodash";
import { notify } from "@kyvg/vue3-notification";
import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import Pusher from "pusher-js";
import PusherLink from "@/services/PusherLink";
import { useWorkspace } from "~/composables/workspace";

export default defineNuxtPlugin(({ vueApp }) => {
  const config = useRuntimeConfig();
  const { getWorkspace } = useWorkspace();
  const { getToken, logout } = useAuth();

  const errorLink = onError((error) => {
    const statusCode = get(error, "networkError.statusCode");
    const statusMessage = get(
      error,
      "graphQLErrors[0].message",
      ""
    ).toLocaleLowerCase();

    if (
      statusCode === 401 ||
      statusMessage.includes("unauthenticated") ||
      statusMessage == "user not authenticated"
    ) {
      logout();
      // refreshTokens();
    } else if (
      statusMessage === "unauthorized" ||
      statusMessage === "unauthorised"
    ) {
      // TODO Log/track unauthorized error
      console.log("Anauthorized query operation", error.operation);
    } else {
      notify({
        // group: "network",
        title: "Network Error",
        text: "Error communicating with server",
      });
    }
  });

  const cache = new InMemoryCache();

  const httpOptions = {
    uri: config.public.server.graph,
    credentials: config.public.server.credentials,
    headers: {
      //
    },
  };

  const uploadHttpOptions = {
    ...httpOptions,
    headers: {
      ...httpOptions.headers,
      "X-Requested-With": "XMLHttpRequest",
    },
  };

  const pusherLink = new PusherLink({
    pusher: new Pusher(config.public.server.subscription.pusherApiKey, {
      cluster: config.public.server.subscription.cluster,
      authEndpoint: config.public.server.subscription.authEndpoint,
      auth: {
        headers: {
          //
        },
      },
    }),
  });

  const middlewareLink = new ApolloLink((operation, forward) => {
    const headers = {
      ...operation.getContext().headers,
      "X-Access": config.public.server.access,
      ...filterUndefined({
        Authorization: getToken(),
        "X-Workspace": getWorkspace(),
      }),
    };

    operation.setContext({ headers });

    return forward(operation);
  });

  const link = createUploadLink(uploadHttpOptions);

  const apolloClient = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, middlewareLink, pusherLink, link]),
  });

  const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
  });

  vueApp.provide(DefaultApolloClient, apolloClient);
  provideApolloClient(apolloClient);
  vueApp.use(apolloProvider);
});
