import { defineNuxtRouteMiddleware } from "#imports";
import { useAppNavShortcutsStore } from "../stores/app-nav";
import { parseURL } from "ufo";

type MemorableNav = {
  name: string;
  route: string;
};

export default defineNuxtRouteMiddleware((to) => {
  const store = useAppNavShortcutsStore();
  const last = useState<MemorableNav | undefined>("lastNavShortcut");
  if (last.value) {
    store.rememberNav(toRaw(last.value));
  }
  if (to.meta.rememberNav) {
    last.value = {
      name: to.meta.rememberNav as string,
      route: parseURL(to.path)?.pathname,
    };
  } else {
    last.value = undefined;
  }
});
