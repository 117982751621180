export default defineNuxtRouteMiddleware(() => {
  const { loggedIn, context } = useAuth();

  if (loggedIn.value && context.value?.workspace?.status) {
    const status = context.value.workspace.status;
    const trial = status.trial_days_left || false;
    const trialDaysLeft = status.trial_days_left ?? 0;
    const hasContract = status.has_contract || false;

    if (!hasContract && trial && trialDaysLeft <= 0) {
      return navigateTo({ name: "access" });
    }
  }
});
