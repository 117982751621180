import { last, upperFirst } from "lodash";
import dayjs, { type Dayjs } from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { ServiceAvailability } from "~/models/dobby/Service";
import type {
  IServiceAvailability,
  OpeningHours,
  OpeningHoursDays,
} from "~/layers/admin/models";

dayjs.extend(LocalizedFormat);

interface FormattedAvailability {
  start: Dayjs;
  end: Dayjs;
  days: number[];
}

export const allDayScheduleVal = "00:00-23:55";
export const defaultScheduleTimes = ["09:00-17:00"];

export const isAllDaySchedule = (times: string[]) => {
  return times?.length == 1 && times[0] == allDayScheduleVal;
};

const _daysShort = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
const _daysFull = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const isSequential = (n: number[]) => {
  let count = min(n);

  for (const i of n) {
    if (i !== count++) {
      return false;
    }
  }

  return true;
  // return max(n) - min(n) !== n.length
};

export const days = _daysFull.map((d, i) => ({
  name: upperFirst(d),
  shortName: _daysShort[i],
  key: d as OpeningHoursDays,
}));

export const getDayFromWeekIndex = (n: number) => {
  n = wrapOffset(n, 6);
  return days[n];
};

export const getDayName = (n: number, short?: boolean) => {
  const d = getDayFromWeekIndex(n);
  return short ? d.shortName : d.name;
};

/**
 * Display minutes in e.g. `15min`, `1h`, `2h 30min`
 * @param minutes
 * @returns
 */
export const formatMinutesSimple = (minutes: number) => {
  const s = minutes * 60;
  const h = Math.floor(s / 3600);
  const m = Math.floor((s % 3600) / 60) + "min";
  if (h) {
    return `${h}h ${m}`;
  }
  return m;
};

/**
 * Display minutes in e.g. `02:15`
 * @param minutes
 * @returns
 */
export const formatMinutes = (minutes: number, use24hr = false) => {
  const s = minutes * 60;
  let h = Math.floor(s / 3600);
  let m = Math.floor((s % 3600) / 60);
  let u = "";

  if (!use24hr) {
    u = h >= 12 ? " PM" : " AM";
    if (h > 12) {
      h -= 12;
    }
  }

  const r = (x: number): string => {
    let s = String(x);
    if (s.length < 2) {
      s = `0${s}`;
    }
    return s;
  };

  return r(h) + ":" + r(m) + u;
};

/**
 * Format minutes in 24hr without units
 * e.g. `02:15`
 * @param minutes
 * @returns
 */
export const formatMinutesNoUnit = (minutes: number) => {
  const s = minutes * 60;
  let h = Math.floor(s / 3600);
  let m = Math.floor((s % 3600) / 60);

  const r = (x: number): string => {
    let s = String(x);
    if (s.length < 2) {
      s = `0${s}`;
    }
    return s;
  };

  return r(h) + ":" + r(m);
};

export const formatAvailabilityTimes = (avail: IServiceAvailability) => {
  const start = parseTimeToDate(avail.start_time).format("LT");
  const end = parseTimeToDate(avail.end_time).format("LT");
  return `${start} - ${end}`;
};

export const getAvailability = (avail: FormattedAvailability) => {
  let d: string;
  const start = avail.start.format("LT");
  const end = avail.end.format("LT");

  if (isSequential(avail.days)) {
    const d1 = _daysShort[min(avail.days, 0)];
    const d2 = _daysShort[max(avail.days, 6)];
    d = d1 == d2 ? d1 : d1 + " - " + d2;
  } else {
    d = avail.days.map((n) => _daysShort[n]).join(", ");
  }

  return `${d}, ${start} - ${end}`;
};

export const formatScheduleTime = (
  availability?: (ServiceAvailability | IServiceAvailability)[],
  startOfWeek = 0
) => {
  if (!availability?.length) return undefined;

  const formatted = availability
    .filter((sch) => !!sch.days)
    .map((sch) => ({
      start: parseTimeToDate(sch.start_time),
      end: parseTimeToDate(sch.end_time),
      days: sch.days!.map((d) => d + startOfWeek).sort(),
    }));

  const group = groupByFields(formatted, ["start", "end", "days"])
    .map((sch) => sch[0])
    .filter((x) => !!x);

  return group.map(getAvailability).join(" and ");
};

export const formatOpeningHourTime = (
  times: string[] = [],
  unavailableText?: string
) => {
  times = filterNilItems(times);

  if (isAllDaySchedule(times)) {
    return unavailableText || "Unavailable all day";
  }

  const format = (time: string) => {
    const [start_time, end_time] = time.split("-");
    return formatAvailabilityTimes({ start_time, end_time });
  };

  const formatted = times.map(format).join(" and ");

  return formatted || unavailableText || "Unavailable";
};

export const availabilityToOpeningHours = (
  availability: (IServiceAvailability | ServiceAvailability)[] = [],
  startOfWeek = 0
): OpeningHours => {
  const schedule = availability.filter((entry) => !!entry.days);
  const overrides = availability.filter((entry) => !!entry.date);

  const openingHours: OpeningHours = {
    startOfWeek,
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
    exceptions: {},
  };

  for (let day = 0; day < 7; day++) {
    const avail = schedule.filter((entry) => entry.days!.includes(day));
    const key = _daysFull[day];
    for (const entry of avail) {
      const start = parseTimeToDate(entry.start_time);
      const end = parseTimeToDate(entry.end_time);
      // @ts-ignore
      openingHours[key].push(start.format("HH:mm") + "-" + end.format("HH:mm"));
    }
  }

  for (const entry of overrides) {
    const start = parseTimeToDate(entry.start_time);
    const end = parseTimeToDate(entry.end_time);
    const key = dayjs(entry.date!).format("YYYY-MM-DD");

    if (!openingHours.exceptions[key]) {
      openingHours.exceptions[key] = [];
    }
    openingHours.exceptions[key].push(
      start.format("HH:mm") + "-" + end.format("HH:mm")
    );
  }

  return openingHours;
};

export const openingHoursToAvailability = (
  openingHours: OpeningHours
): IServiceAvailability[] => {
  type AvailabilityEntry = {
    start: string;
    end: string;
    day: number;
  };
  const entries: AvailabilityEntry[] = [];

  for (let day = 0; day < 7; day++) {
    const { key } = getDayFromWeekIndex(day + openingHours.startOfWeek);
    // @ts-ignore
    const startEndEntries: string[] = openingHours[key] || [];
    for (const startEnd of startEndEntries) {
      const [start, end] = startEnd.split("-");
      entries.push({ start, end, day });
    }
  }

  const availability = groupByFields(entries, ["start", "end"])
    .filter((entries) => !!entries.length)
    .map(
      (entries): IServiceAvailability => ({
        start_time: entries[0].start + ":00",
        end_time: entries[0].end + ":00",
        days: entries.map((entry) => entry.day),
      })
    );

  Object.entries(openingHours.exceptions || {}).forEach(
    ([date, startEndEntries]) => {
      for (const startEnd of startEndEntries) {
        const [start, end] = startEnd.split("-");
        availability.push({
          start_time: start + ":00",
          end_time: end + ":00",
          date,
          // date: moment(date, 'YYYY-MM-DD').toISOString(),
        });
      }
    }
  );

  return availability;
};

export const defaultOpeningHours = (
  hours?: Partial<OpeningHours>
): OpeningHours => ({
  startOfWeek: 0,
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
  exceptions: {},
  ...hours,
});

export const useScheduleTimes = (times?: Ref<string[]>) => {
  if (!times) {
    times = ref<string[]>([]);
  }

  const add = () => {
    if (times!.value?.length) {
      const prev = last(times!.value)!;
      const [_, prevEnd] = prev.split("-");
      const prevEndTime = parseTimeToDate(prevEnd);
      if (prevEndTime.get("hours") < 23) {
        const newEnd = prevEndTime.add(1, "hour").format("HH:mm");
        times!.value = [...times!.value, `${prevEnd}-${newEnd}`];
      }
    } else {
      times!.value = ["09:00-17:00"];
    }
  };

  const remove = (i: number) => {
    const v = [...times!.value];
    deleteAt(v, i);
    times!.value = v;
  };

  return {
    times,
    add,
    remove,
  };
};
