import { boolean, id, integer, json, model, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "../../models/dobby";
import gql from "graphql-tag";
import { isString } from "lodash";
import { isFolder } from "../../modules/media-library/runtime/utils";
import type {
  BrowseData,
  BrowseFilter,
  Downloadable,
  MediaFile as IMediaFile,
  MediaImage as IMediaImage,
  ImageSrc,
  MediaDownloadOptions,
  MediaFolder,
} from "../../modules/media-library/runtime/types";

export type UploadFile = Blob | File;

export type FileUploadOptions = {
  name?: string;
  location?: string;
  description?: string;
  private?: boolean;
  // disk?: string
};

export type ContentShareOptions = {
  name?: string;
  description?: string;
  public?: boolean;
  access_emails?: string[];
  access_type?: "secret" | "token";
  expires_at?: string;
  can_remove?: boolean;
  can_upload?: boolean;
  max_downloads?: number;
  max_upload_size?: number;
  allowed_upload_types?: string[];
  meta?: Record<string, any>;
};

export enum MediaFileType {
  FOLDER = "folder",
  IMAGE = "image",
  AUDIO = "audio",
  VIDEO = "video",
  OTHER = "file",
}
export type SharedContentAccessType = "secret" | "token";

class MediaFile extends Model implements IMediaFile {
  static override entity = "File";
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare id: string;
  @string() declare name: string;
  @string() declare location: string;
  @string() declare description?: string;
  // @string({ readonly: true }) declare readonly disk: string
  @string() declare filename: string;
  @string({ readonly: true }) declare readonly mimetype: string;
  @string({ readonly: true }) declare readonly type: MediaFileType;
  @string({ readonly: true }) declare readonly extension: string;
  @boolean() declare private: boolean;
  @integer({ readonly: true }) declare readonly size: number;
  @integer({ readonly: true }) declare readonly original_size: number;
  @json() declare meta?: Object;
  @model(() => Folder) declare folder?: Folder;
  @model(() => MediaImage) declare image?: MediaImage;

  static override queryRelationships = ["image"];

  static async upload(
    file: UploadFile,
    options?: FileUploadOptions
  ): Promise<IMediaFile> {
    const { data } = await this.apollo().mutate({
      mutation: gql`
        mutation ($file: Upload!, $options: FileUploadOptionsInput) {
          wsUpload(file: $file, options: $options) {
            id
            name
            type
            image {
              xsmall
              small
              thumb
              medium
              large
            }
          }
        }
      `,
      variables: {
        file,
        options,
      },
      context: {
        hasUpload: true,
      },
    });

    return data.wsUpload;
  }

  static async downloadableLink(
    fileId: string,
    options?: MediaDownloadOptions
  ): Promise<Downloadable> {
    const { data } = await this.apollo().query({
      query: gql`
        query ($id: ID!, $options: DownloadableLinkOptionsInput) {
          wsFileDownloadableLink(id: $id, options: $options) {
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        id: fileId,
        options,
      },
    });

    return data.wsFileDownloadableLink;
  }

  static async share(
    fileId: string,
    options?: ContentShareOptions
  ): Promise<string> {
    const { data } = await this.apollo().mutate({
      mutation: gql`
        mutation ($id: ID!, $options: ContentShareOptionsInput) {
          wsFileShareableLink(id: $id, options: $options) {
            id
            url
          }
        }
      `,
      variables: {
        id: fileId,
        options,
      },
    });

    return data.wsFileShareableLink.url;
  }

  static async move(fileId: string, location: string): Promise<MediaFile> {
    const file = await this.newQuery()
      .select("url", "filename", "mimetype")
      .where({
        id: {
          type: "ID",
          value: fileId,
          required: true,
        },
        location: {
          type: "String",
          value: location,
          required: true,
        },
      })
      .parseWith((response) => response.data.wsMoveFile)
      .mutate("wsMoveFile");

    return new MediaFile(file);
  }

  async $downloadableLink(
    options?: MediaDownloadOptions
  ): Promise<Downloadable> {
    // @ts-ignore
    return this.$self().downloadableLink(this.id, options);
  }

  async $share(options?: ContentShareOptions) {
    // @ts-ignore
    return this.$self().share(this.id, options);
  }

  async $move(location: string) {
    // @ts-ignore
    return this.$self().move(this.id, location);
  }
}

export class Folder extends Model implements MediaFolder {
  static override entity = "Folder";
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare id: string;
  @string() declare name: string;
  @string() declare location: string;
  @string() declare description?: string;
  // @string({ readonly: true }) declare readonly disk: string
  // @string() declare image: string
  @boolean() declare private: boolean;
  @model(() => MediaFile, { list: true }) declare files?: MediaFile[];
  @model(() => Folder) declare parent?: Folder;
  @model(() => Folder, { list: true }) declare folders?: Folder[];
  @model(() => MediaImage) declare image?: MediaImage;

  readonly type = MediaFileType.FOLDER;

  static async downloadableLink(
    folderId: string,
    options?: MediaDownloadOptions
  ): Promise<Downloadable> {
    const { data } = await this.apollo().query({
      query: gql`
        query ($id: ID!, $options: DownloadableLinkOptionsInput) {
          wsFolderDownloadableLink(id: $id, options: $options) {
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        id: folderId,
        options,
      },
    });

    return data.wsFolderDownloadableLink;
  }

  static async share(
    folderId: string,
    options?: ContentShareOptions
  ): Promise<string> {
    const { data } = await this.apollo().mutate({
      mutation: gql`
        mutation ($id: ID!, $options: ContentShareOptionsInput) {
          wsFolderShareableLink(id: $id, options: $options) {
            id
            url
          }
        }
      `,
      variables: {
        id: folderId,
        options,
      },
    });

    return data.wsFolderShareableLink.url;
  }

  static async move(folderId: string, location: string): Promise<Folder> {
    const folder = await this.newQuery()
      .select("url", "filename", "mimetype")
      .where({
        id: {
          type: "ID",
          value: folderId,
          required: true,
        },
        location: {
          type: "String",
          value: location,
          required: true,
        },
      })
      .parseWith((response) => response.data.wsMoveFolder)
      .mutate("wsMoveFolder");

    return new Folder(folder);
  }

  async $downloadableLink(
    options?: MediaDownloadOptions
  ): Promise<Downloadable> {
    // @ts-ignore
    return this.$self().downloadableLink(this.id, options);
  }

  async $share(options?: ContentShareOptions) {
    // @ts-ignore
    return this.$self().share(this.id, options);
  }

  async $move(location: string) {
    // @ts-ignore
    return this.$self().move(this.id, location);
  }
}

export class Attachment extends Model {
  static override entity = "Attachment";
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare id: string;
  @string() declare name: string;
  @string({ readonly: true }) declare readonly url: string;
  // @string({ readonly: true }) declare readonly disk: string
  @boolean() declare pending: boolean;
  @string() declare alt: string;
  @string({ readonly: true }) declare readonly location: string;
  @string({ readonly: true }) declare readonly attachment: string;

  static async upload(file: UploadFile) {
    //
  }

  static async purge(url: string) {
    //
  }
}

export class SharedContent extends Model {
  static override entity = "SharedContent";
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare id: string;
  @string() declare name: string;
  @string() declare description?: string;
  @string() declare access_emails: string[];
  @string() declare access_type: SharedContentAccessType;
  @string() declare access_keys: string[];
  @datetime() declare expires_at?: Dayjs;
  @boolean() declare public: boolean;
  @boolean() declare can_remove: boolean;
  @boolean() declare can_upload: boolean;
  @integer({ readonly: true }) declare readonly downloads: number;
  @integer() declare max_downloads: number;
  @integer() declare upload_size: number;
  @integer() declare max_upload_size: number;
  @string() declare allowed_upload_types: string[];
}

export class Media extends Model implements IMediaFile {
  static override entity = "Media";

  static override queryRelationships = ["image"];

  // Assign to undefined to trigger changes
  protected upload?: UploadFile = undefined;

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @string() declare id: string; // make ID writable
  @string() declare name: string;
  @string() declare description?: string;
  @string() declare location: string;
  @string() declare type: MediaFileType;
  @boolean() declare private: boolean;
  @string() declare extension: string;
  @model(() => MediaImage) declare image?: MediaImage;

  static async browse(
    location: string,
    filters?: BrowseFilter
  ): Promise<BrowseData> {
    const { data } = await this.apollo().query({
      query: gql`
        query ($location: String, $filters: MediaBrowseFilterInput) {
          wsBrowseMedia(location: $location, filters: $filters) {
            data {
              id
              name
              type
              private
              image {
                xsmall
                small
                thumb
                medium
                large
              }
            }
            paginate {
              total
              pages
              currentPage
              perPage
              prev
              next
            }
          }
        }
      `,
      variables: {
        location,
        filters,
      },
    });

    const files = data.wsBrowseMedia.data.map((file: any) =>
      isFolder(file) ? new Folder(file) : new MediaFile(file)
    );

    return {
      data: files,
      paginate: data.wsBrowseMedia.paginate,
    };
  }

  public $setImage(image: string | ImageSrc | MediaImage | IMediaImage) {
    if (!this.image) {
      this.$attach("image", new MediaImage());
    }
    if (isString(image)) {
      this.image!.$fill({
        xsmall: image,
        small: image,
        thumb: image,
        medium: image,
        large: image,
      });
    } else if (image instanceof MediaImage) {
      this.image!.$copy(image);
    } else {
      this.image!.$fill(image);
    }
  }

  public async $upload(image: Blob) {
    const upload = await MediaFile.upload(image);
    this.$fill({
      id: upload.id,
      name: upload.name,
      type: upload.type,
    });
    if (upload.image) {
      this.$setImage(upload.image);
    }
  }

  public $setUpload(image: UploadFile, src?: string) {
    this.upload = image;
    // if (!src) {
    //   src = URL.createObjectURL(image)
    // }
    // this.$setImage(src)
    if (src) {
      this.$setImage(src);
    }
  }

  public $getUpload() {
    return this.upload;
  }

  public $clearUpload() {
    this.upload = undefined;
  }

  public $shouldUpload() {
    return !!this.upload;
  }

  public override $keepChanges() {
    super.$keepChanges();
    this.$clearUpload();
  }

  override $isDirty(attribute?: string) {
    if (!attribute && this.$shouldUpload()) return true;
    return super.$isDirty(attribute);
  }

  override $isDeepDirty() {
    if (this.$shouldUpload()) return true;
    return super.$isDeepDirty();
  }

  public $fillWithMediaFile(file: IMediaFile | MediaFile) {
    this.$fill({
      id: file.id,
      name: file.name,
      type: file.type,
      location: file.location,
      private: file.private,
      extension: file.extension,
    });

    if (file.image) {
      this.$setImage(file.image);
    }
  }

  public $toMediaFile(): IMediaFile {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      image: this.image,
      // src: this.src,
      // lazySrc: this.lazySrc,
      location: this.location,
      private: this.private,
      extension: this.extension,
      // loading?: boolean
    };
  }
}

export class MediaImage extends Model implements IMediaImage {
  static override entity = "MediaImage";
  @string() declare readonly xsmall: string;
  @string() declare readonly small: string;
  @string() declare readonly thumb: string;
  @string() declare readonly medium: string;
  @string() declare readonly large: string;

  get src() {
    return this.thumb;
  }
  get lazySrc() {
    return this.xsmall;
  }
}

export { MediaFile, MediaFile as File };
