import {
  isEqual,
  transform,
  isObject,
  get,
  set,
  isString,
  omitBy,
  isUndefined,
  difference as difference0,
} from "lodash";

export const empty = (value: any): boolean => {
  if (typeof value === "boolean") return false;
  if (typeof value === "string") return !value.length;
  if (typeof value === "number") return false;
  return !!value;
};

export const equals = (a: any, b: any): boolean => {
  if (typeof a === "object") {
    if (typeof b !== "object") return false;
    return isEqual(a, b);
  }
  return a === b;
};

export const flattenObject = (object: any) => {
  var toReturn: any = {};

  for (var i in object) {
    if (!object.hasOwnProperty(i)) continue;

    if (typeof object[i] == "object") {
      var flatObject = flattenObject(object[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = object[i];
    }
  }
  return toReturn;
};

export const isChanged = (original: any, object: any): boolean => {
  if (!original || !object) return false;
  if (!isObject(object)) {
    if (object === undefined) return false;
    return original !== object;
  }

  let changed = false;
  for (const key of Object.keys(flattenObject(object))) {
    const value = get(object, key);
    changed = changed || (!empty(value) && !isEqual(value, get(original, key)));
    if (changed) break;
  }

  return changed;
};

export const difference = <T>(a: T[], b: T[]): T[] => {
  const diff = difference0(a, b);
  return !diff.length && (a.length || b.length) ? difference0(b, a) : diff;
};

export function changes<T extends Record<string, any>>(
  object: T,
  original: T
): Partial<T> {
  return transform(object, (result: any, value, key) => {
    const o = original[key];
    if (!isEqual(value, o)) {
      if (Array.isArray(value) && Array.isArray(o)) {
        result[key] = difference(value, o);
      } else if (isObject(value) && isObject(o)) {
        result[key] = changes(value, o);
      } else {
        result[key] = value;
      }
    }
  });
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object    Object compared
 * @param  {Object} original  Object to compare with
 * @return {Object}           Return a new object who represent the diff
 */
export function deepDiff<T extends Record<string, any>>(
  object: T,
  original: T
) {
  if (Array.isArray(object) && Array.isArray(original)) {
    return difference(original, object);
  }
  return changes(object, original);
}

export function assignObject<
  A extends Record<string, any>,
  B extends Record<string, any>
>(a: A, b: B) {
  Object.entries(b).forEach(([key, value]) => {
    set(a, key, value);
  });
}

export function parseJsonString(value: any): any {
  if (isObject(value)) {
    return value;
  }
  if (isString(value)) {
    try {
      value = JSON.parse(value);
    } catch (error) {
      value = undefined;
    }
  }
  return value;
}

export const filterUndefined = <T>(o: T): T => omitBy<any>(o, isUndefined) as T;
