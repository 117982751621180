import { get, isNil } from "lodash";
import { props } from "./props";
import { VChip } from "vuetify/components";
import { RouterLink } from "vue-router";

const base = defineComponent({
  props,
  methods: {
    value() {
      return get(this.item, this.itemKey!);
    },
    withValue(fn: (v: any) => any) {
      const v = this.value();
      if (isNil(v)) {
        return "—";
      }

      return fn(v);
    },
  },
});

export const Date = defineComponent({
  ...base,
  render() {
    return this.withValue((v) => {
      return h("div", formatTime(v));
    });
  },
});

export const Count = defineComponent({
  ...base,
  render() {
    return this.withValue((v) => {
      const n = Array.from(v).length;
      if (this.showMobileTable && this.title) {
        return `${this.title}: ${n}`;
      }
      return n;
    });
  },
});

export const Bool = defineComponent({
  props: {
    ...base.props,
    falseColor: { type: String },
    trueColor: { type: String, default: "primary" },
  },
  methods: base.methods,
  render() {
    return this.withValue((v) => {
      const text = v ? "Yes" : "No";
      const color = v ? this.trueColor : this.falseColor;
      return h(VChip, { color, size: "small" }, text);
    });
  },
});

export const Active = defineComponent({
  ...base,
  methods: base.methods,
  render() {
    return this.withValue((v) => {
      const text = v ? "Active" : "Inactive";
      const color = v ? "green" : "gray";
      return h(VChip, { color, size: "small" }, text);
    });
  },
});

export const Chip = defineComponent({
  props: {
    ...base.props,
    itemTitle: { type: String },
    color: { type: String },
  },
  methods: base.methods,
  render() {
    return this.withValue((v) => {
      const text = this.itemTitle ? get(this.item, this.itemTitle, v) : v;
      return h(VChip, { color: this.color, size: "small" }, text);
    });
  },
});

export const Price = defineComponent({
  props: {
    ...base.props,
    currencyKey: { type: String, default: "currency" },
  },
  methods: base.methods,
  render() {
    return this.withValue((v) => {
      const currency = this.currencyKey
        ? get(this.item, this.currencyKey)
        : undefined;
      return formatPrice(v, currency);
    });
  },
});

export const Email = defineComponent({
  ...base,
  render() {
    return this.withValue((v) => {
      const props = {
        href: "mailto:" + v,
        style: { "text-decoration": "none" },
      };
      return h("a", props, v);
    });
  },
});

export const Phone = defineComponent({
  ...base,
  render() {
    return this.withValue((v) => {
      const props = {
        href: "tel:" + v,
        style: { "text-decoration": "none" },
      };
      return h("a", props, v);
    });
  },
});

export const Title = defineComponent({
  props: {
    ...base.props,
  },
  methods: base.methods,
  render() {
    return this.withValue((v) => {
      const content = this.itemRoute
        ? h(
            RouterLink,
            {
              class: "data-table__title data-table__title--link",
              to: this.itemRoute,
            },
            v
          )
        : v;

      return h("div", { class: "data-table__title" }, content);
    });
  },
});

export const Url = defineComponent({
  props: {
    ...base.props,
    itemTitle: { type: String },
    target: { type: String },
  },
  methods: base.methods,
  render() {
    return this.withValue((v) => {
      const text = this.itemTitle ? get(this.item, this.itemTitle, v) : v;
      const props = {
        href: v,
        target: this.target,
        style: { "text-decoration": "none" },
      };
      return h("a", props, text);
    });
  },
});

export const Resource = defineComponent({
  props: {
    ...base.props,
    target: { type: String },
  },
  methods: base.methods,
  render() {
    return this.withValue((v) => {
      const resource = resolveResource(v);

      if (!resource) {
        return "—";
      }

      const props = {
        href: resource.url,
        target: this.target,
        style: { "text-decoration": "none" },
      };
      return h("a", props, resource.name);
    });
  },
});
