import { Model } from "~/layers/admin/models/dobby";
import Currency from "./Currency";
import { boolean, integer, model, string } from "@moirei/dobby";
import type { ICountry } from "~/layers/admin/models";

export default class Country extends Model implements ICountry {
  static entity = "Country";
  static primaryKey = "code";
  // static eagerLoad = ['currency']

  @string({ readonly: true }) declare readonly code: string;
  @string({ readonly: true }) declare readonly name: string;
  // @string({ readonly: true }) declare code_iso_3166_3?: string
  @string({ readonly: true }) declare readonly full_name?: string;
  @string({ readonly: true }) declare readonly capital?: string;
  @string({ readonly: true }) declare readonly citizenship?: string;
  @string({ readonly: true }) declare readonly country_code: string;
  @string({ readonly: true }) declare readonly currency_code?: string;
  @boolean({ readonly: true }) declare readonly eea: boolean;
  @string({ readonly: true }) declare readonly calling_code?: string;
  @string({ readonly: true }) declare readonly flag?: string;
  @integer({ readonly: true }) declare readonly tax: number;
  @model(() => Currency) declare currency: Currency;
}
