import { Dobby, error, mergeDeep } from "@moirei/dobby";
import type {
  QueryCallback,
  Attributes,
  ModelConstructor,
} from "@moirei/dobby";
import { has, get, set } from "lodash";
import FieldBuilder from "./FieldBuilder";
import type { ApolloClient } from "@apollo/client/core";
import type { SaveContextCustomFill } from "../../helpers/fill-model";

export default abstract class Model extends Dobby {
  $willDelete = false;
  $deleted = false;

  static primaryKeys?: string[];
  protected customAttributes: Attributes = {};

  static override fieldBuilder() {
    return FieldBuilder;
  }

  /**
   * Get the registered apollo client
   * @returns {ApolloClient<any>}
   */
  static apollo(): ApolloClient<any> {
    if (!this.client) {
      error(`Cannot access apollo client on model [${this.modelKey}]`);
    }
    return this.client.graphQlClient as ApolloClient<any>;
  }

  /**
   * Compaire the model with another.
   *
   * @param {Model} model
   * @returns {boolean}
   */
  public override $is(model: Model): boolean {
    // @ts-ignore
    const primaryKeys: string[] = this.$self().primaryKeys;

    if (primaryKeys && primaryKeys.length) {
      if (model.$self().modelKey === this.$self().modelKey) {
        for (const key of primaryKeys) {
          if (model.$getAttribute(key) === this.$getAttribute(key)) {
            return true;
          }
        }
      }

      return false;
    }

    return super.$is(model);
  }

  $markForDeletes() {
    this.$willDelete = true;
  }

  $unmarkForDeletes() {
    this.$willDelete = false;
  }

  override $isDirty(attribute?: string) {
    if (!attribute && this.$willDelete && !this.$deleted) return true;
    return super.$isDirty(attribute);
  }

  override $isDeepDirty() {
    if (this.$willDelete && !this.$deleted) return true;
    return super.$isDeepDirty();
  }

  override $keepChanges(): false | void {
    if (this.$willDelete) this.$deleted = true;
    return super.$keepChanges();
  }

  /**
   * Execute a findUnique operation and hidrate with results.
   *
   * @param {number|string|Attributes} args
   * @param {string[]|QueryCallback} selects
   * @param {string[]} includes
   */
  override async $hidrateWith(
    args: number | string | Attributes,
    selects?: string[] | QueryCallback<ModelConstructor<Model>>,
    includes?: string[]
  ): Promise<boolean> {
    return super.$hidrateWith(args, selects, includes);
  }

  static cache<T>(key: string, callback: () => T): T {
    const k = "$cache" + this.modelKey + key;
    if (!has(this, k)) {
      set(this, k, callback());
    }

    return get(this, k);
  }

  // public $jsonAttributeIsDirty(attribute: string): boolean {
  //   const original = this.$getOriginal(attribute)
  //   const curr = this.$getAttribute(attribute)
  //   const current = curr ? JSON.stringify(curr) : null
  //   return current !== original
  // }

  public $setCustomAttribute(attribute: string, value: any) {
    this.customAttributes = mergeDeep(this.customAttributes, {
      [attribute]: value,
    });
    return this;
  }

  public $getCustomAttribute<T extends any>(attribute: string, defu?: T): T {
    return get(this.customAttributes, attribute, defu);
  }

  public $hasCustomAttribute(attribute: string): boolean {
    return has(this.customAttributes, attribute);
  }

  public $setSaveContextAttribute(
    attribute: string,
    value: SaveContextCustomFill
  ) {
    this.$setCustomAttribute(`save-context:${attribute}`, value);
    return this;
  }

  public $getSaveContextAttribute<T extends any>(
    attribute: string
  ): SaveContextCustomFill | undefined {
    return this.$getCustomAttribute(`save-context:${attribute}`);
  }
}
