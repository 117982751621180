import gql from "graphql-tag";

const userProfileFields = `id
name
first_name
last_name
email
email_verified`;

const authContextFields = `
workspace{
  name code avatar currency
  accepted_terms
  usage
  status{
    plan status store_onboarding_status
    has_contract trial trial_days_left
    workspace_actions
    next_steps{handle title name description complete mandatory}
  }
}
workspaces{name code avatar active owner{id name email}}
app`;

const authPayloadFields = `
access_token
refresh_token
expires_in
user {${userProfileFields}}
context{${authContextFields}}
`;

export const LoginMutation = gql`
  mutation Login($data: LoginInput!) {
    login(data: $data) {${authPayloadFields}}
  }
`;

export const RefreshLoginTokenMutation = gql`
  mutation RefreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      access_token
      refresh_token
      expires_in
    }
  }
`;

export const LogoutMutation = gql`
  mutation Logout {
    logout {
      status
      message
    }
  }
`;

export const ForgotPasswordMutation = gql`
  mutation ForgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data) {
      status
      message
    }
  }
`;

export const UpdateForgottenPasswordMutation = gql`
  mutation UpdateForgottenPassword($data: NewPasswordWithCodeInput!) {
    updateForgottenPassword(data: $data) {
      status
      message
    }
  }
`;

export const SocialLoginMutation = gql`
  mutation SocialLogin($data: SocialLoginInput!) {
    socialLogin(data: $data) {${authPayloadFields}}
  }
`;

export const VerifyEmailMutation = gql`
  mutation VerifyEmail($data: VerifyEmailInput!) {
    verifyEmail(data: $data) {${authPayloadFields}}
  }
`;

export const ResendVerificationEmailMutation = gql`
  mutation ResendVerificationEmail($data: ResendVerificationEmailInput!) {
    resendVerificationEmail(data: $data) {
      status
      message
    }
  }
`;

export const UpdatePasswordMutation = gql`
  mutation UpdatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data) {
      status
      message
    }
  }
`;

export const RegisterMutation = gql`
  mutation Register($data: UserRegisterInput!) {
    register(data: $data) {
      status
      tokens{${authPayloadFields}}
    }
  }
`;

export const AuthContextQuery = gql`
  query AuthContext {
    authContext {${authContextFields}}
  }
`;

export const MeQuery = gql`
  query MeQuery {
    me {${userProfileFields}}
  }
`;
