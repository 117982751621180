import { Adapter } from "@moirei/dobby";
import type { Query, Attribute, ModelType, Dobby } from "@moirei/dobby";
import { upperFirst } from "lodash";
import pluralize, { singular } from "pluralize";

export default class WorkspaceAdapter extends Adapter {
  create(data: Attribute, query: Query<any>, model: ModelType) {
    return this.executeHook(model, "create", [data, query, model], () => {
      const operation = "wsCreate" + upperFirst(model.entity);
      query
        .where("data", {
          type: "Ws" + upperFirst(model.entity) + "CreateInput",
          required: true,
          value: data,
        })
        .mutation()
        .operation(operation)
        .parseWith((response) => response.data[operation]);
    });
  }

  createMany(data: Attribute, query: Query<any>, model: ModelType) {
    return this.executeHook(model, "createMany", [data, query, model], () => {
      const operation = "wsCreate" + pluralize(upperFirst(model.entity));
      query
        .where("data", {
          type: "[" + upperFirst(model.entity) + "CreateInput!]",
          required: true,
          value: data,
        })
        .mutation()
        .operation(operation)
        .parseWith((response) => response.data[operation]);
    });
  }

  upsert(args: any[], data: Attribute, query: Query<any>, model: ModelType) {
    return this.executeHook(model, "upsert", [args, data, query, model], () => {
      const operation = "wsUpsert" + upperFirst(model.entity);
      query
        .where({
          where: {
            type: upperFirst(model.entity) + "WhereInput",
            required: true,
            value: args,
          },
          data: {
            type: upperFirst(model.entity) + "UpdateInput",
            required: true,
            value: data,
          },
        })
        .mutation()
        .operation(operation)
        .parseWith((response) => response.data[operation]);
    });
  }

  update(args: any, data: Attribute, query: Query<any>, model: ModelType) {
    return this.executeHook(model, "update", [args, data, query, model], () => {
      const operation = "wsUpdate" + upperFirst(model.entity);
      query
        .where({
          where: {
            type: this.getInputName(model),
            required: true,
            value: args,
          },
          data: {
            type: "Ws" + upperFirst(model.entity) + "UpdateInput",
            required: true,
            value: data,
          },
        })
        .mutation()
        .operation(operation)
        .parseWith((response) => response.data[operation]);
    });
  }

  findMany(query: Query<any>, model: ModelType) {
    return this.executeHook(model, "findMany", [query, model], () => {
      const operation = "ws" + upperFirst(pluralize(model.entity));
      query
        .operation(operation)
        .parseWith((response) => response.data[operation]);
    });
  }

  findUnique(args: any[], query: Query<any>, model: ModelType) {
    const operation = "ws" + upperFirst(singular(model.entity));
    return this.executeHook(model, "findUnique", [args, query, model], () => {
      query
        .where("where", {
          type: this.getInputName(model),
          required: true,
          value: args,
        })
        .operation(operation)
        .parseWith((response) => response.data[operation]);
    });
  }

  delete(args: any[], query: Query<any>, model: ModelType) {
    return this.executeHook(model, "delete", [args, query, model], () => {
      const operation = "wsDelete" + upperFirst(model.entity);
      query
        .where("where", {
          type: this.getInputName(model),
          required: true,
          value: args,
        })
        .mutation()
        .operation(operation)
        .parseWith((response) => response.data[operation]);
    });
  }

  $update(data: Attribute, query: Query<any>, model: Dobby) {
    return this.executeHook(
      model.$self(),
      "$update",
      [data, query, model],
      () => {
        const operation = "wsUpdate" + upperFirst(model.$self().entity);
        query
          .where({
            where: {
              type: this.getInputName(model.$self()),
              required: true,
              value: {
                [model.$getKeyName()]: model.$getKey(),
              },
            },
            data: {
              type: "Ws" + upperFirst(model.$self().entity) + "UpdateInput",
              required: true,
              value: data,
            },
          })
          .mutation()
          .operation(operation)
          .parseWith((response) => response.data[operation]);
      }
    );
  }

  $delete(query: Query<any>, model: Dobby) {
    return this.executeHook(model.$self(), "$delete", [query, model], () => {
      const operation = "wsDelete" + upperFirst(model.$self().entity);
      query
        .where("where", {
          type: this.getInputName(model.$self()),
          required: true,
          value: {
            [model.$getKeyName()]: model.$getKey(),
          },
        })
        .mutation()
        .operation(operation)
        .parseWith((response) => response.data[operation]);
    });
  }

  getInputName(model: ModelType) {
    return upperFirst(model.entity) + "WhereUniqueInput";
  }
}
