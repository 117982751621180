import { get } from "lodash";
import { injectMetricsContext } from "../data";
import type { ChartTypeLike } from "../types";
import { defineChartComponent } from "../chart-js";
import { normaliseChartType } from "../utils";

const datasetOptions = [
  {
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderColor: "#5c6bc0",
    borderWidth: 1.5,
  },
  {
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderColor: "rgba(0, 0, 0, 0.3)",
    borderWidth: 1.5,
  },
];

export default defineComponent({
  name: "MetricsChart",
  props: {
    type: { type: String as PropType<ChartTypeLike>, default: "line" },
    source: { type: String, required: true },
  },
  setup(props) {
    const ctx = injectMetricsContext();

    const result = ctx.getters.trend(props.source);

    return {
      loading: ctx.loading,
      result,
    };
  },
  render() {
    const cmp = defineChartComponent({
      type: normaliseChartType(this.type),
      options: {
        legend: false,
        beginAtZero: true,
        // title: this.result.handle
      },
    });

    return h(cmp, {
      loading: this.loading,
      data: {
        labels: this.result.labels,
        datasets:
          this.result.datasets?.map((set, i: number) => ({
            interpolate: true, // required for crosshair interpolation interaction to work
            ...get(datasetOptions, i, {}),
            ...set,
          })) || [],
      },
    });
  },
});
