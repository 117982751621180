import cookie from "cookie";
import type { IAuthStorage } from "./auth/types";
import { decodeValue, encodeValue, isSet, isUnset } from "./utils";
import type { AuthStore } from "./store";

export default class AuthStorage implements IAuthStorage {
  constructor(
    protected store: AuthStore,
    protected options: {
      ignoreExceptions?: boolean;
      localStorage:
        | {
            prefix: string;
          }
        | false;
      cookie:
        | {
            prefix: string;
            options: {
              maxAge?: number;
              expires?: Date | number;
              sameSite?: true | false | "lax" | "strict" | "none" | undefined;
              secure?: boolean | undefined;
            };
          }
        | false;
    }
  ) {
    //
  }

  public setUniversal<V extends unknown>(key: string, value: V): V | void {
    if (isUnset(value)) {
      return this.removeUniversal(key);
    }
    this.setCookie(key, value);
    this.setLocalStorage(key, value);
    this.setState(key, value);
    return value;
  }

  public getUniversal(key: string): unknown {
    let value;
    if (process.server) {
      value = this.getState(key);
    }
    if (isUnset(value)) {
      value = this.getCookie(key);
    }
    if (isUnset(value)) {
      value = this.getLocalStorage(key);
    }
    if (isUnset(value)) {
      value = this.getState(key);
    }
    return value;
  }

  public syncUniversal(key: string, defaultValue?: unknown): unknown {
    let value = this.getUniversal(key);
    if (isUnset(value) && isSet(defaultValue)) {
      value = defaultValue;
    }
    if (isSet(value)) {
      this.setUniversal(key, value);
    }
    return value;
  }

  public removeUniversal(key: string): void {
    this.removeState(key);
    this.removeLocalStorage(key);
    this.removeCookie(key);
  }

  public setState<V extends unknown>(key: string, value: V): V {
    this.store.set(key, value);
    return value;
  }

  public getState(key: string): unknown {
    return this.store.get(key);
  }

  public removeState(key: string): void {
    this.setState(key, void 0);
  }

  public setLocalStorage<V extends unknown>(key: string, value: V): V | void {
    if (isUnset(value)) {
      return this.removeLocalStorage(key);
    }
    if (typeof localStorage === "undefined" || !this.options.localStorage) {
      return;
    }
    const _key = this.options.localStorage.prefix + key;
    try {
      localStorage.setItem(_key, encodeValue(value));
    } catch (e) {
      if (!this.options.ignoreExceptions) {
        throw e;
      }
    }
    return value;
  }

  public getLocalStorage(key: string): unknown {
    if (typeof localStorage === "undefined" || !this.options.localStorage) {
      return;
    }
    const _key = this.options.localStorage.prefix + key;
    const value = localStorage.getItem(_key);
    return decodeValue(value);
  }

  public removeLocalStorage(key: string): void {
    if (typeof localStorage === "undefined" || !this.options.localStorage) {
      return;
    }
    const _key = this.options.localStorage.prefix + key;
    localStorage.removeItem(_key);
  }

  public getCookies(): Record<string, unknown> {
    const cookieStr = process.client
      ? document.cookie
      : this.ctx.req.headers.cookie;
    return cookie.parse(cookieStr || "") || {};
  }

  public setCookie<V extends unknown>(
    key: string,
    value: V,
    options: {
      prefix?: string;
    } = {}
  ): V {
    if (!this.options.cookie || (process.server && !this.ctx.res)) {
      return value;
    }
    const _prefix =
      options.prefix !== void 0 ? options.prefix : this.options.cookie.prefix;
    const _key = _prefix + key;
    const _options = Object.assign({}, this.options.cookie.options, options);
    const _value = encodeValue(value);
    if (isUnset(value)) {
      _options.maxAge = -1;
    }
    if (typeof _options.expires === "number") {
      _options.expires = new Date(Date.now() + _options.expires * 864e5);
    }
    const serializedCookie = cookie.serialize(_key, _value, _options);
    if (process.client) {
      document.cookie = serializedCookie;
    } else if (process.server && this.ctx.res) {
      const cookies = this.ctx.res.getHeader("Set-Cookie") || [];
      cookies.unshift(serializedCookie);
      this.ctx.res.setHeader(
        "Set-Cookie",
        cookies.filter(
          (v, i, arr) =>
            arr.findIndex((val) =>
              val.startsWith(v.substr(0, v.indexOf("=")))
            ) === i
        )
      );
    }
    return value;
  }

  public getCookie(key: string): unknown {
    if (!this.options.cookie || (process.server && !this.ctx.req)) {
      return;
    }
    const _key = this.options.cookie.prefix + key;
    const cookies = this.getCookies();
    const value = cookies[_key] ? decodeURIComponent(cookies[_key]) : void 0;
    return decodeValue(value);
  }

  public removeCookie(
    key: string,
    options: {
      prefix?: string;
    } = {}
  ): void {
    this.setCookie(key, void 0, options);
  }
}
