<template>
  <div>
    <v-card rounded="lg" :loading="loading">
      <div class="md:flex">
        <div v-if="mobile" class="pa-2">
          <v-btn-toggle
            v-model="period"
            class="w-full"
            variant="flat"
            width="100%"
            rounded="lg"
            border
            divided
          >
            <v-btn
              v-for="(period, i) in periods"
              :key="`p-${i}`"
              :value="period.value"
              class="flex-grow"
              rounded="0"
            >
              {{ period.title }}
            </v-btn>
          </v-btn-toggle>
        </div>
        <v-card
          v-else
          class="ma-2 px-4 flex-center!"
          variant="flat"
          rounded="lg"
          link
        >
          <span class="flex-align gap-1 min-w-80px justify-center">
            <Icon class="mt-0.5" name="heroicons:calendar-days-16-solid" />
            <span>{{ activePeriodTitle }}</span>
          </span>
          <v-menu
            activator="parent"
            location="bottom"
            transition="slide-y-transition"
            offset="15"
          >
            <v-card class="py-2">
              <v-item-group v-model="period">
                <div v-for="item in periods" :key="item.value">
                  <v-item v-slot="{ isSelected, toggle }" :value="item.value">
                    <v-list-item
                      @click="toggle"
                      :title="item.title"
                      :subtitle="item.description"
                    >
                      <template #prepend>
                        <span class="mr-2 flex-align">
                          <v-icon size="20">
                            {{
                              isSelected
                                ? "radio_button_checked"
                                : "radio_button_unchecked"
                            }}
                          </v-icon>
                        </span>
                      </template>
                    </v-list-item>
                  </v-item>
                </div>
              </v-item-group>
            </v-card>
          </v-menu>
        </v-card>

        <div>
          <v-divider :vertical="!mobile" />
        </div>

        <div class="flex flex-row gap-2 pa-2 overflow-x-auto">
          <template v-for="(item, i) in renderItems" :key="i">
            <div v-if="i" class="flex">
              <v-divider vertical />
            </div>
            <v-card
              variant="flat"
              rounded="lg"
              class="px-3 py-2 min-w-[fit-content]!"
              :to="getUrl(item)"
            >
              <div class="heading-3">{{ item.title }}</div>
              <div class="flex justify-between gap-2">
                <div class="heading-1">{{ item.displayValue }}</div>
                <template v-if="item.hasSparkline">
                  <v-sparkline
                    v-if="item.sparkline"
                    class="min-w-150px"
                    :model-value="item.sparkline"
                    line-width="4"
                    color="primary"
                    auto-draw
                    smooth
                  />
                  <div
                    v-else
                    class="min-w-150px flex-grow flex items-end relative"
                  >
                    <div class="h-1.5px bg-primary w-full"></div>
                    <div
                      class="absolute bottom-[-5px] left-0 right-0 h-5px bg-indigo/20"
                    ></div>
                  </div>
                </template>
              </div>
            </v-card>
          </template>
        </div>

        <!-- TODO: implement for when metrics are overflowing -->
        <!-- <v-spacer />
        <div class="pa-2 flex-align">
          <v-btn elevation="1" rounded="e-lg" size="30" icon="chevron_left" />
          <v-btn elevation="1" rounded="s-lg" size="30" icon="chevron_right" />
        </div> -->
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";
import {
  MetricType,
  type GroupMetricsItem,
  type MetricsControls,
  type MetricsDataSource,
} from "../types";
import {
  addUnit,
  isDbMetricsDataSource,
  normaliseGenericDataSource,
} from "../utils";
import { get, sum } from "lodash";
import { useLocalStorage } from "@vueuse/core";

export default defineComponent({
  props: {
    metricsKey: { type: String, default: "default" },
    items: { type: Array as PropType<GroupMetricsItem[]>, required: true },
  },
  setup(props) {
    const storeKey = `group-metrics:${props.metricsKey}`;
    const { mobile } = useDisplay();
    const period = useLocalStorage<string>(storeKey, "today");
    const until = now();

    const periods = [
      {
        title: "Today",
        value: "today",
        description: "From start of day to current hour",
      },
      {
        title: "7 days",
        value: "days:7",
        description: "From previous 7 days to current hour",
      },
      {
        title: "30 days",
        value: "days:30",
        description: "From previous 30 days to current hour",
      },
      ...(mobile.value
        ? []
        : [
            {
              title: "Last 12 months",
              value: "months:12",
              description: "From previous 12 months to current hour",
            },
            {
              title: "This year",
              value: "year",
              description: `From year to date (${now()
                .startOf("year")
                .format("MMM")}—Today)`,
            },
            {
              title: "Last year",
              value: "years:1",
              description: "From last year (Jan—Dec)",
            },
          ]),
    ];

    const sourceKey = (index: number, handle: string) => {
      return `${index}/${handle}`;
    };

    const sources = computed(() => {
      const entries: MetricsDataSource<string>[] = [];

      props.items.forEach((item, i) => {
        const s = normaliseGenericDataSource(item.sources);
        for (const source of s) {
          if (isDbMetricsDataSource(source)) {
            entries.push({
              ...source,
              handle: sourceKey(i, source.handle!),
            });
          } else {
            entries.push(...s);
          }
        }
      });

      return entries;
    });

    const controls = computed((): MetricsControls => {
      const since = match(period.value, {
        today: () => now().startOf("day"),
        year: () => now().startOf("year"),
        default: () => {
          const [unit, value] = period.value.split(":");
          return now()
            .subtract(parseInt(value), unit as any)
            .startOf("day");
        },
      });

      return {
        since: formatToDatetime(since),
        until: formatToDatetime(until),
      };
    });

    const mCtx = provideMetrics(sources, controls);

    const renderItems = computed(() => {
      return props.items.map((item, i) => {
        let sparkline: number[] | undefined;

        const valueSource = sourceKey(i, item.value);
        const result = mCtx.getResult(valueSource);
        let value: any;

        if (result.type == MetricType.VALUE) {
          value = get(result.datasets, [0, "value"]) || "—";
        } else {
          const data = get(result.datasets, [0, "data"], []);
          value = sum(data);
        }

        if (item.formatPrice && item.withUnit && result.unit) {
          value = formatPrice(value, result.unit.label);
        } else if (item.withUnit) {
          value = addUnit(value, result.unit);
        }

        if (item.trend) {
          const trendSource = sourceKey(i, item.trend);
          const result = mCtx.getResult(trendSource, MetricType.TREND);
          if (result) {
            const s = get(result.datasets, [0, "data"], []);
            if (sum(s) > 0) {
              sparkline = s;
            }
          }
        }

        return {
          ...item,
          hasSparkline: !!item.trend,
          displayValue: value,
          sparkline,
        };
      });
    });

    return {
      mobile,
      period,
      periods,
      loading: mCtx.loading,
      renderItems,
    };
  },
  computed: {
    activePeriodTitle() {
      const active = findWhere(this.periods, {
        value: this.period,
      });
      return active?.title;
    },
  },
  methods: {
    getUrl(item: any) {
      return "#";
    },
  },
});
</script>
