import { id, model, string } from "@moirei/dobby";
import { Model } from "~/layers/admin/models/dobby";
import Product from "./Product";
import type FieldBuilder from "~/layers/admin/models/dobby/FieldBuilder";

export default class ProductType extends Model {
  static override entity = "ProductType";
  static override primaryKey = "handle";
  static override queryAttributes = ["handle", "name"];

  @id()
  declare readonly handle: string;

  @string()
  declare name: string;

  // @ts-ignore
  @model(() => Product, { list: true, default: [] })
  declare products: Product[];

  static override fields(f: FieldBuilder) {
    f.timestamps();
  }
}
