import { get, max, min } from "lodash";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import type {
  FilterItem,
  SelectFilter,
  CheckboxFilter,
  FloatFilter,
  DateFilter,
  SavedFilterValue,
  FilterInput,
  DateDaysFormat,
} from "./types";
import { FilterOperator } from "./types";
import { isDefined } from "@vueuse/core";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const isCheckboxFilter = (input: FilterItem): input is CheckboxFilter =>
  input.type === "checkbox";

export const isSelectFilter = (input: FilterItem): input is SelectFilter =>
  input.type === "select";

export const isItemsType = (
  input: FilterItem
): input is CheckboxFilter | SelectFilter =>
  isCheckboxFilter(input) || isSelectFilter(input);

export const isFloatFilter = (input: FilterItem): input is FloatFilter =>
  input.type === "float";

export const isDateFilter = (input: FilterItem): input is DateFilter =>
  input.type === "date";

const checkOperator = (
  operator: FilterOperator,
  target: any,
  values: any[]
): boolean => {
  const v1 = Number(get(values, 0)) || 0;
  const v2 = Number(get(values, 1)) || 0;
  return match(operator, {
    [FilterOperator.In]: () => values.includes(target),
    [FilterOperator.Equal]: () => target == get(values, 0),
    [FilterOperator.Between]: () => {
      const v = [v1, v2];
      return target >= min(v)! && target <= max(v)!;
    },
    [FilterOperator.Greater]: () => target > v1,
    [FilterOperator.Lesser]: () => target < v1,
    [FilterOperator.GreaterOrEqual]: () => target >= v1,
    [FilterOperator.LesserOrEqual]: () => target <= v1,
    default: false,
  });
};

const checkDate = (tgt: any, savedValue: SavedFilterValue): boolean => {
  const target = dayjs(tgt);

  return match(savedValue.operator, {
    [FilterOperator.LastN]: () => {
      const formatNumber = Number(get(savedValue.values, 2)) || 0;
      const formatFormat = get(savedValue.values, 3, "days") as DateDaysFormat;
      const date = dayjs().subtract(formatNumber, formatFormat);
      return target.isSameOrBefore(date, "day");
    },
    [FilterOperator.Equal]: () => {
      const value = get(savedValue.values, 0);
      if (!value) return true;
      const date = dayjs(value as any);
      return target.isSame(date, "day");
    },
    [FilterOperator.Between]: () => {
      const value0 = get(savedValue.values, 0);
      const value1 = get(savedValue.values, 1);
      if (!value0 || !value1) return true;
      const startDate = dayjs(value0 as number);
      const endDate = dayjs(value1 as number);
      return (
        target.isSameOrAfter(startDate, "day") &&
        target.isSameOrBefore(endDate, "day")
      );
    },
    [FilterOperator.Greater]: () => {
      const value = get(savedValue.values, 0);
      if (!value) return true;
      const date = dayjs(value as number);
      return target.isAfter(date, "day");
    },
    [FilterOperator.GreaterOrEqual]: () => {
      const value = get(savedValue.values, 0);
      if (!value) return true;
      const date = dayjs(value as number);
      return target.isSameOrAfter(date, "day");
    },
    [FilterOperator.Lesser]: () => {
      const value = get(savedValue.values, 0);
      if (!value) return true;
      const date = dayjs(value as number);
      return target.isBefore(date, "day");
    },
    [FilterOperator.LesserOrEqual]: () => {
      const value = get(savedValue.values, 0);
      if (!value) return true;
      const date = dayjs(value as number);
      return target.isSameOrBefore(date, "day");
    },
    default: true,
  });
};

export const checkFilterValues = (
  item: any,
  savedValue: SavedFilterValue,
  filter: FilterInput
) => {
  const v = savedValue.values?.filter(isDefined);
  if (!v?.length) {
    return true;
  }

  const target = get(item, filter.key);

  // does not use an operator
  if (isCheckboxFilter(filter)) {
    const values = get(savedValue.values, 0) || [];
    return !(values as any[]).length || (values as any[]).includes(target);
  }

  if (isSelectFilter(filter)) {
    return target == get(savedValue.values, 0);
  }

  if (!savedValue.operator) {
    return false;
  }

  if (isDateFilter(filter)) {
    return checkDate(target, savedValue);
  }

  if (savedValue.values) {
    const values = savedValue.values as any[];
    return checkOperator(savedValue.operator, target, values);
  }

  return true;
};

export const checkFilter = (target: any, filter: SavedFilterValue) => {
  const v = filter.values?.filter(isDefined);
  if (!v?.length) {
    return true;
  }

  // does not use an operator
  if (filter.type == "checkbox") {
    const values = get(filter.values, 0) || [];
    return !(values as any[]).length || (values as any[]).includes(target);
  }

  if (filter.type == "select") {
    return target == get(filter.values, 0);
  }

  if (!filter.operator) {
    return false;
  }

  if (filter.type == "date") {
    return checkDate(target, filter);
  }

  if (filter.values) {
    const values = filter.values as any[];
    return checkOperator(filter.operator, target, values);
  }

  return true;
};
