import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/opt/build/repo/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Eorgdr12jA from "/opt/build/repo/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_WLsn00x1qh from "/opt/build/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/opt/build/repo/.nuxt/unocss.mjs";
import plugin_Bqy4oFOPfZ from "/opt/build/repo/layers/admin/modules/metrics/runtime/plugin.ts";
import plugin_w88OEPRlic from "/opt/build/repo/layers/admin/modules/media-library/runtime/plugin.ts";
import plugin_1DNvGEdB1g from "/opt/build/repo/layers/admin/modules/auth/runtime/plugin.ts";
import plugin_n2tcAoKjNI from "/opt/build/repo/layers/ui/modules/VDynamicForm/runtime/plugin.ts";
import loading_tYjkhBWuWf from "/opt/build/repo/layers/ui/plugins/loading.ts";
import vee_validate_Dfac7c9cvC from "/opt/build/repo/layers/ui/plugins/vee-validate.ts";
import app_action_BerOiafmCL from "/opt/build/repo/layers/admin/plugins/app-action.ts";
import shortcuts_IaOlHeuoYC from "/opt/build/repo/layers/admin/plugins/shortcuts.ts";
import vue_toastification_CkiAsMK7A4 from "/opt/build/repo/layers/admin/plugins/vue-toastification.ts";
import apollo_1ILFkzpYSr from "/opt/build/repo/plugins/apollo.ts";
import dobby_tGri2zbp3u from "/opt/build/repo/plugins/dobby.ts";
import global_app_action_handler_xB22zFD9O8 from "/opt/build/repo/plugins/global-app-action-handler.ts";
import notification_h17XTKlCK5 from "/opt/build/repo/plugins/notification.ts";
import vuetify_7h9QAQEssH from "/opt/build/repo/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Eorgdr12jA,
  plugin_WLsn00x1qh,
  unocss_MzCDxu9LMj,
  plugin_Bqy4oFOPfZ,
  plugin_w88OEPRlic,
  plugin_1DNvGEdB1g,
  plugin_n2tcAoKjNI,
  loading_tYjkhBWuWf,
  vee_validate_Dfac7c9cvC,
  app_action_BerOiafmCL,
  shortcuts_IaOlHeuoYC,
  vue_toastification_CkiAsMK7A4,
  apollo_1ILFkzpYSr,
  dobby_tGri2zbp3u,
  global_app_action_handler_xB22zFD9O8,
  notification_h17XTKlCK5,
  vuetify_7h9QAQEssH
]