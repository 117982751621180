import { boolean, float, model, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import Country from "./Country";
import type { ICurrency } from "~/layers/admin/models";

export default class Currency extends Model implements ICurrency {
  static entity = "Currency";
  static primaryKey = "code";
  static queryAttributes = ["code", "symbol"];

  @string({ readonly: true }) declare readonly name?: string;
  @string({ readonly: true }) declare readonly code: string;
  @string({ readonly: true }) declare readonly symbol: string;
  @float({ readonly: true }) declare readonly exchange_rate?: number;
  @string({ readonly: true }) declare readonly format?: string;
  @boolean({ readonly: true }) declare readonly active?: boolean;
  @datetime() declare readonly rate_updated_at: Dayjs;
  @model(() => Country, { list: true }) declare countries: Country[];
}
