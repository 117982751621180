import { Dobby, type FieldDecorator } from "@moirei/dobby";
import { v4 as uuidv4 } from "uuid";
import FieldBuilder from "./FieldBuilder";

/**
 * Create a datetime (moment) attribute.
 *
 * @returns {FieldDecorator}
 */
export function datetime(): FieldDecorator {
  return (model, key) => {
    const builder = model.$self().getSchemaBuilder() as FieldBuilder;
    builder.datetime(key);
  };
}

/**
 * Create a pricing attribute.
 *
 * @returns {FieldDecorator}
 */
export function pricing(): FieldDecorator {
  return (model, key) => {
    const builder = model.$self().getSchemaBuilder() as FieldBuilder;
    builder.pricing(key);
  };
}

/**
 * Create a mixed attribute.
 *
 * @returns {FieldDecorator}
 */
export function mixed({ list }: { list?: boolean } = {}): FieldDecorator {
  return (model, key) => {
    const builder = model.$self().getSchemaBuilder() as FieldBuilder;
    builder.mixed(key, { list });
  };
}

/**
 * Create a model instance key attribute.
 * Prefers model's internal identifier.
 *
 * @param {string} key
 * @returns
 */
export function modelKey() {
  return function (target: any, propertyKey: string) {
    let id: string;
    Object.defineProperty(target, propertyKey, {
      get: function (this: Dobby) {
        const v = this.$getKey();
        if (v) {
          return v;
        }
        if (!this._key) {
          this._key = uuidv4();
        }
        return this._key;
      },
    });
  };
}
