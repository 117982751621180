import { type MediaFile, MediaFileType, type MediaFolder } from "./types";

export const logger = {
  log(...msgs: any[]) {
    console.log("[media-library-ui]", ...msgs);
  },
  warn(...msgs: any[]) {
    console.warn("[media-library-ui]", ...msgs);
  },
  info(...msgs: any[]) {
    console.info("[media-library-ui]", ...msgs);
  },
  error(...msgs: any[]) {
    console.error("[media-library-ui]", ...msgs);
  },
};

/**
 * @throws {Error}
 * @param {string} message
 */
export function error(message: string | Error): never {
  logger.error(message);
  throw isString(message)
    ? new Error("[media-library-ui] " + message)
    : message;
}

/**
 * Makes the first character of a string uppercase
 */
export function upperFirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const isServer = () => typeof window === "undefined";

export function isObject(obj: any): obj is object {
  return obj !== null && typeof obj === "object";
}

export const isFunction = <T, F extends { (): any }>(v: F | T): v is F =>
  typeof v === "function";

export const isBool = (v: any): v is boolean => typeof v === "boolean";

export const isString = (v: any): v is string => typeof v === "string";

export const isFolder = <T extends MediaFile & { type: MediaFileType.FOLDER }>(
  f: MediaFile
): f is T => f.type === MediaFileType.FOLDER;

export const isImage = <T extends MediaFile & { type: MediaFileType.IMAGE }>(
  f: MediaFile
): f is T => f.type === MediaFileType.IMAGE;

export const isAudio = <T extends MediaFile & { type: MediaFileType.AUDIO }>(
  f: MediaFile
): f is T => f.type === MediaFileType.AUDIO;

export const isVideo = <T extends MediaFile & { type: MediaFileType.VIDEO }>(
  f: MediaFile
): f is T => f.type === MediaFileType.VIDEO;

export const getItemIcon = (item: MediaFile) => {
  if (!item) return "insert_drive_file";
  if (isFolder(item)) return "folder";
  if (isImage(item)) return "photo";
  if (isAudio(item)) return "music_note";
  if (isVideo(item)) return "movie";

  return "insert_drive_file";
};

export const makeId = (length = 24) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getFolderLocation = (folder: MediaFolder): string => {
  if (!folder.location) return folder.name;
  return `${folder.location}/${folder.name}`;
};
