import { get, filter } from "lodash";
import type { IProduct, IProductVariant } from "../models";

export interface AvailableVariant {
  name: string;
  optionValues: string[];
  inUse: boolean;
  optionsHash: string;
}

export const variantDisplayName = (
  variant: Partial<IProductVariant>
): string => {
  return (
    variant.name ||
    filter([variant.option1, variant.option2, variant.option3]).join("/")
  );
};

export const variantOptionHash = (variant: IProductVariant): string => {
  const ids = filter([
    variant.option1,
    variant.option2,
    variant.option3,
  ]) as string[];
  return hashStringArray(ids);
};

export const variantOptionsHash = (product: IProduct): string[] => {
  return product.variants.map(variantOptionHash);
};

export const availableVariants = (product: IProduct): AvailableVariant[] => {
  const variants: AvailableVariant[] = [];

  const optionValueHashIds = variantOptionsHash(product);

  // TODO: type- safe/track use of `$willDelete`
  const options = product.options.filter(
    (option) => !get(option, "$willDelete")
  );

  options.forEach((option, i) => {
    for (let j = 0; j < options.length; j++) {
      if (i !== j) {
        for (const a of option.values) {
          const variant: AvailableVariant = {
            name: a,
            optionValues: [a],
            inUse: false,
            optionsHash: "",
          };

          for (const b of options[j].values) {
            variant.name += "/" + b;
            variant.optionValues.push(b);
          }

          variant.optionsHash = hashStringArray(variant.optionValues);
          variant.inUse = optionValueHashIds.includes(variant.optionsHash);

          variants.push(variant);
        }
      }
    }
  });

  return variants;
};
