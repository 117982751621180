import { boolean, id, integer, model, string } from "@moirei/dobby";
import type { ModelType, Query } from "@moirei/dobby";
import { cloneDeep } from "@apollo/client/utilities";
import { omitBy, isNil, sum, filter } from "lodash";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import {
  EventAttendeeParticipationStatus,
  Gender,
  PublishableStatus,
  PublishedStatus,
  EventTicketType,
  OnboardStep,
  PeriodInterval,
  Pricing,
  mixed,
} from "~/layers/admin/models";
import PublishedItem from "./PublishedItem";
import { creatingEvent, updatingEvent } from "./hooks/event-hooks";
import {
  creatingEventSection,
  updatingEventSection,
} from "./hooks/event-section-hooks";
import { Media } from "../../layers/admin/models/dobby/Media";
import Address from "./Address";
import {
  creatingEventVenue,
  updatingEventVenue,
} from "./hooks/event-venue-hooks";
import { EventDiscount } from "./EventDiscount";
import { Order } from "./Order";
import ResourceFaq from "./ResourceFaq";
import { Location } from "~/layers/admin/models";
import type {
  EventOccurrenceLocationType,
  EventOccurrenceType,
  Publishable,
} from "~/layers/admin/models";
import type {
  IEventBooking,
  IEventBookingTicket,
  IEventOccurrence,
  IEventTicket,
  IEventTicketSection,
} from "../interfaces/Event";
import { pricingDefaults } from "~/layers/admin/helpers/defaults";

export class EventType extends Model {
  static override entity = "EventType";
  static override queryAttributes = ["id", "name"];

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @string() declare name: string;
  @string() declare description?: string;

  static override hooks() {
    return {
      findMany<T extends ModelType>(
        this: T,
        query: Query<T>,
        model: T
      ): Query<T> | void {
        const operation = "eventTypes";
        query
          .operation(operation)
          .parseWith((response) => response.data[operation]);
      },
      findUnique<T extends ModelType>(
        this: T,
        args: any,
        query: Query<T>,
        model: T
      ): Query<T> | void {
        const operation = "eventType";
        query
          .operation(operation)
          .where("where", {
            type: "EventTypeWhereUniqueInput",
            required: true,
            value: args,
          })
          .parseWith((response) => response.data[operation]);
      },
    };
  }
}

export class Event extends Model implements Publishable {
  static override entity = "Event";
  static override primaryKey = "handle";
  static override queryAttributes = ["handle", "name"];
  // static queryRelationships = ['schedule', 'availability']
  static override get modelKey() {
    return "Event";
  }

  constructor(attributes = {}) {
    super({
      type: {},
      schedule: {},
      availability: {},
      venue: {},
      // image: {},
      gallery: [],
      tickets: [],
      sections: [],
      bookings: [],
      discounts: [],
      occurrences: [],
      tags: [],
      agenda: [],
      faqs: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @id() declare readonly id: string;
  @string() declare readonly gid: string;
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;

  @string() declare readonly handle: string;
  @string() declare name: string;
  @string() declare occurrence_type: EventOccurrenceType;
  @boolean() declare private: Boolean;
  @boolean() declare hide_start_time: Boolean;
  @boolean() declare hide_end_time: Boolean;
  @boolean() declare display_start_time: Boolean;
  @boolean() declare display_end_time: Boolean;
  @string() declare summary?: string;
  @string() declare description?: string;
  @string() declare currency_code?: string;
  @string() declare store_id?: string;
  @string() declare seo_title?: string;
  @string() declare seo_description?: string;
  @string({ default: [], list: true }) declare seo_keywords?: string[];
  @string() declare web_url?: string;
  @string() declare timezone?: string;
  @boolean() declare capacity?: boolean;
  @string() declare purchase_action?: string;
  @string() declare status: PublishableStatus;
  @string() declare publishedStatus: PublishedStatus;
  @model(() => PeriodInterval) declare schedule?: PeriodInterval;
  @model(() => PeriodInterval) declare availability?: PeriodInterval;
  @model(() => Media) declare image?: Media;
  @model(() => Media, { list: true }) declare gallery?: Media[];
  // @model(() => Media, { list: true }) declare attachments?: Media[];
  @string() declare video_url?: string;
  // @model(() => EventManager) declare manager?: EventManager;
  @model(() => EventType) declare type: EventType;
  @string() declare age_restriction?: string;
  @string() declare refund_policy?: string;
  @string() declare count_down?: string;
  @string({ default: [], list: true }) declare tags: string[];
  @model(() => EventAgenda, { list: true }) declare agenda: EventAgenda[];
  @model(() => ResourceFaq, { list: true }) declare faqs: ResourceFaq[];
  @model(() => EventTicketSection, { list: true })
  declare sections: EventTicketSection[];
  @model(() => EventTicket, { list: true }) declare tickets: EventTicket[];
  @model(() => EventTicketsSold) declare ticketsSold: EventTicketsSold;
  @model(() => EventBooking, { list: true }) declare bookings: EventBooking[];
  @model(() => OnboardStep, { list: true }) declare nextSteps: OnboardStep[];
  @model(() => EventDiscount, { list: true })
  declare discounts: EventDiscount[];
  @model(() => EventOccurrence, { list: true })
  declare occurrences: EventOccurrence[];
  @mixed() declare booking_fields?: any;
  @model(() => PublishedItem, { list: true })
  declare publications?: PublishedItem[];
  // @model(() => Currency) declare currency: Currency;

  // bookingTickets, channels, collections, categories, amenities, healthAndSafetyItems

  get startsAt() {
    const dates = filter(this.occurrences.map((o) => o.starts_at!));
    return findRelevantDate(dates);
  }

  get endsAt() {
    const dates = filter(this.occurrences.map((o) => o.ends_at!));
    return findRelevantDate(dates);
  }

  static calendarDisplayQuery() {
    return this.select(["id", "name", "handle", "summary"])
      .include("venue", ["name"])
      .include("type", ["name"])
      .include("occurrences", (query) => {
        return query
          .select([
            "id",
            "name",
            "starts_at",
            "ends_at",
            // "online",
          ])
          .include("bookingLocation", ["full_title"]);
      });
  }

  public $isActive() {
    if (
      this.status !== PublishableStatus.ACTIVE ||
      !(this.starts_at && this.ends_at)
    ) {
      return false;
    }

    if (this.starts_at && now().isBefore(this.starts_at)) {
      return false;
    }

    if (this.ends_at && now().isAfter(this.ends_at)) {
      return false;
    }

    return true;
  }

  public $isPublished(): boolean {
    return (
      this.$getOriginal("starts_at") &&
      this.$getOriginal("status") == PublishableStatus.ACTIVE
    );
  }

  public $isRelease(): boolean {
    return this.status === PublishableStatus.ACTIVE;
  }

  public $url(): string | undefined {
    // if(this.short_link){
    //   return this.short_link
    // }
    if (this.handle) {
      return `https://orie.market/events/${this.handle}`;
    }
  }

  /**
   * If any relationships has been created but not hydrated.
   *
   * @returns {boolean}
   */
  public $shouldReload(): boolean {
    return this.sections
      .map(
        (section) =>
          section.$willDelete || !section.$exists() || section.$shouldReload()
      )
      .some((v) => v == true);
  }

  static override hooks() {
    return {
      $creating: creatingEvent,
      $updating: updatingEvent,
    };
  }
}

export class EventTicketsSold extends Model {
  static override entity = "EventTicketsSold";
  @integer() declare readonly sold: number;
  @integer() declare readonly total: number;
}

export class EventVenue extends Model {
  static override entity = "EventVenue";
  static override queryAttributes = ["id", "name"];

  constructor(attributes = {}) {
    super({
      address: {},
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @string() declare name: string;
  @string() declare description?: string;
  @string({ readonly: true }) declare readonly address_line: string;
  // @string() declare city?: string
  // @string() declare country_code: string
  // @model(() => Country) declare country: Country
  @model(() => Address) declare address?: Address;

  static override hooks() {
    return {
      $creating: creatingEventVenue,
      $updating: updatingEventVenue,
    };
  }
}

export class EventSeating extends Model {
  static override entity = "EventSeating";
  static override queryAttributes = ["id", "name"];

  @string() declare name: string;
}

export class EventOccurrence extends Model implements IEventOccurrence {
  static override entity = "EventOccurrence";
  constructor(attributes = {}) {
    super({
      tickets: [],
      sections: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  // @id() declare id: string;
  // @model(() => EventTicket, { list: true })
  // declare sections?: EventTicketSection[];
  // @model(() => Event) declare event: Event;

  @id() declare readonly id: string;
  @string() declare readonly gid: string;
  @string() declare readonly objectName: string;
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;

  @string() declare type: EventOccurrenceType;
  @string() declare name: string;
  @model(() => Media) declare image?: Media;
  @string() declare description?: string;
  @datetime() declare starts_at?: Dayjs;
  @datetime() declare ends_at?: Dayjs;
  @boolean() declare started: boolean;
  @integer() declare position: number;
  @boolean() declare reserved_seating: boolean;
  @boolean() declare all_day: boolean;
  @boolean() declare booked_out: boolean;
  @integer() declare capacity: number;
  @mixed() declare event_map?: any;
  @string({ default: [], list: true }) declare available_seats: string[];
  @string({ default: [], list: true }) declare special_seats: string[];
  @string({ default: [], list: true }) declare used_seats: string[];
  @string() declare location_type: EventOccurrenceLocationType;
  @boolean() declare location_display_on_booking_page: boolean;
  @string() declare location_address?: string;
  @string() declare location_link?: string;
  @string() declare location_id?: string;
  @string() declare location_venue_id?: string;
  @string() declare event_id: string;
  @model(() => EventTicketSection, { list: true })
  declare sections: EventTicketSection[];
  @model(() => EventTicket, { list: true }) declare tickets: EventTicket[];
  @model(() => Location) declare location?: Location;
  @model(() => EventVenue) declare venue?: EventVenue;
  @model(() => EventBookingLocation)
  declare bookingLocation: EventBookingLocation;

  public get venueDisplay() {
    return this.bookingLocation?.full_title || "Pending announcement";
  }
}

export class EventTicketSection extends Model implements IEventTicketSection {
  static override entity = "EventTicketSection";
  constructor(attributes = {}) {
    super({
      tickets: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @id() declare id: string;
  @string() declare name: string;
  @integer() declare capacity: number;
  @integer() declare position: number;
  @boolean() declare disabled: boolean;
  @string() declare occurrence_id?: string;
  @model(() => EventTicket, { list: true }) declare tickets?: EventTicket[];
  @model(() => Event) declare event: Event;
  @model(() => EventOccurrence) declare occurrence?: EventOccurrence;

  /**
   * Get available capacity.
   *
   * @param {EventTicket[]} except tickets to exclude
   * @returns {number}
   */
  public $availableCapacity(except?: EventTicket[]): number {
    if (!this.capacity) return 0;

    const exceptIds = except?.map((t) => t.id) || [];

    const available =
      this.capacity -
      sum(
        this.tickets
          ?.filter((ticket) => !exceptIds.includes(ticket.id))
          .map((ticket) => ticket.available_quantity || 0)
      );

    return available > 0 ? available : 0;
  }

  /**
   * If any relationships has been created but not hydrated.
   *
   * @returns {boolean}
   */
  public $shouldReload(): boolean {
    return (
      this.tickets
        ?.map((ticket) => ticket.$willDelete || !ticket.$exists())
        .some((v) => v == true) || false
    );
  }

  static override hooks() {
    return {
      $creating: creatingEventSection,
      $updating: updatingEventSection,
    };
  }
}

export class EventTicket extends Model implements IEventTicket {
  static override entity = "EventTicket";
  static override queryAttributes = ["id", "name"];
  static override queryRelationships = ["pricing"];

  constructor(attributes = {}) {
    super({
      type: EventTicketType.PAID,
      pricing: cloneDeep(pricingDefaults),
      comparison_pricing: cloneDeep(pricingDefaults),
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @id() declare readonly gid: string;
  @string() declare name: string;
  @string() declare description?: string;
  @integer() declare per_order_limit?: number;
  @integer() declare available_quantity?: number;
  @string() declare type: EventTicketType;
  @boolean() declare private: boolean;
  @boolean() declare pickup_at_event: boolean;
  @boolean() declare absorb_referral_fees: boolean;
  @boolean() declare absorb_platform_fees: boolean;
  @boolean() declare enable_comparison_pricing?: boolean;
  @string() declare purchase_note?: string;
  @integer() declare max_seats: number;
  @datetime() declare sale_starts_at?: Dayjs;
  @datetime() declare sale_ends_at?: Dayjs;
  @model(() => Pricing) declare pricing?: Pricing;
  @model(() => Pricing) declare comparison_pricing?: Pricing;
  @model(() => Event) declare event: Event;

  // For WsEventTicketBundle pivot
  @integer() declare quantity?: number;

  public $isPaid(): boolean {
    return this.type === EventTicketType.PAID;
  }

  public $isFree(): boolean {
    return this.type === EventTicketType.FREE;
  }

  public $isDonation(): boolean {
    return this.type === EventTicketType.DONATION;
  }
}

export class EventBooking extends Model implements IEventBooking {
  static override entity = "EventBooking";
  static override queryAttributes = ["id", "name"];

  @datetime() declare readonly booked_at?: Dayjs;
  @datetime() declare readonly attended_at?: Dayjs;
  @datetime() declare readonly cancelled_at?: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @string() declare readonly occurrence_id: string;
  @model(() => EventBookingTicket, { list: true })
  declare tickets?: EventBookingTicket[];
  @model(() => EventOccurrence) declare eventOccurrence: EventOccurrence;
  @model(() => Order) declare order?: Order;
}

export class EventBookingLocation extends Model {
  static override entity = "EventBookingLocation";

  @string() declare readonly title?: string;
  @string() declare readonly full_title?: string;
  @string() declare readonly type: EventOccurrenceLocationType;
  @boolean() declare readonly display_on_booking_page: boolean;
  @string() declare readonly maps_url?: Dayjs;
  @mixed() declare readonly value?: any;
}

export class EventBookingTicket extends Model implements IEventBookingTicket {
  static override entity = "EventBookingTicket";
  static override queryAttributes = ["id", "name", "code"];

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @datetime() declare readonly deleted_at?: Dayjs;
  @id() declare readonly id: string;
  @id() declare readonly gid: string;
  @string() declare name: string;
  @string() declare code: string;
  @string() declare email: string;
  @string({ default: [], list: true }) declare seats: string[];
  @string() declare displaySeats?: string;
  @string() declare section?: string;
  @model(() => EventTicket) declare eventTicket: EventTicket;
  // @model(() => EventAttendee) declare attendee: EventAttendee
  @model(() => Order) declare order?: Order;
}

export class EventAgenda extends Model {
  static override entity = "EventAgenda";

  @string() declare id: string;
  @string() declare name: string;
  @integer() declare position: number;
  @string() declare from: string;
  @string() declare to?: string;
  @string() declare icon?: string;
  @string() declare description?: string;
}

export class EventAttendee extends Model {
  static override entity = "EventAttendee";

  @boolean() declare primary?: boolean;
  @string() declare bookable?: string;
  @string() declare name?: string;
  @string() declare email?: string;
  @string() declare phone?: string;
  @integer() declare age?: number;
  @string() declare notes?: string;
  @string() declare gender?: Gender;
  @string() declare participationStatus?: EventAttendeeParticipationStatus;
  @boolean() declare host?: boolean;
}

export default Event;
