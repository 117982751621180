import { omitBy, isNil, sum } from "lodash";
import {
  type Attributes,
  boolean,
  float,
  id,
  model,
  string,
} from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import Address from "./Address";
import { Order } from "./Order";
import type { TaxExemption } from "~/layers/admin/models";
import { Refund } from "./Refund";

export default class Customer extends Model {
  static override entity = "Customer";
  constructor(attributes = {}) {
    super({
      tags: [],
      address: {},
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @id() declare readonly id: string;
  @id() declare readonly gid: string;
  @string() declare readonly objectName: string;
  @string() declare name?: string;
  @string() declare first_name?: string;
  @string() declare last_name?: string;
  @string() declare email: string;
  @string() declare phone?: string;
  @string() declare description?: string;
  @string() declare currency?: string;
  @datetime() declare readonly email_verified_at?: Dayjs;
  @boolean() declare readonly verified: boolean;
  @string() declare tax_exempt: TaxExemption;
  // @string() declare readonly invoice_prefix?: string;
  @boolean() declare readonly accepts_marketing: boolean;
  @datetime() declare readonly accepts_marketing_updated_at?: Dayjs;
  @string() declare readonly marketing_opt_in_level?: string;
  @boolean({ readonly: true }) declare readonly manual: boolean;
  @string() declare notes?: string;
  @string({ default: [], list: true }) declare tags: string[];
  @model(() => Order) declare lastOrder?: Order;
  @model(() => Order, { list: true }) declare orders: Order[];
  @model(() => Refund, { list: true }) declare refunds: Refund[];
  // @model(() => User) declare user?: User
  // @model(() => Address) declare address?: Address;

  get last_updated() {
    return this.updated_at || this.created_at;
  }

  get totalSpend() {
    return sum(this.orders.map((order) => order.total || 0));
  }

  get totalRefund() {
    return sum(this.refunds.map((refund) => refund.amount || 0));
  }

  public override $keepChanges(): void | false {
    super.$keepChanges();
    if (this.address) {
      this.address.$keepChanges();
    }
  }

  static override hooks() {
    return {
      $updating(model: Customer, data: Attributes) {
        if (model.address && model.address.$isDirty()) {
          if (model.address.$exists()) {
            data.address = {
              update: {
                id: model.address.id,
                ...model.address.$getAttributeChanges(),
              },
            };
          } else {
            data.address = {
              create: model.address.$getAttributeChanges(),
            };
          }
        }
      },
    };
  }
}
