import { defineStore } from "pinia";
import { useDisplay } from "vuetify";
import type { AppShortcutNavItem } from "../private/app-navigation";

type MemorableNav = WithPartial<Omit<AppShortcutNavItem, "pinned">, "route">;

export const useAppNavShortcutsStore = defineWorkspaceConfigStore(
  "shortcuts",
  (ctx) => {
    const items = ctx.ref<AppShortcutNavItem[]>("", []);
    const MAX_HISTORY = 4;

    const rememberNav = (item: MemorableNav) => {
      let x = [...items.value];

      const a: AppShortcutNavItem = {
        route: window.location.pathname + window.location.search,
        ...item,
      };

      // Check if the item is already in the array
      const existingIndex = x.findIndex((item) => item.route === a.route);

      if (existingIndex !== -1) {
        // If it exists and is pinned, do nothing
        if (x[existingIndex].pinned) return;

        // If it exists and is not pinned, remove it from the current position
        x.splice(existingIndex, 1);
      }

      // Find the position after the last pinned item
      const lastPinnedIndex = x.reduce(
        (lastIndex, item, index) => (item.pinned ? index : lastIndex),
        -1
      );

      // Insert the new item after the last pinned item
      x.splice(lastPinnedIndex + 1, 0, a);

      // Remove excess history items, keeping pinned items intact
      if (x.length > MAX_HISTORY) {
        let pinnedCount = x.filter((item) => item.pinned).length;
        x = [
          ...x.filter((item) => item.pinned),
          ...x
            .filter((item) => !item.pinned)
            .slice(0, MAX_HISTORY - pinnedCount),
        ];
      }

      items.value = x;
    };

    const shouldRememberNav = (item: MemorableNav) => {
      onBeforeRouteLeave(() => {
        rememberNav(item);
      });
    };

    const pinNav = (item: Omit<AppShortcutNavItem, "pinned">) => {
      const a: AppShortcutNavItem = { ...item, pinned: true };
      const x = [...items.value];

      const index = x.findIndex((item) => item.route === a.route);
      if (index === -1) return; // If the item doesn't exist, do nothing

      x[index].pinned = true;

      // Move pinned item to the correct position
      const pinnedItems = x.filter((item) => item.pinned);
      const unpinnedItems = x.filter((item) => !item.pinned);

      items.value = [...pinnedItems, ...unpinnedItems];
    };

    const forgetNav = (item: AppShortcutNavItem) => {
      const x = [...items.value];
      items.value = x.filter((a) => a.route !== item.route);
    };

    const shortcuts = computed(() => {
      // TODO: filter out active routes
      // TODO: sort by pinned
      return items.value;
    });

    return {
      shortcuts,
      rememberNav,
      shouldRememberNav,
      pinNav,
      forgetNav,
    };
  }
);

export const useAppNavStore = defineStore("app-nav", () => {
  const { mobile } = useDisplay();
  const open = ref(!mobile.value);

  const toggle = () => (open.value = !open.value);

  return {
    open,
    toggle,
  };
});
