import { boolean, float, id, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import { PackageType, SizeUnit, WeightUnit } from "~/layers/admin/models";

export default class Package extends Model {
  static entity = "Package";
  static primaryKey = "handle";
  static queryAttributes = ["handle", "name", "default"];

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly handle: string;
  @string() declare name: string;
  @string() declare type: PackageType;
  @string() declare description?: string;
  @boolean(false) declare default: boolean;
  @string() declare carrier?: string;
  @float() declare length?: number;
  @float() declare width?: number;
  @float() declare height?: number;
  @float() declare weight?: number;
  @string() declare size_unit?: SizeUnit;
  @string() declare weight_unit?: WeightUnit;

  $isCustom() {
    return this.carrier == "custom";
  }

  $isSendle() {
    return this.carrier == "sendle";
  }
}
