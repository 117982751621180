import type MediaLibrary from "./MediaLibrary";
import type { MediaLibraryStore } from "./store";

export type MediaLibraryConfig = {
  adapter: IMediaAdapter | { (): IMediaAdapter };
};

export enum MediaFileType {
  FOLDER = "folder",
  IMAGE = "image",
  AUDIO = "audio",
  VIDEO = "video",
  OTHER = "file",
}

export type ImageSrc = {
  src: string;
  lazySrc?: string;
  srcset?: string;
  sizes?: string;
};

export interface MediaImage {
  xsmall: string;
  small: string;
  thumb: string;
  medium: string;
  large: string;
}

export interface MediaFile {
  id: string;
  name: string;
  type?: MediaFileType;
  image?: string | ImageSrc | MediaImage;
  src?: string;
  // lazySrc?: string;
  location?: string;
  private?: boolean;
  extension?: string;
  loading?: boolean;
}

export interface MediaFolder extends MediaFile {
  type: MediaFileType.FOLDER;
}

export type UploadedFile = File;

export type Downloadable = {
  url: string;
  filename?: string;
  mimetype?: string;
};

export type MediaShareOptions = {
  name?: string;
  description?: string;
  public?: boolean;
  access_emails?: string[];
  access_type?: "secret" | "token";
  expires_at?: string;
  can_remove?: boolean;
  can_upload?: boolean;
  max_downloads?: number;
  max_upload_size?: number;
  allowed_upload_types?: string[];
  meta?: Record<string, any>;
};

export type MediaDownloadOptions = {
  ttl?: string | number;
};
export interface MediaCreateOptions {
  name?: string;
  location?: string;
  description?: string;
  private?: boolean;
}

export type BrowseFilter = {
  modelFiles?: boolean;
  filesOnly?: boolean;
  type?: string;
  mime?: string;
  private?: boolean;
  paginate?: {
    page?: number;
    perPage: number;
  };
};

export type BrowseData = {
  data: MediaFile[];
  paginate?: {
    total: number;
    pages: number;
    currentPage: number;
    perPage: number;
    prev: number | null;
    next: number | null;
  };
};

export type SearchFilter = (
  item: MediaFile,
  filterText: string | undefined,
  filterTypes: MediaFileType[]
) => boolean;

export type ActionContext = {
  store: MediaLibraryStore;
  mediaLibrary: MediaLibrary;
  target?: MediaFile;
};

export interface IMediaAdapter {
  /**
   * Return files and folders in location.
   *
   * @param {string} location
   * @param {BrowseFilter} filters
   * @returns {Promise<BrowseData>}
   */
  browse(location: string, filters?: BrowseFilter): Promise<BrowseData>;

  /**
   * Get a file.
   *
   * @param {string} id ID or FQFN
   * @returns {Promise<MediaFile|undefined>}
   */
  getFile(id: string): Promise<MediaFile | undefined>;

  /**
   * Get a folder.
   *
   * @param {string} id
   * @returns {Promise<MediaFolder|undefined>}
   */
  getFolder(id: string): Promise<MediaFolder | undefined>;

  /**
   * Upload one file unto location
   *
   * @param {UploadedFile} file
   * @param {MediaCreateOptions} options
   * @returns {Promise<MediaFile>}
   */
  uploadOne(
    file: UploadedFile,
    options?: MediaCreateOptions
  ): Promise<MediaFile>;

  /**
   * Upload many files unto location
   *
   * @param {UploadedFile[]} files
   * @param {MediaCreateOptions} options
   * @returns {Promise<MediaFile[]>}
   */
  uploadMany(
    files: UploadedFile[],
    options?: MediaCreateOptions
  ): Promise<MediaFile[]>;

  /**
   * Create one folder unto location
   *
   * @param {string} name
   * @param {MediaCreateOptions} options
   * @returns {Promise<MediaFolder>}
   */
  createOneFolder(
    name: string,
    options?: MediaCreateOptions
  ): Promise<MediaFolder>;

  /**
   * Create many folders unto location
   *
   * @param {string[]} names
   * @param {MediaCreateOptions} options
   * @returns {Promise<MediaFolder[]>}
   */
  createManyFolders(
    names: string[],
    options?: MediaCreateOptions
  ): Promise<MediaFolder[]>;

  /**
   * Update one file
   *
   * @param {MediaFile} file
   * @param {Partial<MediaFile>} data
   * @returns {Promise<MediaFile>}
   */
  updateOne(file: MediaFile, data: Partial<MediaFile>): Promise<MediaFile>;

  /**
   * Update many files
   *
   * @param {MediaFile[]} files
   * @param {Partial<MediaFile>} data
   * @returns {Promise<MediaFile[]>}
   */
  updateMany(
    files: MediaFile[],
    data: Partial<MediaFile>
  ): Promise<MediaFile[]>;

  /**
   * Move file to a different location
   *
   * @param {MediaFile} file
   * @param {string|MediaFolder} location
   * @returns {Promise<MediaFile>}
   */
  move?(file: MediaFile, location: string | MediaFolder): Promise<MediaFile>;

  /**
   * Get shareable link
   *
   * @param {MediaFile} file
   * @param {MediaShareOptions} options
   * @returns {Promise<string>}
   */
  shareableLink?(file: MediaFile, options?: MediaShareOptions): Promise<string>;

  /**
   * Get downloadable link
   *
   * @param {MediaFile} file
   * @param {MediaDownloadOptions} options
   * @returns {Promise<Downloadable>}
   */
  downloadableLink?(
    file: MediaFile,
    options?: MediaDownloadOptions
  ): Promise<Downloadable>;

  /**
   * Delete many file
   *
   * @param {MediaFile} file
   * @param {boolean} force
   * @returns {Promise<boolean>}
   */
  deleteOne(file: MediaFile, force?: boolean): Promise<boolean>;

  /**
   * Delete many files
   *
   * @param {MediaFile[]} files
   * @param {boolean} force
   * @returns {Promise<MediaFile[]>}
   */
  deleteMany(files: MediaFile[], force?: boolean): Promise<MediaFile[]>;
}

export interface MediaLibraryEvents {
  "file:click": { (file: MediaFile): Promise<void> | void };
  "folder:creating": {
    (state: {
      name: string;
      options?: MediaCreateOptions;
    }): Promise<void> | void;
  };
  "folder:create": { (folder: MediaFolder): Promise<void> | void };
  "file:uploading": { (upload: UploadedFile): Promise<void> | void };
  "file:upload": { (file: MediaFile): Promise<void> | void };
  "file:downloading": { (file: MediaFile): Promise<void> | void };
  "file:download": { (file: MediaFile): Promise<void> | void };
  "file:deleting": { (file: MediaFile): Promise<void> | void };
  "file:delete": { (file: MediaFile): Promise<void> | void };
  info: { (data: { message: string }): Promise<void> | void };
  error: {
    (ctx: {
      message: string;
      method: string;
      error: unknown;
    }): Promise<void> | void;
  };
}
