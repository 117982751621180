import type { Attributes } from "@moirei/dobby";
import ShippingProfile from "../ShippingProfile";
import {
  creatingLocationGroup,
  updatingLocationGroup,
} from "./location-group-hooks";

export const creatingShippingProfileLink = (
  model: ShippingProfile,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (model.products && model.products.length) {
    const productsToAdd: string[] = [];

    model.products
      .filter((product) => !product.$willDelete)
      .forEach((product) => {
        if (product.variants.length) {
          for (const variant of product.variants.filter(
            (v) => !v.$willDelete
          )) {
            productsToAdd.push(variant.gid);
          }
        } else {
          productsToAdd.push(product.gid);
        }
      });

    data.products = productsToAdd;
  }

  if (model.locationGroups && model.locationGroups.length) {
    data.locationGroups = model.locationGroups
      // .filter((item) => item.$isDeepDirty())
      .filter((product) => !product.$willDelete)
      .map((item) => creatingLocationGroup(item));
  }

  return data;
};

export const updatingShippingProfileLink = (
  model: ShippingProfile,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (model.products && model.products.length) {
    const hasChanges = !!model.products.filter((product) =>
      product.$isDeepDirty()
    ).length;

    if (hasChanges) {
      const productsToSet: string[] = [];

      model.products
        .filter((product) => !product.$willDelete)
        .forEach((product) => {
          for (const variant of product.variants.filter(
            (v) => !v.$willDelete
          )) {
            productsToSet.push(variant.gid);
          }
        });

      data.products = { set: productsToSet };
    }
  }

  if (model.locationGroups && model.locationGroups.length) {
    data.locationGroups = {
      create: model.locationGroups
        .filter(
          (locationGroup) =>
            !locationGroup.$willDelete &&
            locationGroup.$isDeepDirty() &&
            !locationGroup.$exists()
        )
        .map((locationGroup) => creatingLocationGroup(locationGroup)),
      update: model.locationGroups
        .filter(
          (locationGroup) =>
            !locationGroup.$willDelete &&
            locationGroup.$isDeepDirty() &&
            locationGroup.$exists()
        )
        .map((locationGroup) => updatingLocationGroup(locationGroup)),
      delete: model.locationGroups
        .filter(
          (locationGroup) =>
            locationGroup.$willDelete && locationGroup.$exists()
        )
        .map((locationGroup) => locationGroup.id),
    };
  }

  return data;
};
