import { isString } from "lodash";
import VPhoneInput from "./VPhoneInput";
import VInputGroup from "./VInputGroup";
import VCountrySelect from "./VCountrySelect/VCountrySelect";
import VRegionSelect from "./VCountrySelect/VRegionSelect";

export default defineComponent({
  name: "AddressInput",
  props: {
    modelValue: {
      type: Object,
    },
    width: { type: [String, Number] },
    maxWidth: { type: [String, Number] },
    elevation: { type: [String, Number] },

    /**
     * Show name inputs. Use string to provide validation rules.
     * @type {string|boolean}
     */
    name: { type: [String, Boolean], default: false },

    /**
     * Show phone input. Use string to provide validation rules.
     * @type {string|boolean}
     */
    phone: { type: [String, Boolean], default: false },
  },
  setup(props) {
    const withRule = (value: string | boolean, rule?: string) => {
      if (isString(value)) {
        return rule ? [value, rule] : value;
      }
      return rule;
    };

    const inputs = computed(() => {
      return defineFormInputs({
        first_name: {
          name: "First name",
          type: "text",
          line: 1,
          rules: withRule(props.name, "max:24"),
          hidden: props.name == false,
          props: {
            placeholder: "First name",
          },
        },
        last_name: {
          name: "Last name",
          type: "text",
          line: 1,
          rules: withRule(props.name, "max:24"),
          hidden: props.name == false,
          props: {
            placeholder: "Last name",
          },
        },
        country_code: {
          name: "Country",
          rules: "required",
          component: VCountrySelect,
          props: {
            placeholder: "Country",
          },
        },
        street: {
          name: "Street address",
          type: "text",
          rules: "required|max:64",
          props: {
            placeholder: "Address line 1",
            autocomplete: "address-line1",
          },
        },
        street_extra: {
          name: "Street address 2",
          type: "text",
          rules: "max:64",
          props: {
            placeholder: "Address line 2",
            autocomplete: "address-line2",
          },
        },
        city: {
          name: "City",
          type: "text",
          rules: "max:32",
          line: 5,
          props: {
            placeholder: "City",
          },
        },
        postal_code: {
          name: "Postcode",
          type: "text",
          line: 5,
          rules: "max:5",
          props: {
            placeholder: "Postcode",
            autocomplete: "postal-code",
          },
        },
        province_code: {
          name: "Province",
          component: VRegionSelect,
          rules: "required",
          props: {
            placeholder: "State",
            country: props.modelValue?.country_code,
          },
        },
        phone: {
          name: "Phone",
          type: "text",
          component: VPhoneInput,
          rules: withRule(props.phone),
          hidden: props.phone == false,
          props: {
            defaultCountry: props.modelValue?.country_code,
          },
        },
      });
    });

    return {
      inputs,
    };
  },
  render() {
    return h(VInputGroup, {
      ...this.$attrs,
      inputs: this.inputs,
      modelValue: this.modelValue,
      width: this.width,
      maxWidth: this.maxWidth,
      elevation: this.elevation,
      defaultProps: {
        density: "compact",
      },
    });
  },
});
