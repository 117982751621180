import Pricing from "@moirei/complex-pricing";
import { FieldBuilder as Builder } from "@moirei/dobby";
import dayjs from "dayjs";

export default class FieldBuilder extends Builder {
  /**
   * Create a datetime (dayjs) attribute.
   *
   * @param {string} name
   * @param {string} format
   * @returns {FieldAttribute}
   */
  public datetime(name: string, format = "YYYY-MM-DD HH:mm:ss") {
    return this.attr(name, {
      type: "DateTime",
      get(v) {
        if (typeof v === "string") {
          return dayjs(v, format);
        }
        return v;
      },
      set(v) {
        return v && typeof v !== "string" ? v.format(format) : v;
      },
    });
  }

  public timestamps() {
    this.datetime("created_at").readonly();
    this.datetime("updated_at").readonly();
  }

  public node() {
    this.id();
    this.timestamps();
  }

  /**
   * Create a pricing attribute.
   *
   * @param {string} name
   * @returns {FieldAttribute}
   */
  public pricing(name: string) {
    return this.attr(name, {
      get(v) {
        if (!v) return null;
        return Pricing.make(v ? JSON.parse(v) : {});
      },
      set(v: Pricing | null) {
        if (!v) return null;
        return JSON.stringify(v.get());
      },
    });
  }

  /**
   * Create a mixed attribute.
   *
   * @param {string} name
   * @returns {FieldAttribute}
   */
  public mixed(name: string, { list }: { list?: boolean }) {
    const shallowClone = (x: any) => Object.assign({}, x);
    const clone = (x: any) =>
      list ? wrap(x).map(shallowClone) : shallowClone(x);

    return this.attr(name, {
      get(v) {
        if (!v) return null;
        return clone(v);
      },
      set(v: object | null) {
        if (!v) return null;
        return clone(v);
      },
    });
  }
}
