import { last } from "lodash";

const excludedRoute = (path: string) => {
  const routes = [
    "/login",
    "/register",
    "/oauth/forgot-password",
    "/oauth/reset-password",
    "/onboarding/new-store",
  ];
  return routes.includes(path);
};

export default defineNuxtRouteMiddleware((to) => {
  const { loggedIn, context } = useAuth();
  const { setWorkspace, getWorkspace } = useWorkspace();

  if (!loggedIn.value || excludedRoute(to.path)) {
    return;
  }

  const workspaces = context.value?.workspaces?.map((ws) => ws.code) || [];
  const current = getWorkspace();

  // change/set to last user's workspace
  // also clears workspace
  if (!current && workspaces.length) {
    setWorkspace(last(workspaces));
  }

  if (!workspaces.length) {
    setTimeout(() => navigateTo({ name: "onboarding-new-store" }), 1);
  }
});
