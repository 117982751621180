import { boolean, float, id, integer, model, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import Product from "./Product";
import { isNil, omitBy } from "lodash";
import {
  Model,
  datetime,
  ShippingRateConditionType,
  ShippingRateTransit,
  ShippingZoneRateType,
  Location,
} from "@/layers/admin/models";
import ProductVariant from "./ProductVariant";
import {
  creatingShippingProfileLink,
  updatingShippingProfileLink,
} from "./hooks/shipping-profile-hooks";
import {
  getTransitDays,
  getTransitTypeFromDays,
} from "~/components/forms/shipping-profile/helpers";

export class ShippingProfile extends Model {
  static entity = "ShippingProfile";

  constructor(attributes = {}) {
    super({
      products: [],
      locationGroups: [],
      variants: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @string() declare name: string;
  @string() declare description?: string;
  @boolean() declare default?: boolean;

  @model(() => Product, { default: [], list: true })
  declare products: Product[];
  @model(() => ProductVariant, { default: [], list: true })
  declare variants: ProductVariant[];
  @model(() => LocationGroup, { default: [], list: true })
  declare locationGroups: LocationGroup[];

  $isGeneral(): boolean {
    return this.$exists() && this.default === true;
  }

  // $keepChanges(): void | false {
  //   super.$keepChanges()
  //   // if (this.zones) {
  //   //   this.zones.forEach((zone) => zone.$keepChanges())
  //   // }
  //   // if (this.address) {
  //   //   this.address.$keepChanges()
  //   // }
  // }

  static hooks() {
    return {
      $creating: creatingShippingProfileLink,
      $updating: updatingShippingProfileLink,
    };
  }
}

export class LocationGroup extends Model {
  static entity = "LocationGroup";

  constructor(attributes = {}) {
    super({
      locations: [],
      zones: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @string() declare id: string; // use string so that it's writable
  @string() declare name: string;

  @model(() => Location, { default: [], list: true })
  declare locations: Location[];
  @model(() => DeliveryZone, { default: [], list: true })
  declare zones: DeliveryZone[];

  get displayName() {
    return (
      this.name ?? this.locations.map((location) => location.name).join(", ")
    );
  }
}

export class DeliveryZone extends Model {
  static entity = "DeliveryZone";

  constructor(attributes = {}) {
    super({
      countries: [],
      rates: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @string() declare id: string; // use string so that it's writable
  @string() declare name: string;
  @boolean() declare rest_of_world: boolean;

  @model(() => ShippingZoneCountry, { default: [], list: true })
  declare countries: ShippingZoneCountry[];
  @model(() => ShippingZoneRate, { default: [], list: true })
  declare rates: ShippingZoneRate[];
}

export class ShippingZoneCountry extends Model {
  static entity = "ShippingZoneCountry";

  constructor(attributes = {}) {
    super({
      provinces: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @id() declare id: string;
  @string() declare name: string;
  @string() declare code: string;
  @boolean() declare include_all_provinces: boolean;

  @model(() => ShippingZoneProvince, { default: [], list: true })
  declare provinces: ShippingZoneProvince[];
}
export class ShippingZoneProvince extends Model {
  static entity = "ShippingZoneProvince";

  @id() declare id: string;
  @string() declare name: string;
  @string() declare code: string;
}

export class ShippingZoneRate extends Model {
  static entity = "ShippingZoneRate";

  constructor(attributes = {}) {
    super({
      handling_fee: {},
      conditions: {},
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @string() declare id: string;
  @string() declare name: string;
  @string() declare kind: ShippingZoneRateType;
  // @string() declare transit: ShippingRateTransit
  @integer() declare min_transit_days?: number;
  @integer() declare max_transit_days?: number;
  @float() declare price?: number;
  @string() declare carrier?: string;
  @string({ list: true }) declare carrier_services?: string[];
  @boolean() declare future_services?: boolean;

  // @model(() => Pricing) declare pricing: Pricing
  @model(() => HandlingFee) declare handling_fee: HandlingFee;
  @model(() => ShippingRateConditions)
  declare conditions: ShippingRateConditions;
  // carrierService: CarrierService

  // mainly used for creation
  get transit(): ShippingRateTransit {
    return getTransitTypeFromDays([
      this.min_transit_days,
      this.max_transit_days,
    ]);
  }
  set transit(value: ShippingRateTransit) {
    const [min_transit_days, max_transit_days] = getTransitDays(value);
    this.min_transit_days = min_transit_days;
    this.max_transit_days = max_transit_days;
  }

  $isManual(): boolean {
    return this.kind === ShippingZoneRateType.MANUAL;
  }

  $isCareer(): boolean {
    return this.kind === ShippingZoneRateType.CARRIER;
  }
}

export class HandlingFee extends Model {
  static entity = "HandlingFee";

  @float() declare percentage?: number;
  @float() declare flat_amount?: number;
}

export class ShippingRateConditions extends Model {
  static entity = "ShippingRateConditions";

  @string() declare type: ShippingRateConditionType;
  @float() declare min: number;
  @float() declare max?: number;
}

export default ShippingProfile;
