import type { Attributes } from "@moirei/dobby";
import { ServiceAvailability, ServiceSchedule } from "../Service";

const serviceAvailabilityCreateInput = (avail: ServiceAvailability) => ({
  name: avail.name,
  start_time: avail.start_time,
  end_time: avail.end_time,
  days: avail.days || null, // we want to make sure to clear value if not provided. `undefined` field is auto removed
  date: avail.date || null, // same as above
});

const serviceAvailabilityUpdateInput = (avail: ServiceAvailability) => ({
  ...serviceAvailabilityCreateInput(avail),
  id: avail.id,
});

export const creatingServiceSchedule = (
  model: ServiceSchedule,
  data: Attributes
) => {
  if (model.availability) {
    data.availability = model.availability
      .filter((avail) => avail.$isDirty())
      .map((avail) => serviceAvailabilityCreateInput(avail));
  }

  return data;
};

export const updatingServiceSchedule = (
  model: ServiceSchedule,
  data: Attributes
) => {
  if (model.availability) {
    // Make sure array is not Collection
    const availability = Array.from(model.availability);
    data.availability = {
      create: availability
        .filter((avail) => !avail.$willDelete && !avail.$exists())
        .map((avail) => serviceAvailabilityCreateInput(avail)),
      update: availability
        .filter(
          (avail) =>
            !avail.$willDelete && avail.$isDeepDirty() && avail.$exists()
        )
        .map((avail) => serviceAvailabilityUpdateInput(avail)),
      delete: availability
        .filter((avail) => avail.$willDelete && avail.$exists())
        .map((avail) => avail.id),
    };
  }

  return data;
};
