import { createHooks } from "hookable";
import type { Entity, InteractionType } from "./types";
import type { MapSaveState } from "./store";

type EventMapHooks = {
  "switch-interaction": { (type: InteractionType): void };
  loaded: { (payload: Required<MapSaveState>): void };
  "map-reload": { (): void };
  "map-cleared": { (): void };
  added: { (entity: Entity): void };
  removed: { (entities: Entity[]): void };
  updated: { (entity: Entity): void };
  selected: { (entities: Entity[]): void };
  deselected: { (): void };
  exit: { (): void };
  // save: [done: { (): void }];
};

export const events = createHooks<EventMapHooks>();
