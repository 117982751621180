import { boolean, id, string } from "@moirei/dobby";
import { Model } from "~/layers/admin/models/dobby";

export default class BankAccount extends Model {
  static entity = "BankAccount";
  @id() declare readonly id: string;
  @string() declare account_holder_name?: string;
  @string() declare account_holder_type?: BankAccountHolderType;
  @string() declare account_type?: string;
  @string() declare bank_name?: string;
  @string() declare country?: string;
  @string() declare currency?: string;
  @string() declare fingerprint?: string;
  @boolean() declare default_for_currency?: boolean;
  @string() declare last4?: string;
  @string() declare routing_number?: string;
  @string() declare status?: string;
}

export enum BankAccountHolderType {
  INDIVIDUAL = "individual",
  COMPANY = "company",
}
