import { isNil, isUndefined, upperFirst } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Location } from "~/layers/admin/models";
import ShippingProfile, {
  HandlingFee,
  LocationGroup,
  ShippingRateConditions,
} from "~/models/dobby/ShippingProfile";
import {
  ShippingRateConditionType,
  ShippingRateTransit,
} from "~/layers/admin/models";

export const createNewLocationGroup = (
  locations: Location[],
  shippingProfile: ShippingProfile
): LocationGroup => {
  const locationGroup = new LocationGroup();
  locationGroup.$fill({
    id: uuidv4(),
    // name: props.location.name,
  });
  // locationGroup.locations.push(location)
  locations.forEach((location) => locationGroup.locations.push(location));
  shippingProfile.locationGroups.push(locationGroup);
  return locationGroup;
};

export const formatTransit = (transit: ShippingRateTransit) => {
  if (!transit) {
    return "—";
  }

  return `${getTransitName(transit)} (${getTransitTime(transit)})`;
};

export const getTransitName = (transit: ShippingRateTransit) => {
  return match(transit, {
    [ShippingRateTransit.STANDARD]: "Standard",
    [ShippingRateTransit.EXPRESS]: "Express",
    [ShippingRateTransit.STANDARD_INTERNATIONAL]: "Standard international",
    [ShippingRateTransit.CUSTOM]: "Custom flat rate",
    default: "—",
  });
};

export const getTransitDays = (
  transit: ShippingRateTransit
): [number | undefined, number | undefined] => {
  return match(transit, {
    [ShippingRateTransit.STANDARD]: [2, 8],
    [ShippingRateTransit.EXPRESS]: [1, 4],
    [ShippingRateTransit.STANDARD_INTERNATIONAL]: [6, 27],
    default: [undefined, undefined],
  });
};

export const getTransitTypeFromDays = (
  days: [number | undefined, number | undefined]
): ShippingRateTransit => {
  return match(days.join("/"), {
    [getTransitDays(ShippingRateTransit.STANDARD).join("/")]:
      ShippingRateTransit.STANDARD,

    [getTransitDays(ShippingRateTransit.EXPRESS).join("/")]:
      ShippingRateTransit.EXPRESS,

    [getTransitDays(ShippingRateTransit.STANDARD_INTERNATIONAL).join("/")]:
      ShippingRateTransit.STANDARD_INTERNATIONAL,

    default: ShippingRateTransit.CUSTOM,
  });
};

export const getTransitTime = (transit: ShippingRateTransit) => {
  const [from, to] = getTransitDays(transit);

  if (isUndefined(from) || isUndefined(to)) {
    return "no transit time";
  } else {
    return `${from} to ${to} business days`;
  }

  // if (!isUndefined(from) && !isUndefined(to)) {
  //   return `${from} to ${to} business days`
  // } else if (!isUndefined(from)) {
  //   return `${from} business days`
  // } else if (!isUndefined(to)) {
  //   return `before ${to} business days`
  // } else {
  //   return 'no transit time'
  // }
};

export const formatCondition = (
  conditions: ShippingRateConditions,
  currencySymbol = "$"
): string => {
  if (isNil(conditions.min)) {
    return "—";
  }

  const dollarUnit =
    conditions.type == ShippingRateConditionType.WEIGHT ? "" : currencySymbol;
  const weightUnit =
    conditions.type == ShippingRateConditionType.WEIGHT ? "kg" : "";

  const formatUnit = (value: number) => `${dollarUnit}${value}${weightUnit}`;

  if (isNil(conditions.max)) {
    return formatUnit(conditions.min) + " and up";
  }

  return formatUnit(conditions.min) + "—" + formatUnit(conditions.max);
};

export const formatHandlingFee = (
  handlingFee: HandlingFee,
  currencySymbol = "$"
): string => {
  if (handlingFee.percentage && handlingFee.flat_amount) {
    return `${handlingFee.percentage}% + ${currencySymbol}${handlingFee.flat_amount}`;
  }
  if (handlingFee.percentage) {
    return handlingFee.percentage + "%";
  }
  if (handlingFee.flat_amount) {
    return currencySymbol + handlingFee.flat_amount;
  }

  return "—";
};

export const formatCarrierName = (handle: string): string => {
  if (!handle) {
    return "—";
  }

  return upperFirst(handle) + " (Discounted rates from Orie)";
};
