import type { Attributes } from "@moirei/dobby";
import { omit } from "lodash";
import { EventOccurrence } from "../Event";

export const creatingEventOccurrence = (
  model: EventOccurrence,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  const date = model.$getSaveContextAttribute("date");
  if (date) {
    data.starts_at = formatToDatetime(parseDateLike(date.data[0]));
    data.ends_at = formatToDatetime(parseDateLike(date.data[1]));
  }

  return omit(data, ["id"]);
};

export const updatingEventOccurrence = (
  model: EventOccurrence,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  const date = model.$getSaveContextAttribute("date");
  if (date?.isDirty) {
    data.starts_at = formatToDatetime(parseDateLike(date.data[0]));
    data.ends_at = formatToDatetime(parseDateLike(date.data[1]));
  }

  data[model.$getKeyName()] = model.$getKey();

  return data;
};
