export const addTokenPrefix = (token: string, tokenType?: string) => {
  if (
    !token ||
    !tokenType ||
    typeof token !== "string" ||
    token.startsWith(tokenType)
  ) {
    return token;
  }
  return tokenType + " " + token;
};

export const removeTokenPrefix = (token: string, tokenType?: string) => {
  if (!token || !tokenType || typeof token !== "string") {
    return token;
  }
  return token.replace(tokenType + " ", "");
};

export const isUnset = (o: any) => typeof o === "undefined" || o === null;
export const isSet = (o: any) => !isUnset(o);

export const encodeValue = (val: any) => {
  if (typeof val === "string") {
    return val;
  }
  return JSON.stringify(val);
};

export const decodeValue = (val: any) => {
  if (typeof val === "string") {
    try {
      return JSON.parse(val);
    } catch (_) {}
  }
  return val;
};
