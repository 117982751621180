import { debounce, isEmpty } from "lodash";
import { provideMetricsContext, useMetrics } from "../data";
import type { GenericMetricsDataSource, MetricsControls } from "../types";
import { normaliseGenericDataSource, normaliseMetricInterval } from "../utils";

export const useMetricsContext = (
  sources: MaybeRef<GenericMetricsDataSource>,
  controls?: MaybeRef<MetricsControls | undefined>
) => {
  const context = useMetrics(() => {
    const s = unref(sources);
    const c = unref(controls);

    return {
      interval: c?.interval,
      precision: withValue(c?.precision, (v) => Number(v)),
      growthRate: c?.growthRate,
      comparisons: c?.comparisons,
      since: c?.since,
      until: c?.until,
      metrics: normaliseGenericDataSource(s),
    };
  });

  return context;
};

export const provideMetrics = (
  sources: MaybeRef<GenericMetricsDataSource>,
  controls?: MaybeRef<MetricsControls | undefined>
) => {
  const context = useMetricsContext(sources, controls);
  provideMetricsContext(context);
  watchEffect(debounce(context.fetch));
  return context;
};

// TODO: remove if not used
export const useMetricsControls = (defaults?: Partial<MetricsControls>) => {
  const interval = ref(defaults?.interval);
  const precision = ref(defaults?.precision);
  const growthRate = ref(!!defaults?.growthRate);
  const comparisons = ref(defaults?.comparisons || []);
  const since = ref(defaults?.since);
  const until = ref(defaults?.until);

  const out = computed(
    (): MetricsControls => ({
      precision: precision.value,
      growthRate: growthRate.value,
      since: since.value,
      until: until.value,
      comparisons: !isEmpty(comparisons.value) ? comparisons.value : undefined,
      interval: withValue(interval.value, normaliseMetricInterval),
    })
  );

  return {
    interval,
    precision,
    growthRate,
    comparisons,
    since,
    until,
    out,
  };
};
