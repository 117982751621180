import { boolean, id, integer, model, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import {
  Model,
  datetime,
  Location,
  InventoryPolicy,
  type IInventory,
  type IInventoryLevel,
} from "~/layers/admin/models";
import ProductVariant from "./ProductVariant";

export default class Inventory extends Model implements IInventory {
  static entity = "Inventory";
  static primaryKey = "id";
  static queryAttributes = ["id", "sku"];

  constructor(attributes = {}) {
    super({
      inventoryLevels: [],
      ...attributes,
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @string() declare readonly sku: string;
  @string() declare barcode?: string;
  @string() declare hs_code: string;
  @string() declare country_code_of_origin?: string;
  @string() declare province_code_of_origin?: string;
  @boolean() declare requires_shipping?: boolean;
  @boolean() declare tracked?: boolean;
  @string() declare policy?: InventoryPolicy;
  @model(() => InventoryLevel, { list: true })
  declare inventoryLevels?: InventoryLevel[];
  @model(() => ProductVariant) declare variant: ProductVariant;

  $keepChanges(): void | false {
    super.$keepChanges();
    if (this.inventoryLevels && this.inventoryLevels.length) {
      this.inventoryLevels.forEach((inventoryLevel) =>
        inventoryLevel.$keepChanges()
      );
    }
  }

  get variantName() {
    return this.variant?.name;
  }
}

export class InventoryLevel extends Model implements IInventoryLevel {
  static entity = "InventoryLevel";
  static primaryKey = "id";

  @id() declare readonly id: string;
  @integer({ default: 0 }) declare available: number;
  @integer() declare readonly incoming?: number;
  @model(() => Location) declare location: Location;

  /**
   * Set the available value
   */
  public async $setAvailable(amount: number): Promise<void> {
    const { data } = await InventoryLevel.select("available")
      .where({
        id: {
          type: "ID",
          required: true,
          value: this.id,
        },
        set: {
          type: "Int",
          value: amount,
        },
      })
      .mutate("wsUpdateInventoryLevel");
    this.available = data.wsUpdateInventoryLevel.available;
  }

  /**
   * Add to the available value
   */
  public async $addAvailable(amount: number): Promise<void> {
    if (amount == 0) return;
    const { data } = await InventoryLevel.select("available")
      .where({
        id: {
          type: "ID",
          required: true,
          value: this.id,
        },
        add: {
          type: "Int",
          value: amount,
        },
      })
      .mutate("wsUpdateInventoryLevel");
    this.available = data.wsUpdateInventoryLevel.available;
  }
}
