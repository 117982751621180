import { Dobby, float, model, string } from "@moirei/dobby";
import { Pricing as ComplexPricing } from "@moirei/complex-pricing";
import type {
  PricingData as IPricing,
  PriceTier,
} from "@moirei/complex-pricing/build/types";
import { isNil } from "lodash";
import Model from "./Model";
import { PricingMeasurementUnit, PricingModel } from "../enums";

export class Pricing extends Model implements IPricing {
  static entity = "Pricing";
  static queryAttributes = ["*"];

  constructor(attributes: IPricing) {
    super({
      model: PricingModel.STANDARD,
      tiers: [],
      unit_amount: null,
      units: null,
      data: {
        measurementType: PricingMeasurementUnit.QUANTITY,
      },
      ...attributes,
    });
  }

  @string()
  declare model: PricingModel;

  @float()
  declare unit_amount?: number;

  @float()
  declare units?: number;

  // @ts-ignore
  @model(() => PricingTier, { default: [], list: true })
  declare tiers: PricingTier[];

  // @ts-ignore
  @model(() => PricingData)
  declare data?: PricingData;

  get pricing() {
    return new ComplexPricing(this.$toJson());
  }

  $isTieredPricing(): boolean {
    return isTieredPricing(this);
  }

  /**
   * @inheritdoc
   */
  $toJson(): IPricing {
    return {
      model: this.model,
      tiers: (this.tiers || []).map((tier) => ({
        max: Number(tier.max),
        unit_amount: tier.unit_amount,
        flat_amount: tier.flat_amount,
      })),
      unit_amount: this.unit_amount,
      units: this.units,
    };
  }

  /**
   * @inheritdoc
   */
  public $keepChanges(): void | false {
    super.$keepChanges();
    if (this.tiers) {
      this.tiers.forEach((tier) => tier.$keepChanges());
    }
    if (this.data) {
      this.data.$keepChanges();
    }
  }

  /**
   * Get the pricing value
   *
   * @param {number} amount
   * @return {number}
   */
  public $price(amount: number) {
    return this.pricing.price(amount);
  }

  /**
   * Check if the Pricing is valid
   * @returns {boolean}
   */
  public $isValid(): boolean {
    return isValidPricing(this);
  }
}

export class PricingData extends Dobby {
  static entity = "PricingData";
  static queryAttributes = ["*"];

  @string()
  declare measurementType: string;
}

export class PricingTier extends Dobby implements PriceTier {
  static entity = "PricingTier";
  static queryAttributes = ["*"];

  @float()
  declare max: number | "inf" | "infinity";

  @float()
  declare unit_amount?: number;

  @float()
  declare flat_amount?: number;
}
