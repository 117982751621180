import { defineNuxtPlugin } from "#app";
import { createApp } from "vue";
import Loading from "../private/Loading.vue";
import { useLoadingStore } from "../private/loading-store";
import { isFunction } from "lodash";

interface Loader {
  (): void;
  (hotText: string): void;
  (loading: boolean, hotText?: string): void;
}

export default defineNuxtPlugin(() => {
  const store = useLoadingStore();
  const vm = createApp(Loading);

  const el = document.createElement("div");
  // this.$refs.container.appendChild(el);
  document.body.appendChild(el);
  vm.mount(el);

  const loading: Loader = (input?: string | boolean, input2?: string) => {
    const isString = typeof input === "string";
    store.loading = isString ? true : !!input;
    store.hotText = isString ? input : input2;
  };

  const asyncLoading = async (fn: { (): any }) => {
    return new Promise((resolve, reject) => {
      store.loading = true;
      const finished = (cb: Function) => {
        return (result: any) => {
          cb(result);
          store.loading = false;
        };
      };
      Promise.resolve(typeof fn === "function" ? fn() : fn)
        .then(finished(resolve))
        .catch(finished(reject));
    });
  };

  const wrapLoading = <A extends any[], R>(
    fn: { (...a: A): Promise<R> },
    loadingText?: string | { (...a: A): string }
  ) => {
    return async (...a: A): Promise<R> => {
      const text = isFunction(loadingText) ? loadingText(...a) : loadingText;
      return new Promise((resolve, reject) => {
        loading(true, text);
        fn(...a)
          .then(resolve)
          .catch(reject)
          .finally(() => {
            loading(false);
          });
      });
    };
  };

  return {
    provide: {
      loading,
      asyncLoading,
      wrapLoading,
    },
  };
});
