import Toast, { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(Toast, {
    //
  });

  let toast: ReturnType<typeof useToast>;

  return {
    provide: {
      get toast() {
        if (!toast) {
          toast = useToast();
        }
        return toast;
      },
    },
  };
});
