import { id, model, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import { PublicationStatus } from "~/layers/admin/models";
import SalesChannel from "./SalesChannel";

export default class PublishedItem extends Model {
  static entity = "PublishedItem";
  static queryRelationships = ["salesChannel"];

  @id() declare readonly id: string;
  @id() declare readonly handle: string;
  @string() declare readonly name: string;
  @string() declare readonly status: PublicationStatus;
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly published_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @model(() => SalesChannel) declare readonly salesChannel: SalesChannel;
}
