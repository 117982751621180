import { defineNuxtPlugin } from "#app";
import MetricsChart from "./components/MetricsChart";
import MetricTrend from "./components/MetricTrend";
import MetricTrendValue from "./components/MetricTrendValue";
import MetricValue from "./components/MetricValue";
import ProvideMetrics from "./components/ProvideMetrics";
import GroupMetrics from "./components/GroupMetrics.vue";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("MetricsChart", MetricsChart);
  nuxtApp.vueApp.component("MetricTrend", MetricTrend);
  nuxtApp.vueApp.component("MetricTrendValue", MetricTrendValue);
  nuxtApp.vueApp.component("MetricValue", MetricValue);
  nuxtApp.vueApp.component("ProvideMetrics", ProvideMetrics);
  nuxtApp.vueApp.component("GroupMetrics", GroupMetrics);
});
