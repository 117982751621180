import { get } from "lodash";
import { getItemComponent, getItemComponentProps } from "../utils";
import { props } from "./props";
import type { RouteLocationRaw } from "vue-router";

export default defineComponent({
  props: {
    ...props,
    itemRoute: { type: Object as PropType<RouteLocationRaw> },
  },
  setup(props) {
    return () => {
      if (!props.component) {
        const value = get(props.item, props.itemKey!, "—");
        if (props.title) {
          return `${props.title}: ${value}`;
        }
        return value;
      }

      const c = getItemComponent(props.component);
      const p = getItemComponentProps(
        props.itemKey!,
        props.item,
        props.component
      );

      return h(c, {
        ...p,
        title: props.title,
        showMobileTable: props.showMobileTable,
        itemRoute: props.itemRoute,
      });
    };
  },
});
