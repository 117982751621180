import type { Attributes } from "@moirei/dobby";
import Service, { ServiceLocation, ServiceVariant } from "../Service";
import { omit } from "lodash";
import {
  createsWithProductMedia,
  updatesProductMedia,
  updatesProductPricing,
} from "./common";

export const creatingService = (model: Service, data: Attributes) => {
  // unset ID. Tempt used by UI to track instances
  model.$setChangesAttribute("id", undefined);

  createsWithProductMedia(model, data);
  updatesProductPricing(model, data);

  const members = model.$getSaveContextAttribute("members");
  if (members?.isDirty) {
    data.members = members.data;
  }

  if (model.locations) {
    data.locations = model.locations
      .filter((location) => !location.$willDelete)
      .map((location) => creatingServiceLocation(location));
  }

  if (data.group_id) {
    data.group = data.group_id;
  }

  return omit(data, ["schedule_id", "group_id"]);
};

export const updatingService = (model: Service, data: Attributes) => {
  updatesProductMedia(model, data);
  updatesProductPricing(model, data);

  const members = model.$getSaveContextAttribute("members");
  if (members?.isDirty) {
    data.members = members.data;
  }

  if (model.locations) {
    data.locations = {
      create: model.locations
        .filter((location) => !location.$willDelete && !location.$exists())
        .map((location) => creatingServiceLocation(location)),
      update: model.locations
        .filter(
          (location) =>
            !location.$willDelete &&
            location.$isDeepDirty() &&
            location.$exists()
        )
        .map((location) => updatingServiceLocation(location)),
      delete: model.locations
        .filter((location) => location.$willDelete && location.$exists())
        .map((location) => location.id),
    };
  }
  if (model.variants) {
    data.variants = {
      create: model.variants
        .filter((variant) => !variant.$willDelete && !variant.$exists())
        .map((variant) => creatingServiceVariant(variant)),
      update: model.variants
        .filter(
          (variant) =>
            !variant.$willDelete && variant.$isDeepDirty() && variant.$exists()
        )
        .map((variant) => updatingServiceVariant(variant)),
      delete: model.variants
        .filter((variant) => variant.$willDelete && variant.$exists())
        .map((variant) => variant.id),
    };
  }
  if (model.booking_limits && model.booking_limits.$isDirty()) {
    data.booking_limits = model.booking_limits.$toJson();
  }
  if (model.duration_limits && model.duration_limits.$isDirty()) {
    data.duration_limits = model.duration_limits.$toJson();
  }
  if (data.schedule_id) {
    data.schedule = data.schedule_id;
  }
  if (data.group_id) {
    data.group = data.group_id;
  }
  if (
    model.recurring &&
    model.recurring.$isDirty() &&
    model.recurring.$isValid()
  ) {
    data.recurring = model.recurring.$toJson();
  }

  if (model.faqs) {
    data.faqs = model.faqs
      .filter((item) => !item.$willDelete)
      .map((item) => item.$toJson());
  }

  return omit(data, ["schedule_id", "group_id"]);
};

export const creatingServiceLocation = (
  model: ServiceLocation,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributes();
  }
  data.location = model.location_id;
  return omit(data, ["location_id"]);
};

export const updatingServiceLocation = (
  model: ServiceLocation,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }
  data[model.$getKeyName()] = model.$getKey();
  if (model.location_id) {
    data.location = model.location_id;
  }
  return omit(data, ["location_id"]);
};

export const creatingServiceVariant = (
  model: ServiceVariant,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributes();
  }

  updatesProductPricing(model, data);

  return data;
};

export const updatingServiceVariant = (
  model: ServiceVariant,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  data[model.$getKeyName()] = model.$getKey();

  updatesProductPricing(model, data);

  return data;
};
