import { defineNuxtPlugin } from "#app";
import { useApolloClient } from "@vue/apollo-composable";
import Auth from "./auth";
import AuthStorage from "./storage";
import { useAuthStore } from "./store";
import type { ApolloClient } from "@apollo/client/core";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig().public.auth;

  const store = useAuthStore();
  const storage = new AuthStorage(store, {
    localStorage: { prefix: "auth." },
    cookie: {
      prefix: "auth.",
      options: { ...config.cookie },
    },
  });

  const provider = once((): ApolloClient<any> => {
    const apollo = useApolloClient();
    return apollo.resolveClient();
  });

  const auth = new Auth(provider, storage, {
    autoLogout: config.autoLogout,
    autoFetchAuthContext: config.autoFetchAuthContext,
    rememberLogoutLocation: config.rememberLogoutLocation,
    paths: config.paths,
    token: config.token,
    refreshToken: config.refreshToken,
  });

  nuxtApp.hook("app:mounted", function () {
    auth.mounted();
  });

  return {
    provide: {
      auth,
    },
  };
});
