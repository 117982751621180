import { createMutations, useEventSeatingApi } from "./lib";
import { InteractionType } from "./types";
import type {
  Entity,
  EntityLike,
  SectionEntity,
  ShapeEntity,
  TableEntity,
  TextEntity,
} from "./types";
import { cloneDeep, isEmpty, last } from "lodash";
import {
  isAssignedSeatingSection,
  isRoundTable,
  isSection,
  isShape,
  isTable,
  isText,
} from "./entity";
import type { Coordinate } from "ol/coordinate";

type CopiedEntity<T extends EntityLike> = {
  entities: T[];
  cutting: boolean;
};

export interface EventSeatingState {
  title?: string;
  sections: { id: string; name: string }[];
  originalEntities: Record<string, Entity>;
  entities: Record<string, Entity>;
  viewport: {
    zoom: number;
    center: Coordinate;
  };
  controls: {
    interaction: InteractionType;
    selection: string[];
    lockTool: boolean;
    addToMapTempEntity: Partial<Entity>;
    copy: CopiedEntity<Entity>;
  };
}

export interface MapSaveState {
  entities?: Entity[];
  viewport?: EventSeatingState["viewport"];
}

const getSeatingCapacity = (entities: Record<string, Entity> | Entity[]) => {
  let capacity = 0;

  if (!Array.isArray(entities)) {
    entities = Object.values(entities);
  }

  for (const entity of entities) {
    if (isSection(entity)) {
      if (isAssignedSeatingSection(entity)) {
        capacity += entity.seats * entity.rows;
      } else {
        capacity += entity.capacity;
      }
    } else if (isTable(entity)) {
      if (isRoundTable(entity)) {
        capacity += entity.seats;
      } else {
        capacity += entity.seats + entity.endSeats;
      }
    }
  }

  return capacity;
};

const selectionsGetter = (state: EventSeatingState) => {
  return state.controls.selection
    .map((id) => state.entities[id])
    .filter((e) => !!e);
};

export const useSeatingStore = defineStore("map-seating", () => {
  const state = reactive<EventSeatingState>({
    sections: [],
    originalEntities: {},
    entities: {},
    viewport: {
      zoom: 1,
      center: [0, 0],
    },
    controls: {
      interaction: InteractionType.SELECT,
      selection: [],
      lockTool: false,
      addToMapTempEntity: {},
      copy: {
        entities: [],
        cutting: false,
      },
    },
  });

  const { fetching, saving, fetch, save: _save } = useEventSeatingApi();

  const mutations = createMutations(state);

  const loading = computed(() => fetching.value || saving.value);

  const isDirty = computed<boolean>(() => {
    const entities = cloneDeep(state.entities);
    const originalEntities = cloneDeep(state.originalEntities);

    return (
      (isEmpty(entities) && !isEmpty(originalEntities)) ||
      isChanged(originalEntities, entities)
    );
  });
  const viewport = computed(() => state.viewport);
  const capacity = computed<number>(() => getSeatingCapacity(state.entities));
  const selection = computed(() => last(selectionsGetter(state)));
  const selections = computed<Entity[]>(() => selectionsGetter(state));
  const copied = computed<CopiedEntity<Entity>>(() => state.controls.copy);
  const entities = computed<Entity[]>(() => Object.values(state.entities));
  const sectionEntities = computed<SectionEntity[]>(() =>
    entities.value.filter(isSection)
  );
  const tableEntities = computed<TableEntity[]>(() => {
    return entities.value.filter(isTable);
  });
  const shapeEntities = computed<ShapeEntity[]>(() => {
    return entities.value.filter(isShape);
  });
  const textEntities = computed<TextEntity[]>(() => {
    return entities.value.filter(isText);
  });
  const interaction = computed({
    set(value: InteractionType) {
      mutations.setInteraction(value);
    },
    get(): InteractionType {
      return state.controls.interaction;
    },
  });
  const addToMapTempEntity = computed<Partial<Entity>>(
    () => state.controls.addToMapTempEntity
  );
  const lockTool = computed({
    set(value: boolean) {
      mutations.updateControls({ lockTool: value });
    },
    get(): boolean {
      return state.controls.lockTool;
    },
  });

  const eventName = computed(() => {
    return state.title;
  });

  const eventSections = computed(() => {
    return state.sections;
  });

  const load = async (event: string, occurrence: string) => {
    state.title = event;
    const data = await fetch(event, occurrence);
    if (data) {
      state.title = data.name || data.event;
      state.sections = data.sections;
      mutations.load(data.map);
    }
  };

  const removeEntity = (entity?: EntityLike) => {
    const entities = entity ? [entity] : selections.value;
    mutations.removeEntity(entities);
  };

  const copyEntity = (entities?: EntityLike[], cutting = false) => {
    if (!entities) {
      entities = selections.value;
    }

    if (!entities.length) {
      return;
    }

    mutations.copyEntity({ entities, cutting });

    return entities;
  };

  const getSeatingMap = (): Required<MapSaveState> => ({
    viewport: cloneDeep(viewport.value),
    entities: cloneDeep(entities.value),
  });

  const toggleLockTool = () => {
    lockTool.value = !lockTool.value;
  };

  const save = async () => {
    const data = mutations.getSaveDate();
    const ok = await _save(data);
    if (ok) {
      mutations.keepChanges();
    }
  };

  return {
    fetching,
    saving,
    loading,
    isDirty,
    capacity,
    selections,
    selection,
    copied,
    entities,
    sectionEntities,
    tableEntities,
    shapeEntities,
    textEntities,
    interaction,
    viewport,
    addToMapTempEntity,
    lockTool,
    eventName,
    eventSections,
    load,
    removeEntity,
    getSeatingMap,
    copyEntity,
    toggleLockTool,
    save,
    addEntity: mutations.addEntity,
    updateEntity: mutations.updateEntity,
    selectEntity: mutations.selectEntity,
    deselectEntity: mutations.deselectEntity,
    updateViewport: mutations.updateViewport,
    clearMap: mutations.clearMap,
    updateControls: mutations.updateControls,
    // keepChanges: mutations.keepChanges,
    discardChanges: mutations.discardChanges,
  };
});
