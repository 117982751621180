declare module "vue-router" {
  interface RouteMeta {
    usageAccess?: string;
  }
}

export default defineNuxtRouteMiddleware((to) => {
  const usageAccess = to.meta.usageAccess;

  if (usageAccess) {
    const { usage, refresh } = useServiceUsage();

    const v = usage.value[usageAccess];
    if (v) {
      const use = 1;
      const { limit, usage: count } = v;
      if ((typeof limit === "number" && count + use > limit) || !limit) {
        navigateTo({
          name: "access-usage-service",
          params: { service: usageAccess },
        });
      } else {
        // we assume the current page changes
        // usage state, auto refresh when we're leaving page
        onBeforeRouteLeave(() => {
          refresh();
        });
      }
    }
  }
  //
});
