import { id, integer, string } from "@moirei/dobby";
import { Model } from "~/layers/admin/models/dobby";

/**
 * FAQ object for resource models.
 * Created at the time for Event but can be used
 * by Service and Product also.
 */
export default class ResourceFaq extends Model {
  static entity = "ResourceFaq";

  @string() declare id: string;
  @string() declare question: string;
  @string() declare answer: string;
  @integer() declare position: number;
}
