import {
  type Attributes,
  boolean,
  id,
  integer,
  model,
  string,
} from "@moirei/dobby";
import { omitBy, isNil } from "lodash";
import type { Dayjs } from "dayjs";
import Model from "./Model";
import { datetime, mixed } from "./model-decorators";
import { cloneDeep } from "@apollo/client/utilities";
import Address from "../../../../models/dobby/Address";
import Country from "../../../../models/dobby/Country";
import { Pricing } from "./Pricing";
import type { OpeningHours, ILocation, ILocationZone } from "../interfaces";
import { ExpectedPickupTime, LocalDeliveryType } from "../enums";
import { pricingDefaults } from "../../helpers/defaults";

export class Location extends Model implements ILocation {
  static entity = "Location";
  static primaryKey = "handle";
  static queryAttributes = [
    "handle",
    "name",
    "disabled",
    "default",
    "country_code",
  ];

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly handle: string;
  @id() declare readonly id: string;
  @string() declare name: string;
  @string() declare description?: string;
  @boolean() declare public?: boolean;
  @boolean() declare active?: boolean;
  @boolean() declare default?: boolean;
  @boolean() declare local_pickup?: boolean;
  @boolean() declare local_delivery?: boolean;
  @boolean({ readonly: true }) declare verified?: boolean;
  @boolean() declare fulfills_online_orders?: boolean;
  @integer() declare fulfillment_priority: number;
  @string() declare local_delivery_type?: LocalDeliveryType;
  @string() declare expected_pickup_time?: ExpectedPickupTime;
  @string() declare pickup_instructions?: string;
  @string() declare country_code?: string;
  @string() declare currency_code?: string;
  @string() declare address_line?: string;
  @integer() declare local_delivery_radius: number;
  @model(() => Address) declare address?: Address;
  @model(() => Country) declare country: Country;
  // @model(LocationGroup, { list: true }) declare groups?: LocationGroup[]
  @model(() => LocationZone, { list: true, default: [] })
  declare zones?: LocationZone[];
  @mixed() declare opening_hours?: OpeningHours;

  static async setDefault(handle: string) {
    return this.newQuery()
      .select("handle")
      .where("where", {
        type: "LocationWhereUniqueInput",
        required: true,
        value: { handle },
      })
      .mutate("wsSetDefaultLocation");
  }

  static async setFulfillmentPriority(handles: string[]) {
    return this.newQuery()
      .select("handle")
      .where("where", {
        type: "[LocationWhereUniqueInput!]",
        required: true,
        value: handles.map((handle) => ({ handle })),
      })
      .mutate("wsSetLocationFulfillmentPriority");
  }

  async $makeDefault() {
    await Location.setDefault(this.handle);
    this.default = true;
  }

  $keepChanges(): void | false {
    super.$keepChanges();
    if (this.zones) {
      this.zones.forEach((zone) => zone.$keepChanges());
    }
    if (this.address) {
      this.address.$keepChanges();
    }
  }

  $isVerified() {
    return this.verified === true;
  }

  static hooks() {
    return {
      $creating(model: Location, data: Attributes) {
        if (model.address && model.address.$isDirty()) {
          data.address = {
            create: model.address.$getAttributeChanges(),
          };
        }
        if (model.zones && model.zones.length) {
          data.zones = {
            create: model.zones
              .filter((zone) => !zone.$willDelete && zone.$isDirty())
              .map((zone) => ({
                ...zone.$getAttributeChanges(),
                name: zone.name,
              })),
          };
        }
      },

      $updating(model: Location, data: Attributes) {
        if (model.address && model.address.$isDirty()) {
          if (model.address.$exists()) {
            data.address = {
              update: {
                id: model.address.id,
                ...model.address.$getAttributeChanges(),
              },
            };
          } else {
            data.address = {
              create: model.address.$getAttributeChanges(),
            };
          }
        }

        if (model.zones && model.zones.length) {
          data.zones = {
            create: model.zones
              .filter(
                (zone) =>
                  !zone.$willDelete && zone.$isDeepDirty() && !zone.$exists()
              )
              .map((zone) => zone.$getDataForCreate()),
            update: model.zones
              .filter(
                (zone) =>
                  !zone.$willDelete && zone.$isDeepDirty() && zone.$exists()
              )
              .map((zone) => zone.$getDataForUpdate()),
            delete: model.zones
              .filter((zone) => zone.$willDelete && zone.$exists())
              .map((zone) => zone.id),
          };
        }
      },
    };
  }
}

export class LocationZone extends Model implements ILocationZone {
  static entity = "LocationZone";
  static queryRelationships = ["pricing"];

  constructor(attributes = {}) {
    super({
      pricing: cloneDeep(pricingDefaults),
      postcodes: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @id() declare id: string;
  @string() declare name: string;
  @string({ default: [], list: true }) declare postcodes: string[];
  @string() declare delivery_information?: string;
  @model(() => Pricing) declare pricing?: Pricing;

  $keepChanges(): void | false {
    super.$keepChanges();
    if (this.pricing) {
      this.pricing.$keepChanges();
    }
  }

  $getDataForCreate() {
    const data: Record<string, any> = this.$getAttributeChanges();
    data.name = this.name;
    if (this.pricing) {
      data.pricing = this.pricing.$toJson();
    }
    return data;
  }

  $getDataForUpdate() {
    const data: Record<string, any> = this.$getAttributeChanges();
    data.id = this.id;
    if (this.pricing) {
      data.pricing = this.pricing.$toJson();
    }
    return data;
  }
}
