import type { Attributes } from "@moirei/dobby";
import ProductVariant from "../ProductVariant";
import { updatesProductPricing } from "./common";
import { variantDisplayName } from "~/layers/admin/helpers/product";
import { omit } from "lodash";

export const upsertingVariant = (model: ProductVariant, data?: Attributes) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  updatesProductPricing(model, data);

  if (model.inventory && model.inventory.$isDeepDirty()) {
    data.inventory = {
      ...model.inventory.$getAttributeChanges(),
      inventoryLevels: model.inventory.inventoryLevels?.map((level) => ({
        available: level.available,
        location: {
          handle: level.location.handle,
        },
      })),
    };
  }

  if (
    data.name &&
    data.name ==
      variantDisplayName({
        option1: model.option1,
        option2: model.option2,
        option3: model.option3,
      })
  ) {
    // omit name if it hasn't changed
    return omit(data, ["name"]);
  }

  return data;
};

export const updatingVariantWithId = (model: ProductVariant) => {
  const data = upsertingVariant(model);
  data.id = model.id;
  return data;
};
