import { isUndefined, omitBy, upperFirst } from "lodash";

/**
 * Check if the (primitive) elements in arr1 are all in arr2.
 *
 * @param {unknown[]} arr1
 * @param {unknown[]} arr2
 * @returns {boolean}
 */
export const arrayAllIn = (arr1: unknown[], arr2: unknown[]): boolean => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  return arr1.every((item) => arr2.includes(item));
};

export const enumTitle = (value: string): string => {
  return upperFirst(String(value).toLowerCase().replace(/_/g, " "));
};

export const filterUndefined = <T>(o: T): T => omitBy<any>(o, isUndefined) as T;

/**
 * Ensure a variable is o plain object.
 */
export const castObject = (o: any) => {
  if (typeof o !== "object") return {};
  return o.toJSON ? o.toJSON() : Object.assign({}, o);
};

type RefAccess<T> = {
  set: { (v: T): void };
  get: { (): T };
};
export const bindRefs = <T>({ a, b }: { a: RefAccess<T>; b: RefAccess<T> }) => {
  const updatingA = ref(false);
  const updatingB = ref(false);

  const updateA = (v: T) => {
    if (updatingB.value) return;
    updatingA.value = true;
    nextTick(() => {
      a.set(v);
      nextTick(() => {
        updatingA.value = false;
      });
    });
  };

  const updateB = (v: T) => {
    if (updatingA.value) return;
    updatingB.value = true;
    nextTick(() => {
      b.set(v);
      nextTick(() => {
        updatingB.value = false;
      });
    });
  };

  watch(a.get, updateB, { deep: true });
  watch(b.get, updateA, { deep: true });

  return {
    updateA: () => updateA(b.get()),
    updateB: () => updateB(a.get()),
  };
};
