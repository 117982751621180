import gql from "graphql-tag";

export const metricsQuery = gql`
  query ($input: MetricsInput!) {
    wsMetrics(input: $input) {
      handle
      type
      labels
      meta
      datasets {
        label
        labels
        data
        value
        meta
      }
      growthRate {
        value
        trendUp
        unit {
          label
          prefix
        }
      }
      unit {
        label
        prefix
      }
    }
  }
`;
