import { defineNuxtPlugin } from "#app";
import { createVuetify, type ThemeDefinition } from "vuetify";
import DayJsAdapter from "@date-io/dayjs";
import { VTimePicker } from "vuetify/labs/VTimePicker";
import { VNumberInput } from "vuetify/labs/VNumberInput";
import colors from "vuetify/lib/util/colors.mjs";
import { aliases, md } from "vuetify/iconsets/md";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/styles";

const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: colors.indigo.lighten1,
    accent: "#e91e63",
    secondary: "#36394C",
    success: "#4CAF50",
    info: "#2166F3",
    warning: "#FB8C00",
    error: "#FF5252",
    // background: '#e6ebf1',
    background: "#f6f6f7",
  },
};

const darkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    primary: colors.indigo.base,
    secondary: "#36394C",
    background: "#36394c",
    "background-darken-1": "#2a3142",
    "background-darken-2": "#222736",
    "background-lighten-1": "#545978",
    "background-lighten-2": "#e6ebf1",
  },
};

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    defaults: {
      VBtn: {
        rounded: "lg",
        variant: "flat",
        size: "small",
      },
      VCard: {
        rounded: "lg",
      },
      VList: {
        density: "compact",
      },
      VListItem: {
        density: "compact",
      },
    },
    icons: {
      defaultSet: "md",
      aliases,
      sets: {
        md,
      },
    },
    theme: {
      defaultTheme: "light",
      themes: {
        light: lightTheme,
        dark: darkTheme,
      },
    },
    components: { VTimePicker, VNumberInput },
    // date: {
    //   adapter: DayJsAdapter,
    // },
  });
  nuxtApp.vueApp.use(vuetify);
});
