import { isObject, isString } from "lodash";
import {
  ChartType,
  MetricAggregateFunction,
  MetricInterval,
  MetricType,
  MetricsDataSourceType,
  type ChartTypeLike,
  type GenericMetricsDataSource,
  type MetricAggregateFunctionLike,
  type MetricIntervalLike,
  type MetricTypeLike,
  type MetricUnit,
  type MetricsDataSource,
  type MetricsDataSource_Db,
} from "./types";

export const normaliseGenericDataSource = (
  sources: GenericMetricsDataSource
): MetricsDataSource<string>[] => {
  const s: MetricsDataSource<string>[] = [];

  if (isObject(sources)) {
    s.push(
      ...Object.entries(sources).map(([key, entry]) => ({
        handle: key,
        ...entry,
      }))
    );
  } else {
    const x = Array.isArray(sources) ? sources : sources.trim().split(",");
    s.push(
      ...x.map((s) =>
        isString(s) ? { source: MetricsDataSourceType.Db, name: s } : s
      )
    );
  }

  return s;
};

export const normaliseChartType = (value: ChartTypeLike): ChartType => {
  return value.toLowerCase() as any;
};

export const normaliseMetricInterval = (
  value: MetricIntervalLike
): MetricInterval => {
  return value.toUpperCase() as any;
};

export const normaliseMetricType = (value: MetricTypeLike): MetricType => {
  return value.toUpperCase() as any;
};

export const normaliseMetricAggregateFunction = (
  value: MetricAggregateFunctionLike
): MetricAggregateFunction => {
  return value.toUpperCase() as any;
};

export const addUnit = (value: any, unit?: MetricUnit) => {
  if (!unit?.label) {
    return value;
  }

  if (unit.prefix) {
    return unit.label + value;
  }

  return value + unit.label;
};

export const isDbMetricsDataSource = <M extends string>(
  s: MetricsDataSource<M>
): s is MetricsDataSource_Db<M> => {
  return s.source == MetricsDataSourceType.Db;
};
