import { createHooks } from "hookable";
import type { StaScanStatus, Ticket } from "./types";

type StaHooks = {
  // loaded: { (payload: Required<MapSaveState>): void };
  scanned: { (status: StaScanStatus): void };
  "share:qrcode": { (): void };
  "view:map": { (): void };
  "view:ticket": { (ticket?: Ticket): void };
  exit: { (): void };
};

export const events = createHooks<StaHooks>();
