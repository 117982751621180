import { useApolloClient } from "@vue/apollo-composable";
import type {
  MetricResult,
  MetricsContext,
  MetricsDataSource,
  MetricsDataSourceInput,
  MetricsDataSource_Db,
} from "./types";
import { MetricType, MetricsDataSourceType } from "./types";
import { get, omit, sum, mean } from "lodash";
import { metricsQuery } from "./queries";
import {
  addUnit,
  normaliseMetricAggregateFunction,
  normaliseMetricInterval,
  normaliseMetricType,
} from "./utils";

const isDbSource = <T extends string>(
  s: MetricsDataSource<T>
): s is MetricsDataSource_Db<T> => s.source == MetricsDataSourceType.Db;

export const useMetrics = <M extends string>(source: {
  (): MetricsDataSourceInput<M>;
}) => {
  const { client } = useApolloClient();

  const defu: Partial<MetricResult<M>> = {
    labels: [],
    datasets: [],
  };
  const results = ref<MetricResult<M>[]>([]);
  const loading = ref(false);

  const fetch$ = async () => {
    const source_ = source();
    loading.value = true;

    const dbMetricsQuery = client
      .query({
        query: metricsQuery,
        variables: {
          input: {
            ...source_,
            metrics: source_.metrics
              .filter(isDbSource)
              .map((s) => omit(s, ["source"]))
              .map((s) => ({
                ...s,
                type: withValue(s.type, normaliseMetricType),
                aggregate: withValue(
                  s.aggregate,
                  normaliseMetricAggregateFunction
                ),
                interval: withValue(s.interval, normaliseMetricInterval),
              })),
          },
        },
      })
      .finally(() => {
        loading.value = false;
      });

    const [dbMetrics] = await Promise.all([dbMetricsQuery]);

    const r: MetricResult<M>[] = [];
    r.push(...(dbMetrics.data?.wsMetrics || []));

    results.value = r as any[];
  };

  const watchSource = once(() => {
    watchEffect(fetch$);
  });

  const getResult = (m: M, type?: MetricType) => {
    return (
      results.value.find((r) => r.handle == m && (!type || type == r.type)) ||
      defu
    );
  };

  const metric = (source: M) => computed(() => getResult(source));

  const trend = (source: M) => {
    return computed(() => getResult(source, MetricType.TREND));
  };

  const trendValue = <T>(
    source: M,
    options?: Partial<{
      method: "sum" | "avg";
      dataset: number;
      withUnit: boolean;
      formatPrice: boolean;
      formatDecimal: boolean;
    }>
  ) => {
    const dataset = options?.dataset || 0;
    const withUnit = !!options?.withUnit;

    return computed(() => {
      const result = getResult(source, MetricType.TREND);
      const set = get(result.datasets, dataset);
      const data = get(result.datasets, [dataset, "data"], []);
      let value = match(options?.method, {
        avg: () => mean(data),
        default: () => sum(data),
      });

      if (options?.formatPrice && withUnit && result.unit) {
        value = formatPrice(value, result.unit.label);
      } else {
        if (options?.formatDecimal) {
          value = formatNumber(value);
        }
        if (withUnit) {
          value = addUnit(value, result.unit);
        }
      }

      return {
        label: set?.label,
        value: value,
        unit: result.unit,
        growthRate: result.growthRate,
      };
    });
  };

  const value = <T>(
    source: M,
    options?: Partial<{
      dataset: number;
      default: T;
      withUnit: boolean;
    }>
  ) => {
    const dataset = options?.dataset || 0;
    const defu = options?.default || "—";
    const withUnit = !!options?.withUnit;

    return computed(() => {
      const result = getResult(source, MetricType.VALUE);
      const set = get(result.datasets, dataset);
      let value: any = set?.value ?? defu;

      if (withUnit) {
        value = addUnit(value, result.unit);
      }

      return {
        label: set?.label,
        value: value,
        unit: result.unit,
        growthRate: result.growthRate,
      };
    });
  };

  const datasets = (source: M) => {
    return computed(() => {
      const result = getResult(source);
      return result.datasets;
    });
  };

  const unit = (source: M) => {
    return computed(() => {
      const result = getResult(source);
      return result.unit;
    });
  };

  const growthRate = (source: M) => {
    return computed(() => {
      const result = getResult(source);
      return result.growthRate;
    });
  };

  const fetch = afterEach(fetch$, watchSource);

  return {
    loading,
    fetch,
    getResult,
    get getters() {
      return {
        metric,
        datasets,
        trend,
        trendValue,
        value,
        unit,
        growthRate,
      };
    },
  };
};

export const getMetricsDataFromSource = async <M extends string>(
  source: MetricsDataSourceInput<M>
): Promise<MetricResult<M>[]> => {
  return [];
};

export const provideMetricsContext = (ctx: MetricsContext) => {
  provide("__metrics__", ctx);
};

export const injectMetricsContext = (): MetricsContext => {
  const ctx = inject("__metrics__");
  if (!ctx) {
    throw new Error("[metrics] Context undefined");
  }
  return ctx as any;
};
