export enum PricingModel {
  STANDARD = 'standard',
  PACKAGE = 'package',
  GRADUATED = 'graduated',
  VOLUME = 'volume',
}

export enum PricingMeasurementUnit {
  QUANTITY = 'QUANTITY',
  WEIGHT = 'WEIGHT',
  VOLUME = 'VOLUME',
}
