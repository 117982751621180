import { defineNuxtPlugin } from "#app";
import { defineRule } from "vee-validate";
import {
  required,
  email,
  min,
  max,
  min_value,
  max_value,
  url,
  numeric,
  confirmed,
} from "@vee-validate/rules";
import { isNil, isObject, isString } from "lodash";
import dayjs from "dayjs";
import Pricing from "@moirei/complex-pricing";

export default defineNuxtPlugin(() => {
  defineRule("required", required);
  defineRule("email", email);
  defineRule("min", min);
  defineRule("max", max);
  defineRule("min_value", min_value);
  defineRule("max_value", max_value);
  defineRule("url", url);
  defineRule("numeric", numeric);
  defineRule("greater_than_zero", (value, _params, ctx) => {
    if (isNil(value) || Number(value) > 0) return true;
    return ctx.name + " must be greater than 0";
  });
  defineRule("positive_value", (value, _params, ctx) => {
    if (isNil(value) || Number(value) >= 0) return true;
    return ctx.name + " must be a positive value";
  });
  defineRule("confirmed", confirmed);
  defineRule("lower", (value, _params, ctx) => {
    if (isNil(value)) return true;
    const regex = new RegExp("(?=.*[a-z])");
    if (isString(value) && regex.test(value)) return true;
    return ctx.name + " must contain at least one lowercase letter";
  });
  defineRule("upper", (value, _params, ctx) => {
    if (isNil(value)) return true;
    const regex = new RegExp("(?=.*[A-Z])");
    if (isString(value) && regex.test(value)) return true;
    return ctx.name + " must contain at least one uppercase letter";
  });
  defineRule("digit", (value, _params, ctx) => {
    if (isNil(value)) return true;
    const regex = new RegExp("(?=.*[0-9])");
    if (isString(value) && regex.test(value)) return true;
    return ctx.name + " must contain at least one digit";
  });
  defineRule("special", (value, _params, ctx) => {
    if (isNil(value)) return true;
    const regex = new RegExp("(?=.*?[#?!@$%^&*-])");
    if (isString(value) && regex.test(value)) return true;
    return ctx.name + " must contain a special character";
  });
  defineRule("before", (value: any, params, ctx) => {
    if (isNil(value)) return true;
    const [limit, included] = params as [any, any];
    const tLimit = dayjs(limit);
    const tValue = dayjs(value);
    if (
      included
        ? tValue.isSame(tLimit) || tValue.isBefore(tLimit)
        : tValue.isBefore(tLimit)
    ) {
      return true;
    }
    return ctx.name + " must be before " + limit;
  });
  defineRule("after", (value: any, params, ctx) => {
    if (isNil(value)) return true;
    const [limit, included] = params as [any, any];
    const tLimit = dayjs(limit);
    const tValue = dayjs(value);
    if (
      included
        ? tValue.isSame(tLimit) || tValue.isAfter(tLimit)
        : tValue.isAfter(tLimit)
    ) {
      return true;
    }
    return ctx.name + " must be after " + limit;
  });
  defineRule("notIn", (value, params: any, ctx) => {
    if (isNil(value)) return true;
    const arr = Array.from(params);
    if (!arr.includes(value)) return true;
    return ctx.name + " must not be in " + arr.join(", ");
  });
  defineRule("in", (value, params: any, ctx) => {
    if (isNil(value)) return true;
    const arr = Array.from(params);
    if (arr.includes(value)) return true;
    return ctx.name + " must be in " + arr.join(", ");
  });
  defineRule("min_count", (value, params: any, ctx) => {
    if (isNil(value)) return true;
    const min = Number(params);
    const arr = wrap(value);
    if (arr.length >= min) return true;
    return ctx.name + " must be greater than " + min + " values";
  });
  defineRule("max_count", (value, params: any, ctx) => {
    if (isNil(value)) return true;
    const max = Number(params);
    const arr = wrap(value);
    if (arr.length <= max) return true;
    return ctx.name + " must be lesser than " + max + " values";
  });
  defineRule("pricing", (value, params: any, ctx) => {
    if (value) {
      const pricing = new Pricing(value);
      if (!pricing.isValid()) {
        return ctx.name + " must be a valid pricing";
      }
    }
    return true;
  });
  defineRule("address", (value, params: any, ctx) => {
    if (isNil(value)) return true;
    if (isString(value)) {
      return true;
    }
    if (isObject(value)) {
      // @ts-ignore
      if (value.street && value.province_code && value.country_code) {
        return true;
      }
    }

    return ctx.name + " must be a valid address";
  });
});
