import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import nav_45shortcut_45global from "/opt/build/repo/layers/admin/middleware/nav-shortcut.global.ts";
import _0_45workspace_45global from "/opt/build/repo/middleware/0.workspace.global.ts";
import _1_45valid_45access_45global from "/opt/build/repo/middleware/1.valid-access.global.ts";
import usage_45access_45global from "/opt/build/repo/middleware/usage-access.global.ts";
import auth from "/opt/build/repo/layers/admin/modules/auth/runtime/middleware/auth.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  nav_45shortcut_45global,
  _0_45workspace_45global,
  _1_45valid_45access_45global,
  usage_45access_45global,
  auth,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "select-plan": () => import("/opt/build/repo/middleware/select-plan.ts"),
  "redirect-login": () => import("/opt/build/repo/layers/admin/modules/auth/runtime/middleware/redirect-login.ts")
}