import BankAccount from "./BankAccount";
import BankCard from "./BankCard";
import Collection from "./Collection";
import Customer from "./Customer";
import Event, { EventType, EventVenue } from "./Event";
import Package from "./Package";
import Product from "./Product";
import ProductVariant from "./ProductVariant";
import ProductType from "./ProductType";
import Inventory, { InventoryLevel } from "./Inventory";
import Service, {
  ServiceBooking,
  ServiceGroup,
  ServiceSchedule,
} from "./Service";
import ShippingProfile from "./ShippingProfile";
import {
  Location,
  Folder,
  File,
  Attachment,
  Media,
  BioSite,
} from "@/layers/admin/models";
import { CheckoutLink } from "./Checkout";
import { Order } from "./Order";
import { DiscountCode } from "./DiscountCode";
import { EventDiscount } from "./EventDiscount";
import TeamMember from "./TeamMember";
import { Refund } from "./Refund";

export default [
  BankAccount,
  BankCard,
  BioSite,
  Attachment,
  CheckoutLink,
  Collection,
  Customer,
  DiscountCode,
  EventDiscount,
  File,
  Folder,
  Event,
  EventType,
  EventVenue,
  Order,
  Package,
  Product,
  ProductVariant,
  ProductType,
  Inventory,
  InventoryLevel,
  Location,
  Media,
  Refund,
  Service,
  ServiceBooking,
  ServiceGroup,
  ServiceSchedule,
  ShippingProfile,
  TeamMember,
];
