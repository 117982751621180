import type { PricingData as IPricing } from "@moirei/complex-pricing/build/types";
import { PricingMeasurementUnit } from "../models/enums";

export const pricingDefaults: IPricing = {
  model: "standard",
  tiers: [],
  // units: undefined,
  // unit_amount: undefined,
  data: {
    measurementType: PricingMeasurementUnit.QUANTITY,
  },
};
