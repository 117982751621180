import { boolean, float, id, integer, model, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import {
  Model,
  datetime,
  ProductAvailability,
  WeightUnit,
  Pricing as PricingModel,
  type IProductVariant,
} from "~/layers/admin/models";
import { cloneDeep } from "@apollo/client/utilities";
import { omitBy, isNil, filter } from "lodash";
import Product from "./Product";
import Inventory from "./Inventory";
import { upsertingVariant } from "./hooks/variant-hooks";
import ShippingProfile from "./ShippingProfile";
import { Media } from "../../layers/admin/models/dobby/Media";
import { pricingDefaults } from "~/layers/admin/helpers/defaults";
import {
  variantDisplayName,
  variantOptionHash,
} from "~/layers/admin/helpers/product";

export default class ProductVariant extends Model implements IProductVariant {
  static entity = "ProductVariant";
  static queryAttributes = ["id", "name"];

  constructor(attributes = {}) {
    super({
      pricing: cloneDeep(pricingDefaults),
      comparison_pricing: cloneDeep(pricingDefaults),
      whole_sale_pricing: cloneDeep(pricingDefaults),
      inventory: {},
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;
  @id() declare readonly id: string;
  @id() declare readonly gid: string;
  @string() declare name: string;
  @string() declare product_id: string;
  // @string() declare display_image?: string
  // @json() declare media?: Object
  @boolean() declare taxable?: boolean;
  @float() declare weight?: number;
  @string() declare weight_unit?: WeightUnit;
  @string() declare fulfillment_service?: string;
  @string() declare inventory_management?: string;
  @string() declare availability: ProductAvailability;
  @boolean({ default: false }) declare enable_comparison_pricing: boolean;
  @integer() declare booking_duration?: number;
  @float() declare booking_cancellation_fee?: number;
  @string() declare option1?: string;
  @string() declare option2?: string;
  @string() declare option3?: string;

  @model(() => PricingModel) declare pricing?: PricingModel;
  @model(() => PricingModel) declare comparison_pricing?: PricingModel;
  @model(() => PricingModel) declare whole_sale_pricing?: PricingModel;
  @float() declare cost_per_item?: number;
  @model(() => Product) declare product: Product;
  @model(() => Inventory) declare inventory: Inventory;
  @model(() => ShippingProfile) declare shippingProfile: ShippingProfile;
  @model(() => Media) declare image?: Media;

  get displayName(): string {
    return variantDisplayName(this);
  }

  $isVariant(): this is ProductVariant {
    return true;
  }

  $optionsHash(): string {
    return variantOptionHash(this);
  }

  $keepChanges(): void | false {
    super.$keepChanges();
    if (this.pricing) {
      this.pricing.$keepChanges();
    }
    if (this.comparison_pricing) {
      this.comparison_pricing.$keepChanges();
    }
    if (this.whole_sale_pricing) {
      this.whole_sale_pricing.$keepChanges();
    }
    if (this.inventory) {
      this.inventory.$keepChanges();
    }
  }

  static hooks() {
    return {
      $creating: upsertingVariant,
      $updating: upsertingVariant,
    };
  }
}
