<template>
  <v-text-field v-bind="inputProps" @click:clear="handleClear" readonly>
    <v-dialog
      activator="parent"
      :width="width"
      :min-width="minWidth"
      :max-width="maxWidth"
    >
      <template #default="{ isActive }">
        <v-card>
          <v-confirm-edit
            v-model="model"
            @cancel="isActive.value = false"
            @save="isActive.value = false"
            :ok-text="okText"
            :cancel-text="cancelText"
          >
            <template #default="{ model: proxyModel, actions }">
              <div class="relative">
                <div class="flex-align pa-4">
                  <div v-if="title" class="text-h6">{{ title }}</div>
                  <v-spacer />
                  <v-btn
                    @click="isActive.value = false"
                    size="35"
                    variant="text"
                    icon
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </div>
                <div
                  v-if="subtitle"
                  class="mb-4 px-4 max-w-full"
                  v-html="subtitle"
                ></div>
                <v-divider />
                <div class="flex justify-center" :class="{ 'py-2 px-4': pad }">
                  <component
                    v-model="proxyModel.value"
                    :is="cmp"
                    v-bind="cmpProps"
                  />
                </div>
                <v-divider />
                <div class="flex-align justify-end gap-2 py-2 px-4">
                  <component :is="actions" />
                </div>
              </div>
            </template>
          </v-confirm-edit>
        </v-card>
      </template>
    </v-dialog>
  </v-text-field>
</template>

<script lang="ts">
import { get, isString, omit, pick } from "lodash";
import { defineComponent, type Component, type PropType } from "vue";
import { VTextField } from "vuetify/components";

const extractProps = (cmp: Component): any => get(cmp, "props", {});

const localProps = {
  is: {
    type: [Object, String] as PropType<Component | string>,
    required: true,
  },
  targetProps: { type: Object },
  okText: { type: String },
  cancelText: { type: String },
  title: { type: String },
  subtitle: { type: String },
  width: { type: [String, Number], default: 350 },
  minWidth: { type: [String, Number] },
  maxWidth: { type: [String, Number] },
  modelValue: {},
  formatter: { type: Function as PropType<{ (v: any): any }> },
  pad: { type: Boolean, default: false },
};

const textFieldPropKeys = Object.keys(extractProps(VTextField));

export default defineComponent({
  props: {
    ...extractProps(VTextField),
    ...localProps,
  },
  setup(props) {
    const cmp = isString(props.is)
      ? resolveComponent(props.is)
      : markRaw(props.is);

    const cmpPropKeys: string[] = isString(cmp)
      ? []
      : Object.keys(extractProps(cmp));

    return {
      cmp,
      cmpPropKeys,
    };
  },
  computed: {
    model: {
      set(v: any) {
        this.$emit("update:modelValue", v);
      },
      get() {
        return this.modelValue;
      },
    },
    inputValue() {
      if (this.formatter) {
        return this.formatter(this.modelValue);
      }
      return this.modelValue;
    },
    cmpProps() {
      const p = {
        ...this.targetProps,
        ...pick(this.$props, this.cmpPropKeys),
      };

      return omit(p, ["modelValue", "okText", "cancelText"]);
    },
    inputProps() {
      const modelValue = this.formatter
        ? this.formatter(this.modelValue)
        : this.modelValue;

      return {
        ...pick(this.$props, textFieldPropKeys),
        modelValue,
      };
    },
  },
  methods: {
    handleClear() {
      this.model = undefined;
    },
  },
});
</script>
