import { boolean, id, model, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import { EventBookingTicket, EventTicket } from "./Event";
import { isNil, omitBy } from "lodash";
import {
  creatingEventDiscount,
  updatingEventDiscount,
} from "./hooks/event-discount-hooks";
import type { IEventDiscount } from "../interfaces/Event";

export class EventDiscount extends Model implements IEventDiscount {
  static override entity = "EventDiscount";
  static override primaryKey = "id";
  static override primaryKeys = ["id", "gid", "token"];

  constructor(attributes = {}) {
    super({
      tickets: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @datetime() declare readonly use_by: Dayjs;
  @id() declare readonly id: string;
  @id() declare readonly gid: string;
  @string() declare name: string;
  @string() declare email: string;
  @string() declare phone?: string;
  @string({ readonly: true }) declare readonly token: string;
  @string({ readonly: true }) declare readonly web_url: string;
  @boolean({ readonly: true }) declare readonly used: boolean;

  @model(() => EventTicket, { default: [], list: true })
  declare tickets: EventTicket[];

  @model(() => EventBookingTicket) declare usedBy?: EventBookingTicket[];

  get ticketsCount() {
    return this.tickets?.length || 0;
  }

  static override hooks() {
    return {
      $creating: creatingEventDiscount,
      $updating: updatingEventDiscount,
    };
  }
}
