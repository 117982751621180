import { boolean, id, json, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";
import { BioSiteStatus } from "~/layers/admin/private/bio-site/remote";
import type { BioSite as IBioSiteRemote } from "~/layers/admin/private/bio-site/remote";

export interface IBioSite extends IBioSiteRemote {
  gid: string;
  url: string;
}

export interface BioSiteFileUploadInput {
  key: string;
  file: Blob | File;
}

export interface BioSiteUpdateInput {
  data: string; // JSON
  uploads?: BioSiteFileUploadInput[];
}

export class BioSite extends Model implements IBioSite {
  static entity = "BioSite";
  static primaryKey = "handle";

  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @id() declare readonly id: string;
  @id() declare readonly gid: string;
  @string() declare readonly status: BioSiteStatus;
  @id() declare readonly handle: string;
  @string() declare readonly name: string;
  @string() declare readonly url: string;
  @string() declare workspace?: string;
  @boolean() declare verified: boolean;
  @json() declare data: any;
  @json() declare resources: any;

  public $isActive() {
    return [BioSiteStatus.ACTIVE, BioSiteStatus.UNPUBLISHED_CHANGES].includes(
      this.status
    );
  }

  public getTransfer(): IBioSite {
    return {
      gid: this.gid,
      url: this.url,
      handle: this.handle,
      workspace: this.workspace,
      status: this.status,
      verified: this.verified,
      data: {
        name: this.name,
        ...this.data,
      },
      resources: {
        ...this.resources,
      },
    };
  }
}
