import type { Attributes } from "@moirei/dobby";
import { EventVenue } from "../Event";

export const creatingEventVenue = (model: EventVenue, data: Attributes) => {
  if (model.address && model.address.$isDirty()) {
    data.address = model.address.$toJson();
  }
  return data;
};

export const updatingEventVenue = creatingEventVenue;
