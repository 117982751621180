import { get, has, set } from "lodash";
import { createHooks, type Hookable } from "hookable";
import { useMediaLibraryStore, type MediaLibraryStore } from "./store";
import type {
  Downloadable,
  IMediaAdapter,
  MediaDownloadOptions,
  MediaFile,
  MediaFolder,
  MediaLibraryConfig,
  MediaLibraryEvents,
  MediaCreateOptions,
  UploadedFile,
  BrowseData,
  BrowseFilter,
} from "./types";
import { error, isFolder, isFunction, logger } from "./utils";

export default class MediaLibrary implements IMediaAdapter {
  protected adapter: IMediaAdapter;
  protected $bus: Hookable<MediaLibraryEvents>;
  protected get store(): MediaLibraryStore {
    const key = "__store__";
    if (!has(this, key)) {
      const s = useMediaLibraryStore();
      set(this, key, s);
    }
    return get(this, key) as MediaLibraryStore;
  }

  constructor({ adapter }: MediaLibraryConfig) {
    this.adapter = isFunction(adapter) ? adapter() : adapter;
    this.$bus = createHooks();
  }

  /**
   * Get location
   * @returns {string}
   */
  public getLocation(): string {
    return this.store.location;
  }

  /**
   * Send a notification
   * @param location
   * @returns
   */
  public notify<E extends keyof MediaLibraryEvents>(
    event: E,
    ...data: Parameters<MediaLibraryEvents[E]>
  ) {
    this.$bus.callHook(event, ...data);
  }

  /**
   * Register an event listener
   * @param location
   * @returns
   */
  public on<E extends keyof MediaLibraryEvents>(
    event: E,
    callback: MediaLibraryEvents[E]
  ) {
    this.$bus.hook(event, callback as any);
  }

  /**
   * Register once off event listener
   * @param location
   * @returns
   */
  public once<E extends keyof MediaLibraryEvents>(
    event: E,
    callback: MediaLibraryEvents[E]
  ) {
    this.$bus.hookOnce(event, callback as any);
  }

  /**
   * Remove registered event listener
   * @param location
   * @returns
   */
  public off<E extends keyof MediaLibraryEvents>(
    event: E,
    callback: MediaLibraryEvents[E]
  ) {
    this.$bus.removeHook(event, callback as any);
  }

  /**
   * Return files and folders in location.
   *
   * @param {string} location
   * @param {BrowseFilter} filters
   */
  async browse(
    location: string,
    filters?: BrowseFilter,
    { updateStore = true, forceRefetch = false } = {}
  ): Promise<BrowseData> {
    const browseData: BrowseData = {
      data: [],
    };

    if (!forceRefetch && this.store.browse[location]) {
      browseData.data = this.store.getLocationFiles(location);
      browseData.paginate = this.store.getLocationPagination(location);
    } else {
      this.store.setBrowsing(true);

      const browseData = await this.adapter.browse(location, filters);
      browseData.data = browseData.data;
      browseData.paginate = browseData.paginate;

      this.store.setBrowseFiles(location, browseData.data);
      this.store.setBrowsePagination(location, browseData.paginate);
      this.store.setBrowsing(false);
    }

    if (updateStore) {
      this.store.location = location;
    }

    return browseData;
  }

  /**
   * File a file.
   *
   * @param {string} id ID or FQFN
   * @returns {Promise<MediaFile|undefined>}
   */
  async getFile(id: string): Promise<MediaFile | undefined> {
    const file = this.store.getFile(id);
    if (file && !isFolder(file)) return file;
    return this.adapter.getFile(id);
  }

  /**
   * File a folder.
   *
   * @param {string} id
   * @returns {Promise<MediaFolder|undefined>}
   */
  async getFolder(id: string): Promise<MediaFolder | undefined> {
    const file = this.store.getFile(id);
    if (file && isFolder(file)) return file;
    return this.adapter.getFolder(id);
  }

  /**
   * Doanload one file
   *
   * @param {MediaFile} file
   */
  async downloadOne(file: MediaFile): Promise<void> {
    if (!this.adapter.downloadableLink) {
      error("No move method exists");
    }

    this.notify("file:downloading", file);
    set(file, "loading", true);

    try {
      const downloadable = await this.adapter.downloadableLink(file);

      const a = document.createElement("a");
      a.setAttribute("href", downloadable.url);
      a.setAttribute("download", downloadable.filename || file.name);
      a.setAttribute("target", "_blank");
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.notify("file:download", file);
    } catch (error) {
      set(file, "loading", false);
      const errorMessage = "Failed to download file";
      logger.error(errorMessage);
      this.notify("error", {
        message: errorMessage,
        method: "downloadOne",
        error,
      });
    }

    set(file, "loading", false);
  }

  /**
   * Upload one file unto location
   *
   * @param {UploadedFile} file
   * @param {MediaCreateOptions} options
   */
  async uploadOne(
    file: UploadedFile,
    options: MediaCreateOptions = {}
  ): Promise<MediaFile> {
    if (options.location === undefined) {
      options.location = this.getLocation();
    }
    this.store.setUploading(true);
    this.notify("file:uploading", file);
    const upload = await this.adapter.uploadOne(file, options);
    this.notify("file:upload", upload);
    this.store.setUploading(false);
    this.store.addMediaFiles([upload], options.location);
    return upload;
  }

  /**
   * Upload many files unto location
   *
   * @param {UploadedFile[]} files
   * @param {string} location
   */
  async uploadMany(
    files: UploadedFile[],
    options: MediaCreateOptions = {}
  ): Promise<MediaFile[]> {
    if (options.location === undefined) {
      options.location = this.getLocation();
    }
    this.store.setUploading(true);
    files.forEach((file) => this.notify("file:uploading", file));
    const uploads = await this.adapter.uploadMany(files, options);
    uploads.forEach((upload) => this.notify("file:upload", upload));
    this.store.setUploading(false);
    this.store.addMediaFiles(uploads, options.location);
    return uploads;
  }

  /**
   * Create one folder unto location
   *
   * @param {MediaFolder|string} name
   * @param {string} location
   * @param {Omit<MediaFolder, "name">} options
   */
  async createOneFolder(
    name: string,
    options: MediaCreateOptions = {}
  ): Promise<MediaFolder> {
    if (options.location === undefined) {
      options.location = this.getLocation();
    }
    this.notify("folder:creating", {
      name,
      options,
    });
    this.store.setCreatingFolder(true);
    const folder = await this.adapter.createOneFolder(name, options);
    this.notify("folder:create", folder);
    this.store.addMediaFiles([folder], options.location);
    this.store.setCreatingFolder(false);
    // TODO catch errors
    return folder;
  }

  /**
   * Create many folders unto location
   *
   * @param {string[]} names
   * @param {string} location
   * @param {Omit<MediaFolder, "name">} options
   */
  async createManyFolders(
    names: string[],
    options: MediaCreateOptions = {}
  ): Promise<MediaFolder[]> {
    if (options.location === undefined) {
      options.location = this.getLocation();
    }
    this.store.setCreatingFolder(true);
    const folders = await this.adapter.createManyFolders(names, options);
    // TODO notify
    this.store.addMediaFiles(folders, options.location);
    this.store.setCreatingFolder(false);
    return folders;
  }

  /**
   * Update one file
   *
   * @param {MediaFile} file
   * @param {Partial<MediaFile>} data
   * @returns {Promise<MediaFile>}
   */
  async updateOne(
    file: MediaFile,
    data: Partial<MediaFile>
  ): Promise<MediaFile> {
    set(file, "loading", true);
    return new Promise((resolve, reject) => {
      this.adapter
        .updateOne(file, data)
        .then((data) => {
          this.store.updateFile(file.id, data);
          resolve(data);
        })
        .catch(reject)
        .finally(() => set(file, "loading", false));
    });
  }

  /**
   * Update many files
   *
   * @param {MediaFile[]} files
   * @param {Partial<MediaFile>} data
   * @returns {Promise<MediaFile[]>}
   */
  async updateMany(
    files: MediaFile[],
    data: Partial<MediaFile>
  ): Promise<MediaFile[]> {
    const updates: MediaFile[] = await Promise.all(
      files.map((file) => this.updateOne(file, data))
    );
    return updates;
  }

  /**
   * Move file to a different location
   *
   * @param {MediaFile} file
   * @param {string|MediaFolder} location
   * @returns {Promise<MediaFile>}
   */
  async move(
    file: MediaFile,
    location: string | MediaFolder
  ): Promise<MediaFile> {
    const move = this.adapter.move;
    if (!move) {
      error("No move method exists");
    }

    set(file, "loading", true);

    return new Promise((resolve, reject) => {
      move(file, location)
        .then((data) => {
          this.store.moveBrowse(file, location);
          resolve(data);
        })
        .catch(reject)
        .finally(() => set(file, "loading", false));
    });
  }

  /**
   * Get shareable link
   *
   * @param {MediaFile} file
   * @param {Record<string, any>} options
   * @returns {Promise<string>}
   */
  async shareableLink(
    file: MediaFile,
    options?: Record<string, any>
  ): Promise<string> {
    if (!this.adapter.shareableLink) {
      error("No shere link method exists");
    }

    return this.adapter.shareableLink(file, options);
  }

  /**
   * Get downloadable link
   *
   * @param {MediaFile} file
   * @param {MediaDownloadOptions} options
   * @returns {Promise<Downloadable>}
   */
  async downloadableLink(
    file: MediaFile,
    options?: MediaDownloadOptions
  ): Promise<Downloadable> {
    if (!this.adapter.downloadableLink) {
      error("No download link method exists");
    }

    return this.adapter.downloadableLink(file, options);
  }

  /**
   * Delete many file
   *
   * @param {MediaFile} file
   */
  async deleteOne(file: MediaFile): Promise<boolean> {
    this.store.setDeleting(true);
    this.notify("file:deleting", file);
    set(file, "loading", true);
    const ok = await this.adapter.deleteOne(file);
    set(file, "loading", false);
    if (ok) {
      this.notify("file:delete", file);
      this.store.removeMediaFiles([file]);
    }
    this.store.setDeleting(false);
    return ok;
  }

  /**
   * Delete many files
   *
   * @param {MediaFile[]} files
   */
  async deleteMany(files: MediaFile[]): Promise<MediaFile[]> {
    this.store.setDeleting(true);
    const deletes = await this.adapter.deleteMany(files);
    // TODO notify
    this.store.removeMediaFiles(files.filter((_file, i) => deletes[i]));
    this.store.setDeleting(false);
    return deletes;
  }

  /**
   * Check if library can move files
   * @returns {boolean}
   */
  public canMoveFiles(): boolean {
    return !!this.adapter.move;
  }

  /**
   * Check if library share file links
   * @returns {boolean}
   */
  public canGetShareableLinks(): boolean {
    return !!this.adapter.shareableLink;
  }

  /**
   * Check if library share file links
   * @returns {boolean}
   */
  public canGetDownloadableLinks(): boolean {
    return !!this.adapter.downloadableLink;
  }
}
