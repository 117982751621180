import type { ApolloClient } from "@apollo/client/core";
import { createHooks } from "hookable";
import { withoutHost } from "ufo";
import { Token } from "./Token";
import {
  AuthContextQuery,
  ForgotPasswordMutation,
  LoginMutation,
  LogoutMutation,
  MeQuery,
  RefreshLoginTokenMutation,
  RegisterMutation,
  ResendVerificationEmailMutation,
  SocialLoginMutation,
  UpdateForgottenPasswordMutation,
  UpdatePasswordMutation,
  VerifyEmailMutation,
} from "./queries";
import type {
  AuthPayload,
  AuthResponse,
  ForgotPasswordInput,
  LoginInput,
  NewPasswordWithCodeInput,
  RefreshTokenPayload,
  RegisterResponse,
  SocialLoginInput,
  UpdatePasswordInput,
  UserProfile,
  UserRegisterInput,
} from "../types/api";
import type { AuthContext } from "#orie";
import { ExpiredAuthSessionError } from "./errors";
import type { AuthHooks } from "../types/auth";
import type { IAuthStorage } from "./types";

interface AuthOptions {
  autoLogout: boolean;
  autoFetchAuthContext: boolean;
  rememberLogoutLocation: boolean;
  paths: {
    home: string;
    login: string;
  };
  token: {
    maxAge: number;
    required: boolean;
    tokenRequired: boolean;
    prefix: string;
    expirationPrefix: string;
  };
  refreshToken: {
    maxAge: number;
    required: boolean;
    tokenRequired: boolean;
    prefix: string;
    expirationPrefix: string;
  };
}

export default class Auth {
  private rememberLogoutLocation: boolean;
  private token: Token;
  private refreshToken: Token;
  private hooks = createHooks<AuthHooks>();

  private get apollo(): ApolloClient<any> {
    return this.apolloProvider();
  }

  constructor(
    protected apolloProvider: { (): ApolloClient<any> },
    protected storage: IAuthStorage,
    protected options: AuthOptions
  ) {
    this.rememberLogoutLocation = options.rememberLogoutLocation;
    this.token = new Token({
      type: "Bearer",
      name: "graphql",
      prefix: options.token.prefix,
      expirationPrefix: options.token.expirationPrefix,
      maxAge: options.token.maxAge,
      storage: this.storage,
    });
    this.refreshToken = new Token({
      name: "graphql",
      prefix: options.refreshToken.prefix,
      expirationPrefix: options.refreshToken.expirationPrefix,
      maxAge: options.refreshToken.maxAge,
      storage: this.storage,
    });
  }

  mounted() {
    const { tokenExpired, refreshTokenExpired } = this.check(true);
    if (refreshTokenExpired) {
      if (this.options.autoLogout) {
        this.reset();
      }
    } else if (tokenExpired) {
      this.reset();
    }
    this.fetchUserOnce();
    if (this.options.autoFetchAuthContext) {
      this.fetchAuthContext();
    }
  }

  public getToken() {
    return this.token.get();
  }

  public async login(credentials: LoginInput, { reset = true } = {}) {
    if (reset) {
      this.reset();
    }

    const p = this.apollo
      .mutate<{ login: AuthPayload }>({
        mutation: LoginMutation,
        variables: { data: credentials },
      })
      .then(({ data }) => Promise.resolve(data?.login));

    return this.authenticates(p);
  }

  public async refreshTokens() {
    if (!this.check().valid) {
      return Promise.resolve();
    }
    const refreshTokenStatus = this.refreshToken.status();
    if (refreshTokenStatus.expired()) {
      this.reset();
      throw new ExpiredAuthSessionError();
    }

    if (!this.options.refreshToken.tokenRequired) {
      this.hooks.callHook("logout");
    }

    return this.apollo
      .mutate<{ refreshToken: RefreshTokenPayload }>({
        mutation: RefreshLoginTokenMutation,
        variables: { data: { refresh_token: this.refreshToken.sync() } },
      })
      .then(({ data }) => Promise.resolve(data?.refreshToken))
      .then((response) => {
        if (response) {
          this.updateTokens(response, { isRefreshing: true });
        }
        return response;
      })
      .catch((error) => {
        this.hooks.callHook("error", error, "refreshToken");
        return Promise.reject(error);
      });
  }

  public updateTokens(
    response: AuthPayload | RefreshTokenPayload,
    { isRefreshing = false, updateOnRefresh = true } = {}
  ) {
    if (response.access_token) {
      this.token.set(response.access_token);
    }
    if (
      response.refresh_token &&
      (!isRefreshing || (isRefreshing && updateOnRefresh))
    ) {
      this.refreshToken.set(response.refresh_token);
    }
    if (response.access_token) {
      this.hooks.callHook("login", response.access_token);
    }
  }

  public async fetchUser() {
    if (!this.check().valid) {
      return Promise.resolve();
    }

    return this.apollo
      .query<{ me: UserProfile }>({ query: MeQuery, fetchPolicy: "no-cache" })
      .then(({ data }) => Promise.resolve(data.me))
      .then((data) => {
        if (!data) {
          const error = new Error(`[auth] user data response not resolved`);
          return Promise.reject(error);
        }
        this.setUser(data);
        return data;
      })
      .catch((error) => {
        this.hooks.callHook("error", error, "fetchUser");
        return Promise.reject(error);
      });
  }

  public async fetchAuthContext() {
    if (!this.check().valid) {
      return Promise.resolve();
    }

    return this.apollo
      .query<{ authContext: AuthContext }>({
        query: AuthContextQuery,
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => Promise.resolve(data.authContext))
      .then((data) => {
        if (!data) {
          const error = new Error(`[auth] user context response not resolved`);
          return Promise.reject(error);
        }
        if (data) {
          this.hooks.callHook("context", data);
          this.storage.setState("context", data);
        }
        return data;
      })
      .catch((error) => {
        this.hooks.callHook("error", error, "fetchAuthContext");
        return Promise.reject(error);
      });
  }

  async setUser(user: UserProfile | false) {
    if (user) {
      this.hooks.callHook("user", user);
    }

    this.storage.setState("user", user);

    let check = { valid: Boolean(user) };
    if (check.valid) {
      check = this.check();
    }

    this.storage.setState("loggedIn", check.valid);

    return user;
  }

  async logout() {
    await this.apollo
      .mutate({
        mutation: LogoutMutation,
      })
      .catch(() => {
        // Handle errors
      });

    this.reset();

    if (this.rememberLogoutLocation) {
      this.storage.setState("_dest", withoutHost(window.location.href));
    }

    this.hooks.callHook("logout");
    navigateTo(this.options.paths.login);
  }

  public forgotPassword(data: ForgotPasswordInput) {
    return this.apollo
      .mutate<{ forgotPassword: AuthResponse }>({
        mutation: ForgotPasswordMutation,
        variables: { data },
      })
      .then(({ data }) => Promise.resolve(data?.forgotPassword))
      .catch(() => {
        // Handle errors
      });
  }

  public updateForgottenPassword(data: NewPasswordWithCodeInput) {
    return this.apollo
      .mutate<{ updateForgottenPassword: AuthResponse }>({
        mutation: UpdateForgottenPasswordMutation,
        variables: { data },
      })
      .then(({ data }) => Promise.resolve(data?.updateForgottenPassword))
      .catch(() => {
        // Handle errors
      });
  }

  public socialLogin(data: SocialLoginInput) {
    const p = this.apollo
      .mutate<{ socialLogin: AuthPayload }>({
        mutation: SocialLoginMutation,
        variables: { data },
      })
      .then(({ data }) => Promise.resolve(data?.socialLogin));

    return this.authenticates(p);
  }

  public verifyEmail(code: string) {
    const p = this.apollo
      .mutate<{ verifyEmail: AuthPayload }>({
        mutation: VerifyEmailMutation,
        variables: {
          data: { code },
        },
      })
      .then(({ data }) => Promise.resolve(data?.verifyEmail));

    return this.authenticates(p);
  }

  public resendVerificationEmail(code: string) {
    return this.apollo
      .mutate<{ resendVerificationEmail: AuthResponse }>({
        mutation: ResendVerificationEmailMutation,
        variables: {
          data: { code },
        },
      })
      .then(({ data }) => Promise.resolve(data?.resendVerificationEmail));
  }

  public updatePassword(data: UpdatePasswordInput) {
    return this.apollo
      .mutate<{ updatePassword: AuthResponse }>({
        mutation: UpdatePasswordMutation,
        variables: {
          data,
        },
      })
      .then(({ data }) => Promise.resolve(data?.updatePassword));
  }

  public async register(data: UserRegisterInput) {
    const response = await this.apollo
      .mutate<{ register: RegisterResponse }>({
        mutation: RegisterMutation,
        variables: {
          data,
        },
      })
      .then(({ data }) => Promise.resolve(data?.register));

    if (response?.tokens) {
      this.setAuth(response.tokens);
    }

    return response?.status;
  }

  public reset() {
    this.setUser(false);
    this.token.reset();
    this.refreshToken.reset();
  }

  public fetchUserOnce() {
    if (!this.storage.getState("user")) {
      return this.fetchUser();
    }
    return Promise.resolve();
  }

  public setUserToken(token: string, refreshToken?: string) {
    this.token.set(token);
    if (refreshToken) {
      this.refreshToken.set(refreshToken);
    }
    return this.fetchUser();
  }

  public navigateToHome() {
    const url = this.storage.getState("_dest") || this.options.paths.home;
    this.storage.setState("_dest", undefined);
    navigateTo(url as string);
  }

  private async authenticates<T extends AuthPayload | undefined>(
    res: Promise<T>
  ) {
    const p = await res;

    if (p) {
      this.setAuth(p);
    }
    if (window.location.pathname == this.options.paths.login) {
      this.navigateToHome();
    }

    return p;
  }

  private async setAuth(auth: AuthPayload) {
    this.updateTokens(auth);
    if (auth.user) {
      await this.setUser(auth.user);
    }

    if (auth.context) {
      this.hooks.callHook("context", auth.context);
      this.storage.setState("context", auth.context);
    }
  }

  private check(checkStatus = false) {
    const response = {
      valid: false,
      tokenExpired: false,
      refreshTokenExpired: false,
      isRefreshable: true,
    };
    const token = this.token.sync();
    const refreshToken = this.refreshToken.sync();
    if (!token || !refreshToken) {
      return response;
    }
    if (!checkStatus) {
      response.valid = true;
      return response;
    }
    const tokenStatus = this.token.status();
    const refreshTokenStatus = this.refreshToken.status();
    if (refreshTokenStatus.expired()) {
      response.refreshTokenExpired = true;
      return response;
    }
    if (tokenStatus.expired()) {
      response.tokenExpired = true;
      return response;
    }
    response.valid = true;
    return response;
  }
}
