import { boolean, float, id, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import { Model, datetime } from "~/layers/admin/models/dobby";

export default class Address extends Model {
  static entity = "Address";
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at: Dayjs;

  @id() declare readonly id: string;
  @string() declare description?: string;
  @string() declare company?: string;
  @string() declare phone?: string;
  @string() declare label?: string;
  @string() declare first_name?: string;
  @string() declare last_name?: string;
  @string() declare street: string;
  @string() declare street_extra?: string;
  @string() declare city: string;
  @string() declare province_code?: string;
  @string() declare postal_code: string;
  @string() declare country_code: string;
  @float() declare latitude?: number;
  @float() declare longitude?: number;
  @boolean() declare is_public?: boolean;
  @boolean() declare is_primary?: boolean;
  @boolean() declare is_billing?: boolean;
  @boolean() declare is_shipping?: boolean;

  get country() {
    return this.country_code;
  }
  set country(value: string) {
    this.country_code = value;
  }

  get postcode() {
    return this.postal_code;
  }
  set postcode(value: string) {
    this.postal_code = value;
  }

  public $addressLine() {
    return stringifyAddress({
      street: this.street,
      street_extra: this.street_extra,
      city: this.city,
      country: this.country_code,
      postal_code: this.postal_code,
      province: this.province_code,
    });
  }
}
