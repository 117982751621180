export enum BioSiteStyleFontType {
  Roboto = 'roboto',
  Salsa = 'salsa',
  Playfair = 'playfair',
  Lato = 'lato',
  Lobster = 'lobster',
  // premium
  OpenSans = 'open-sans',
  Cinzel = 'cinzel',
  CinzelDecorative = 'cinzel-decorative',
  SpaceMono = 'space-mono',
  Comfortaa = 'comfortaa',
}

export interface BioSiteStyleFont {
  id: string
  font: string
}

export interface BioSiteStyleFonts {
  importUrl: string
  fonts: BioSiteStyleFont[]
}
