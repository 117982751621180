export enum InteractionType {
  SELECT = 'select',
  PAN = 'pan',

  // shapes
  FREE_DRAW = 'shape:free-draw',
  ARROW = 'shape:arrow',
  TEXT = 'text',
  SHAPE = 'shape:shape',
  DIAMOND = 'shape:diamond',
  LINE = 'shape:line',
  ELLIPSE = 'shape:ellipse',
  RECTANGLE = 'shape:rectangle',
  CIRCLE = 'shape:circle',
  TRIANGLE = 'shape:triangle',
  PENTAGON = 'shape:pentagon',
  HEXAGON = 'shape:hexagon',
  STAR = 'shape:star',
  RHOMBUS = 'shape:rhombus',

  // tables
  ROUND_TABLE = 'table:round',

  ROTATE = 'rotate',
  RESIZE = 'resize',
}

export enum EntityType {
  SECTION = 'section',
  TABLE = 'table',
  // CHAIR = 'chair',
  SHAPE = 'shape',
  TEXT = 'text',
  LINE = 'line',
}

export enum SectionAdmissionType {
  ASSIGNED = 'assigned',
  GENERAL = 'general',
}

export enum SectionSeatingType {
  STANDING = 'standing',
  SEATED = 'seated',
}

export enum TableStyle {
  ROUND = 'round',
  RECTANGLE = 'rectangle',
}

export enum ShapeStyle {
  RECTANGLE = 'rectangle',
  CIRCLE = 'circle',
  TRIANGLE = 'triangle',
  DIAMOND = 'diamond',
  PENTAGON = 'pentagon',
  HEXAGON = 'hexagon',
  STAR = 'star',
  RHOMBUS = 'rhombus',
  POLYGON = 'polygon',
  LINE_STRING = 'line-string',
  ARROW = 'arrow',
  // LINE = 'line',
  ELLIPSE = 'ellipse',
  // OCTAGON = 'octagon',
  // TRAPEZOID = 'trapezoid',
}

export enum TextSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  EXTRA_LARGE = 'xl',
  LARGEST = 'xxl',
}

export enum Shortcuts {
  ARROW = 'A',
  RHOMBUS = 'B',
  DIAMOND = 'D',
  PENTAGON = 'G',
  PAN = 'H',
  TRIANGLE = 'I',
  LINE = 'L',
  CIRCLE = 'O',
  PEN = 'P',
  LOCK = 'Q',
  RECTANGLE = 'R',
  STAR = 'S',
  ROUND_TABLE = 'T',
  SELECT = 'V',
  HEXAGON = 'X',
  UNDO = 'Ctrl+Z',
  ELLIPSE = 'Ctrl+O',
  TEXT = 'Ctrl+T',
  REDO = 'Ctrl+Y',

  // editing these here does not change how they're registered
  COPY = 'Ctrl+C',
  CUT = 'Ctrl+X',
  PASTE = 'Ctrl+V',
  DELETE = 'delete',
}
