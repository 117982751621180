import { isString } from "lodash";
import { File, Folder, Media } from "../../../models/dobby/Media";
import type {
  BrowseData,
  BrowseFilter,
  Downloadable,
  IMediaAdapter,
  MediaCreateOptions,
  MediaDownloadOptions,
  MediaFile,
  MediaFolder,
  MediaShareOptions,
  UploadedFile,
} from "./types";
import { isFolder } from "./utils";

export default class DobbyAdapter implements IMediaAdapter {
  /**
   * Get a file.
   *
   * @param {string} id ID or FQFN
   * @returns {Promise<MediaFile|undefined>}
   */
  async getFile(id: string): Promise<MediaFile | undefined> {
    return File.findOrFail({ id });
  }

  /**
   * Get a folder.
   *
   * @param {string} id
   * @returns {Promise<MediaFolder|undefined>}
   */
  async getFolder(id: string): Promise<MediaFolder | undefined> {
    return Folder.findOrFail({ id }) as any;
  }

  /**
   * Return files and folders in location.
   *
   * @param {string} location
   * @param {BrowseFilter} filters
   * @return {BrowseData}
   */
  public async browse(
    location: string,
    filters?: BrowseFilter
  ): Promise<BrowseData> {
    return Media.browse(location, filters);
  }

  /**
   * Upload one file unto location
   *
   * @param {UploadedFile} file
   * @param {MediaCreateOptions} options
   * @returns {Promise<MediaFile>}
   */
  public async uploadOne(
    file: UploadedFile,
    options?: MediaCreateOptions
  ): Promise<MediaFile> {
    return File.upload(file, options);
  }

  /**
   * Upload many files unto location
   *
   * @param {UploadedFile[]} files
   * @param {MediaCreateOptions} options
   * @returns {Promise<MediaFile[]>}
   */
  async uploadMany(
    files: UploadedFile[],
    options?: MediaCreateOptions
  ): Promise<MediaFile[]> {
    const items: MediaFile[] = await Promise.all(
      files.map((file) => this.uploadOne(file, options))
    );
    return items;
  }

  /**
   * Create one folder unto location
   *
   * @param {string} name
   * @param {MediaCreateOptions} options
   * @returns {Promise<MediaFolder>}
   */
  public async createOneFolder(
    name: string,
    options?: MediaCreateOptions
  ): Promise<MediaFolder> {
    return Folder.create({ name, location, ...options }) as any;
  }

  /**
   * Create many folders unto location
   *
   * @param {string[]} names
   * @param {MediaCreateOptions} options
   * @returns {Promise<MediaFolder[]>}
   */
  public async createManyFolders(
    names: string[],
    options?: MediaCreateOptions
  ): Promise<MediaFolder[]> {
    const folders: MediaFolder[] = await Promise.all(
      names.map((name) => this.createOneFolder(name, options))
    );
    return folders;
  }

  /**
   * Update one file
   *
   * @param {MediaFile} file
   * @param {Partial<MediaFile>} data
   * @returns {Promise<MediaFile>}
   */
  async updateOne(
    file: MediaFile,
    data: Partial<MediaFile>
  ): Promise<MediaFile> {
    if (isFolder(file)) {
      return Folder.update({ id: file.id }, data);
    }

    return File.update({ id: file.id }, data);
  }

  /**
   * Update many files
   *
   * @param {MediaFile[]} files
   * @param {Partial<MediaFile>} data
   * @returns {Promise<MediaFile[]>}
   */
  async updateMany(
    files: MediaFile[],
    data: Partial<MediaFile>
  ): Promise<MediaFile[]> {
    const updates: MediaFile[] = await Promise.all(
      files.map((file) => this.updateOne(file, data))
    );
    return updates;
  }

  /**
   * Move file to a different location
   *
   * @param {MediaFile} file
   * @param {string|MediaFolder} location
   * @returns {Promise<MediaFile>}
   */
  async move(
    file: MediaFile,
    location: string | MediaFolder
  ): Promise<MediaFile> {
    location = isString(location) ? location : location.id;
    if (isFolder(file)) {
      return Folder.move(file.id, location);
    }

    return File.move(file.id, location);
  }

  /**
   * Get shareable link
   *
   * @param {MediaFile} file
   * @param {Record<string, any>} options
   * @returns {Promise<string>}
   */
  async shareableLink(
    file: MediaFile,
    options?: MediaShareOptions
  ): Promise<string> {
    if (isFolder(file)) {
      return Folder.share(file.id, options);
    }

    return File.share(file.id, options);
  }

  /**
   * Get downloadable link
   *
   * @param {MediaFile} file
   * @param {MediaDownloadOptions} options
   * @returns {Promise<Downloadable>}
   */
  async downloadableLink(
    file: MediaFile,
    options?: MediaDownloadOptions
  ): Promise<Downloadable> {
    if (isFolder(file)) {
      return Folder.downloadableLink(file.id, options);
    }

    return File.downloadableLink(file.id, options);
  }

  /**
   * Delete many file
   *
   * @param {MediaFile} file
   * @param {boolean} force
   * @return {boolean}
   */
  public async deleteOne(file: MediaFile, force?: boolean): Promise<boolean> {
    let media: File | Folder;
    if (isFolder(file)) {
      media = await Folder.delete({ id: file.id });
    } else {
      media = await File.delete({ id: file.id });
    }

    return !!media;
  }

  /**
   * Delete many files
   *
   * @param {MediaFile[]} files
   * @param {boolean} force
   * @returns {Promise<MediaFile[]>}
   */
  public async deleteMany(
    files: MediaFile[],
    force?: boolean
  ): Promise<MediaFile[]> {
    const deletes: boolean[] = await Promise.all(
      files.map((file) => this.deleteOne(file, force))
    );
    return files.filter((_, i) => deletes[i]);
  }
}
