export enum ShippingRateTransit {
  STANDARD = 'standard',
  EXPRESS = 'express',
  STANDARD_INTERNATIONAL = 'standard_international',
  CUSTOM = 'custom',
}

export enum ShippingRateConditionType {
  // Based on item weight. In KG.
  WEIGHT = 'WEIGHT',

  // Based on item price
  ITEM = 'ITEM',

  // Based on order price
  ORDER = 'ORDER',
}

export enum ShippingZoneRateType {
  MANUAL = 'MANUAL',
  CARRIER = 'CARRIER',
}
