import { float, id, integer, model, string } from "@moirei/dobby";
import type { Dayjs } from "dayjs";
import type {
  RefundReason,
  RefundRestockType,
  RefundStatus,
} from "~/layers/admin/models";
import { datetime, Model } from "~/layers/admin/models/dobby";
import TeamMember from "./TeamMember";

export class Refund extends Model {
  static override entity = "Refund";

  @id() declare readonly id: string;
  @id() declare readonly gid: string;
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @datetime() declare processed_at?: Dayjs;
  @string() declare status: RefundStatus;
  @float() declare amount: number;
  @string() declare reason: RefundReason;
  @float() declare note?: string;

  @model(() => RefundLine, { default: [], list: true })
  declare lines: RefundLine[];
  @model(() => TeamMember) declare readonly issuer: TeamMember;
}

export class RefundLine extends Model {
  static override entity = "RefundLine";

  @id() declare readonly id: string;
  @datetime() declare readonly created_at: Dayjs;
  @datetime() declare readonly updated_at?: Dayjs;
  @id() declare refund_id: string;
  @id() declare line_item_id?: string;
  @integer() declare quantity: number;
  @string() declare restock_type: RefundRestockType;
}
