import gql from "graphql-tag";
import { cloneDeep, get, isString } from "lodash";
import { events } from "./events";
import type { EventSeatingState, MapSaveState } from "./store";
import type {
  CopiedEntity,
  Entity,
  EntityLike,
  InteractionType,
} from "./types";
import { useToast } from "vue-toastification";

const FetchQuery = gql`
  query EventSeating($event: String!, $occurrence: ID!) {
    wsEventMap(event: $event, occurrence: $occurrence) {
      name
      event
      map
      sections {
        id
        name
      }
    }
  }
`;

const SaveQuery = gql`
  mutation ($event: String!, $occurrence: ID!, $data: Mixed!) {
    wsEventMap(event: $event, occurrence: $occurrence, data: $data)
  }
`;

type EventMapPayload = {
  name?: string;
  event: string;
  map: MapSaveState;
  sections: { id: string; name: string }[];
};

export const useEventSeatingApi = () => {
  const fetching = ref(false);
  const saving = ref(false);
  const eventHandle = ref<string>();
  const occurrenceId = ref<string>();

  const apollo = useApollo();
  const toast = useToast();

  const fetch = async (event: string, occurrence: string) => {
    fetching.value = true;
    eventHandle.value = event;
    occurrenceId.value = occurrence;

    const result = await apollo.query<{ wsEventMap: EventMapPayload }>({
      query: FetchQuery,
      variables: {
        event,
        occurrence,
      },
    });

    fetching.value = false;

    return result?.data?.wsEventMap;
  };

  const save = async (data: MapSaveState) => {
    saving.value = true;

    const result = await apollo
      .mutate({
        mutation: SaveQuery,
        variables: {
          event: eventHandle.value,
          occurrence: occurrenceId.value,
          data,
        },
      })
      .catch(() => {
        toast.error("Error saving event map");
      });

    saving.value = false;

    return !!get(result?.data, "wsEventMap");
  };

  return {
    fetching,
    saving,
    fetch,
    save,
  };
};

export const createMutations = (state: EventSeatingState) => {
  return {
    load: (data: MapSaveState) => {
      const entities: Entity[] = get(data, "entities", []);
      const mappedEntities = entities.reduce(
        (acc, entity) => ((acc[entity.id] = entity), acc),
        {} as EventSeatingState["entities"]
      );
      state.entities = mappedEntities;
      state.originalEntities = cloneDeep(mappedEntities);
      state.viewport = {
        ...state.viewport,
        ...get(data, "viewport", {}),
      };

      const payload = cloneDeep({ entities, viewport: state.viewport });
      events.callHook("loaded", payload);
    },
    getSaveDate: (): MapSaveState => {
      const entities: Entity[] = Object.values(state.entities);
      return cloneDeep({ entities, viewport: state.viewport });
    },
    keepChanges: () => {
      state.originalEntities = cloneDeep(state.entities);
    },
    discardChanges: () => {
      state.entities = cloneDeep(state.originalEntities);
      events.callHook("map-reload");
    },
    clearMap: () => {
      state.entities = {};
      events.callHook("map-cleared");
    },
    addEntity: (entity: Entity) => {
      state.entities = {
        ...state.entities,
        [entity.id]: entity,
      };
      events.callHook("added", entity);
    },
    removeEntity: (entities: EntityLike[]) => {
      const newEntities = cloneDeep(state.entities);
      const removed: Entity[] = [];

      entities.forEach((entity) => {
        const id = isString(entity) ? entity : entity.id;
        const value = cloneDeep(newEntities[id]);
        removed.push(value);
        delete newEntities[id];
      });
      state.entities = newEntities;

      events.callHook("removed", removed);
    },
    updateEntity: (entity: EntityLike, data: Partial<Entity>) => {
      const id = isString(entity) ? entity : entity.id;
      entity = {
        ...state.entities[id],
        ...data,
      };
      state.entities = {
        ...state.entities,
        [id]: entity,
      };
      events.callHook("updated", entity);
    },
    selectEntity: (entities: Entity[]) => {
      const ids = (entities as EntityLike[])
        .map((entity) => (isString(entity) ? entity : entity.id))
        .filter((id) => !!state.entities[id]);

      if (ids.length) {
        const selected = ids.map((id) => state.entities[id]);
        state.controls.selection = ids;
        events.callHook("selected", selected);
      }
    },
    deselectEntity: () => {
      state.controls.selection = [];
      events.callHook("deselected");
    },
    setInteraction: (interaction: InteractionType) => {
      state.controls.interaction = interaction;
      events.callHook("deselected");
    },
    updateViewport: (viewport: Partial<EventSeatingState["viewport"]>) => {
      state.viewport = {
        ...state.viewport,
        ...viewport,
      };
    },
    copyEntity: ({ entities, cutting }: CopiedEntity<EntityLike>) => {
      const entries = entities
        .map((entity) => (isString(entity) ? entity : entity.id))
        .map((id: string) => state.entities[id]);

      state.controls.copy.entities = entries;
      state.controls.copy.cutting = cutting;
    },
    updateControls: (controls: Partial<EventSeatingState["controls"]>) => {
      state.controls = {
        ...state.controls,
        ...controls,
      };
    },
  };
};
