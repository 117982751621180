import { min } from "lodash";

export const isNumeric = (value: any): boolean => {
  return !isNaN(value);
};

export const countDecimals = (value: number) => {
  if (value % 1 != 0) return value.toString().split(".")[1].length;
  return 0;
};

export const roundToMost = (value: number, decimals = 2) => {
  const d = min([countDecimals(value), decimals]);
  return value.toFixed(d);
};

export const formatVolumeAmount = (value: number) => {
  if (value) {
    if (value >= 1e6) {
      const v = roundToMost(value / 1e6);
      return `${v}M`;
    }
    if (value >= 1e3) {
      const v = roundToMost(value / 1e3);
      return `${v}k`;
    }
    return String(value);
  }

  return "0";
};
