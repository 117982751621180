import type { Attributes } from "@moirei/dobby";
import { omit } from "lodash";
import { EventDiscount } from "../EventDiscount";

export const creatingEventDiscount = (
  model: EventDiscount,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  if (model.$hasCustomAttribute("occurrence")) {
    data.event_occurrence = model.$getCustomAttribute("occurrence");
  }

  if (model.$hasCustomAttribute("send_email")) {
    data.send_email = model.$getCustomAttribute("send_email");
  }

  if (model.$hasCustomAttribute("send_text")) {
    data.send_text = model.$getCustomAttribute("send_text");
  }

  if (model.tickets && !data.tickets) {
    // ensure not a collection
    data.tickets = Array.from(
      model.tickets.map((ticket) => ({
        id: ticket.gid,
        quantity: 1,
      }))
    );
  }

  return omit(data, ["id"]);
};

export const updatingEventDiscount = (
  model: EventDiscount,
  data?: Attributes
) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }
  data[model.$getKeyName()] = model.$getKey();

  return data;
};
