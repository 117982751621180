import { useApolloClient } from "@vue/apollo-composable";
import { useStorage } from "@vueuse/core";
import type { ICurrency } from "~/layers/admin/models";

export const useWorkspace = () => {
  const { context, fetchAuthContext } = useAuth();
  const current = useStorage<string | undefined>("ws", undefined);

  const workspace = computed(() => context.value?.workspace);

  const currency = computed((): ICurrency | undefined => {
    const code = context.value?.workspace?.currency;
    if (code) {
      return {
        code,
        symbol: currencySymbol(code)!,
      };
    }
  });

  const workspaces = computed(() => context.value?.workspaces || []);

  const switchTo = async (code: string) => {
    const { loading } = useLoading();
    const apollo = useApolloClient();
    const apolloClient = apollo.resolveClient();

    loading(true);

    setWorkspace(code); // hold state of this switch

    await wait(2); // wait for storage to be updated

    await fetchAuthContext(); // fetch new workspace and update store
    apolloClient.clearStore(); // reset apollo cache without refetching active queries

    window.location.href = "/"; // reload window
  };

  const isWorkspace = (code: string | undefined) => {
    const wsCodes = context.value?.workspaces?.map((ws) => ws.code) || [];
    return code && wsCodes.includes(code);
  };

  const setWorkspace = (code: string | undefined) => {
    if (isWorkspace(code)) {
      current.value = code;
    }
  };

  const getWorkspace = (): string | undefined => {
    if (isWorkspace(current.value)) {
      return current.value;
    }
    // return context.value?.workspace?.code;
  };

  return {
    currency,
    workspace,
    workspaces,
    switchTo,
    setWorkspace,
    getWorkspace,
  };
};
