import { defineNuxtPlugin } from "#app";
import { Client } from "@moirei/dobby";
import WorkspaceAdapter from "~/models/dobby/WorkspaceAdapter";
import models from "~/models/dobby";
import { useApolloClient } from "@vue/apollo-composable";
import type { ApolloClient } from "@apollo/client";

export default defineNuxtPlugin(() => {
  const apolloProvider = once((): ApolloClient<any> => {
    const apollo = useApolloClient();
    return apollo.resolveClient();
  });

  const client = new Client({
    graphQlClient: apolloProvider,
    adapter: new WorkspaceAdapter(),
  });

  client.register(models);
});
